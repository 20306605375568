import React, { useState, useMemo } from "react";
import { Card, message, Tooltip } from "antd";
import { API } from "aws-amplify";
import { XIcon, TickIcon } from "../../../../../assets/icons";
import LoaderComponent from "../../../../commonComponents/LoadableComp/LoadableComp";
import dayjsTZ from "../../../../../utils/dayjs";
import { useAuth } from "../../../../../authentication/authHelpers";
import { notificationUserToAdmin } from "../../../../../utils/sendNotification";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { LogsIcon } from "../../../../Documentation/View/assets";
import "./FestatZyrtare.scss";
import Row from "./Row";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import SettingsFooter from "../../../dynamic components/SettingsFooter/SettingsFooter";
import ActivityModal from "../../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import { updateKeylogStructure } from "./updateKeylogStructure";
import DetailedFestaModal from "../DetailedFestaModal/DetailedFestaModal";
import { useMediaQuery } from "react-responsive";
import MobileRow from "./MobileRow";

const HOLIDAYS_FIELD_ID = "a3a610f9-5a60-40ec-8a1d-d94ffbb10054";

function FestatZyrtare() {
  const { user } = useAuth();

  const { programFields } = useSelector((state) => state.programFields);
  const { users, isDarkMode } = useSelector((state) => state.usersList);

  const [spin, setSpin] = useState(false);
  const [festat, setFestat] = useState([]);
  const [changes, setChanges] = useState({});
  const [singleFesta, setSingleFesta] = useState({});
  const [openLogs, setOpenLogs] = useState(false);
  const [defaultFestat, setDefaultFestat] = useState([]);

  const initialFestat = useMemo(() => {
    const idx = (programFields || []).findIndex(
      (el) => el?.fieldId === HOLIDAYS_FIELD_ID
    );
    if (idx === -1) {
      return {};
    }

    const festat = programFields[idx];
    const toSet = (festat?.fieldOptions || []).sort(
      (a, b) =>
        dayjsTZ(a?.date, "DD/MM/YYYY").valueOf() -
        dayjsTZ(b?.date, "DD/MM/YYYY").valueOf()
    );

    setFestat(toSet);
    setDefaultFestat(toSet);

    return festat;
  }, [programFields]);

  const getChanges = (array, idx) => {
    const oldHoliday = defaultFestat?.[idx];
    const newHoliday = array?.[idx];

    if (oldHoliday?.effectiveDate !== newHoliday?.effectiveDate) {
      setChanges((prev) => ({ ...prev, [newHoliday?.id]: { ...newHoliday } }));
    } else {
      setChanges((prev) =>
        Object.fromEntries(
          Object.entries(prev || {}).filter(([id, _]) => id !== newHoliday?.id)
        )
      );
    }
  };

  //region UPDATE EFFECTIVE
  const updateEffectiveDate = (idx, value) => {
    if (idx > -1) {
      let newFestat = [...festat];

      let keylog = {
        id: uuidv4(),
        activity: "Ndryshuar",
        author: user?.given_name + " " + user?.family_name,
        changeDate: Date.now(),
        field: "Data Efektive",
        oldValue: initialFestat?.fieldOptions?.[idx].effectiveDate,
        newValue: value,
        userSub: user?.userSub,
      };

      const dayOfWeek = dayjsTZ(
        initialFestat?.fieldOptions?.[idx]?.date,
        "DD/MM/YYYY"
      ).get("day");
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

      newFestat[idx] = {
        ...newFestat[idx],
        effectiveDate: value,
        icon: isWeekend ? "Kolltuku.svg" : "Calendar.svg",
        color: isWeekend ? "#ea3942" : "#18aa73",
        logs: [...(initialFestat?.fieldOptions?.[idx]?.logs || []), keylog],
      };

      setFestat(updateKeylogStructure(newFestat));
      getChanges(newFestat, idx);
    }
  };

  //region ONSAVE
  const onSave = async () => {
    setSpin(true);
    let notificationBody =
      user?.given_name +
      " " +
      user?.family_name +
      " ndryshoi datën efektive për";

    Object.entries(changes || {}).forEach(([_, obj], idx) => {
      const text = `${idx > 0 ? "," : ""} ${obj?.title} në ${dayjsTZ(
        obj?.effectiveDate
      ).format("DD/MM/YYYY")}`;

      notificationBody = notificationBody + text;
    });

    await API.put("programFields", `/programFields/${HOLIDAYS_FIELD_ID}`, {
      body: { fieldOptions: festat },
    })
      .then(async () => {
        setChanges({});
        message.success({ key: "onSave", content: "Te dhënat u ruajtën" });
        await notificationUserToAdmin(
          {
            notificationTitle: "Ndryshim i datës efektive",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationPriority: true,
            notificationCategory: "Info",
            notificationBody,
            isAnnouncement: false,
            notificationUrl: "/konfigurimet/politikat/festave/zyrtare",
            notificationFirstName: user?.given_name,
            notificationLastName: user?.family_name,
          },
          users?.allUsers?.Items
        );
      })
      .catch((err) => {
        console.log(err);
        message.error({ key: "onSave", content: "Ndodhi një gabim" });
      })
      .finally(() => setSpin(false));
  };

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  //region MEMOS
  const keylog = useMemo(() => {
    return (festat || [])
      .map((el) => el?.logs)
      .filter(Boolean)
      .flat(Infinity)
      .sort((a, b) => b?.changeDate - a?.changeDate);
  }, [festat]);

  const tableHeaders = {
    dayOfWeek: "Dita e javës",
    date: "Data Zyrtare",
    title: "Titulli",
    effectiveDate: "Data efektive",
    ...(!isMobileView ? { extra: "" } : {}),
  };

  const rows = useMemo(() => {
    const comp = (props) =>
      isMobileView ? (
        <MobileRow {...props} tableHeaders={tableHeaders} />
      ) : (
        <Row {...props} />
      );
    return (
      <div className="tableRows">
        {(festat || []).map((row, idx) =>
          comp({
            ...{
              row,
              onDatePicker: (val) =>
                updateEffectiveDate(idx, dayjsTZ(val).valueOf()),
              onClick: () => setSingleFesta(row),
            },
          })
        )}
      </div>
    );
  }, [isMobileView, festat]);

  //region RETURN
  return (
    <LoaderComponent loading={festat?.length > 0 ? false : true}>
      <div className="globalPageWrapper">
        <div className={`politikatFestaveZyrtare ${isDarkMode ? "dark" : ""}`}>
          <Card
            className={`festaCard ${isDarkMode ? "dark" : ""}`}
            title={`Kalendari i festave zyrtare për vitin ${dayjsTZ().format(
              "YYYY"
            )}`}
            extra={
              <Tooltip title={"Shfaq historikun"}>
                <LogsIcon
                  width={24}
                  height={24}
                  onClick={() => setOpenLogs(true)}
                />
              </Tooltip>
            }
          >
            <div className="cardContent">
              {!isMobileView ? (
                <div
                  className="tableHeaders"
                  style={{
                    // gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr)`,
                    gridTemplateColumns: "1fr 1fr 4fr 2fr 1fr",
                  }}
                >
                  {Object.values(tableHeaders).map((el) => (
                    <h3>{el}</h3>
                  ))}
                </div>
              ) : null}
              {rows}
            </div>
          </Card>
        </div>
        <SettingsFooter
          visible={!!Object.keys(changes || {}).length}
          children={[
            <MondayButton
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon height={10} width={10} />,
                onClick: () => {
                  setFestat(defaultFestat);
                  setChanges({});
                },
              }}
            >
              Discard Changes
            </MondayButton>,
            <MondayButton
              {...{
                className: "mondayButtonGreen",
                spin,
                Icon: <TickIcon height={17} width={17} />,
                onClick: onSave,
              }}
            >
              Save Changes
            </MondayButton>,
          ]}
        />
      </div>
      {!!Object.keys(singleFesta || {}).length ? (
        <DetailedFestaModal
          {...{
            singleFesta,
            initialFestat,
            setSingleFesta,
          }}
        />
      ) : null}
      {openLogs ? (
        <ActivityModal keylog={keylog} setIsModalVisible={setOpenLogs} />
      ) : null}
    </LoaderComponent>
  );
}

export default FestatZyrtare;
