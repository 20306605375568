import { useSelector } from "react-redux";
import { Close, Tick } from "../../assets/index";
import "./FieldOption.scss";

const FieldOption = ({ children, className = "", added = false, onClick }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <div
      className={`docFieldOption ${
        isDarkMode ? "dark" : ""
      } ${className}`.trim()}
    >
      <div className="fieldOptionLabel">{children}</div>
      <span
        className={`fieldOptionButton ${!!added ? "added" : "notAdded"}`}
        onClick={onClick}
      >
        {!added ? <Tick /> : <Close className="closeIcon" />}
      </span>
    </div>
  );
};

export default FieldOption;
