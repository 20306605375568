import React, { useContext, useMemo, useState } from "react";
import "./Kategorite.scss";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { Button, Tag, message } from "antd";
import QuillRenderer from "./Components/QuillRenderer";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import dayjsTZ from "../../../../utils/dayjs";
import { InterviewsContext } from "../../data";
import { useDispatch } from "react-redux";
import { candidates as candidatesReducer } from "../../../../store/actions";
import { useAuth } from "../../../../authentication/authHelpers";
import moment from "moment";
import AddTagModal from "./AddTagModal";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";
import { htmlParser } from "../../../../utils";
import { useMediaQuery } from "react-responsive";

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";
const TEXTAREA = ["customquill", "quill"];
const EXCLUDED_FIELDS = [
  "createdAt",
  "applicantTag",
  "applicantRoles",
  "applicantNotes",
  "applicantStatus",
  "applicantLastName",
  "applicantFirstName",
  "applicantDepartment",
  "applicantInterviewDate",
  "applicantDisqualificationReason",
];

const Pergjithshme = ({ fields }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const {
    setIntervistat,
    kandidatiSelektuar,
    setKandidatiSelektuar,
    defaultSwalProperties,
  } = useContext(InterviewsContext);

  const [visible, setVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [applicantTagNames, setApplicantTagNames] = useState(
    kandidatiSelektuar?.applicantTag || []
  );

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  //region candidateFields
  const candidateFields = useMemo(() => {
    let quills = [];
    let filteredArr = (fields || [])
      .map((el) => {
        let toReturn = [];
        if (
          !EXCLUDED_FIELDS.includes(el?.formItemName) &&
          !!kandidatiSelektuar?.[el?.formItemName]
        ) {
          if (!!TEXTAREA.includes(el?.type)) {
            quills.push(el);
          } else {
            toReturn.push(el);
          }
        }
        return toReturn;
      })
      .flat();

    const splicedArr = [];
    while (filteredArr.length) {
      splicedArr.push(filteredArr.splice(0, 4));
    }

    const splicedQuills = [];
    while (quills.length) {
      splicedQuills.push(quills.splice(0, 2));
    }

    const infoJsxArray = splicedArr?.map((row, idx) => (
      <div className="pergjithshmeRow" key={idx}>
        <div
          className="rowItems"
          style={{
            justifyContent: `${
              idx === splicedArr.length - 1 && row.length < 3
                ? "space-around"
                : "space-between"
            }`,
          }}
        >
          {row?.map(({ formItemName, label, type }) => {
            const dayjsTypes = ["datepicker", "timepicker"];
            let value = kandidatiSelektuar?.[formItemName];

            if (dayjsTypes.includes(type))
              value = dayjsTZ(value).format("DD/MM/YYYY");
            else if (/<\/?[a-z][\s\S]*>/i.test(value))
              value = htmlParser(value);
            else if (Array.isArray(value)) value = value.join(", ");

            if (formItemName === "applicantPayment") {
              return (
                <p className="password-show" data-testid="rowItem">
                  <span>Norma e pagës:</span>{" "}
                  {showPassword ? value : "***********"}
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? <EyeFilled /> : <EyeOutlined />}
                  </button>
                </p>
              );
            } else {
              return (
                <div className="rowItem" data-testid="rowItem">
                  <span>{label}:</span>
                  {value}
                </div>
              );
            }
          })}
        </div>
        {!isMobileView ? (
          <div className="divider" />
        ) : idx + 1 === splicedArr?.length ? (
          <div className="divider" />
        ) : null}
      </div>
    ));

    const quillJsxArray = (
      <div className="quillsContainer">
        {splicedQuills?.map((row, idx) => (
          <div
            className="quillItems"
            style={{
              gridTemplateColumns: `${
                idx === splicedQuills.length - 1 && row.length < 2
                  ? "1fr"
                  : "1fr 1fr"
              }`,
            }}
          >
            {row?.map((el, i) => (
              <div
                className="quillItem"
                style={{
                  ...(!!isMobileView
                    ? { flexDirection: "column", gap: 0 }
                    : {}),
                }}
              >
                <div className="quillContent" data-testid="rowItem">
                  <span>{el?.label}:</span>
                  <QuillRenderer
                    value={kandidatiSelektuar?.[el?.formItemName]}
                  />
                </div>
                {i !== row.length - 1 && row.length > 1 ? (
                  <div className="divider" style={{ margin: 5 }} />
                ) : null}
              </div>
            ))}
            {idx !== splicedQuills.length - 1 ? (
              <div className="divider" style={{ gridArea: "2 / 1 / 3 / 3" }} />
            ) : null}
          </div>
        ))}
      </div>
    );

    return infoJsxArray.concat(quillJsxArray);
  }, [fields, kandidatiSelektuar, isMobileView]);

  //region UPDATE
  const handleUpdate = async (tagName = "", e) => {
    if (!!tagName) e.preventDefault();
    const updatedTags = applicantTagNames.filter((items) => items !== tagName);

    const { applicantId, userId, ...rest } = kandidatiSelektuar;

    const tempData = {
      ...rest,
      applicantTag: updatedTags,
      keylog: [
        ...(kandidatiSelektuar?.keylog || []),
        {
          activity: "Ndryshuar",
          author:
            auth.userAccess[0]?.given_name +
            " " +
            auth.userAccess[0]?.family_name,
          changeDate: dayjsTZ().valueOf(),
          field: "Taget",
          oldValue: kandidatiSelektuar?.applicantTag,
          newValue: updatedTags,
        },
      ],
    };

    Swal.fire({
      title: "Ju lutemi prisni...",
      customClass: {
        popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await API.put(
      "interviews",
      `/interviews/${kandidatiSelektuar?.applicantId}`,
      {
        body: tempData,
      }
    )
      .then(() => {
        let newBody = {
          ...tempData,
          applicantId,
          userId,
        };
        dispatch(candidatesReducer(newBody));
        setIntervistat((prev) => [...prev, newBody]);
        setKandidatiSelektuar({ ...newBody });
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "success",
          title: "Kandidati u ndryshua!",
        });
      })
      .catch((err) => {
        console.log("Pergjithshme page error: ", { err });
        message.error("Ndodhi nje gabim");
      });
  };

  //region RETURN
  return (
    <div
      className={`pergjithshme-container ${isDarkMode ? "dark" : ""}`}
      data-testid="candidatePergjithshme"
    >
      <div className="content-container">
        {/* <div className="first-section">
          <p>Përmbledhje</p>
        </div> */}
        <div className="divider" />
        <div className="tags-section">
          <div className="tag-container">
            {kandidatiSelektuar.applicantTag &&
              kandidatiSelektuar.applicantTag.map((tag, index) => (
                <Tag
                  key={index}
                  closable
                  data-testid="tag"
                  onClose={(e) => handleUpdate(tag, e)}
                >
                  #{tag}
                </Tag>
              ))}
          </div>
          <MondayButton
            className="mondayButtonGreen"
            testid="shtoTag"
            onClick={() => setVisible(!visible)}
          >
            Shtoni një tag
          </MondayButton>
        </div>
        <div className="divider" />
        {candidateFields}
      </div>
      {visible ? (
        <AddTagModal
          {...{
            visible,
            setVisible,
            kandidatiSelektuar,
            handleUpdate,
            applicantTagNames,
            setApplicantTagNames,
          }}
        />
      ) : null}
    </div>
  );
};

export default Pergjithshme;
