export const columnDefs = [
  {
    headerName: "",
    field: "employeeId",
    hide: true,
  },
  {
    headerName: "Punonjesi",
    field: "employeeFirstName",
    valueGetter: ({ data }) =>
      data?.employeeFirstName + " " + data?.employeeLastName,
    cellRenderer: ({ data }) =>
      data?.employeeFirstName + " " + data?.employeeLastName,
    filter: "agTextColumnFilter",
    resizable: true,
  },
  {
    headerName: "Roli",
    field: "employeeRoleName",
    filter: "agTextColumnFilter",
    resizable: true,
  },
  {
    headerName: "Skuadra",
    field: "employeeTeam",
    filter: "agTextColumnFilter",
    resizable: true,
  },
];
