import dayjsTZ from "../../utils/dayjs";
import { FormGridStatusRenderer } from "./FormGridStatusRenderer/FormGridStatusRenderer";
import UploadedFilesRenderer from "../Documentation/UploadedFilesRenderer/UploadedFilesRenderer";
import { RedWarning } from "../../assets/icons";
import { Tooltip } from "antd";
import { htmlParser } from "../../utils";
import colorShade from "../../utils/colorShade";
import getAgThemePreference from "../../utils/getAgThemePreference";

const DATE_FORMAT = "DD/MM/YYYY";
const { mode, accentColor } = getAgThemePreference();

export const documentationsPageColDef = ({
  accessToken,
  statuses,
  employees,
  navigate,
  isMobileView,
}) => [
  {
    headerName: "Punonjësi",
    field: "name",
    filter: "agTextColumnFilter",
    mobile: true,
    cellClickKey: "recordId",
    isCollapseTitle: true,
    isExported: true,
    onCellClick: ({ clickId }) => navigate(`/punonjesit/specifikat/${clickId}`),
    ...(!isMobileView
      ? {
          cellRenderer: function ({ data }) {
            try {
              return (
                <div
                  style={{
                    cursor: "pointer",
                    ...(mode.includes("dark")
                      ? { color: "#FFF" }
                      : { color: colorShade(accentColor, 0.25) }),
                    fontWeight: 600,
                  }}
                  onClick={() =>
                    navigate(`/punonjesit/specifikat/${data?.recordId}`)
                  }
                >
                  {data?.name}
                </div>
              );
            } catch (err) {
              if (data?.name) {
                return data?.name;
              } else {
                return null;
              }
            }
          },
        }
      : {}),
  },
  {
    headerName: "Departamenti",
    field: "employeeDepartmentName",
    filter: "agTextColumnFilter",
    mobile: true,
    isExported: true,
    cellRenderer: function ({ data }) {
      try {
        return (
          <div>
            {
              employees?.find((item) => item?.employeeId === data?.recordId)
                ?.employeeDepartmentName
            }
          </div>
        );
      } catch (err) {
        if (data?.employeeDepartmentName) {
          return data?.employeeDepartmentName;
        } else {
          return null;
        }
      }
    },
    // hide: true,
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: "Roli",
    field: "employeeRoleName",
    filter: "agTextColumnFilter",
    mobile: true,
    isExported: true,
    cellRenderer: function ({ data }) {
      try {
        return (
          <div>
            {
              employees?.find((item) => item?.employeeId === data?.recordId)
                ?.employeeRoleName
            }
          </div>
        );
      } catch (err) {
        if (data?.employeeRoleName) {
          return data?.employeeRoleName;
        } else {
          return null;
        }
      }
    },
    // hide: true,
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: "Lloji i Dokumentacionit",
    field: "docType",
    mobile: true,
    filter: "agTextColumnFilter",
    cellClickKey: "recordId",
    stateKeys: ["docId"],
    isCollapseTitle: true,
    isExported: true,
    onCellClick: ({ clickId, states }) =>
      navigate(`/punonjesit/specifikat/${clickId}`, {
        state: {
          tab: "Dokumentacione",
          key: "3",
          openDocModal: states?.[0],
        },
      }),
    ...(!isMobileView
      ? {
          cellRenderer: ({ data }) => (
            <div
              style={{
                cursor: "pointer",
                ...(mode.includes("dark")
                  ? { color: "#FFF" }
                  : { color: colorShade(accentColor, 0.25) }),
                fontWeight: 600,
              }}
              onClick={() =>
                navigate(`/punonjesit/specifikat/${data?.recordId}`, {
                  state: {
                    tab: "Dokumentacione",
                    key: "3",
                    openDocModal: data?.docId,
                  },
                })
              }
            >
              {data?.docType}
            </div>
          ),
        }
      : {}),
    enableRowGroup: true,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Statusi",
    field: "docStatus",
    mobile: true,
    isExported: true,
    filter: "agTextColumnFilter",
    cellRenderer: ({ data }) =>
      htmlParser(FormGridStatusRenderer({ value: data?.docStatus, statuses })),
    cellClass: "centeredCell",
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  // {
  //   headerName: "Kategoria",
  //   field: "categoryName",
  //   filter: "agTextColumnFilter",
  //   resizable: true,
  //   sortable: true,
  //   enableRowGroup: true,
  //   cellClass: "centeredCell",
  // },
  {
    headerName: "Skedarët e Ngarkuar",
    field: "googleDriveUploads",
    filter: "agTextColumnFilter",
    cellRenderer: ({ data }) =>
      UploadedFilesRenderer({
        node: { data: { googleDriveUpload: data?.googleDriveUpload } },
        accessToken,
      }),
    cellClass: "centeredCell",
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: "Krijuar në",
    field: "createdAt",
    hide: true,
    format: "DD/MM/YYYY",
    isExported: true,
  },
  {
    headerName: "Data e Skadencës",
    field: "expirationDate",
    mobile: true,
    isExported: true,
    format: "DD/MM/YYYY",
    filter: "agTextColumnFilter",
    cellClass: "centeredCell",
    cellRenderer: ({ data }) => {
      if (!!data?.expirationDate) {
        let isExpiring = false;
        const currentTimestamp = parseInt(dayjsTZ().format("x"));
        const expirationTimestamp = dayjsTZ(parseInt(data?.expirationDate));
        if (
          currentTimestamp + 604800000 > expirationTimestamp &&
          expirationTimestamp > currentTimestamp
        ) {
          isExpiring = true;
        }
        return (
          <Tooltip
            placement="top"
            title={`${!!isExpiring ? "Po skadon" : ""}`}
            classNames={{ root: "expirationDateTooltip" }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>
                {dayjsTZ(parseInt(data?.expirationDate)).format(DATE_FORMAT)}
              </span>
              {!!isExpiring && <RedWarning />}
            </div>
          </Tooltip>
        );
      } else {
        return "";
      }
    },
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
];
