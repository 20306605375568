import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SquircleBackButton.scss";
import { useSelector } from "react-redux";

const SquircleBackButton = ({ onClick, className = "" }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  return (
    <div
      {...{
        onClick,
        className: `squircleBackButton ${
          isDarkMode ? "dark" : ""
        } ${className}`.trim(),
      }}
    >
      <ArrowLeftOutlined />
    </div>
  );
};

export default SquircleBackButton;
