import { DatePicker, Divider } from "antd";
import { CalendarIconFest, KolltukuBardh } from "../../../../../assets/icons";
import dayjsTZ from "../../../../../utils/dayjs";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";

const DATE_FORMAT_LIST = ["DD/MM/YYYY", "DD/MM/YY"];
const Row = ({ row, onDatePicker, onClick }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const { color, icon, dayOfWeek, date, effectiveDate, title } = row || {};

  return (
    <div className="rowWrapper">
      <div className="dayOfWeek" style={{ background: color }}>
        <div className="dayOfWeekIcon">
          {icon === "Kolltuku.svg" ? <KolltukuBardh /> : <CalendarIconFest />}
        </div>
        <span className="dayOfWeekLabel">{dayOfWeek}</span>
      </div>
      <span className="data">{date}</span>
      <span className="rowTitle">
        <span>{title}</span>
        <Divider />
      </span>
      <DatePicker
        format={DATE_FORMAT_LIST}
        placeholder="Selekto datën"
        allowClear={false}
        value={!!effectiveDate ? dayjsTZ(effectiveDate) : null}
        onChange={onDatePicker}
        popupClassName={isDarkMode ? "darkDropdown" : ""}
        style={{
          width: "200px",
          color: "red",
          ...(isDarkMode
            ? {
                background: "#1a1a1a",
                border: "none",
                boxShadow: "0 0 2px #e6e6e6",
              }
            : {}),
        }}
      />
      <MondayButton className="mondayButtonGreen" onClick={onClick}>
        Shiko më shumë
      </MondayButton>
    </div>
  );
};

export default Row;
