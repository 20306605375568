import "../../../PaySub/PayStub.scss";
import React from "react";
import { isNumber } from "lodash";
import { DeleteFilled } from "@ant-design/icons";
import { noSignFormatter } from "../../warnings/CustomWarnings";
function Table({ columnsDef, rowData, deleteRow, deletable, color }) {
	return (
		<div className="payStub-table">
			<table>
				<tr>
					{columnsDef?.map((el) => (
						<th>{el}</th>
					))}
				</tr>
				{rowData?.length > 0 ? (
					rowData?.map((rd, idx) => (
						<tr>
							{rd?.map((trd) => (
								<td>
                  {isNumber(trd)
                    ? noSignFormatter.format(trd).replace("ALL", "")
                    : trd}
                </td>
							))}

							{idx !== rowData?.length - 1 && deletable ? (
								<td>
									<DeleteFilled
										onClick={() => deleteRow(rd, idx)}
										style={{
											fontSize: "18px",
											color: "red",
											cursor: "pointer",
										}}
									/>
								</td>
							) : (
								<td></td>
							)}
						</tr>
					))
				) : (
					<strong style={{ display: "flex", justifyContent: "center", color: "red" }}>
						{/* Nuk ka te dhena per te shfaqur */}
					</strong>
				)}
			</table>
		</div>
	);
}

export default Table;
