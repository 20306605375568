import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router";
import "./GeneratePage.scss";
import { Button, Card, Collapse, DatePicker, message } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import { Select } from "antd";
import FilterDrawer from "../filters/FilterDrawer";
import { getTranslation } from "./translations";
import { CaretDownFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useRedux } from "../../../hooks/useRedux";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { intervals } from "./intervals";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const PERIOD_OPTIONS = [
  { value: "range", label: "Interval" },
  { value: "today", label: "Sot" },
  { value: "yesterday", label: "Dje" },
  { value: "thisWeek", label: "Këtë javë" },
  { value: "thisMonth", label: "Këtë muaj" },
  { value: "previousMonth", label: "Muaji i kaluar" },
  { value: "thisYear", label: "Këtë vit" },
];

function GeneratePageHeader({
  filterReportsClockings,
  updateColumns,
  contentData,
  resetCustom,
  onGenerate,
}) {
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [filters, setFilters] = useState({
    filterType: "range",
    default: true,
    timeInterval: intervals["thisMonth"],
  });
  const [shortcut, setShortcut] = useState("range");
  const [visible, setVisible] = useState(false);
  const [spin, setSpin] = useState(false);
  const [columns, setColumns] = useState(contentData?.selectedColumns || []);

  const onTimeChange = (e) => {
    if (shortcut === "range") {
      const tempInterval = !!e
        ? [dayjsTZ(e?.[0]), dayjsTZ(e?.[1])]
        : [null, null];
      if (
        filters.timeInterval[0] !== tempInterval[0] ||
        filters.timeInterval[1] !== tempInterval[1]
      )
        setFilters((prev) => ({
          ...prev,
          timeInterval: tempInterval,
        }));
    }
  };

  const updateFilters = async (cb) => {
    !!cb && cb();
    await filterReportsClockings(filters)
      .then(() => updateColumns(!!columns.length ? columns : null))
      .then(() => setSpin(false));
  };

  useLayoutEffect(() => {
    if (!!contentData?.selectedColumns) {
      setColumns(contentData?.selectedColumns || []);
    }
  }, [contentData]);

  //region RETURN
  return (
    <div className="generate-page-header">
      <Collapse
        className="card-report-header reports-collapse"
        title="Gjenero raportin"
        defaultActiveKey={["1"]}
        style={{ width: "100%" }}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <CaretDownFilled rotate={isActive ? 0 : 90} />
        )}
      >
        <Panel key={1} header={<div>Gjenero raportin</div>}>
          <div className="content-header-card">
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => navigate("/raportet")}
            >
              Ktheu pas
            </MondayButton>
            <div className="time-period">
              <div className="range-options">
                <span>Periudha:</span>
                <Select
                  style={{
                    width: 120,
                  }}
                  suffixIcon={<CaretDownFilled />}
                  className="select-option"
                  popupClassName={isDarkMode ? "darkDropdown" : ""}
                  variant={false}
                  value={shortcut}
                  onChange={(e) => {
                    setShortcut(e);
                    setFilters((prev) => ({
                      ...prev,
                      filterType: e,
                      ...(e !== "range" ? { timeInterval: intervals[e] } : {}),
                    }));
                  }}
                >
                  {PERIOD_OPTIONS.map(({ value, label }) => (
                    <Option value={value}>{label}</Option>
                  ))}
                </Select>
                <RangePicker
                  bordered={false}
                  className="range-pick-report"
                  allowClear={true}
                  popupClassName={isDarkMode ? "darkDropdown" : ""}
                  disabledDate={(params) =>
                    disabledDate(params, filters.timeInterval)
                  }
                  value={!!filters ? filters.timeInterval : null}
                  renderExtraFooter={() =>
                    "Intervali maksimal i te dhenave per kete report eshte 4 muaj."
                  }
                  onChange={(e) => {
                    onTimeChange(e);
                    setShortcut("range");
                  }}
                  format={"DD/MM/YYYY"}
                />
              </div>

              {!!contentData?.allColumns?.length ? (
                <Select
                  mode="multiple"
                  allowClear={false}
                  className="select-option-multi"
                  variant={false}
                  popupClassName={isDarkMode ? "darkDropdown" : ""}
                  placeholder="Please select"
                  value={columns}
                  onChange={(e) => setColumns(e)}
                  maxTagCount={"responsive"}
                >
                  {contentData?.allColumns?.map((el) => (
                    <Option value={el}>{getTranslation(el)}</Option>
                  ))}
                </Select>
              ) : null}
              <div style={{ display: "flex", gap: 10 }}>
                {!!contentData?.allColumns?.length ? (
                  <MondayButton
                    onClick={() =>
                      updateColumns(!!columns.length ? columns : null)
                    }
                    className="mondayButtonGreen"
                  >
                    Përditëso kolonat
                  </MondayButton>
                ) : null}
                <MondayButton
                  spin={spin}
                  onClick={() => updateFilters(setSpin(true))}
                  className="mondayButtonGreen"
                >
                  Gjenero
                </MondayButton>
                {!!contentData?.rowData?.length ? (
                  <MondayButton
                    className="mondayButtonGreen"
                    onClick={() => setVisible(true)}
                  >
                    Opsione filtrimi
                  </MondayButton>
                ) : null}
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      {visible ? (
        <FilterDrawer
          {...{ visible, setVisible, contentData, onGenerate, resetCustom }}
        />
      ) : null}
    </div>
  );
}

export default GeneratePageHeader;
function disabledDate(current, ledjo) {
  // Disable dates beyond a 3-month range
  return current && !!ledjo?.[0]
    ? current < dayjsTZ().subtract(4, "months") ||
        current > dayjsTZ().add(4, "months")
    : false;
}
