import { EyeFilled } from "@ant-design/icons";
import dayjsTZ from "../../../../../utils/dayjs";
import { checkWhetherEdited } from "./utils/validations";

export const colDefs = ({ clockins, onEditShift, isMobileView, showModal }) => [
  {
    headerName: "Statusi",
    field: "approved",
    filter: "agTextColumnFilter",
    filterParams: {
      readOnly: true,
    },
    mobile: true,
    isExported: true,
    valueGetter: ({ data }) => (!!data?.approved ? "Aprovuar" : "Pa Aprovuar"),
    cellRenderer: ({ data }) =>
      !isMobileView ? (
        <div style={{ cursor: "pointer" }} onClick={() => onEditShift(data)}>
          {renderStatus(data?.approved)}
        </div>
      ) : !!data?.approved ? (
        "Aprovuar"
      ) : (
        "Pa Aprovuar"
      ),
  },
  {
    headerName: "Data",
    field: "data",
    filter: "agNumberColumnFilter",
    mobile: true,
    isExported: true,
    isCollapseTitle: true,
    cellRenderer: ({ data }) => {
      try {
        return dayjsTZ(data?.data).format("DD/MM/YYYY");
      } catch (err) {
        // console.log("createdAt--err");
      }
    },
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    headerName: "Fillimi i turnit",
    field: "fillim_turni",
    filter: "agNumberColumnFilter",
    mobile: true,
    isExported: true,
    cellRenderer: ({ data }) => (
      <div>
        <div variant="outlined" cursor="pointer">
          {dayjsTZ(data.fillim_turni).format("HH:mm")}{" "}
          {checkWhetherEdited(clockins, data.clockingId, "editedClockIn") ===
          true ? (
            <EyeFilled
              style={styleEyeIcon}
              onClick={(e) => showModal(e, data, "clockIn")}
            />
          ) : null}
        </div>
      </div>
    ),
    filterParams: {
      readOnly: true,
    },
  },
  {
    headerName: "Mbarimi i turnit",
    field: "mbarim_turni",
    filter: "agNumberColumnFilter",
    mobile: true,
    isExported: true,
    cellRenderer: ({ data }) => (
      <div>
        <div variant="outlined" cursor="pointer">
          {dayjsTZ(data.mbarim_turni).format("HH:mm") !== "Invalid Date" ? (
            dayjsTZ(data.mbarim_turni).format("HH:mm")
          ) : (
            <h4 style={{ color: "#ea3943" }}>Pa percaktuar</h4>
          )}{" "}
          {checkWhetherEdited(clockins, data.clockingId, "editedClockOut") ===
          true ? (
            <EyeFilled
              style={styleEyeIcon}
              onClick={(e) => showModal(e, data, "clockOut")}
            />
          ) : null}
        </div>
      </div>
    ),
    filterParams: {
      readOnly: true,
    },
  },
  {
    headerName: "Orët Totale",
    field: "oret_totale",
    filter: "agNumberColumnFilter",
    filterParams: {
      readOnly: true,
    },
    mobile: true,
    isExported: true,
    cellRenderer: ({ data }) => (
      <div>
        <strong>{isNaN(data.oret_totale) ? "0.00" : data.oret_totale}</strong>
      </div>
    ),
  },
];

const statuses = {
  true: {
    background: "#1da193",
    label: "Aprovuar",
  },
  false: {
    background: "#ea3943",
    label: "Pa Aprovuar",
  },
  default: {
    background: "grey",
    label: "Pa Përcaktuar",
  },
};

const renderStatus = (val) => {
  const { background, label } = statuses?.[val] || statuses.default;
  return (
    <div
      style={{
        background,
        marginTop: "8px",
        cursor: "pointer",
        height: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        color: "white",
      }}
    >
      {label}
    </div>
  );
};

const styleEyeIcon = {
  color: "#fff",
  padding: 3,
  borderRadius: 3,
  backgroundColor: "#1da193",
};
