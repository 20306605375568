import dayjsTZ from "../../../../../utils/dayjs";
import { excludeWeekends } from "./LeaveRequests";

export const colDefs = ({
  navigate,
  renderStatus,
  setIsAddModalVisible,
  setEditData,
  userRole,
}) => [
  {
    title: "Emri i punonjësit",
    dataIndex: "requestId",
    key: "requestId",
    align: "center",
    ellipsis: true,
    render(_, data) {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (!!data?.employeeId) {
              navigate(`/punonjesit/specifikat/${data?.employeeId}`);
            }
          }}
        >{`${data.given_name} ${data.family_name}`}</span>
      );
    },
  },
  {
    title: "Data e krijimit",
    dataIndex: "createdAt",
    key: "createdAt",
    align: "center",
    render: (record) => <span>{dayjsTZ(record).format("DD/MM/YYYY")}</span>,
  },
  {
    title: "Statusi",
    dataIndex: "requestStatus",
    key: "requestStatus",
    align: "center",
    width: "140px",
    render: (text, data) => renderStatus(text, data),
  },
  {
    title: "Data e fillimit",
    dataIndex: "requestPeriod",
    key: "requestPeriod",
    align: "center",
    render: (record, data) => {
      return (
        <span
          style={{
            cursor: userRole === "Admin" ? "pointer" : "not-allowed",
          }}
          onDoubleClick={
            userRole === "Admin"
              ? () => {
                  setIsAddModalVisible(true);
                  setEditData(data);
                }
              : null
          }
        >
          {dayjsTZ(record[0]).format("DD/MM/YYYY")}
        </span>
      );
    },
  },
  {
    title: "Data e mbarimit",
    dataIndex: "requestPeriod",
    key: "requestPeriod",
    align: "center",
    ellipsis: true,
    render: (record, data) => (
      <span
        style={{
          cursor: userRole === "Admin" ? "pointer" : "not-allowed",
        }}
        onDoubleClick={
          userRole === "Admin"
            ? () => {
                setIsAddModalVisible(true);
                setEditData(data);
              }
            : null
        }
      >
        {dayjsTZ(record?.slice(-1)[0]).format("DD/MM/YYYY")}
      </span>
    ),
  },

  {
    title: "Totali",
    dataIndex: "requestPeriod",
    key: "requestPeriod",
    align: "center",
    width: "70px",
    ellipsis: true,
    render: (record) => (
      <span>{`${excludeWeekends({
        startDate: dayjsTZ(record[0]),
        endDate: dayjsTZ(record?.slice(-1)[0]),
      })}`}</span>
    ),
  },
];
