import React, { useMemo, useState, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "./AgGridComponent.scss";
import getAgThemePreference from "../../utils/getAgThemePreference";
import CustomAgPaginator from "./CustomAgPaginator/CustomAgPaginator";
import { useMediaQuery } from "react-responsive";
import MobileAgGrid from "./MobileAgGrid/MobileAgGrid";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
import collapseWrapper from "./collapseWrapper";
import FooterCounter from "./FooterCounter/FooterCounter";
import { modules } from "./modules";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import { useSelector } from "react-redux";

ModuleRegistry.registerModules([AllCommunityModule]);

function AgGridComponent({
  className = "",
  onGridReady,
  columnDefs,
  paginationPageSize,
  onCellValueChanged,
  rowData,
  onRowValueChanged,
  onSelectionChanged,
  rowSelection,
  overlayNoRowsTemplate,
  onPaginationChanged,
  overlayLoadingTemplate,
  onFirstDataRendered,
  gridApi,
  statusBar,
  rowHeight,
  headerHeight,
  customDefaultColDef = {},
  paginator,
  headerProps = {},
  counterProps = {},
  collapsible,
  rowClickKey,
  onRowClick,
  idKey,
}) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [activeFilter, setActiveFilter] = useState({});
  const [selectedObj, setSelectedObj] = useState({});
  const [togglerUtils, setTogglerUtils] = useState({});

  const isNotDesktop = useMediaQuery({
    query: "(max-width: 1366px)",
  });

  const defaultColDef = {
    sortable: true,
    ...(!isNotDesktop ? { flex: 1 } : {}),
    filter: true,
    resizable: true,
    // editable: { edit },
  };
  const { customTheme, backgroundColor, foregroundColor } = useMemo(() => {
    return getAgThemePreference();
  }, [isDarkMode]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const getRowId = useCallback((p) => p?.data?.[idKey], [idKey]);

  return !!isMobileView ? (
    <div className="mobileAgComponentWrapper">
      {collapseWrapper({ ...collapsible, isMobileView })}
      {!!Object.keys(headerProps)?.length ? (
        <AgGridHeader
          {...headerProps}
          rowData={rowData}
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          idKey={idKey}
          columnDefs={columnDefs}
          togglerUtils={togglerUtils}
        />
      ) : null}
      <MobileAgGrid
        {...{
          className,
          gridApi,
          rowData,
          selectedObj,
          setSelectedObj,
          idKey,
          rowClickKey,
          onRowClick,
          columnDefs,
          activeFilter,
          setTogglerUtils,
        }}
      />
    </div>
  ) : (
    <>
      {!!collapsible?.content ? collapsible?.content : null}
      <div
        // className={`agComponentWrapper ${theme} ${className}`}
        // style={{ ...style, width: "100%", height: "100%" }}
        className={`agComponentWrapper ${
          isDarkMode ? "dark" : ""
        } ${className}`}
        style={{ width: "100%", height: "100%" }}
      >
        <div className="agGridComponent">
          {!!Object.keys(headerProps)?.length ? (
            <AgGridHeader
              {...headerProps}
              idKey={idKey}
              gridApi={gridApi}
              rowData={rowData}
              columnDefs={columnDefs}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          ) : null}
          <AgGridReact
            theme={customTheme}
            gridApi={gridApi}
            rowData={rowData}
            rowSelection={{
              mode: "singleRow",
              groupSelectsChildren: true,
              ...(rowSelection || {}),
            }}
            editType={"fullRow"}
            onCellValueChanged={onCellValueChanged}
            onRowValueChanged={onRowValueChanged}
            columnDefs={columnDefs}
            statusBar={statusBar}
            defaultColDef={Object.assign(defaultColDef, customDefaultColDef)}
            onGridReady={onGridReady}
            pagination={true}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            // singleClickEdit={true}
            paginationPageSize={paginationPageSize}
            paginationAutoPageSize={true}
            onSelectionChanged={onSelectionChanged}
            enterNavigatesVertically={true}
            enterNavigatesVerticallyAfterEdit={true}
            overlayNoRowsTemplate={overlayNoRowsTemplate}
            onPaginationChanged={onPaginationChanged}
            overlayLoadingTemplate={overlayLoadingTemplate}
            onFirstDataRendered={onFirstDataRendered}
            getRowId={getRowId}
            animateRows={true}
            selection={{
              mode: "cell",
              enableMultiSelectWithClick: true,
            }}
          />
        </div>
        <div className="customFooter">
          {!!paginator ? (
            <CustomAgPaginator
              gridApi={gridApi}
              style={{
                background: backgroundColor,
                color: foregroundColor,
              }}
            />
          ) : null}
          {!!Object.keys(counterProps)?.length ? (
            <FooterCounter {...counterProps} />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default AgGridComponent;
