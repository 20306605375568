import { DatePicker } from "antd";
import dayjsTZ from "../../../../../utils/dayjs";
import { CalendarIconFest, KolltukuBardh } from "../../../../../assets/icons";
import { useSelector } from "react-redux";

const DATE_FORMAT_LIST = ["DD/MM/YYYY", "DD/MM/YY"];

const MobileRow = ({ row, onDatePicker, onClick, tableHeaders }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <div
      className="mobileRowWrapper"
      // onClick={onClick} we wont open modal onClick
    >
      {Object.entries(tableHeaders || {}).map(([key, title]) =>
        key === "dayOfWeek" ? (
          <div className="dayOfWeek" style={{ background: row?.color }}>
            <div className="dayOfWeekIcon">
              {row?.icon === "Kolltuku.svg" ? (
                <KolltukuBardh />
              ) : (
                <CalendarIconFest />
              )}
            </div>
            <span className="dayOfWeekLabel">{row?.[key]}</span>
          </div>
        ) : key !== "effectiveDate" ? (
          <span style={{ fontWeight: "600" }}>
            {key === "date" && title + ": "}
            {row?.[key]}
          </span>
        ) : (
          <span onClick={(e) => e.stopPropagation()}>
            <DatePicker
              format={DATE_FORMAT_LIST}
              placeholder="Selekto datën"
              allowClear={false}
              value={!!row?.[key] ? dayjsTZ(row?.[key]) : null}
              onChange={onDatePicker}
              style={{
                ...(isDarkMode
                  ? {
                      background: "#1a1a1a",
                      border: "none",
                      boxShadow: "0 0 2px #e6e6e6",
                    }
                  : {}),
                width: "200px",
                color: "red",
                borderRadius: "10px",
              }}
            />
          </span>
        )
      )}
    </div>
  );
};

export default MobileRow;
