import types from "../types/types";
const initialState = {
  users: undefined,
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case types.EDIT_USER: {
      return {
        ...state,
        users: editUsersWS(state.users, payload),
      };
    }
    case types.SET_DARKMODE: {
      return {
        ...state,
        isDarkMode: payload,
      };
    }
    default:
      return state;
  }
};

const editUsersWS = (array, user) => {
  const temp = array.map((item) => {
    return item.userId === user.userId ? user : item;
  });

  return Array.from(new Set(temp));
};

export default usersReducer;
