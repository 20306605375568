import { Modal } from "antd";
import React from "react";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import "./ConfirmModal.scss";
import { TickIcon, XIcon, Warning } from "../../../../assets/icons";
import { useDispatch } from "react-redux";
import { programFields as programFieldsReducer } from "../../../../store/actions";
import { useSelector } from "react-redux";

const ConfirmModal = ({
  isConfirmModalVisible,
  setIsConfirmModalVisible,
  TableData,
  setChanges,
  gridApi,
  QuestionProgramField,
  programFields,
}) => {
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.usersList);

  //confirm Modal submit
  const handleSubmit = () => {
    Swal.fire({
      title: "Ju lutem prisni...",
      customClass: {
        popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let gridRowData = [];
    gridApi.forEachNode(({ data }) => gridRowData.push(data));

    //if we click confirm on confirm changes
    if (isConfirmModalVisible === "Confirm") {
      API.put(
        "programFields",
        `/programFields/${QuestionProgramField.fieldId}`,
        {
          body: {
            fieldOptions: gridRowData,
          },
        }
      )
        .then(() => {
          setIsConfirmModalVisible(!isConfirmModalVisible);
          Swal.fire({
            icon: "success",
            title: "Ndryshimet u ruajten!",
            customClass: {
              popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
            },
          });
          const tmpProgramFields = [...programFields];
          const emailIndex = tmpProgramFields.findIndex(
            ({ fieldId }) => fieldId === QuestionProgramField.fieldId
          );

          if (emailIndex > -1) {
            tmpProgramFields[emailIndex]["fieldOptions"] =
              structuredClone(gridRowData);
          }
          dispatch(programFieldsReducer(tmpProgramFields));

          setChanges(false);
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    } else {
      //if we click confirm on cancel changes
      gridApi.setRowData(TableData?.fieldOptions);
      setIsConfirmModalVisible(!isConfirmModalVisible);
      setChanges(false);
      Swal.fire({
        icon: "success",
        title: "Ndryshimet u anulluan!",
        customClass: {
          popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
        // timer: 1500,
      });
    }
  };

  return (
    <Modal
      open={!!isConfirmModalVisible}
      centered
      onCancel={() => setIsConfirmModalVisible(!isConfirmModalVisible)}
      title="Warning Message"
      className="confirm-modal"
      closeIcon={<XIcon />}
      footer={[
        <>
          <MondayButton
            className="mondayButtonRed whiteIconBackground"
            Icon={<XIcon width={10} height={10} />}
            onClick={() => setIsConfirmModalVisible(!isConfirmModalVisible)}
          >
            Cancel
          </MondayButton>
          <MondayButton
            Icon={<TickIcon width={18} height={18} />}
            onClick={handleSubmit}
            className="mondayButtonGreen"
          >
            Save
          </MondayButton>
        </>,
      ]}
    >
      <div className="confirm-body">
        <img src={Warning} width={60} height={60} alt="Warning Icon" />
        <p>{`Are you sure you want to ${
          isConfirmModalVisible === "Confirm" ? "save" : "cancel"
        } this changes?`}</p>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
