import dayjs from "dayjs";
import dayjsTZ from "./dayjs";

export const getTimePassed = (start, end) => {
  const diff = dayjs.duration(dayjsTZ(end).diff(dayjsTZ(start)));

  const years = diff.years();
  const months = diff.months();
  const days = diff.days();

  return `${
    !!years
      ? years + ` vit${years > 1 ? "e" : ""}${!!days && !!months ? ", " : " "}`
      : ""
  }${!!months ? months + " muaj" : ""}${
    !!days ? (!!months || years ? " e " + days + " ditë" : days + "  ditë") : ""
  }`;
};
