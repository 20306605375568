import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../authentication/authHelpers";
import PayStub from "../../PaySub/PaySub";
import NewPayStub from "../../PaySub/previewPayStub/NewPayStub";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import "./EmployeeWageView.scss";
import { Modal, Skeleton } from "antd";
import { FulfillingSquareSpinner } from "react-epic-spinners";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";

function EmployeePaySub({ isModalVisible, selectedData, handleClose }) {
  const { employeeId } = useAuth();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [payStubObj, setPayStubObj] = useState(null);
  const [timestance, setTimesance] = useState({
    start: dayjsTZ().startOf("month"),
    end: dayjsTZ().endOf("month"),
  });
  // console.log("SelectedData", selectedData);
  useEffect(() => {
    if (!!employeeId) {
      setTimeout(() => {
        setPayStubObj({ employeeInfo: { employeeId: employeeId } });
      }, 1000);
    }
  }, [employeeId]);

  useEffect(() => {
    if (!!selectedData?.muaji) {
      setTimesance({
        start: dayjsTZ(selectedData?.muaji).startOf("month"),
        end: dayjsTZ(selectedData?.muaji).endOf("month"),
      });
    }
  }, [selectedData]);

  return (
    <>
      {" "}
      <Modal
        width={863}
        className={`employeePaySubModal ${
          isDarkMode ? "darkHeader" : "lightHeader"
        }`}
        destroyOnClose={true}
        title={`Paga ${dayjsTZ(timestance.start).format("MMMM YYYY")}`}
        open={isModalVisible}
        onCancel={() => handleClose()}
        centered={true}
        footer={[
          <MondayButton className="mondayButtonRed" onClick={handleClose}>
            Mbyll Faqen
          </MondayButton>,
        ]}
      >
        {!!payStubObj ? (
          <PayStub viewType={"Employee"} {...{ payStubObj, timestance }} />
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FulfillingSquareSpinner color="#1da193" size={100} />
            </div>
            <Skeleton />
            <Skeleton />
          </>
        )}
      </Modal>{" "}
    </>
  );
}

export default EmployeePaySub;
