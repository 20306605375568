import { Badge, Card, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { TotalNumber } from "../../../../assets/icons";
import { useSelector } from "react-redux";

function CardComponent({ data }) {
  const navigate = useNavigate();

  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <div
      className="departmentDragContainer"
      onClick={() =>
        navigate(`/konfigurimet/kompania/rolet/${data?.data?.departmentId}`)
      }
      style={{ cursor: "pointer" }}
    >
      <div className="dragEmployeeView">
        <TotalNumber
          {...(isDarkMode
            ? {
                fill: "none",
                stroke: "#fbfbfb",
                strokeWidth: 10,
              }
            : {})}
        />
        <div className="title">
          {data?.data?.lengthForEachDepartment?.length} Punonjës
        </div>
      </div>
      <div className="dragDepartmentRoles">
        <p className="dragDepartmentRoleTitle">Rolet</p>
        {data?.data?.departmentRoles?.map((data) => (
          <div className="roleNumber">
            <Badge status="default" />
            {data?.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardComponent;
