import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import React from "react";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

//nuk perdoret
function MobileWageGrid({ rowData }) {
	return (
		<div>
			<MobileHtmlTabel
				tableData={rowData?.map?.(({ nr, muaji, neto, bruto, taxes, totalCost }) => ({
					nr,
					muaji: dayjsTZ(muaji).format("MMMM - YYYY"),
					neto,
					bruto,
					taxes,
					totalCost,
				}))}
				header={["Nr.", "Muaji", "Neto", "Bruto", "Taxes", "Total Cost"]}
				isHeader={false}
				isLink={false}
			/>
		</div>
	);
}

export default MobileWageGrid;
