import { Alert, Form, message, Modal, Space, Typography } from "antd";
import "./CreateUserModal.scss";
import UserInformations from "./UserInformations";
import { ModalXIcon } from "../../../../../assets/icons";
import { QuestionsMark } from "../../../../Intervistat/Kandidatet/ShtoKandidat/Image";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderStepper } from "../../../..";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { API, Auth } from "aws-amplify";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";

const steps = [
  {
    title: "Regjistro përdoruesin",
    Comp: UserInformations,
    disabled: true,
  },
  {
    title: "Verifiko llogarinë",
    Comp: UserInformations,
    disabled: true,
  },
];
const { Text } = Typography;

const CreateUserModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const { employee, setEmployee } = useContext(PunonjesitContext);
  const { users, isDarkMode } = useSelector((state) => state.usersList);

  const [spin, setSpin] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [userSub, setUserSub] = useState("");
  const [missingData, setMissingData] = useState(false);

  //region HANDLE CANCEL
  const handleCancel = (skippableObj) => {
    let isEdited;
    for (const [key, value] of Object.entries(form.getFieldsValue())) {
      if (!!value && !skippableObj.hasOwnProperty(key)) {
        Modal.confirm({
          title: "A jeni të sigurt?",
          icon: <QuestionsMark />,
          content: (
            <Text>
              Ju po anuloni krijimi e një përdoruesi.
              <Space className="space-gap">
                Nëse jeni të sigurt për këtë veprim, klikoni
                <span className="text-bold">"Po, anuloje"</span>
              </Space>
            </Text>
          ),
          okText: "Po, anuloje",
          cancelText: "Jo, mos e anulo",
          width: "660px",
          className: `cancelModal ${isDarkMode ? "dark" : ""}`,
          centered: true,
          cancelButtonProps: {
            type: "primary",
            style: {
              background: "#ea3943",
              borderColor: "#ea3943",
              borderRadius: "5px",
            },
          },
          okButtonProps: {
            className: "ant-btn-confirm",
          },
          onOk() {
            onCancelConfirm();
          },
          onCancel() {},
        });
        isEdited = true;
        break;
      }
    }
    if (!isEdited) setOpen(false);
  };
  //region >onCancelConfirm()
  const onCancelConfirm = () => {
    setOpen(false);
    form.resetFields();
    setCurrentStep(0);
  };

  //region HANDLE CONTINUE
  const handleContinue = () => {
    form
      .validateFields()
      .then(async (formData) => {
        const existingUser = (users?.allUsers?.Items || []).find(
          (el) =>
            el.given_name?.toUpperCase() ===
              formData?.given_name?.toUpperCase() &&
            el.family_name?.toUpperCase() ===
              formData?.family_name?.toUpperCase() &&
            el.email?.toUpperCase() === formData?.email?.toUpperCase()
        );

        if (existingUser) {
          message.warning("Ky përdorues ekziston tashmë");
        } else if (formData.password !== formData.konfirmopassword) {
          message.warning("Fjalëkalimet nuk janë të njëjtë!");
        } else {
          signUp(setCurrentStep(currentStep + 1));
        }
      })
      .catch((err) => console.log("err: ", err));
  };

  //region SIGN UP
  const signUp = async (callback) => {
    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone_number")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("family_name"),
        given_name: form.getFieldValue("given_name"),
        gender: form.getFieldValue("gender"),
        "custom:department": form.getFieldValue("department"),
        "custom:userRole": form.getFieldValue("userRole"),
        "custom:role": form.getFieldValue("departmentRole"),
      },
    })
      .then((res) => {
        !!employee && setUserSub(res?.userSub);
        !!callback && callback();
      })
      .catch((err) => console.log(err));
  };

  //region >updateEmployee()
  const updateEmployee = async () => {
    const toSend = {
      userSub,
      accountStatus: "created",
    };
    await API.put("employees", `/employees/${employee.employeeId}`, {
      body: { ...toSend },
    }).then(() => {
      !!setEmployee && setEmployee((prev) => ({ ...prev, ...toSend }));
    });
  };

  //region HANDLE FINISH
  const handleFinish = () => {
    setSpin(true);
    Auth.confirmSignUp(
      form.getFieldValue("email"),
      form.getFieldValue("verifyCode")
    )
      .then(async (res) => {
        if (res === "SUCCESS") {
          if (!!employee && !!userSub) {
            await updateEmployee();
          }
          onCancelConfirm();
          message.success("Përdoruesi u krijua me sukses!");
        }
      })
      .finally(() => setSpin(false));
  };

  const defaultFieldsValue = !!employee
    ? {
        given_name: employee?.employeeFirstName,
        family_name: employee?.employeeLastName,
        gender: employee?.employeeGender,
        email: employee?.employeeEmailWork,
        phone_number: employee?.employeePhoneNumber,
        department: employee?.employeeDepartmentName,
        departmentRole: employee?.employeeRoleName,
      }
    : {};
  useEffect(() => {
    if (!!employee) {
      // if (!Object.values(defaultFieldsValue).every((v) => !!v))
      //   setMissingData(true);
      const missing = Object.entries(defaultFieldsValue).filter(
        ([_, val]) => !val
      );
      if (!!missing.length) {
        setMissingData(Object.fromEntries(missing));
      }

      form.setFieldsValue(defaultFieldsValue);
    }
  }, [employee]);

  //region RETURN
  return (
    <Modal
      className={`createUserModal ${isDarkMode ? "dark darkHeader" : "lightHeader"}`}
      open={open}
      autoComplete="off"
      destroyOnClose
      onCancel={() => handleCancel(defaultFieldsValue)}
      maskClosable={false}
      width={900}
      closeIcon={<ModalXIcon />}
      footer={[
        <MondayButton
          key={1}
          className="mondayButtonRed"
          onClick={() => handleCancel(defaultFieldsValue)}
        >
          Anuloje
        </MondayButton>,
        currentStep === 0 ? (
          <MondayButton
            key={2}
            className="mondayButtonGreen"
            onClick={handleContinue}
            spin={spin}
          >
            Vazhdo më tej
          </MondayButton>
        ) : (
          <MondayButton
            className="mondayButtonGreen"
            onClick={handleFinish}
            key={3}
          >
            Përfundo
          </MondayButton>
        ),
      ]}
      title="Krijimi i një përdoruesi"
      centered
    >
      <div className="createUserContainer" data-testid="createUserContainer">
        <Form form={form} disabled={missingData}>
          {missingData ? (
            <Alert
              banner
              showIcon
              type="warning"
              message="Të dhënat e punonjësit janë të pamjaftueshme për të krijuar një përdorues për të. Ju lutemi t'i shtoni ato fillimisht"
            />
          ) : null}
          <HeaderStepper
            {...{ steps, currentStep, setCurrentStep, missingData }}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
