import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { Button } from "antd";
import { DeleteIcon, IconNdrysho } from "../../../../assets/icons";
import "./paisjet_orarit.scss";
import PaisjetOraritPostModal from "./PaisjetOraritPostModal";
import { API } from "aws-amplify";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { htmlParser } from "../../../../utils";
import { useMediaQuery } from "react-responsive";
import { v4 as uuidv4 } from "uuid";
import { programFields as programFieldsReducer } from "../../../../store/actions";
import { LogsIcon } from "../../../Documentation/View/assets";
import ActivityModal from "../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import { colDefs } from "./colDefs";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

const FILTER_KEYS = [
  { key: "name", label: "Pajisjet e orarit", type: "string" },
  { key: "description", label: "Pershkrimi per pajisjen", type: "string" },
  { key: "ip", label: "Ip për pajisjen", type: "string" },
];

const PAJISJET_ORARIT_FIELD_ID = "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b";

function SistemiPaisjetOrarit() {
  const dispatch = useDispatch();

  const { programFields } = useSelector((state) => state.programFields);

  const [gridApi, setGridApi] = useState(null);
  const [visible, setVisible] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [editData, setEditData] = useState({});

  // delete the row in the agGrid
  const handleRequestDelete = async (id) => {
    let fieldOptions = rowData.filter((r) => r.id !== id);

    await API.put(
      "programFields",
      `/programFields/${PAJISJET_ORARIT_FIELD_ID}`,
      {
        body: { fieldOptions },
      }
    ).then(() => {
      let idx = (programFields || []).findIndex(
        ({ fieldId }) => fieldId === PAJISJET_ORARIT_FIELD_ID
      );

      let newProgramFields = [...programFields];
      if (idx > -1) {
        newProgramFields[idx] = { ...newProgramFields[idx], fieldOptions };
      }

      dispatch(programFieldsReducer(newProgramFields));

      gridApi.applyTransaction({
        remove: [{ id: id }],
      });
    });
  };

  const getRowId = useCallback((param) => {
    return param?.id;
  }, []);

  const handleUpdate = (row) => {
    setVisible(true);
    setEditData(row);
  };

  //region MEMOS
  const columnDefs = useMemo(() => {
    return colDefs({ handleUpdate, handleRequestDelete });
  }, []);

  const rowData = useMemo(() => {
    if (!programFields) return [];

    const idx = programFields.findIndex(
      (el) => el?.fieldId === PAJISJET_ORARIT_FIELD_ID
    );

    return programFields[idx]?.fieldOptions || [];
  }, [programFields]);

  const ITEMS = {
    search: {},
    icons: {
      filter: {
        filterKeys: FILTER_KEYS,
      },
      excel: {},
      pdf: {},
      print: {},
      modals: [{ onClick: () => setLogsVisible(true) }],
    },
  };

  //region RETURN
  return (
    <LoadableComp loading={!programFields}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          getRowId={getRowId}
          rowData={rowData}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={(p) => setGridApi(p.api)}
          columnDefs={columnDefs}
          headerProps={{
            items: ITEMS,
            exportTitle: "Pajisjet e orarit",
            children: [
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => setVisible(true)}
              >
                Shto një pajisje të re
              </MondayButton>,
            ],
          }}
          idKey={"id"}
        />
      </div>
      {logsVisible ? (
        <ActivityModal
          keylog={rowData.flatMap((el) => el?.logs)}
          setIsModalVisible={setLogsVisible}
        />
      ) : null}
      {visible ? (
        <PaisjetOraritPostModal
          {...{
            visible,
            editData,
            setVisible,
            setEditData,
          }}
        />
      ) : null}
    </LoadableComp>
  );
}

export default SistemiPaisjetOrarit;
