import { Button, Form, Input, message, Select } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import dayjsTZ from "../../../../utils/dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import ReturnImg from "../../../../utils/ReturnImg";
import "./info_rreth_kompanis.scss";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
function EmployOfMonthContent() {
  const { Option } = Select;
  //get active employes from redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { isDarkMode } = useSelector((state) => state.usersList);
  // state with selected employe return object
  const [currentEmp, setCurrentEmp] = useState(null);
  // state with data for employe of month , week from program fields return object
  const [emplWeekMonth, setEmplWeekMonth] = useState(null);
  // program fieds data return arrray
  const [tableData, setTableData] = useState(null);
  // input mesage return input mesage value
  const [messageInput, setMessageInput] = useState(null);

  //select the current employe
  const onSelect = (e) => {
    setCurrentEmp(activeEmployees?.find?.((el) => el?.employeeId === e) || {});
  };

  //store the input value  in state
  const onSelectMessage = (e) => {
    setMessageInput(e?.target?.value);
  };

  // find and store in state employe month & week data from program fields
  const EmpMonthWeek = () => {
    const kompaniaFieldOptions = tableData?.find?.(
      (data) => data?.fieldName === "Employees of the Week & Month"
    );
    setEmplWeekMonth(kompaniaFieldOptions?.fieldOptions);
  };

  // get data from api
  const getProgramFields = () => {
    API.get("programFields", "/programFields").then((r) => setTableData(r));
  };

  // show ProgramFields data
  useEffect(() => {
    getProgramFields();
  }, []);

  useEffect(() => {
    if (!!tableData) {
      EmpMonthWeek();
    }
  }, [tableData]);

  // save the data in db
  const handleSaveEmpMonth = async () => {
    let now = dayjsTZ();
    let startMonth = now.clone().startOf("month");
    let endMonth = now.clone().endOf("month");
    let prev = emplWeekMonth?.previous;
    let initialMessazh = "Jemi krenarë për ty! Urime për punën e bërë mirë!";
    const monthData = {
      start: dayjsTZ(startMonth).valueOf(),
      end: dayjsTZ(endMonth).valueOf(),
      userSub: currentEmp?.userSub || "",
      name:
        Object.keys(currentEmp || {})?.length > 0
          ? `${currentEmp?.employeeFirstName} ${currentEmp?.employeeLastName}`
          : "",
      type: "month",
      mesazhPublikMonth: messageInput || initialMessazh || "",
      googleDriveFileId: currentEmp?.googleDriveFileId || "",
    };
    await API.put(
      "programFields",
      "/programFields/4sf-s5d4f-645dfhgrt-9hg89o8775",
      {
        body: {
          fieldOptions: {
            current: emplWeekMonth?.current || {},
            month: monthData,
            previous: [...prev, monthData],
          },
        },
      }
    ).then(() => {
      message.success("Të dhënat u ndryshuan me sukses");
    });
  };

  useEffect(() => {
    if (!!emplWeekMonth) {
      setCurrentEmp(
        activeEmployees?.find?.(
          (el) =>
            `${el?.employeeFirstName} ${el?.employeeLastName}` ===
            emplWeekMonth?.month?.name
        )
      );
    }
  }, [emplWeekMonth]);

  return (
    <div className="comp-info-card-container">
      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr", gap: 5 }}>
        <div className="employee-of-month">
          <div className="input">
            <h3>Punonjësi</h3>
            <Form.Item
              style={{ width: "100%" }}
              name="nameMonth"
              rules={[
                {
                  required: false,
                  message: "Zgjidhni Punonjësin!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch={true}
                variant={false}
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                placeholder="Punonjësit"
                onChange={(val) => onSelect(val)}
              >
                {activeEmployees?.map((emp) => (
                  <Option value={emp?.employeeId} key={emp?.employeeId}>
                    {`${emp?.employeeFirstName} ${emp?.employeeLastName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="input">
            <h3>Mesazh publik</h3>
            <Form.Item
              style={{ width: "100%" }}
              name="mesazhPublikMonth"
              rules={[
                {
                  required: false,
                  message: "Zgjidhni Punonjësin!",
                },
              ]}
            >
              <Input.TextArea
                rows={6}
                variant={false}
                placeholder="Mesazh publik"
                onChange={(val) => onSelectMessage(val)}
              />
            </Form.Item>
          </div>
        </div>
        {!!currentEmp ? (
          <div className="img-div">
            <ReturnImg
              width={220}
              height={220}
              borderRadius={5}
              googleDriveFileId={currentEmp?.googleDriveFileId}
              defaultImg={
                <Avatar
                  name={
                    Object.keys(currentEmp || {})?.length > 0 &&
                    currentEmp?.employeeFirstName +
                      " " +
                      currentEmp?.employeeLastName
                  }
                  size="180"
                  round={true}
                />
              }
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <MondayButton
        className="mondayButtonGreen"
        onClick={() => handleSaveEmpMonth()}
      >
        Publiko
      </MondayButton>
    </div>
  );
}

export default EmployOfMonthContent;
