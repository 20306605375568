import { useSelector } from "react-redux";
import "./EmployeeSince.scss";
import { useMemo, useState } from "react";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";
import { HollowDotsSpinner } from "react-epic-spinners";
import { Badge, Tooltip } from "antd";
import { getRandomColor } from "../Employees/Employees";
import { getTimePassed } from "../../../../../utils";
import { TransferIcon } from "../../../../../assets/icons";

const EmployeeSince = () => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { activeEmployees } = useSelector((state) => state.employeesList);

  const [sort, setSort] = useState(false);

  const employees = useMemo(() => {
    if (!activeEmployees) return [];

    return activeEmployees
      .map((el) => {
        const elapsedTime = !!el?.employeeHireDate
          ? getTimePassed(el?.employeeHireDate, dayjsTZ())
          : null;

        return {
          ...el,
          ...(elapsedTime ? { elapsedTime } : {}),
        };
      })
      .sort((a, b) =>
        !!sort
          ? dayjsTZ(a?.employeeHireDate) - dayjsTZ(b?.employeeHireDate)
          : dayjsTZ(b?.employeeHireDate) - dayjsTZ(a?.employeeHireDate)
      );
  }, [activeEmployees, sort]);

  return !!employees ? (
    <div className={`employeeSinceCard ${isDarkMode ? "dark" : ""}`}>
      <Tooltip
        title={`Sort ${sort ? "ascending" : "descending"}`}
        mouseEnterDelay={0.3}
      >
        <div className="sortIcon" onClick={() => setSort((prev) => !prev)}>
          <TransferIcon fill={isDarkMode ? "#e6e6e6" : "#1d3943"} />
        </div>
      </Tooltip>
      {!!employees.length ? (
        <div className="employeeSinceContainer">
          {employees.map((el, idx) => (
            <div key={idx} className="employeeItem">
              <Badge
                className="name-badger"
                color={getRandomColor()}
                text={el?.employeeFirstName + " " + el?.employeeLastName}
              />
              <span className="line-card"></span>
              <span>{el?.elapsedTime || "Nuk ka të dhëna"}</span>
            </div>
          ))}
        </div>
      ) : (
        <div> Nuk ka të dhëna </div>
      )}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 225,
        width: 385,
      }}
    >
      <HollowDotsSpinner color="#1da193" size={24} />
    </div>
  );
};

export default EmployeeSince;
