import React, { useMemo, useContext } from "react";
import { Modal } from "antd";
import "./ShiftModal.scss";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import PunonjesitContext from "../../../store/PunonjesitContext";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import Approvals from "../../../../Pagat/approvals/Approvals";
import { colDefs } from "./colDefs";
import { useSelector } from "react-redux";

const ShiftModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedData,
  setSelectedData,
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { employee } = useContext(PunonjesitContext);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedData(null);
  };

  const { titleModal, range } = useMemo(() => {
    if (!employee) return "";
    return {
      titleModal: `${employee?.employeeFirstName} ${
        employee?.employeeLastName
      } - ${dayjsTZ(selectedData?.muaji).format("MMMM YYYY")}`,
      range: [
        dayjsTZ(selectedData?.muaji).startOf("month"),
        dayjsTZ(selectedData?.muaji).endOf("month"),
      ],
    };
  }, [employee, selectedData]);

  return (
    <Modal
      wrapClassName="shift-modal"
      className={`shift-modal ${isDarkMode ? "darkHeader" : "lightHeader"}`}
      title={titleModal}
      onCancel={handleCancel}
      open={isModalVisible}
      centered={true}
      destroyOnClose
      footer={[
        <MondayButton
          key="1"
          className="mondayButtonRed"
          onClick={handleCancel}
        >
          Mbyll faqen
        </MondayButton>,
      ]}
    >
      <Approvals
        singleEmployee={employee}
        defaultFilter={{ range }}
        colDefsGenerator={colDefs}
        showChart={true}
      />
      {/* <ShiftGrid selected={selectedData} {...{ setSelectedData, employee }} /> */}
    </Modal>
  );
};
export default ShiftModal;
