import { IconEditWhite } from "../../assets/icons";
import { htmlParser } from "../../utils";
import dayjsTZ from "../../utils/dayjs";
import AnnouncementReplies from "./AnnouncementReplies/AnnouncementReplies";

export const colDefs = ({
  retrieveEditData = () => {},
  isMobileView,
  customCols = [],
  categories,
}) => {
  return [
    {
      headerName: "Titulli",
      field: "announcementTitle",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      isExported: true,
      isCollapseTitle: true,
    },
    {
      headerName: "Kategoria",
      field: "announcementCategory",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      isExported: true,
      valueGetter: ({ data }) => categories[data?.announcementCategory],
      cellRenderer: ({ data }) => categories[data?.announcementCategory],
    },
    {
      headerName: "Përmbajtja",
      field: "announcementBody",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      isExported: true,
      cellRenderer: ({ data }) => htmlParser(data?.announcementBody),
    },
    {
      headerName: "Url",
      field: "announcementUrl",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Krijuar në",
      field: "createdAt",
      sort: "desc",
      filter: "agDateColumnFilter",
      mobile: true,
      isExported: true,
      format: "DD/MM/YYYY",
      cellRenderer: ({ data }) => dayjsTZ(data?.createdAt).format("DD/MM/YYYY"),
    },

    {
      headerName: "Krijuar Nga",
      field: "createdBy",
      sortable: true,
      filter: true,
      mobile: true,
      isExported: true,
      width: 200,
    },
    {
      headerName: "Përgjigjet",
      field: "usersResponses",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      isExported: true,
      cellRenderer: ({ data }) => {
        let responses = {};
        Object.values(data?.usersResponses || {}).forEach((res) => {
          responses[res?.response] = (responses[res?.response] || 0) + 1;
        });
        return isMobileView ? (
          <div>
            {Object.entries(responses).map(([key, val]) => (
              <span>
                {[key]}: {val}
              </span>
            ))}
          </div>
        ) : (
          <div className="userResponses">
            <AnnouncementReplies
              userResponses={data?.usersResponses || {}}
              id={data?.announcementId}
              title={data?.announcementTitle}
            />
            <span
              style={{ backgroundColor: "#1da193" }}
              onClick={() => retrieveEditData(data)}
            >
              <IconEditWhite height={15} width={15} />
            </span>
          </div>
        );
      },
    },
  ].concat(
    customCols.map((el) => ({
      ...el,
      sortable: true,
      filter: true,
      width: el?.width || 200,
    }))
  );
};
