import { Modal, Form, message, Input, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { RichTextEditor } from "../../../commonComponents";
import { useAuth } from "../../../../authentication/authHelpers";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { programFields as programFieldsReducer } from "../../../../store/actions";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { osName, osVersion, deviceType } from "react-device-detect";
import { useSelector } from "react-redux";
import { getObjectChanges } from "../../../../utils";

const PAJISJET_ORARIT_FIELD_ID = "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b";

function PaisjetOraritPostModal({
  visible,
  editData,
  setVisible,
  setEditData,
}) {
  const [form] = Form.useForm();
  const { user } = useAuth();

  const dispatch = useDispatch();
  
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [spin, setSpin] = useState({});

  //region ON SAVE
  const handleSave = () => {
    setSpin({ save: true });
    form
      .validateFields()
      .then(async (res) => {
        //if no ID from editData it means we are in create mode
        let toSend = { ...res, id: editData?.id || uuidv4() };
        const keylogs = getKeylogs(editData, toSend);

        toSend["logs"] = [...(editData?.logs || []), ...keylogs];
        const fieldOptions = [...(rowData || [])];

        const idx = fieldOptions.findIndex((el) => el?.id === toSend.id);

        //edit mode
        if (idx > -1) {
          fieldOptions[idx] = toSend;
        } else {
          fieldOptions.push(toSend);
          //create mode
        }

        await API.put(
          "programFields",
          `/programFields/${PAJISJET_ORARIT_FIELD_ID}`,
          { body: { fieldOptions } }
        ).then(() => {
          message.success({
            key: "pajisjetModal",
            content: "Të dhënat u regjistruan me sukses!",
          });

          const newProgramFields = [...programFields];
          const idx = newProgramFields.findIndex(
            (el) => el?.fieldId === PAJISJET_ORARIT_FIELD_ID
          );

          if (idx > -1) {
            newProgramFields[idx]["fieldOptions"] = fieldOptions;
          }

          dispatch(programFieldsReducer(newProgramFields));

          form.resetFields();
          setEditData({});
          setVisible(false);
        });
      })
      .catch((err) => {
        console.log(err);
        message.error({
          key: "pajisjetModal",
          content: "Ju lutem plotësoni të gjitha fushat!",
        });
      })
      .finally(() => setSpin(false));
  };

  //region >getKeylogs()
  function getKeylogs(previousObject, currentObject) {
    if (!Object.keys(previousObject || {}).length) return [];
    const changes = getObjectChanges(previousObject, currentObject);

    const keylogs = [];

    let constants = {
      activity: "Ndryshuar",
      author: user?.given_name + " " + user?.family_name,
      changeDate: Date.now(),
      userSub: user?.userSub,
    };

    const albKeys = {
      description: "Përshkrimi",
      ip: "IP Adresa",
      name: "Emri i pajisjes",
      shfletuesi: "Shfletuesi",
      sistemi: "Sistemi",
    };

    Object.entries(changes || {}).forEach(([key, val]) => {
      constants["oldValue"] = previousObject[key] || "-";
      constants["newValue"] = val || "-";
      constants["field"] = albKeys[key];

      if (!!albKeys[key]) {
        keylogs.push({ ...constants, id: uuidv4() });
      }
    });

    return keylogs;
  }

  const handleCancel = () => {
    setVisible(false);
    setEditData({});
    form.resetFields();
  };

  const getIp = async () => {
    setSpin({ ip: true });
    await API.get("getIp", "/getIp")
      .then((res) => {
        form.setFieldValue("ip", res.split(",")[0]);
      })
      .catch((err) => console.log("err: ", err))
      .finally(() => setSpin({ ip: false }));
  };

  const rowData = useMemo(() => {
    if (!programFields) return [];

    const idx = programFields.findIndex(
      (el) => el?.fieldId === PAJISJET_ORARIT_FIELD_ID
    );

    return programFields[idx]?.fieldOptions || [];
  }, [programFields]);

  useEffect(() => {
    if (!!Object.keys(editData || {}).length) {
      form.setFieldsValue(editData);
    } else {
      getIp();
      form.setFieldsValue({
        shfletuesi: deviceType,
        sistemi: `${osName} ${osVersion}`,
      });
    }
  }, [editData]);

  //region RETURN
  return (
    <Modal
      title="Pajisje e orarit"
      className={`paisjet-per-orarin-modal ${
        isDarkMode ? "dark darkHeader" : "lightHeader"
      }`}
      centered
      open={visible}
      onClose={handleCancel}
      onCancel={handleCancel}
      width={550}
      destroyOnClose
      footer={[
        <MondayButton onClick={handleCancel} className="mondayButtonRed">
          Anulo
        </MondayButton>,
        <MondayButton
          spin={spin?.save}
          onClick={handleSave}
          className="mondayButtonGreen"
        >
          {!!Object.keys(editData || {}).length
            ? "Ruaj ndryshimet"
            : "Shto pajisjen"}
        </MondayButton>,
      ]}
    >
      <Form name="PaisejePerOrarin" form={form}>
        <h3>Emri i pajisjes:</h3>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruani emrin e pajisjes!",
            },
            {
              validator: (_, value) =>
                !!value &&
                (rowData || []).some(
                  ({ name }) => name.toLowerCase() === value.toLowerCase()
                )
                  ? Promise.reject(
                      new Error("Ekziston një pajisje orari me këtë emër!")
                    )
                  : Promise.resolve(),
            },
          ]}
        >
          <Input placeholder="Emri i pajisjes" />
        </Form.Item>
        <h3>Përshkrimi për pajisjen:</h3>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Ju lutem përshkruani pajisjen!",
            },
          ]}
        >
          <RichTextEditor className={"modal-quill-edit"} />
          {/* <Input placeholder="Pershkrimi per pajisjen" /> */}
        </Form.Item>
        <h3>Adresa IP:</h3>
        <Form.Item
          name="ip"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruaj adresën IP!",
            },
          ]}
        >
          {!!spin?.ip ? (
            <Spin spinning={true} />
          ) : (
            <Input placeholder="Ip adresa për paisjen" />
          )}
        </Form.Item>
        <h3>Shfletuesi:</h3>
        <Form.Item
          name="shfletuesi"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruaj shfletuesin!",
            },
          ]}
        >
          <Input placeholder="Shfletuesi" />
        </Form.Item>
        <h3>Sistemi:</h3>
        <Form.Item
          name="sistemi"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruaj sistemin!",
            },
          ]}
        >
          <Input placeholder="Sistemi" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PaisjetOraritPostModal;
