import "./ShowContactDetails.scss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CloseIcon, InfoIcon, CalendarIcon, TimeIcon } from "../Image";
import { Typography, Button, Modal, Space, message } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import QuillRenderer from "../Components/QuillRenderer";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ShowContactDetailsSecondModal from "../ShowContactDetailsSecondModal/ShowContactDetailsSecondModal";
import { ModalXIcon, NewUploadIcon } from "../../../../../assets/icons";
import AddContactDocUploader from "../AddContactDocUploader/AddContactDocUploader";
import { sortBy } from "lodash";
import { fileIcons } from "../../../../Documentation/View/documentationViewData";
import { openInNewTab } from "../../../../Documentation/utils/openInNewTab";
import { useAuth } from "../../../../../authentication/authHelpers";
import { RedCloseIcon } from "../../../../Documentation/View/assets";
import MultipleFilePrevieModal from "../../../../dashboard/AdminDashboard/AdminCards/Problems/MultipleFilePreviewModal/MultipleFilePreviewModal";
import { InterviewsContext } from "../../../data";
import { htmlParser } from "../../../../../utils";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";
// import { cancelScheduledEvent } from "../../../../../utils/calendlyEvents";

const { Text, Link } = Typography;
const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";
const INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID =
  "345dgdf-456457fgjvbmn-96789khjghj";

const EXCLUDED_FIELDS = [
  "applicantFirstName",
  "applicantLastName",
  "applicantStatus",
  "applicantInterviewDate",
];
const TEXTAREA = ["customquill", "quill"];
const ShowContactDetails = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const auth = useAuth();
  console.log(programFields);

  const {
    onDelete,
    isEditable,
    driveRequest,
    setIsChanged,
    uploadedFiles,
    temporaryArray,
    parentFolderId,
    onPickerSuccess,
    setTemporaryArray,
    tempUploadedFiles,
    kandidatiSelektuar,
    googleDriveFolderId,
    setTempUploadedFiles,
    setGoogleDriveFolderId,
    showSecondLayoutStatus,
    setShowSecondLayoutStatus,
  } = useContext(InterviewsContext);
  console.log(temporaryArray);
  //region candidateFields
  const candidateFields = useMemo(() => {
    const fieldsArr = [];
    if (!programFields?.length) return [];
    const { fieldOptions = [] } = programFields?.find(
      ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
    );
    fieldOptions?.forEach(({ fields = [] }) => {
      fieldsArr.push(
        fields.map(
          ({ formItemName, label, type }) =>
            ({
              formItemName,
              label,
              type,
            } || [])
        )
      );
    });
    let quills = [];

    const filteredArr = fieldsArr?.map((configStep) => {
      let toReturn = [];
      configStep?.forEach((el) => {
        if (
          !EXCLUDED_FIELDS.includes(el?.formItemName) &&
          !!temporaryArray[el?.formItemName]
        ) {
          if (!!TEXTAREA.includes(el?.type)) {
            quills.push(el);
          } else {
            toReturn.push(el);
          }
        }
      });
      return toReturn;
    });

    return filteredArr?.map((step, idx) => (
      <div className="firstContainerRow" key={idx}>
        {step?.map(({ formItemName, label }) => {
          let value = temporaryArray?.[formItemName];

          if (dayjs.isDayjs(value)) value = dayjsTZ(value).format("DD/MM/YYYY");
          else if (/<\/?[a-z][\s\S]*>/i.test(value)) value = htmlParser(value);
          else if (Array.isArray(value)) value = value.join(", ");

          return (
            <div className="rowItem" data-testid="rowItem">
              <span>{label}:</span>
              {value}
            </div>
          );
        })}
        {idx === 0 ? (
          <Link
            onClick={() => {
              setShowSecondLayoutStatus(!showSecondLayoutStatus);
            }}
          >
            Kliko këtu për të shfaqur avantazhet dhe disavantazhet e kandidatit
          </Link>
        ) : null}
        {idx !== filteredArr?.length - 1 ? (
          <div className="diviner-line" />
        ) : (
          <div className="quillsContainer">
            <div className="diviner-line" />
            {quills?.map((el) => (
              <div className="rowItem" data-testid="rowItem">
                <span>{el?.label}:</span>
                {htmlParser(temporaryArray[el?.formItemName])}
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  }, [programFields, temporaryArray]);

  //region BUILD KEYLOGS
  const updatedFields = (originalObject, changedObject) => {
    const excludedFields = ["applicantEventURI", "googleDriveFolderId"];
    const { fieldOptions = [] } = programFields?.find(
      ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
    );
    let changes = [];
    let constants = {
      activity: "Ndryshuar",
      author:
        auth.userAccess[0]?.given_name + " " + auth.userAccess[0]?.family_name,
      changeDate: dayjsTZ().valueOf(),
    };
    for (const key in changedObject) {
      if (changedObject[key] !== null && !excludedFields.includes(key)) {
        if (Array.isArray(changedObject[key])) {
          if (key === "googleDriveFiles") {
            for (let i = 0; i < changedObject[key].length; i++) {
              if (changedObject[key][i] !== originalObject[key][i]) {
                changes.push({
                  ...constants,
                  field: key,
                  oldValue: originalObject[key][i],
                  newValue: changedObject[key][i],
                });
              }
            }
          } else {
            if (
              JSON.stringify(changedObject[key]) !==
              JSON.stringify(originalObject[key])
            ) {
              changes.push({
                ...constants,
                field: key,
                newValue: changedObject[key]?.join(", ") || changedObject[key],
                oldValue:
                  originalObject[key]?.join(", ") || originalObject[key],
              });
            }
          }
        } else if (
          new Date(changedObject[key]).toDateString() !==
          new Date(originalObject[key]).toDateString()
        ) {
          if (key === "applicantInterviewDate") {
            const interviewDateValues = applicantInterviewDateKeylog({
              originalObject,
              changedObject,
              key,
            });
            for (let i = 0; i < interviewDateValues.length; i++) {
              const { key, value, format } = interviewDateValues[i];
              changes.push({
                ...constants,
                field: key,
                oldValue: dayjsTZ(originalObject[key]).format(format),
                newValue: value,
              });
            }
          } else {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
          }
        } else {
          if (changedObject[key] !== originalObject[key]) {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
          }
        }
      } else if (
        originalObject[key] !== null &&
        !excludedFields.includes(key)
      ) {
        changes.push({
          ...constants,
          field: key,
          oldValue: originalObject[key],
          newValue: null,
        });
      }
    }

    return translateObjectFields({ fieldOptions, changes });
  };

  //region DRIVE FOLDER
  const getDriveFolder = async () => {
    let folderIdOnCreate = "";
    const folderName = `${kandidatiSelektuar?.applicantFirstName} ${
      kandidatiSelektuar?.applicantLastName
    }-${dayjsTZ().valueOf()}`;

    const { fieldOptions } = programFields?.find(
      ({ fieldId }) => fieldId === INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID
    );

    folderIdOnCreate = await createDriveFolder({
      driveRequest,
      parentId: parentFolderId,
      folderName,
      defaultPermissions: fieldOptions || [],
    });
    setGoogleDriveFolderId(folderIdOnCreate);
  };

  useEffect(() => {
    // on manual create mode we generate a folder Id
    if (!googleDriveFolderId) {
      getDriveFolder();
    }
  }, []);

  useEffect(() => {
    const fields = !!isEditable
      ? updatedFields(kandidatiSelektuar, temporaryArray)
      : [];
    setTemporaryArray((prev) => ({
      ...prev,
      googleDriveFolderId,
      keylog: [...(kandidatiSelektuar?.keylog || []), ...(fields || [])],
    }));
  }, [kandidatiSelektuar, googleDriveFolderId]);

  //constants
  let applicantFullName =
    temporaryArray?.applicantFirstName +
    " " +
    temporaryArray?.applicantLastName;

  let textFormater =
    applicantFullName.length > 20
      ? applicantFullName.slice(0, 20) + "..."
      : applicantFullName;

  //region RETURN
  return (
    <>
      <div className="previewCandidate" data-testid="previewCandidate">
        <div className="container-header">
          <img src={InfoIcon} alt="Info Icon" />
          <span className="header-text">
            Kjo është pamja paraprake për kandidatin <span>{textFormater}</span>
            , lexoni edhe një herë të gjitha të dhënat para së të konfirmoni.
          </span>
        </div>
        <div className="container-content">
          <div className="left-side-content">
            <div
              className="first-row-first-container"
              data-testid="applicantFullName"
            >
              <p>{textFormater}</p>
            </div>
            <div className="first-content-container">
              <div className="left-side-container">{candidateFields}</div>
              <div className="right-side-container">
                {!!temporaryArray?.applicantStatus ? (
                  <div
                    className="custom-status-style"
                    style={{
                      backgroundColor:
                        temporaryArray?.applicantStatus === "Skualifikuar"
                          ? "#ea3943"
                          : temporaryArray?.applicantStatus ===
                            "Listuar për intervistë"
                          ? "#FA8532"
                          : temporaryArray?.applicantStatus === "Në proçes"
                          ? "#FCC94A"
                          : "#20AC9D",
                    }}
                  >
                    {temporaryArray?.applicantStatus}
                  </div>
                ) : null}
                {temporaryArray?.applicantInterviewDate ? (
                  <div className="input-row-style">
                    <img src={CalendarIcon} alt="Calendar Icon" />
                    <p>
                      <span>Data e intervistës: </span>
                      {dayjsTZ(temporaryArray?.applicantInterviewDate)?.format(
                        "D/M/YYYY"
                      )}
                    </p>
                  </div>
                ) : null}
                {temporaryArray?.applicantInterviewDate ? (
                  <div className="input-row-style">
                    <img src={TimeIcon} alt="Time Icon" />
                    <p>
                      <span>Ora e intervistës: </span>
                      {dayjsTZ(temporaryArray?.applicantInterviewDate)?.format(
                        "HH:mm",
                        {
                          trim: false,
                        }
                      )}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="right-side-content">
            <div className="upload-kandidati-button">
              <NewUploadIcon width={90} height={90} />
              <div className="upload-kandidati-button-text-container">
                <span className="upload-kandidati-button-text">
                  Hidhni dokumentat këtu ose klikoni butonin e mëposhtëm
                </span>

                <AddContactDocUploader
                  {...{
                    onPickerSuccess(param) {
                      onPickerSuccess(param);
                      setTempUploadedFiles([
                        ...tempUploadedFiles,
                        ...param.uploadedFiles.map(({ id }) => id),
                      ]);
                      setIsChanged(true);
                    },
                    uploadedFiles,
                    parentFolderId: googleDriveFolderId,
                  }}
                />
              </div>
            </div>

            {!!uploadedFiles?.length ? (
              <div className="uploadedFilesSection">
                {sortBy(uploadedFiles, "lastEditedUtc")
                  .reverse()
                  ?.map(({ url, name = "", id, type, mimeType }) => {
                    const fileExtension = name.split(".").pop();

                    return (
                      <div className="fileCard" key={id}>
                        <div className="uploadFileName">
                          <span className="uploadedFileIcon">
                            {fileIcons({ type: fileExtension }) ||
                              fileIcons({ type: "image" })}
                          </span>
                          <span
                            className="fileName"
                            onClick={() => {
                              if (
                                type !== "photo" &&
                                mimeType !== "application/pdf" &&
                                mimeType !== "image/png"
                              )
                                openInNewTab(url);
                              else {
                                setPreviewModalVisible(true);
                                driveRequest.getImageSrc(id).then((file) => {
                                  setCurrentFile({
                                    ...file,
                                    url,
                                    mimeType,
                                    id,
                                  });
                                });
                              }
                            }}
                          >
                            {name}
                          </span>
                        </div>

                        <RedCloseIcon
                          height={18}
                          width={14}
                          className="closeIcon"
                          onClick={() => {
                            onDelete(id);
                            setIsChanged(true);
                          }}
                        />
                      </div>
                    );
                  })}
                <MultipleFilePrevieModal
                  {...{
                    visible: previewModalVisible,
                    setVisible: () => {
                      setCurrentFile({});
                      setPreviewModalVisible(false);
                    },
                    setCurrentFile,
                    file: currentFile,
                    uploadedFiles,
                    driveRequest,
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* </Modal> */}
      <ShowContactDetailsSecondModal
        showSecondLayoutStatus={showSecondLayoutStatus}
        setShowSecondLayoutStatus={setShowSecondLayoutStatus}
        temporaryArray={temporaryArray}
      />
    </>
  );
};

export default ShowContactDetails;
// export const fields = {
//   applicantAddress: "Adresa e banimit",
//   applicantAdvantages: "Avantazhet",
//   applicantBirthday: "Datëlindja",
//   applicantDiplomaLevel: "Niveli i diplomës",
//   applicantDisadvantages: "Disavantazhet",
//   applicantEmail: "Email",
//   applicantFirstName: "Emër",
//   applicantInterviewDate: "Data e intervistës",
//   applicantLastName: "Mbiemër",
//   applicantPayment: "Norma e pagës",
//   applicantPersonalNumber: "Nr.Personal",
//   applicantPhoneNumber: "Nr.Celulari",
//   applicantPlatform: "Platforma",
//   applicantReference: "Referenca",
//   applicantStartDate: "Data e mundshme e fillimit",
//   applicantTeam: "Skuadra",
//   applicantStatus: "Statusi",
//   applicantTraining: "Trajnime dhe kualifikime të tjera",
//   applicantUniversity: "Universiteti",
//   googleDriveFiles: "Dokumentet",
//   applicantRoles: "Rolet",
//   applicantDepartment: "Departamenti",
// };
export const translateObjectFields = ({ fieldOptions, changes }) => {
  const fieldLabels = new Map(
    (fieldOptions || [])?.map(({ formItemName, label }) => [
      formItemName,
      label,
    ])
  );
  const rest = changes
    .filter((el) => !["keylog"].includes(el?.field))
    .map((log) => {
      const translatedField = fieldLabels.get(log?.field) || log?.field;
      return {
        ...log,
        field: translatedField,
      };
    });
  return rest;
};
export const applicantInterviewDateKeylog = ({
  originalObject,
  changedObject,
  key,
}) => {
  const dataFormats = ["DD/MM/YYYY", "HH:mm"];
  let values = [];
  for (let i = 0; i < dataFormats.length; i++) {
    const format = dataFormats[i];
    if (
      dayjsTZ(originalObject?.[key]).format(format) !==
      dayjsTZ(changedObject?.[key]).format(format)
    ) {
      const formattedValue = dayjsTZ(changedObject[key]).format(format);
      values.push({ key, value: formattedValue, format });
    }
  }
  return values;
};
export async function createDriveFolder({
  driveRequest,
  parentId,
  folderName,
  resetValues,
  defaultPermissions = [],
}) {
  let result = "";
  await driveRequest
    .getFolderIdOrCreate({
      name: folderName,
      parent: parentId,
      defaultPermissions,
    })
    .then((res) => (result = res))
    .catch((err) => {
      message.error({
        key: 0,
        content: "Nuk u lidh dot me Google Drive",
        duration: 2,
      });
      console.log("Error: ", err);
      setTimeout(() => {
        if (resetValues && typeof resetValues === "function") {
          resetValues();
        }
      }, 600);
    });
  return result;
}
