import React, { useContext, useEffect, useState } from "react";
import "./ListaEkandidateve.scss";
import FilterComponent from "../MainTabs/FilterComponent";
import { Badge, Button } from "antd";
import Kandidati from "./Kandidati";
import { useMediaQuery } from "react-responsive";
import {
  CheckIcon,
  ClockIcon,
  InfoBluecIcon,
  IntervistatBigIconGreen,
} from "../../../assets/icons";
import dayjsTZ from "../../../utils/dayjs";
import { InterviewsContext } from "../data";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { API } from "aws-amplify";
import { candidates as candidatesReducer } from "../../../store/actions";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import ApplicantCard from "./ApplicantCard";
const ListaEkandidateve = () => {
  const { candidates } = useSelector((state) => state.candidates);
  const { isDarkMode } = useSelector((state) => state.usersList);
  // const [candidates, setCandidates] = useState(null);
  const location = useLocation();
  const {
    colors,
    intervistat,
    kandidatiSelektuar,
    setKandidatiSelektuar,
    setCreateUserPage,
    setGoogleDriveFolderId,
    activeFilters,
  } = useContext(InterviewsContext);

  //UseEffect qe shikon location State
  useEffect(() => {
    if (!!location?.state?.kandidatiSelektuarId) {
      setKandidatiSelektuar(
        candidates.find(
          (item) => item.applicantId === location?.state?.kandidatiSelektuarId
        )
      );
    }
  }, [location?.state]);

  const selectedCandidate = !!Object.keys(kandidatiSelektuar).length > 0;

  const isNotDesktop = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });
  const responsiveClasses = !!isNotDesktop && !!selectedCandidate;

  const onClick = (data) => {
    setKandidatiSelektuar(data);
    setGoogleDriveFolderId(data?.googleDriveFolderId);
  };

  const boxShadow = `0px 0px 5px ${
    colors?.[kandidatiSelektuar?.applicantStatus] || "#75eade"
  }`;

  return (
    <>
      {candidates?.length > 0 ? (
        <div
          className={`listaEkandidateve ${isDarkMode ? "dark" : ""}`}
          style={
            isNotDesktop
              ? !selectedCandidate
                ? { gridTemplateColumns: "1fr 1fr" }
                : { gridTemplateColumns: "minmax(0, 1fr) minmax(0, 3fr)" }
              : {}
          }
        >
          <div
            className="teGjithKandidatet"
            style={
              !!isMobile
                ? !selectedCandidate
                  ? { height: "max-content" }
                  : { maxHeight: "250px" }
                : isDarkMode
                ? { boxShadow }
                : {}
            }
          >
            <FilterComponent />
            <div className="kandidatet">
              {intervistat?.map((intervista) => {
                return (
                  <ApplicantCard
                    {...{ responsiveClasses, intervista, onClick, badge: true }}
                  />
                );
              })}
            </div>
          </div>
          {Object.keys(kandidatiSelektuar).length === 0 ? null : (
            <div
              className="kandidatiContent"
              style={isDarkMode ? { boxShadow } : {}}
            >
              <Kandidati />
            </div>
          )}
        </div>
      ) : (
        <div className="noKandidads">
          <div className="noData">
            <IntervistatBigIconGreen />
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => {
                setCreateUserPage(true);
              }}
            >
              Shtoni një kandidat
            </MondayButton>
            <span className="txtKandidat">
              Aktualisht nuk ka asnjë kandidat, nëse dëshironi të shtoni një,
              klikoni butonin e mësipërm.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ListaEkandidateve;
