import { API } from "aws-amplify";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Alert, Form, message, Skeleton } from "antd";
import { Auth } from "aws-amplify";
import SiguriaPerdoruesitModal from "../../../../Konfigurimet/SiguriaSettings/SiguriaPerdoruesit/SiguriaPerdoruesitModal";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./User.scss";
import LoadedUser from "./LoadedUser";
import { NoUser, WarningRed } from "../../../../../assets/icons";
import MergeEmployeeUser from "./MergeEmployeeUser";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import CreateUserModal from "../../../../Konfigurimet/SiguriaSettings/SiguriaPerdoruesit/CreateUserModal/CreateUserModal";
import PunonjesitContext from "../../../store/PunonjesitContext";
function User() {
  const { employee } = useContext(PunonjesitContext);

  const { users } = useSelector((state) => state.usersList);

  const [renderUser, setRenderUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [mergeVisib, setMergeVisib] = useState(false);

  useEffect(() => {
    let toReturn = null;
    if (!!employee?.userSub) {
      toReturn = users?.allUsers?.Items?.find(
        (el) => el.userSub === employee?.userSub
      );
    }
    setRenderUser(toReturn);
  }, [users?.allUsers?.Items, employee]);

  const noAccount = (content) => (
    <>
      <span>
        <WarningRed />
      </span>
      <span className="no-user-merge__account">Nuk ka llogari!</span>
      <span className="no-user-merge__employee">
        {content ||
          "Punonjësi i zgjedhur nuk ka një llogari për të aksesuar sistemin."}
      </span>
      <MondayButton className="mondayButtonGreen" onClick={() => setOpen(true)}>
        Krijo Përdorues
      </MondayButton>
      <MondayButton
        className="mondayButtonGreen"
        onClick={() => setMergeVisib(true)}
      >
        Lidh Userin me Punonjësin
      </MondayButton>
    </>
  );

  return (
    <div className="loaded-user-merge-card">
      {!!renderUser ? (
        <LoadedUser {...{ renderUser, setRenderUser }} />
      ) : (
        <div className="no-user-merge">
          {employee?.accountStatus !== "created" &&
          employee?.accountStatus !== "registered" ? (
            noAccount()
          ) : employee?.accountStatus === "created" ? (
            <>
              <span>
                <NoUser />
              </span>
              <span className="no-user-merge__account">
                Llogaria është krijuar me sukses!
              </span>
              <span className="no-user-merge__employee">
                Pritet që përdoruesi të logohet në sistem për të aktivizuar
                llogarinë.
              </span>
            </>
          ) : (
            noAccount(
              "Llogaria e regjistruar më parë për këtë punonjës është fshirë!"
            )
          )}
        </div>
      )}
      {!!open ? <CreateUserModal {...{ open, setOpen }} /> : null}
      {!!mergeVisib ? (
        <MergeEmployeeUser
          visible={mergeVisib}
          setVisible={setMergeVisib}
          selected={employee}
        />
      ) : null}
    </div>
  );
}
export default User;
