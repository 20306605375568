import { Tooltip } from "antd";
import { PDFIcon } from "../../../../assets/icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { valueFormatter } from "../utils/valueFormatter";

const AgGridToPDF = ({
  gridApi,
  rowData,
  title,
  tableCols,
  modifyRows,
  className,
  style,
  idKey,
}) => {
  const handleClick = () => {
    let filteredRows = [];
    if (!!gridApi) {
      const selectedNodes = gridApi
        .getSelectedNodes()
        .map((node) => node.data?.[idKey]);
      gridApi.forEachNodeAfterFilter((node) => {
        if (!!selectedNodes?.length) {
          if (selectedNodes.includes(node.data?.[idKey]))
            filteredRows.push(node.data);
        } else {
          filteredRows.push(node.data);
        }
      });
    } else if (!!rowData && Array.isArray(rowData)) {
      filteredRows = [...rowData];
    }

    if (!filteredRows || filteredRows.length === 0) {
      return;
    }

    if (!!modifyRows && typeof modifyRows === "function") {
      filteredRows = modifyRows(filteredRows);
    }

    const doc = new jsPDF({ orientation: "landscape" });
    const tableBody = [];
    filteredRows?.forEach((row) => {
      const tableRow = [];
      tableCols?.forEach((col) => {
        let value = row[col?.en || col];
        let formattedValue = valueFormatter(col, value, false);
        tableRow.push(formattedValue);
      });
      tableBody.push(tableRow);
    });
    autoTable(doc, {
      head: [tableCols?.map((el) => el?.al || el)],
      body: tableBody,
      margin: { top: 20 },
      didDrawPage: () => {
        doc.setFontSize(20);
        doc.text(110, 15, title);
        doc.setCreationDate();
      },
    });
    // const blobb = new Blob([doc.output("blob")], { type: "application/pdf" });
    // const url = URL.createObjectURL(blobb);
    // window.open(url);

    doc.save(`${title}.pdf`);
  };
  return (
    <div
      className={`headerIcon ${className}`}
      data-testid="headerPDF"
      onClick={handleClick}
      style={style}
    >
      <Tooltip
        placement="top"
        classNames={{ root: "global-icon-tooltip" }}
        title={"Eksporto në PDF"}
      >
        <PDFIcon width={20} height={20} fill={style?.fill || "#323338"} />
      </Tooltip>
    </div>
  );
};

export default AgGridToPDF;
