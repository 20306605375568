import "./NotFound.scss";
import { NotFound404 } from "../../../assets/images";
import { useNavigate } from "react-router";
import MondayButton from "../MondayButton/MondayButton";
import { PageNotFoundLogo, SidebarClose } from "../../../assets/icons";
import PageNotFound from "../PageNotFound";
import { useSelector } from "react-redux";

const NotFound = () => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const navigate = useNavigate();

  return NotFound404 ? (
    <div className={`NotFoundViewContainer ${isDarkMode ? "dark" : ""}`}>
      <span className="title">Faqja nuk u gjet!</span>
      <span className="subtitle">Na vjen keq, kjo faqe nuk ekziston.</span>
      <PageNotFoundLogo />
      {/* <img src={NotFound404} /> */}
      <MondayButton
        className="mondayButtonBlue"
        // Icon={<SidebarClose fill="#ea3943" />}
        onClick={() => navigate("/kryefaqja")}
      >
        Shko në Kryefaqe
      </MondayButton>
    </div>
  ) : null;
};
export default NotFound;
