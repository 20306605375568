import React from "react";
import { useSelector } from "react-redux";
import { FullTime } from "../../../assets/icons";
import DragCard from "./Draggable/DragCard";
import { useNavigate } from "react-router-dom";
import { HollowDotsSpinner } from "react-epic-spinners";
import { Subcontractors as SubsIcon } from "../../../assets/icons";

const Subcontractors = () => {
  const navigate = useNavigate();

  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const subcontractors = activeEmployees?.filter(
    (el) => el?.employeeTypeOfEmployment === "Nen Kontraktor"
  );

  const data = {
    icon: <SubsIcon fill={isDarkMode ? "#e6e6e6" : "#1d3943"} />,
    number: (
      <div className="link-kryefaqja-card-send">
        {subcontractors?.length || 0}
      </div>
    ),
    text: "Nënkontraktorë",
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {!!subcontractors ? (
        <DragCard
          data={data} // will navigate to employes tab with the state to filter the grid with the type in the state
          onClick={() =>
            navigate("/punonjesit", {
              state: {
                stateValue: "Nen Kontraktor",
                filterKey: "employeeTypeOfEmployment",
              },
            })
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </div>
  );
};
export default Subcontractors;
