import { useState, useMemo } from "react";
import { IconPunonjesit } from "../../../../assets/icons";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import "./siguria_perdoruesit.scss";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import Enable2FaModal from "./Enable2FaModal/Enable2FaModal";
import { colDefs } from "./colDefs";
import CreateUserModal from "./CreateUserModal/CreateUserModal";

const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: [
        { key: "given_name", label: "Emri", type: "string" },
        {
          key: "family_name",
          label: "Mbiemri",
          type: "string",
        },
        { key: "gender", label: "Gjinia", type: "string" },
        { key: "email", label: "Email", type: "string" },
        { key: "userRole", label: "Lloji i userit", type: "string" },
        { key: "department", label: "Departamenti", type: "string" },
        { key: "department_role", label: "Pozicioni", type: "string" },
        { key: "phone_number", label: "Nr.Tel", type: "string" },
        { key: "createdAt", label: "Krijuar në", type: "string" },
      ],
    },
    excel: {},
    pdf: {},
    print: {},
  },
  dropdowns: [
    {
      keyName: "department",
      placeholder: "Të gjithë",
      icon: <IconPunonjesit width={15} />,
    },
  ],
};

function SiguriaPerdoruesit() {
  const navigate = useNavigate();

  const { users } = useSelector((state) => state.usersList);

  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [mfaOpen, setMfaOpen] = useState(false);

  const rowData = useMemo(() => {
    if (!users?.allUsers?.Items?.length) return [];
    else {
      return users?.allUsers?.Items?.map((user) => ({
        ...user,
        id: user?.identityId,
        name: user?.given_name + " " + user?.family_name,
        is2FaActive: !!user?.auth2Fa?.is2FaActive,
        visible: true,
        employeeFullName: user?.given_name + " " + user?.family_name,
      }));
    }
  }, [users?.allUsers?.Items]);

  //region RETURN
  return (
    <LoadableComp loading={!!rowData ? false : true}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          rowData={rowData}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={(p) => setGridApi(p.api)}
          paginationPageSize={16}
          columnDefs={colDefs({ navigate })}
          suppressServerSideInfiniteScroll={"full"}
          rowModelType={"serverSide"}
          headerProps={{
            items: ITEMS,
            children: [
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => setOpen(true)}
              >
                Krijo përdorues
              </MondayButton>,
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => setMfaOpen(true)}
              >
                Konfiguro 2FA
              </MondayButton>,
            ],
          }}
          idKey={"identityId"}
          onRowClick={(rowId) => navigate(`/konfigurimet/perdoruesit/${rowId}`)}
        />
      </div>
      {open ? <CreateUserModal open={open} setOpen={setOpen} /> : null}
      {!!mfaOpen ? (
        <Enable2FaModal
          open={mfaOpen}
          gridApi={gridApi}
          setOpen={setMfaOpen}
          dataList={rowData}
          title="Modifiko 2FA për punonjësit"
        />
      ) : null}
    </LoadableComp>
  );
}

export default SiguriaPerdoruesit;
