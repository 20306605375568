import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { useSelector } from "react-redux";
import "./ClockInStats.scss";
import { iconPicker } from "./iconPicker";
import { Col, Row, Tooltip } from "antd";
import { HollowDotsSpinner } from "react-epic-spinners";

const NoClockIn = () => {
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { clockings } = useSelector((state) => state.clockings);
  const [missedClockIns, setMissedClockIns] = useState([]);
  //NQS PUNONJESIT NUK KANE BERE CLOCK OUT NJE DITE ME PARE, ATA DO TE SHFAQEN SI PUNONJES QE NUK KANE BERE CLOCK IN PER DITEN E SOTME

  useEffect(() => {
    if (clockings?.length > 0 && eligibleEmployeesClockIn?.length > 0) {
      //Merr clock in e bere diten e sotme
      let filteredClockIns = clockings?.filter((el) =>
        dayjsTZ()?.isSame(el?.clockInDate, "day")
      );
      //Nga tabela employees merr ata punonjes id e te cileve, nuk gjendet te clock in e dites se sotme
      let filteredUsers = eligibleEmployeesClockIn?.filter(
        (el) =>
          !filteredClockIns?.find((clk) => el?.employeeId === clk?.employeeId)
      );
      setMissedClockIns(filteredUsers);
    }
  }, [clockings, eligibleEmployeesClockIn]);

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!eligibleEmployeesClockIn && !!clockings ? (
        <div className={`no-clockin-container ${isDarkMode ? "dark" : ""}`}>
          {missedClockIns?.map((dco) => {
            return (
              <div className="dep-icon" key={dco?.employeeId}>
                <div
                  key={dco?.employeeId}
                  className="dep-icon-text"
                  style={{ width: "40%" }}
                >
                  {dco?.employeeFirstName + " " + dco?.employeeLastName}
                </div>

                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {iconPicker(
                    dco?.employeeDepartmentName,
                    isDarkMode ? "#e6e6e6" : "#323338"
                  )}
                  <div className="dep-icon-text">
                    {dco.employeeDepartmentName}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </>
  );
};

export default NoClockIn;
