import "./DayPayHeader.scss";
import React, { useState } from "react";
import dayjsTZ from "../../../../../utils/dayjs";
import { InputNumber, message } from "antd";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { dayCalculateFactory } from "../../../PaySub/employeeCalc/dayCalculateFactory";
import { add, subtract } from "lodash";
import { AutoCompleteIcon } from "../../../../../assets/icons";
import { useSelector } from "react-redux";

function DayPayHeader({ shift, salaryPerHour, setSalaryPerHour, setAmounts }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const calculateTotalities = async () => {
    const newRate =
      parseFloat(shift?.totalities?.employeeRate) !== parseFloat(salaryPerHour);

    if (newRate) {
      message.loading({
        key: "tots",
        content: "Duke llogaritur...",
      });
      const { employeeId, clockingId, ...rest } = shift || {};
      await dayCalculateFactory({
        ...rest,
        totalities: {
          ...(rest?.totalities || {}),
          employeeRate: parseFloat(salaryPerHour),
        },
      })
        .then((res) => {
          const savedTotalDay =
            res?.normal?.pay + res?.late?.pay + res?.extra?.pay;
          setAmounts((prev) => ({
            ...(prev || {}),
            normal: res?.normal?.pay,
            late: res?.late?.pay,
            extra: res?.extra?.pay,
            savedTotalDay,
            totalDay: subtract(
              add(savedTotalDay || 0, prev?.additions || 0),
              prev?.deductions || 0
            ),
          }));
          message.success({
            key: "tots",
            content: "Pagesa ditore u përditësua me sukses!",
          });
        })
        .catch((err) => {
          message.error({
            key: "tots",
            content: "Dicka shkoi gabim!",
          });
          console.log("err: ", err);
        });
    }
  };

  //region RETURN
  return (
    <div className={`dayPayHeader ${isDarkMode ? "dark" : ""}`}>
      <div className="headerItem">
        Data:
        <span className="disabledInput">
          {dayjsTZ(shift?.data).format("DD/MM/YYYY")}
        </span>
      </div>
      <div className="headerItem">
        Kategoria:
        <span className="disabledInput">{dayType(shift?.dayType)}</span>
      </div>
      <div className="headerItem">
        Fillimi Turnit:
        <span className="disabledInput">
          {dayjsTZ(shift?.fillim_turni).format("HH:mm")}
        </span>
      </div>
      <div className="headerItem">
        Mbarimi Turnit:
        <span className="disabledInput">
          {dayjsTZ(shift?.mbarim_turni).format("HH:mm")}
        </span>
      </div>
      <div className="headerItem" style={{ flex: 2 }}>
        <strong style={{ color: "red" }}>*</strong>Pagesa për orë:
        <span className="disabledInput">
          <InputNumber
            variant={false}
            min={1}
            max={100000}
            value={salaryPerHour}
            onChange={(e) => setSalaryPerHour(e)}
          />
        </span>
        <MondayButton
          className="mondayButtonGreen"
          onClick={calculateTotalities}
          Icon={<AutoCompleteIcon />}
        />
      </div>
    </div>
  );
}

export default DayPayHeader;

const dayType = (dayType) => {
  switch (dayType) {
    case "Regular":
      return "Normal";
    case "Sick":
      return "Sëmurë";
    case "Maternity":
      return "Leje Lindje";
    case "NationalHoliday":
      return "Feste Zyrtare";
    case "HolidayRequest":
      return "Kërkesë Leje";
  }
};
