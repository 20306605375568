import React, { useState } from "react";
import { Row, Col, message, Popover, Alert } from "antd";
import { FlexLogo, IconPerdoruesi } from "../../assets/icons";
import "./auth-scss/forgotPassword.scss";
import { Form, Input, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { emailRegex, passwordRegex } from "../dashboard/utils/regex";
import { EyeFilled, InfoCircleOutlined, LockFilled } from "@ant-design/icons";
import swal from "sweetalert";
import { useSelector } from "react-redux";

function ForgotPassword() {
  const navigate = useNavigate();

  const { isDarkMode } = useSelector((state) => state.usersList);

  const [login, setLogin] = useState({ log_in: "" });
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sentCode, setSentCode] = useState(false);
  const [successChange, setSuccessChange] = useState(false);

  const validateReset = (code, passw, passw2) => {
    if (code !== "" && passw === passw2) {
      if (passw) return true;
    } else {
      return false;
    }
  };

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);
    setLoading(true);
    setEmail(values?.username);
    await Auth.forgotPassword(values.username)
      .then(
        (result) => {
          message.success("Kodi i sigurisë është dërguar në emailin tuaj. ");
          // console.log("result ", result);
          setSentCode(true);
        },
        (err) => {
          console.error("error sending code", err);
          message.error("Nodhi një gabim!");
        }
      )
      .catch((e) => {
        console.error("error", e);
      });
    setSentCode(true);
  };

  const onReset = async (values) => {
    const { verification, password, confirmPassword } = values;
    let validation = validateReset(verification, password, confirmPassword);
    if (validation && email !== null) {
      await Auth.forgotPasswordSubmit(email, verification, password)
        .then((succ) => {
          setSuccessChange(true);
          swal({
            title: `Fjalëkalimi u ndryshua me sukses! ${email}`,
            icon: "success",
            className: isDarkMode ? "custum-swal-dark" : "custum-swal",
          });
          navigate("/login");
        })
        .catch((err) => {
          console.error("error changing password", err);
          swal({
            title: `Fjalëkalimi nuk mund te ndryshohet, kontrollo të dhënat dhe provo përsëri.`,
            icon: "error",
            className: isDarkMode ? "custum-swal-dark" : "custum-swal",
          });
        });
    }
  };

  return !sentCode ? (
    <div className="login-grid">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              pattern: new RegExp(emailRegex),
              message: "Email i pasaktë!",
            },
          ]}
        >
          <Input
            size="medium"
            width={100}
            placeholder="Emri i Përdoruesit/Email"
            autoComplete="off"
            suffix={
              <IconPerdoruesi
                width={16}
                height={16}
                className="site-form-item-icon"
              />
            }
            onChange={(e) => {
              setLogin({ ...login, log_in: e.target.value });
            }}
          />
        </Form.Item>
        <br />
        <br />
        <div className="custom-form">
          <Link to="/login">
            <Button type="primary" className="back-form-button">
              Kthehu pas
            </Button>
          </Link>
          <Button
            type="primary"
            htmlType="submit"
            className={login.log_in ? "filled-button" : "login-form-button"}
          >
            Dërgo Emailin e Verifikimit
          </Button>
        </div>
      </Form>
    </div>
  ) : (
    <Col span={24}>
      <div className="background">
        <div className="login-grid-final">
          <div className="form-header">Vendosni një fjalëkalim të ri </div>
          <Form
            name="login_reset"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onReset}
          >
            <Form.Item
              name="verification"
              rules={[
                {
                  required: true,
                  message: "Ju lutem vendosni kodin e verifikimit!",
                },
              ]}
            >
              <Input
                placeholder="Kodi i verifikimit"
                className="verification_code"
                width={100}
                controls={false}
                autoFocus={true}
                prefix={
                  <Popover
                    content={
                      "Ju lutem vendosni kodin tuaj të dërguar në email."
                    }
                    trigger="hover"
                  >
                    <InfoCircleOutlined
                      style={{
                        fontSize: 14,
                        marginRight: "5px",
                        color: "#1F2A44",
                      }}
                    />
                  </Popover>
                }
              />
            </Form.Item>
            <Alert
              message="Fjalëkalimi juaj duhet të ketë të paktën 8 karaktere, një shkronjë të vogël, një shkronjë të madhe, dhe një karakter të veçantë."
              type="warning"
              banner
              style={{
                width: 375,
                lineHeight: 1.4,
                marginLeft: 70,
              }}
            />
            <br />
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(passwordRegex),
                  message:
                    "Ju lutem vendosni një fjalëkalim që zbaton rregullat e sigurisë.",
                },
              ]}
            >
              <Input.Password
                placeholder="Fjalëkalimi"
                prefix={<LockFilled style={{ height: 25 }} />}
                autoComplete="new-password"
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeFilled />
                }
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Ju lutem, konfirmoni fjalëkalimin e ri!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Fjalekalimet nuk bashkangjiten!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password..."
                prefix={<LockFilled style={{ height: 25 }} />}
                autoComplete="new-password"
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeFilled />
                }
              />
            </Form.Item>
            <div className="forgot_password_action_btn">
              <Link to="/login">
                <Button type="primary" className="back-form-button">
                  Kthehu pas
                </Button>
              </Link>
              <Button
                type="primary"
                htmlType="submit"
                className="filled-button"
              >
                Ndrysho
              </Button>
            </div>
          </Form>
        </div>{" "}
      </div>{" "}
    </Col>
  );
}

export default ForgotPassword;
