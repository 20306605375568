import "./EditWageDetails.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, InputNumber, Select, Space, Typography } from "antd";
import { PlusIcon } from "../../../../../../assets/icons";
import { useEffect, useMemo, useState } from "react";
import { multiply } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

const { Option } = Select;

const EditWageDetails = ({
  rowObj,
  setRowObj,
  onAdd,
  salaryPerHour,
  resetFields,
  children,
}) => {
  const { deductions: deds } = useSelector((state) => state.deductions);
  const { earnings } = useSelector((state) => state.earnings);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [newType, setNewType] = useState("");
  const [options, setOptions] = useState({});
  const [currentMode, setCurrentMode] = useState("additions");

  //region >onChange()
  const onChange = (key, val) => {
    if (key === "hours") {
      setRowObj((prev) => ({
        ...prev,
        [key]: val,
        days: val / 8,
        amount: multiply(val, salaryPerHour),
      }));
    }
    if (key === "amount") {
      setRowObj((prev) => ({ ...prev, [key]: val, hours: 0, days: 0 }));
    } else if (key === "days") {
      setRowObj((prev) => ({
        ...prev,
        [key]: val,
        hours: multiply(8, val),
        amount: multiply(multiply(8, val), salaryPerHour),
      }));
    } else {
      setRowObj((prev) => ({ ...prev, [key]: val }));
    }
  };

  //region >onTypeSelect()
  const onTypeSelect = (id, obj) => {
    if (!!id) {
      onChange("type", obj?.name);

      const idx = (options?.[currentMode].types || []).findIndex(
        (el) => el?.id === id
      );

      if (idx > -1) {
        const { rate } = options[currentMode].types[idx] || {};
        onChange("rate", parseFloat(rate));
      }
    } else {
      resetFields();
    }
  };

  //region >addNewOption()
  const addNewOption = () => {
    const id = uuidv4();
    setOptions((prev) => ({
      ...(prev || {}),
      [currentMode]: {
        ...(prev?.[currentMode] || {}),
        hasCustomOptions: true,
        types: [
          ...(prev?.[currentMode].types || []),
          {
            option: (
              <Option
                key={prev?.[currentMode] || [].length + 1}
                value={id}
                name={newType}
              >
                {newType}
              </Option>
            ),
            id: uuidv4(),
            isCustomOption: true,
          },
        ],
      },
    }));
    setNewType("");
  };

  //region >getDefaultOptions()
  const getDefaultOptions = () => {
    const deductions = {
      hasCustomOptions: false,
      types: [],
    };

    deds.forEach(({ deductionName, deductionId, deductionRule }, idx) => {
      deductions.types.push({
        rate: deductionRule,
        option: (
          <Option key={idx} value={deductionId} name={deductionName}>
            {deductionName}
          </Option>
        ),
        id: deductionId,
        name: deductionName,
        isCustomOption: false,
      });
    });

    const additions = {
      hasCustomOptions: false,
      types: [],
    };

    earnings.forEach(({ earningName, earningId, rateFactor }, idx) => {
      additions.types.push({
        rate: rateFactor,
        option: (
          <Option key={idx} value={earningId} name={earningName}>
            {earningName}
          </Option>
        ),
        id: earningId,
        name: earningName,
        isCustomOption: false,
      });
    });
    setOptions({ deductions, additions });
  };

  const disableRate = useMemo(() => {
    if (!!options?.[currentMode]?.hasCustomOptions) {
      const types = options?.[currentMode]?.types || [];
      const idx = types.findIndex((el) => el?.name === rowObj?.type);
      if (idx > 0) {
        const selectedType = types[idx];
        return !selectedType.isCustomOption;
      }
      return false;
    } else {
      return true;
    }
  }, [currentMode, options, rowObj]);

  useEffect(() => {
    if (!!deds && !!earnings) {
      getDefaultOptions();
    }
  }, [deds, earnings]);

  //region RETURN
  return (
    <div className={`actions-wrapper ${isDarkMode ? "dark" : ""}`}>
      <div className="action-btns">
        <div className="first">
          <span
            onClick={() => setCurrentMode("additions")}
            className="action-btn"
            style={
              currentMode === "additions"
                ? { background: "#1da193", color: "white" }
                : null
            }
          >
            Fitime
          </span>
          <span
            className="action-btn"
            onClick={() => setCurrentMode("deductions")}
            style={
              currentMode === "deductions"
                ? { background: "#ea3943 ", color: "white" }
                : null
            }
          >
            Zbritje
          </span>
        </div>
        {!!children && children}
      </div>
      <div className="edit-wage-row" style={{ gap: 10, padding: "20px 0" }}>
        <div className="editWageItem">
          <label>Lloji:</label>
          <Select
            showSearch
            placeholder="Zgjidh"
            popupClassName={isDarkMode ? "darkDropdown" : ""}
            optionFilterProp="children"
            allowClear
            value={rowObj?.type}
            defaultValue={rowObj?.type}
            onChange={onTypeSelect}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Shto Opsion"
                    value={newType}
                    onChange={(e) => setNewType(e.target.value)}
                  />
                  <Typography.Link
                    onClick={() => {
                      if (!!newType) {
                        addNewOption();
                      }
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined />
                  </Typography.Link>
                </Space>
              </>
            )}
          >
            {(options?.[currentMode]?.types || []).map((el) => el?.option)}
            {/* {additionalOptions?.map((el) => (
              <Option value={el}>{el}</Option>
            ))} */}
          </Select>
        </div>
        <div className="editWageItem">
          <label>Shuma:</label>
          <InputNumber
            min={0}
            placeholder="Zgjidh Shumen"
            value={rowObj?.amount}
            defaultValue={rowObj?.amount}
            onChange={(val) => onChange("amount", val)}
          />
        </div>
        <div className="editWageItem">
          <label>Orët:</label>
          <InputNumber
            min={0}
            placeholder="Zgjidh Oret"
            value={rowObj?.hours}
            defaultValue={rowObj?.hours}
            onChange={(val) => onChange("hours", val)}
          />
        </div>
        <div className="editWageItem">
          <label>Ditët (1=8 orë):</label>
          <InputNumber
            disabled={false}
            min={0}
            placeholder="Zgjidh Ditet"
            value={rowObj?.days}
            defaultValue={rowObj?.days}
            onChange={(val) => onChange("days", val)}
          />
        </div>
        <div className="editWageItem">
          <label>Shënimi</label>
          <Input
            placeholder="Vendos Shenimin"
            value={rowObj?.note}
            defaultValue={rowObj?.note}
            onChange={(val) => onChange("note", val.target.value)}
          />
        </div>
        <div className="editWageItem">
          <label>Koeficienti</label>
          <Select
            showSearch
            placeholder="Zgjidh Koeficentin"
            optionFilterProp="children"
            disabled={disableRate}
            value={rowObj?.rate}
            onChange={(val) => onChange("rate", parseFloat(val))}
          >
            <Option value={0.8}>0.8</Option>
            <Option value={1}>1</Option>
            <Option value={1.2}>1.2</Option>
            <Option value={1.5}>1.5</Option>
          </Select>
        </div>
        <div className="editWageItem">
          <label>Shto</label>
          <span className="add-icon" onClick={() => onAdd(currentMode)}>
            <PlusIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditWageDetails;
