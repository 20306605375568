import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Button, Tooltip } from "antd";
import {
  IconKerko,
  DeleteIcon,
  // IconFilter,
  IconNdrysho,
  IconExcel,
  IconNgargo,
} from "../../../../assets/icons";
import "./politikatDItetPushim.scss";
import { API } from "aws-amplify";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { htmlParser } from "../../../../utils";
import PolitikatDitetPushimModal from "./PolitikatDitetPushimModal";
import PolitikatDItetPushimFilter from "./PolitikatDItetPushimFilter";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import HtmlExelTabel from "../../dynamic components/HtmlExelTabel";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import { programFields as programFieldsDispatch } from "../../../../store/actions";
import { requestData } from "../../../../helpers/API/RequestFactory";
import { useMediaQuery } from "react-responsive";
import MobilePolitikatDitePushim from "./MobilePolitikatDitePushim";
import { LogsIcon } from "../../../Documentation/View/assets";
import PaisjetOraritLogs from "../../SistemiSettings/SistemiPaisjetOrarit/PaisjetOraritLogs";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

/**
 * * @EneaXharau - Websocket connection
 */
// const socketHandler = new WebSocket(
//   getSocketUrl("/settings/policies/days/off")
// );
const FILTER_KEYS = [
  { key: "name", label: "Ditët e pushimit", type: "string" },
  {
    key: "description",
    label: "Përshkrimi për ditët e pushimit",
    type: "string",
  },
  { key: "formula", label: "Formula", type: "string" },
];
const EXPORT_COLS = [
  { en: "name", al: "Ditët e pushimit" },
  { en: "description", al: "Përshkrimi për ditët e pushimit" },
  { en: "formula", al: "Formula" },
];

function PolitikatDitetPushim() {
  // are the columnDefs that are showed in the AgGrid
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "id",
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      flex: 1,
      hide: true,
    },
    {
      headerName: "Ditët e pushimit",
      field: "name",
      mobile: true,
      isCollapseTitle: true,
      filter: "agTextColumnFilter",
      flex: 3,
    },
    {
      headerName: "Përshkrimi për ditët e pushimit",
      field: "description",
      filter: "agTextColumnFilter",
      mobile: true,
      flex: 5,
      cellRenderer: ({ data }) => htmlParser(data?.description),
    },
    {
      headerName: "Formula",
      field: "formula",
      mobile: true,
      filter: "agTextColumnFilter",
      flex: 5,
    },
    {
      headerName: "",
      field: "",
      filter: "agTextColumnFilter",
      flex: 2,
      cellRenderer: (params) => (
        <div className="iconat-paisjet">
          <div variant="outlined" cursor="pointer" className="icon-edit">
            <IconNdrysho
              onClick={() => handleUpdate(params.data)}
              width={14}
              height={14}
            />
          </div>
          <GlobalPopupKonfirm
            Button={
              <div variant="outlined" cursor="pointer" className="icon-delete">
                <DeleteIcon
                  width={17}
                  height={17}
                  //   onClick={() => handleRequestDelete(params.node.data.id)}
                />
              </div>
            }
            title={"Jeni të sigurt që do të fshini këtë rekord?"}
            onConfirm={() => handleRequestDelete(params.node.data.id)}
            okText={"Po"}
            cancelText={"Jo"}
          />
        </div>
      ),
    },
  ];
  const initialValue = {
    name: "",
    description: "",
    formula: "",
  };
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [visible, setVisible] = useState(false);
  //get the table programFields with redoux
  const { programFields } = useSelector((state) => state.programFields);
  const [request, setRequests] = useState([]);
  const [dataLista, setDataLista] = useState("");
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const [error, setError] = useState(true);
  const [logsVisible, setLogsVisible] = useState(false);

  const dispatch = useDispatch();

  const filterData = useMemo(() => {
    return programFields?.find(
      (data) => data?.fieldId === "52ad6701-32b0-414c-92c6-549bc45c5656"
    );
  }, [programFields]);

  // find the nedded objec in the table
  // const filterData = programFields?.find(
  // 	(data) => data?.fieldId === "52ad6701-32b0-414c-92c6-549bc45c5656"
  // );
  // delete the row in the agGrid

  const refreshRedux = async () => {
    await requestData("programFields").then((res) =>
      dispatch(programFieldsDispatch(res))
    );
  };

  const handleRequestDelete = (id) => {
    let newArray = tableData.filter((r) => r.id !== id);
    API.put(
      "programFields",
      "/programFields/52ad6701-32b0-414c-92c6-549bc45c5656",
      {
        body: {
          fieldOptions: newArray,
        },
      }
    ).then(() => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "policy_removed",
      // 			body: "A policy was removed",
      // 		})
      // 	);
      // }
      setTableData(newArray);
      let field = request.fieldOptions.filter((el) => el.id !== id);
      setRequests((prev) => ({ ...prev, fieldOptions: field }));
    });
  };
  //get the data and save it in the state
  const fetchData = () => {
    const fieldsData = filterData;
    setTableData(fieldsData?.fieldOptions);
    setRequests(fieldsData);
  };
  //opens the modal with the state with the object of the clicked row
  const handleUpdate = (oldData) => {
    setVisible(true);
    setDataLista(oldData);
    setError(false);
  };
  // get the data when the page is renderd
  useEffect(() => {
    fetchData();
  }, [filterData]);
  //make the agGrid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  // opens the modal to create a new record
  const handleClickOpen = () => {
    setVisible(true);
    setDataLista(initialValue);
    setError(true);
    // form.resetFields();
  };

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  // dropdon filter check to not repeat same value 2 times
  const duplicateCheck = [];
  const descriptionduplicateCheck = [];
  const formuladuplicateCheck = [];
  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.name)) return null;
        duplicateCheck.push(data.name);
        return data;
      })
      ?.filter((e) => e);
  tableData &&
    tableData
      ?.map((data) => {
        if (descriptionduplicateCheck.includes(data.description)) return null;
        descriptionduplicateCheck.push(data.description);
        return data;
      })
      ?.filter((e) => e);
  tableData &&
    tableData
      ?.map((data) => {
        if (formuladuplicateCheck.includes(data.formula)) return null;
        formuladuplicateCheck.push(data.formula);
        return data;
      })
      ?.filter((e) => e);
  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "policy_removed_received": {
  // 				refreshRedux();
  // 				break;
  // 			}
  // 			case "policy_added_received": {
  // 				refreshRedux();
  // 				break;
  // 			}
  // 			case "policy_updated_received": {
  // 				refreshRedux();
  // 				break;
  // 			}
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  const ITEMS = {
    search: {},
    icons: {
      filter: {
        filterKeys: FILTER_KEYS,
      },
      excel: { tableCols: EXPORT_COLS },
      pdf: { tableCols: EXPORT_COLS },
      print: { tableCols: EXPORT_COLS },
      modals: [{ onClick: () => setLogsVisible(true) }],
    },
  };

  //region RETURN
  return (
    <LoadableComp loading={!!tableData ? false : true}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          rowData={tableData}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={onGridReady}
          paginationPageSize={15}
          columnDefs={columnDefs}
          headerProps={{
            items: ITEMS,
            children: [
              <MondayButton
                className="mondayButtonGreen"
                onClick={handleClickOpen}
              >
                Shto një ditë pushimi
              </MondayButton>,
            ],
          }}
          idKey={"id"}
        />
      </div>
      {visible ? (
        <PolitikatDitetPushimModal
          {...{
            visible,
            setVisible,
            request,
            setTableData,
            setRequests,
            tableData,
            dataLista,
            setError,
            error,
            // socketHandler,
            socketHandler: () => {},
          }}
        />
      ) : null}
      {logsVisible ? (
        <PaisjetOraritLogs
          data={tableData}
          isVisible={logsVisible}
          setIsVisible={setLogsVisible}
          logsName={"Pushimet"}
        />
      ) : null}
    </LoadableComp>
  );
}
export default PolitikatDitetPushim;
