import React, { forwardRef, useMemo } from "react";
import { Popover } from "antd";

import { useSelector } from "react-redux";
import { useAuth } from "../../../../authentication/authHelpers";
import { DragIcon } from "../../../../assets/icons";
import { CardIce } from "../../../../assets/holidays/icons";

export const DashboardLayoutCard = forwardRef(
  (
    {
      style,
      title,
      cardKey,
      children,
      className,
      onMouseUp,
      onTouchEnd,
      onMouseDown,
      handleBlur,
      handleDelete,
      data,
      change,
      ...rest
    },
    ref
  ) => {
    const { isDarkMode } = useSelector((state) => state.usersList);

    const Component = data.content;

    return (
      <div
        {...{
          className: `${className} sortable-item-style ${
            isDarkMode ? "dark" : ""
          }`,
          ref,
          style,
          onMouseUp,
          onTouchEnd,
          onMouseDown,
          children,
          ...rest,
        }}
        // ref={ref}
      >
        <div className={`content-style ${!!isDarkMode ? "dark" : ""}`}>
          {data?.name === "ClockIn" ? (
            <DynamicHeader
              item={{ ...data, i: cardKey }}
              handleDelete={handleDelete}
              handleBlur={handleBlur}
              change={change}
              // name={props?.data?.name}
              {...{
                style,
                title,
                cardKey,
                children,
                isDarkMode,
                className,
                onMouseUp,
                onTouchEnd,
                onMouseDown,
                data,
                ...rest,
              }}
            />
          ) : (
            <StaticHeader
              item={{ ...data, i: cardKey }}
              handleDelete={handleDelete}
              handleBlur={handleBlur}
              change={change}
              // name={props?.data?.name}
              {...{
                style,
                title,
                cardKey,
                children,
                isDarkMode,
                className,
                onMouseUp,
                onTouchEnd,
                onMouseDown,
                data,
                ...rest,
              }}
            />
          )}
          <data.src
            data={data}
            content={<Component />}
            blur={data?.blur}
            // width={item?.width}
            // height={item?.height}
          />
          {/* winter */}
          {/* {data?.name !== "ClockIn" ? (
            <div style={{ position: "absolute", right: 0, top: 25 }}>
              <CardIce width={100} />
            </div>
          ) : null} */}
        </div>
        {children}
      </div>
    );
  }
);

// export default React.memo(SortableItem);
const DragPopOver = ({ handleDelete, item, handleBlur }) => {
  return (
    <div className="drag-pop-over">
      <span className="action-label" onClick={() => handleDelete(item?.i)}>
        Fshih
      </span>
      <span className="action-label" onClick={() => handleBlur(item?.i)}>
        Konfidenciale
      </span>
    </div>
  );
};

const DynamicHeader = ({
  item,
  handleDelete,
  handleBlur,
  change,
  isDarkMode,
}) => {
  const { clockings } = useSelector((state) => state.clockings);
  const { employeeId } = useAuth();
  const curr = useMemo(() => {
    if (!clockings) return null;
    else if (!clockings?.length) return [];
    else {
      return (
        clockings
          ?.filter((el) => el?.employeeId === employeeId)
          ?.sort((a, b) => a?.clockInDate - b?.clockInDate) || []
      );
    }
  }, [clockings]);

  const condition = useMemo(() => {
    if (!!curr) {
      return (
        curr[curr.length - 1]?.clockInDate !== null &&
        !curr[curr.length - 1]?.clockOutDate &&
        item?.name === "ClockIn"
      );
    } else return false;
  }, [curr]);

  const color = !!curr
    ? !!curr?.length
      ? condition
        ? "#ea3943"
        : "#1da193"
      : "#1da193"
    : "#1da193";

  return (
    <div
      className={`header-dashbord-card-title-container ${
        color === "#ea3943" ? "dynamic-red-header" : "dynamic-green-header"
      }`}
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        // background: item?.name !== "ClockIn" ? "#E7EEF2" : "#1da193",
        borderRadius: "10px 10px 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: color,
        cursor: change ? "all-scroll" : "",
      }}
    >
      <span
        className="header-dashbord-card-title"
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19.07px",
          letterSpacing: "0em",
          color: "#FBFBFB",
          marginLeft: "20px",
          textAlign: "center",
        }}
      >
        {item?.emri}
      </span>{" "}
      <Popover
        style={{
          cursor: "pointer",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        placement="rightTop"
        title={"Ndryshime"}
        classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
        content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
        trigger="hover"
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
            zIndex: 999,
          }}
        >
          <DragIcon />{" "}
        </span>{" "}
      </Popover>
    </div>
  );
};
const StaticHeader = ({
  item,
  handleDelete,
  handleBlur,
  change,
  isDarkMode,
}) => {
  return (
    <div
      className="header-dashbord-card-title-container"
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        background: !!isDarkMode ? "#121212" : "#E7EEF2",
        //winter
        // background: "#1da193",
        borderRadius: "10px 10px 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: change ? "all-scroll" : "",
      }}
    >
      <span
        className="header-dashbord-card-title"
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19.07px",
          letterSpacing: "0em",
          color: !!isDarkMode ? "#1da193" : "#1d3943",
          //winter
          // color: "#1da193",
          marginLeft: "20px",
        }}
      >
        {item?.emri}
      </span>{" "}
      <Popover
        style={{
          cursor: "pointer",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        placement="rightTop"
        title={"Ndryshime"}
        classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
        content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
        trigger="hover"
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
            zIndex: 999,
          }}
        >
          <DragIcon fill={isDarkMode ? "#1da193" : "#1d3943"} />
          {/* winter */}
          {/* <DragIcon fill="#1da193" /> */}
        </span>
      </Popover>
    </div>
  );
};
