import React from "react";
import { Input, Menu } from "antd";
import { useState, useEffect } from "react";
import { Iconkonfigurimet, IconKerko } from "../../.././assets/icons";
import { Link } from "react-router-dom";
import { KonfigurimetMenuData } from "./KonfigurimetMenuData";
import { useLocation } from "react-router-dom";
import "./konfigurimet_menu.scss";
import { EmailIcon } from "../../punonjesit/EmployeeView/Personal/User/assets/icons";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function KonfigurimetMenu({ data }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const { SubMenu } = Menu;
  const [sData, setSData] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [k, setK] = useState("");
  const [value, setValue] = useState("");
  const loc = useLocation();

  useEffect(() => {
    KonfigurimetMenuData?.map((el) => {
      if (el?.parent === true) {
        if (`/konfigurimet/${el?.to}` === loc.pathname) {
          setK(el.SubmenuKey);
        }
      } else {
        if (
          !!el.submenu?.find((td) => `/konfigurimet/${td?.to}` === loc.pathname)
        ) {
          setK(el.SubmenuKey);
        }
      }
    });
    if (loc.pathname.startsWith("/konfigurimet/perdoruesit/")) {
      setK("sub5");
    }
  }, [loc]);

  useEffect(() => {
    if (data?.length > 0) {
      setSData(data);
      setValue("");
    }
  }, [data]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  // search konfigurimet
  const onSearchCard = (e) => {
    let substring = e.target.value;
    let filteredData = data[0]?.accessConfig?.settingsConfig?.map((data) =>
      data.submenu?.filter(
        (item) => item.title.toLowerCase().includes(substring.toLowerCase())
        // item.earningName.toLowerCase().includes(substring.toLowerCase())
      )
    );
    setSearchTerm(filteredData);
    setValue(substring);
    substring.length > 0 ? setFilterActive(true) : setFilterActive(false);
  };

  // clear the search input
  const clearInput = () => {
    setFilterActive(false);
    setValue("");
  };

  return (
    <div className={`konfigurimet-menu ${isDarkMode ? "dark" : ""}`}>
      <div className="konfig-menu-container">
        {!isMobileView ? (
          <>
            <div className="menu-header">
              <Iconkonfigurimet
                className="kofig-icon"
                width={40}
                height={40}
                fill={isDarkMode ? "#e6e6e6" : "#1a1a1a"}
              />
              <h1>KONFIGURIMET</h1>
            </div>
            <div
              className={
                data[0]?.userRole === "Employee"
                  ? "HideSearch"
                  : "konfig-search-input"
              }
            >
              <IconKerko className="search-icon" width={20} height={20} />
              <Input
                onChange={onSearchCard}
                placeholder="Kerko.."
                style={{
                  width: 163,
                  border: "0px",
                  height: 36,
                  outline: "none",
                }}
                value={value}
                autoComplete="off"
              />
            </div>
          </>
        ) : null}
        {sData?.length > 0 && (
          <Menu
            className="Menu-config"
            // onClick={handleClick}
            style={{
              width: 270,
              background: "transparent",
              border: 0,
            }}
            mode="vertical"
            selectedKeys={k}
            defaultSelectedKeys={k}
          >
            {filterActive === false
              ? KonfigurimetMenuData?.map((el) => {
                  let comparator =
                    data[0]?.accessConfig?.settingsConfig?.filter(
                      (f) => f.SubmenuKey === el.SubmenuKey
                    );
                  let to = el?.to;
                  if (comparator?.length > 0) {
                    let toCompare = comparator[0]?.submenu;
                    if (!to && toCompare?.some((tcmp) => tcmp.auth === true)) {
                      return (
                        <SubMenu
                          key={el.SubmenuKey}
                          title={el.title}
                          className="subMenu-konfig"
                          icon={<el.icon />}
                        >
                          {el.submenu?.map((r, idx) => {
                            if (
                              comparator[0]?.submenu?.filter(
                                (c) => c.to === r.to && c?.auth === true
                              ).length > 0
                            ) {
                              return (
                                <Menu.Item
                                  className="subMenu-konfig"
                                  style={{
                                    color: "#fff",
                                    background: isDarkMode
                                      ? "#1a1a1a"
                                      : "#1d3445",
                                  }}
                                  key={r.key}
                                >
                                  <Link to={r.to} key={r.key}>
                                    {r.title}
                                  </Link>
                                </Menu.Item>
                              );
                            }
                          })}
                        </SubMenu>
                      );
                    } else {
                      if (
                        comparator[0]?.parent === false &&
                        comparator[0]?.auth === true
                      ) {
                        return (
                          //   <Menu.item>
                          //     <Link onClick={() => clearInput()} to={"emailTemplate"}>
                          //       Email Template
                          //     </Link>
                          //   </Menu.item>
                          // </>
                          <Menu.Item
                            className="subMenu-konfig"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#fff",
                              // background: "#1d3943",
                              height: 50,
                            }}
                            key={el.SubmenuKey}
                            danger={el.SubmenuKey}
                            icon={<el.icon />}
                            onTitleClick={el.title}
                          >
                            <Link
                              style={{
                                color: "#fff",
                              }}
                              to={el.to}
                              key={el.SubmenuKey}
                            >
                              {el.title}
                            </Link>
                          </Menu.Item>
                        );
                      }
                    }
                  }
                })
              : searchTerm.map((el, idx) => {
                  if (el?.length > 0) {
                    return el
                      ?.filter((el) => el.auth === true)
                      ?.map((newEl) => {
                        let comparator =
                          data[0]?.accessConfig?.settingsConfig?.map((l) => {
                            if (
                              l.submenu?.filter(
                                (lsub) =>
                                  lsub?.key === newEl?.key && lsub.auth === true
                              ).length > 0
                            ) {
                              return l;
                            }
                          });
                        let to = newEl?.to;
                        if (comparator.length > 0) {
                          if (!!to)
                            return (
                              <Menu.Item
                                icon={<IconKerko width={15} height={15} />}
                                className="searching-menu"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#fff",
                                  // background: "#1d3943",
                                  height: 50,
                                }}
                                key={newEl.key}
                              >
                                <Link onClick={() => clearInput()} to={to}>
                                  {newEl.title}
                                </Link>
                              </Menu.Item>
                            );
                        }
                      });
                  }
                })}
          </Menu>
        )}
      </div>
    </div>
  );
}
export default KonfigurimetMenu;
