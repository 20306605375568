import { v4 as uuidv4 } from "uuid";
import { memo } from "react";
const StepRenderer = ({ current, steps = [], useKeys = false, form, ...stepRest }) => {
	const { Comp: StepToRender, ...rest } = steps.find((_, index) => index === current) || {};
	return (
    <StepToRender
      {...rest}
      {...stepRest}
      form={form}
      step={current + 1}
      {...(!!useKeys ? { key: uuidv4() } : {})}
    />
  );
};

export default memo(StepRenderer);
