import "@theme-toggles/react/css/InnerMoon.css";
import { InnerMoon } from "@theme-toggles/react";
import { useSelector } from "react-redux";
import { editTheme } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { eventTracker } from "../../../utils/eventTracker";

const ThemeToggle = ({ themeRef }) => {
  const dispatch = useDispatch();
  const { users, isDarkMode } = useSelector((state) => state.usersList);

  const [requesting, setRequesting] = useState(false);

  const onToggle = async () => {
    if (!requesting) {
      const value = !isDarkMode;
      setRequesting(true);
      localStorage.setItem("isDarkMode", value);

      const { theme } = JSON.parse(sessionStorage.getItem("agThemePreference"));
      sessionStorage.setItem(
        "agThemePreference",
        JSON.stringify({
          theme,
          mode: value ? "dark" : "light",
          accentColor: value ? "#213c4f" : "#1d3445",
        })
      );
      eventTracker("Header", "click", "Updated theme preference");

      dispatch(editTheme(!isDarkMode));
      await API.put("users", `/users/${users?.identityId}`, {
        body: { isDarkMode: value },
      })
        .catch((err) => console.log("Error: ", err))
        .finally(() => setRequesting(false));
    }
  };

  // useEffect(() => {
  //   if (!!Object.keys(users || {}).length) {
  //     const ls = localStorage.getItem("isDarkMode");
  //     const value = isDarkMode;
  //     if (ls === null) {
  //       localStorage.setItem("isDarkMode", value);
  //     }
  //     setDarkMode(!!isDarkMode);
  //   }
  // }, [users]);

  return (
    <span onClick={() => onToggle()} ref={themeRef}>
      <InnerMoon duration={750} toggled={isDarkMode} />
    </span>
  );
};

export default ThemeToggle;
