import { useEffect, useState } from "react";
import { Modal, Input, message, Form } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import { API } from "aws-amplify";
import MondayButton from "../../../components/commonComponents/MondayButton/MondayButton";
import { CheckOutlined, SaveFilled } from "@ant-design/icons";
import {
  Check,
  CloseIcon,
  IconaMbyll,
  ModalXIcon,
} from "../../../assets/icons";
import "./EditQOTD.scss";
import { useAuth } from "../../../authentication/authHelpers";
import { useSelector } from "react-redux";
const { TextArea } = Input;

function EditQOTD({ qotd, setQotd }) {
  const auth = useAuth();
  const [form] = Form.useForm();

  const { isDarkMode } = useSelector((state) => state.usersList);

  const [visible, setVisible] = useState(false);

  const handleFinish = () => {
    form
      .validateFields()
      .then(async (res) => {
        await API.put(
          "programFields",
          "/programFields/9d4c8f40-3a6d-11ed-a92c-178f43e8cbef",
          {
            body: {
              fieldOptions: {
                date: dayjsTZ().format("DD/MM/YYYY"),
                quote: res?.quote,
                author: res?.author,
                editedBy:
                  auth?.userAccess?.[0]?.given_name +
                    " " +
                    auth?.userAccess?.[0]?.family_name ?? "",
              },
            },
          }
        ).then(() => {
          setQotd({ quote: res?.quote, author: res?.author });
          message.success("Quote of the day updated successfully");
          setVisible(false);
        });
      })
      .catch((err) => message.error("Error validating fields"));
  };

  useEffect(() => {
    form.setFieldValue("quote", qotd?.quote);
    form.setFieldValue("author", qotd?.author);
  }, [qotd]);

  return (
    <>
      {auth?.userRole === "Admin" && (
        <span
          style={{ color: "grey", cursor: "pointer", marginLeft: "10px" }}
          onClick={() => setVisible(true)}
        >
          edit
        </span>
      )}
      {!!visible ? (
        <Modal
          title="Change Quote Of The Day"
          open={visible}
          destroyOnClose
          className={`edit-qotd-modal ${
            isDarkMode ? "dark darkHeader" : "lightHeader"
          }`}
          centered
          onCancel={() => setVisible(false)}
          closeIcon={<ModalXIcon />}
          footer={[
            <MondayButton
              className="mondayButtonRed"
              onClick={() => setVisible(false)}
              Icon={<IconaMbyll />}
            >
              Cancel
            </MondayButton>,
            <MondayButton
              className="mondayButtonGreen"
              onClick={handleFinish}
              Icon={<CheckOutlined />}
            >
              Submit
            </MondayButton>,
          ]}
        >
          <div className="edit-qotd-wrapper">
            <Form form={form}>
              <Form.Item name="quote" required={true} label="Quote">
                <TextArea />
              </Form.Item>
              <Form.Item name="author" required={true} label="Author">
                <Input />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

export default EditQOTD;
