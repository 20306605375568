import { useState, useEffect } from "react";
import "./AdminShiftLogs.scss";
import { Drawer, Skeleton } from "antd";
import ShiftLogCard from "./ShiftLogCard";
import { ClearIconDrawer, ModalXIcon } from "../../../../assets/icons";
import { Alert } from "antd";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";

function GeneralAdminShift({ open, setOpen }) {
  const { clockings } = useSelector((state) => state.clockings);
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const { activeEmployees } = useSelector((state) => state.employeesList);

  const [shiftLogs, setShiftLogs] = useState(null);

  useEffect(() => {
    if (!!activeEmployees && !!users && !!clockings) {
      API.get("clockingLogs", "/clockingLogs")
        .then((res) => {
          console.log(res);
          let items = res
            .sort((a, b) => b.createdAt - a.createdAt)
            .slice(0, 500);
          setShiftLogs(items);
        })
        .catch((err) => console.log(err));
    }
  }, [activeEmployees, users, clockings, open]);

  return (
    <Drawer
      title="Historiku i turnit"
      placement="right"
      onClose={() => setOpen(false)}
      open={open}
      className={isDarkMode ? "dark darkDrawer" : ""}
      width={576}
      destroyOnClose={true}
      closeIcon={<ClearIconDrawer fill={isDarkMode ? "#e6e6e6" : "#1d3445"} />}
    >
      {Array.isArray(shiftLogs) ? (
        <div className="shift-log-cards-container">
          {shiftLogs.length > 0 ? (
            shiftLogs.map((el, idx) => <ShiftLogCard content={el} key={idx} />)
          ) : (
            <div className="alert-no-changes">
              <Alert
                message="Nuk ka ndryshime për këtë turn!"
                type="info"
                showIcon
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {[...Array(5)].map((el) => (
            <Skeleton active />
          ))}
        </>
      )}
    </Drawer>
  );
}

export default GeneralAdminShift;
