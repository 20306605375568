import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { groupBy } from "lodash";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";

export const exportToCSV = (apiData, fileName, time, note) => {
	// console.log("time", time);
	console.log("time", apiData);
	let dateRange =
		dayjsTZ(time[0]).format("DD/MM/YYYY") + " - " + dayjsTZ(time[1]).format("DD/MM/YYYY");
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const grouped = groupBy(apiData, "Punonjësi");
	Object.keys(grouped).forEach((key) => {
		grouped[key].push({
			"19:00 - 22:00": parseFloat(
				grouped[key].reduce((a, b) => Number(a || 0) + Number(b["19:00 - 22:00"] || 0), 0) || 0
			),
			"22:00 - 07:30": parseFloat(
				grouped[key].reduce((a, b) => Number(a || 0) + Number(b["22:00 - 07:30"] || 0), 0) || 0
			),
			"Deri në 19": parseFloat(
				grouped[key].reduce((a, b) => Number(a || 0) + Number(b["Deri në 19"] || 0), 0) || 0
			),
			Kohëzgjatja: parseFloat(
				grouped[key].reduce((a, b) => Number(a || 0) + Number(b["Kohëzgjatja"] || 0), 0) || 0
			),
			"Kohëzgjatja me pushim": parseFloat(
				grouped[key].reduce((a, b) => Number(a || 0) + Number(b["Kohëzgjatja me pushim"] || 0), 0) || 0
			),
		});
	});
	console.log("grouped", grouped);
	let wbtest = { Sheets: {}, SheetNames: [] };

	Object.entries(grouped).forEach(([key, value]) => {
		wbtest.SheetNames.push(key);
		wbtest.Sheets[key] = XLSX.utils.json_to_sheet(
			note !== "" && !!note
				? [
						...value,
						{
							Data: "",
							Departamenti: "",
							Kohëzgjatja: "",
							"Midis 19 dhe 22": "",
							"Ora e fillimit": "",
							"Ora e mbarimit": "",
							"Para 19": "",
							"Pas 22": "",
							Punonjësi: "",
						},
						{
							Punonjësi: "Shenimi",
						},
						{
							Punonjësi: note?.toString(),
						},
				  ]
				: value
		);
		wbtest.Sheets[key]["!cols"] = Object.keys(apiData[0]).map(() => ({ wch: 15 }));
	});
	console.log("wb test", wbtest);
	const excelBuffer = XLSX.write(wbtest, { bookType: "xlsx", type: "array" });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + dateRange + fileExtension);
};
