import types from "../types/types";

const users = (users) => {
	return (dispatch) => {
		dispatch({
			type: types.SET_USERS,
			payload: users,
		});
	};
};
export const editUser = (user) => {
	return (dispatch) => {
		dispatch({
			type: types.EDIT_USER,
			payload: user,
		});
	};
};
export const editTheme = (value) => {
	return (dispatch) => {
		dispatch({
      type: types.SET_DARKMODE,
      payload: value,
    });
	};
};
export default users;
