import React, { useState, useEffect, useMemo } from "react";
import { Modal, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { ClearIconDrawer, ModalXIcon } from "../../../assets/icons";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import Chart from "react-google-charts";
import { getShiftChart } from "./utils/gcharts";
import { ShiftCard } from "./ProgressShift";
import { paySubFactory } from "../../Pagat/PaySub/employeeCalc/employeeSubFactory";
import { API } from "aws-amplify";
import { getAppliedShifts } from "../../dashboard/components/clockInOut/clockOutModal/utils";
import useIpAddress from "./hooks/use-IpAddress";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

function InfoModal({
  info,
  isVisible,
  toggleVisible,
  totalHours,
  shiftsConfig,
}) {
  const geoData = useIpAddress();

  const { employees } = useSelector((state) => state.employeesList);
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { programFields } = useSelector((state) => state.programFields);

  const [dayShift, setDayShift] = useState(null);
  const [moreDetails, setMoreDetails] = useState(true);

  useEffect(() => {
    if (!!info && !!shiftsConfig) {
      const testTemp = info?.map((el) => ({
        ...el,
        dayType: "Regular",
        clockInDate:
          el?.clockingCategory === "midnightReset"
            ? dayjsTZ(el?.clockInDate).add(1, "days")
            : el?.clockInDate,
        clockOutDate:
          el?.clockingCategory === "midnightReset"
            ? dayjsTZ(el?.clockOutDate).add(1, "days")
            : el?.clockOutDate,
      }));
      const resu = paySubFactory({
        type: "slicing",
        employee: testTemp[0]?.employeeId,
        clockings: testTemp,
        momentInstance: null,
        programFields: programFields,
      });
      if (resu?.calculations?.length > 0) {
        let toSet = [];
        resu?.calculations?.forEach((calcArr) => {
          calcArr.forEach((calc) => {
            toSet.push(calc);
          });
        });
        toSet = toSet
          ?.map((r) => ({
            ...r,
            fillim: dayjsTZ(r?.clockInDate).format("HH:mm"),
            mbarimi: dayjsTZ(r?.clockOutDate).format("HH:mm"),
            duration: dayjsTZ(r?.clockOutDate)
              .diff(r?.clockInDate, "hours", true)
              .toFixed(2),
            applicableShift: getEmployeeShift(
              employees,
              r?.employeeId,
              shiftsConfig
            ),
          }))
          .sort((a, b) => a.clockOutDate - b.clockInDate);
        setDayShift(toSet);
      }
    }
  }, [info, shiftsConfig]);

  const chartData = useMemo(
    () => getShiftChart(dayShift, geoData),
    [dayShift, geoData]
  );

  //   useEffect(() => {
  //     if (info?.length > 0 && !!employees) {
  //       let employeeInfo = employees.filter((el) => el?.employeeId === info[0]?.employeeId);
  //       let subres = info;
  //       let resu = paySubFactory({
  //         type: "slicing",
  //         employee: employeeInfo,
  //         clockings: info,
  //         momentInstance: null,
  //       });
  //       console.log("Calculated Per Person", resu);
  //     }
  //   }, [info, employees]);
  // console.log("Dayshift", dayShift, "chartData", chartData);

  return (
    <Modal
      centered={true}
      className={`progress-shift-modal ${moreDetails ? "more-details" : ""} ${
        isDarkMode ? "darkHeader dark" : "lightHeader"
      }`}
      title={"Më shumë informacion për turnin"}
      open={isVisible}
      onOk={toggleVisible}
      width={1500}
      zIndex={100000000}
      onCancel={toggleVisible}
      destroyOnClose={true}
      closeIcon={<ModalXIcon />}
      //   closeIcon={<ClearIconDrawer />}
      footer={[
        <MondayButton className="mondayButtonRed" onClick={toggleVisible}>
          Mbyll
        </MondayButton>,
      ]}
    >
      {dayShift !== null && chartData !== null ? (
        <>
          <span className="content-label">
            Këto janë oraret e regjistruara për datën{" "}
            {dayShift.length > 0
              ? `${dayjsTZ(dayShift[0]?.clockInDate).format("DD/MM/YYYY")}`
              : null}
          </span>
          <div className="static-shift-tellers">
            {" "}
            <Chart
              chartType="Timeline"
              data={chartData}
              width="100%"
              height="400px"
              options={{
                timeline: {
                  showRowLabels: true,
                  colorByRowLabel: true,
                },
                hAxis: { format: "HH:mm" },
                legend: "none",

                allowHtml: true,
                tooltip: { isHtml: true, trigger: "focus" },
              }}
            />
          </div>{" "}
          <div className="more-details-cont">
            <h3 className="hours-label">Totali: {totalHours || 0} orë</h3>
            <span
              className="more-details-shift"
              onClick={() => setMoreDetails(!moreDetails)}
            >
              {!moreDetails ? "Më shumë Detaje" : "Më pak detaje"}
            </span>
          </div>
          {moreDetails ? (
            <div className="shift-cards-wrapper">
              {" "}
              {dayShift?.map((el) => (
                <ShiftCard info={el} />
              ))}
            </div>
          ) : null}
        </>
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
}

export default InfoModal;

const getEmployeeShift = (employees, employeeId, shiftsConfig) => {
  let result = [];
  let test = [];

  if (Array.isArray(employees) && !!employeeId) {
    result = employees.find(
      (el) => el?.employeeId === employeeId
    )?.employeeShiftId;
    result.map((el) => {
      let temp = shiftsConfig?.find((shift) => shift?.shiftId === el);
      if (!!temp) {
        test.push(temp);
      }
    });

    return test;
    let shenim = getAppliedShifts(test);
    console.log("shenim", shenim);
  }
  return test;
};
