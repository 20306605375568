import { message, Steps } from "antd";
import { StepRenderer } from "./components";
import "./Stepper.scss";
import { memo, useEffect, useState } from "react";
import { isStepDisabled } from "./utils/isDisabled";
import { useSelector } from "react-redux";

/**
 * Customized AntD stepper.
 *
 * A customized AntD stepper that accepts all the properties of the AntD stepper.
 *
 * @param {int} currentStep  Active step's index. Should be saved in the state.
 * @param {function} setCurrentStep  Function that sets the active step.
 * @param {Array} steps  Array containing an object list of the steps. Step's object should have:
 *                        title - The title that is gonna appear on the stepper.
 *                        Comp - Component to be rendered.
 *                        disabled - Whether the step is disabled or not.
 *                        Any other key is gonna be passed as a prop to the Comp.
 * @param {String} [stepperClassName] Stepper's Container class name.
 * @param {String} [componentContainerClassName] Rendered Component's class name.
 * @returns {Node} Component of the active step.
 */

const { Step } = Steps;

const Stepper = ({
  currentStep: current,
  setCurrentStep,
  size = "small",
  steps = [],
  stepperClassName = "",
  stepRenderer = true,
  componentContainerClassName = "",
  useKeys = false,
  statusColor,
  onChange,
  form,
  ...rest
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [completedSteps, setCompletedSteps] = useState({});

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  useEffect(() => {
    setCompletedSteps((curr) => ({ ...curr, [current]: true }));
  }, [current]);

  const Wrapper = ({ wrap, children }) => {
    return wrap ? (
      <div className={componentContainerClassName}>{children}</div>
    ) : (
      children
    );
  };

  return (
    <>
      <div
        className={`antCustomStepper ${
          isDarkMode ? "dark" : ""
        } ${stepperClassName}`.trim()}
      >
        <Steps
          {...{
            size,
            current,
            onChange: (curr) => {
              if (curr < current) {
                setCurrentStep(curr);
              } else {
                if (!!onChange && !completedSteps[curr]) {
                  onChange(curr);
                } else {
                  setCurrentStep(curr);
                }
              }
            },
            type: "navigation",
            ...rest,
          }}
        >
          {steps?.map(
            ({ title, disabled = false, status, statusColor }, idx) => {
              const isDisabled =
                disabled || isStepDisabled(completedSteps, current, idx);
              return (
                <Step
                  style={
                    statusColor
                      ? {
                          backgroundColor:
                            current === idx
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                          "--color":
                            current === idx
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                          "--baseColor":
                            current === idx
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                          "--processColor":
                            current === idx
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                        }
                      : {}
                  }
                  {...{ title, status, disabled: isDisabled }}
                />
              );
            }
          )}
        </Steps>
      </div>
      {stepRenderer ? (
        <Wrapper
          wrap={!!componentContainerClassName}
          children={
            <StepRenderer
              {...{
                current,
                steps,
                useKeys,
                form,
                ...rest,
              }}
            />
          }
        />
      ) : null}
    </>
  );
};

export default memo(Stepper);
