import {
  NotificationFilled,
  NotificationOutlined,
  SettingFilled,
} from "@ant-design/icons";
import {
  IconKryefaqja,
  IconPunonjesit,
  IconPrezenca,
  IconPagat,
  PagatIcon,
  // IconRaportet,
  Iconkonfigurimet,
  ApprovalsIcon,
  DocPage,
  IconRaportet,
  DepIconMob,
  IntervistatIcon,
  DocumentationWarningIcon,
  KonfigurimetIcon,
} from "../../assets/icons";
import { htmlParser } from "../../utils";

export const SidebarData = [
  {
    key: "1",
    to: "/kryefaqja",
    src: IconKryefaqja,
    title: "Kryefaqja",
  },
  {
    key: "2",
    to: "/punonjesit/specifikat/:employeeId",
    src: IconPunonjesit,
    title: "Punonjësit",
  },
  {
    key: "2",
    to: "/punonjesit",
    src: IconPunonjesit,
    title: "Punonjësit",
  },
  {
    key: "3",
    to: "/prezencat",
    src: IconPagat,
    title: "Listë Prezencat",
  },
  {
    key: "4",
    to: "/pagat",
    src: PagatIcon,
    title: "Pagat",
  },

  {
    key: "6",
    to: "/dokumentacione",
    src: DocPage,
    title: "Dokumentacione",
  },
  {
    key: "5",
    to: "/raportet",
    src: IconRaportet,
    title: "Raportet",
  },

  {
    key: "7",
    to: "/prezencat",
    src: IconPagat,
    title: "Listë Prezencat",
  },
  {
    key: "8",
    to: "/departamentet",
    src: DepIconMob,
    title: "Departamentet",
  },
  {
    key: "9",
    to: "/aprovimet",
    src: ApprovalsIcon,
    title: "Aprovimet",
  },
  {
    key: "10",
    to: "/lajmerimet",
    src: NotificationFilled,
    title: "Lajmerimet",
  },
  {
    key: "11",
    to: `/intervistat`,
    src: IntervistatIcon,
    title: "Intervistat",
  },
  {
    key: "55",
    to: `/konfigurimet`,
    src: KonfigurimetIcon,
    title: "Konfigurimet",
  },
  {
    key: "12",
    to: `/problematikat`,
    src: DocumentationWarningIcon,
    title: "Problematikat",
  },
];
