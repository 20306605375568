import { useEffect, useState } from "react";
import ConfirmationModal from "../../../ConfirmationModal/ConfirmationModal";
import { useDocumentationContext } from "../../context";
import { deleteCategory } from "../../utils/dispatchFunctions";
import { views } from "../../documentationConfigurationData";
import { formatCategoryName } from "../../View/utils/formatCategoryName";
import CategoryCard from "../CategoryCard/CategoryCard";
import "./CategoriesPanel.scss";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";

const CATEGORIES_PANEL_TITLE = "Këto janë kategoritë aktuale.";
const DELETION_MODAL_CONTENT =
  "Jeni të sigurt që dëshironi ta fshini këtë kategori?";

const CategoriesPanel = () => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  const {
    categories = [],
    setSelectedCategoryName,
    setActiveView,
    curryDispatch,
  } = useDocumentationContext();

  const [categoryToDelete, setCategoryToDelete] = useState();
  const [numberOfDocuments, setNumberOfDocuments] = useState();

  const onOpenConfiguration = (catName) => {
    setSelectedCategoryName(formatCategoryName(catName));
    setActiveView(views.CATEGORY_VIEW);
  };

  const onDeleteClick = (catName) => {
    setCategoryToDelete(formatCategoryName(catName));
  };

  //* deletes category
  const onCategoryDelete = async () => {
    await curryDispatch(deleteCategory(categoryToDelete));
    setCategoryToDelete("");
  };

  const getDocConfiguration = async () => {
    await API.get("docConfiguration", "/docConfiguration").then((res) => {
      setNumberOfDocuments(res[0].documentationsAvailable.length);
    });
  };

  useEffect(() => {
    getDocConfiguration();
  }, []);

  return (
    <>
      <div className={`docCategoriesPanel ${isDarkMode ? "dark" : ""}`}>
        <div className="categoriesPanelTitle">{CATEGORIES_PANEL_TITLE}</div>
        <span
          className="doc"
          style={{
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {numberOfDocuments}
        </span>
        <div className="docCategoriesContainer">
          {categories?.map(({ categoryName }) => (
            //! maps categories from DB and shows it at doc configuration
            <CategoryCard
              key={categoryName}
              title={formatCategoryName(categoryName)}
              onClick={onOpenConfiguration}
              onDelete={onDeleteClick}
            />
          ))}
        </div>
      </div>
      <ConfirmationModal
        {...{
          visible: !!categoryToDelete,
          setVisible: () => setCategoryToDelete(""),
          onConfirm: onCategoryDelete,
          title: categoryToDelete,
          text: DELETION_MODAL_CONTENT,
        }}
      />
    </>
  );
};

export default CategoriesPanel;
