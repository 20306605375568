import { Form } from "antd";
import { useState } from "react";
import ConfirmationModal from "../../../ConfirmationModal/ConfirmationModal";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { views } from "../../documentationConfigurationData";
import DocCard from "../DocCard/DocCard";
import NewCustomFieldModal from "../NewCustomFieldModal/NewCustomFieldModal";
import NewDocPanel from "./components/NewDocPanel";
import NewTemplateModal from "../NewTemplateModal/NewTemplateModal";
import {
  CUSTOM_FIELDS_TITLE,
  DOCUMENTATION_TITLE,
  TEMPLATES_TITLE,
} from "./documentationsPanelData";
import { NewIcon } from "../../assets";
import { useProgramFields } from "../../hooks/useProgramFields";
import { useDocumentationContext } from "../../context";
import {
  createDocumentation,
  deleteCustomField,
  deleteDocumentation,
  deleteTemplate,
} from "../../utils/dispatchFunctions";
import "./DocumentationsPanel.scss";
import { notificationUserToAdmin } from "../../../../utils/sendNotification";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { useAuth } from "../../../../authentication/authHelpers";
import { useSelector } from "react-redux";

//! disaplays custom fields documentation, documentation type, and templates where you can also create them

const deleteFunctions = {
  customsFieldsAvailable: deleteCustomField,
  documentationsAvailable: deleteDocumentation,
  templatesAvailable: deleteTemplate,
};

const TIME_FORMAT = "DD/MM/YYYY";

const DocumentationsPanel = () => {
  const {
    selectedCategory,
    setActiveView,
    setSelectedDocumentationName,
    curryDispatch,
  } = useDocumentationContext();

  const programFields = useProgramFields();
  const defaultStatuses = programFields?.["Default Documentation Statuses"];

  const userData = useAuth();
  const { users, isDarkMode } = useSelector((state) => state.usersList);

  const [creatingDoc, setCreatingDoc] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedCustomField, setSelectedCustomField] = useState();
  const [newCustomFieldModalVisible, setNewCustomFieldModalVisible] =
    useState();
  const [templateModalVisible, setTemplateModalVisible] = useState();
  const [recordToDelete, setRecordToDelete] = useState();

  const [form] = Form.useForm();

  const {
    documentationsAvailable = [],
    templatesAvailable = [],
    customsFieldsAvailable = [],
  } = selectedCategory;

  const onDocClick = (docTitle) => {
    setSelectedDocumentationName(docTitle);
    setActiveView(views.DOCUMENTATION_PREVIEW);
  };

  //* deletes records
  const onDelete = (record) => {
    setRecordToDelete(record);
  };

  const deleteRecord = async () => {
    const { key, item } = recordToDelete;

    await curryDispatch(
      deleteFunctions[key](selectedCategory, ...Object.values(item))
    );
  };

  const onNewDocSubmit = async () => {
    const docName = form.getFieldValue("categoryName");
    await curryDispatch(
      createDocumentation(selectedCategory, docName, defaultStatuses)
    );
    setCreatingDoc(false);
    form.resetFields();
    notificationUserToAdmin(
      {
        notificationTitle: "Lloj i ri dokumentacioni",
        createdAt: dayjsTZ().format("DD/MM/YYYY"),
        notificationPriority: false,
        notificationCategory: "Info",
        notificationBody: `${userData?.userAccess[0]?.given_name} ${userData?.userAccess[0]?.family_name}, krijoi një dokumentacion të ri, ${docName}.`,
        isAnnouncement: false,
        notificationUrl: "/konfigurimet/sistemi/dokumentacionet",
        notificationFirstName: userData?.userAccess[0]?.given_name,
        notificationLastName: userData?.userAccess[0]?.family_name,
      },
      users?.allUsers?.Items
    );
  };

  return (
    <>
      <div
        className={`configDocumentationsContainers ${isDarkMode ? "dark" : ""}`}
      >
        <div className="gridContainer">
          <span className="docNumber">{customsFieldsAvailable.length}</span>
          <DocCard
            //! maps customFieldsAvailable, their name and type,and shows them ate Fushat e Personalizuara
            {...{
              title: CUSTOM_FIELDS_TITLE,
              data: [
                { label: "Emri", value: "Lloji" },
                ...customsFieldsAvailable?.map((field) => ({
                  label: (
                    <span
                      className="docName"
                      onClick={() => setSelectedCustomField(field)}
                    >
                      {field.label}
                    </span>
                  ),
                  value: field.type,
                })),
              ],
              childrenClassName: "infoContainer",
              hasSubtitle: true,
              onDelete,
            }}
          />
          <MondayButton
            //* you can create a new customField
            className="newButton"
            onClick={() => setNewCustomFieldModalVisible(true)}
            Icon={<NewIcon />}
          >
            Fushë e Re
          </MondayButton>
        </div>
        <div className="gridContainer">
          <span className="docNumber">{documentationsAvailable.length}</span>
          <DocCard
            //! maps documentationsAvailable, their name and when they are created and shows them at Lloji Dokumentacionit
            {...{
              title: DOCUMENTATION_TITLE,
              data: [
                { label: "Emri", value: "Krijuar Në" },
                ...documentationsAvailable?.map(({ docName, createdAt }) => ({
                  label: (
                    <span
                      onClick={() => onDocClick(docName)}
                      className="docName"
                    >
                      {docName}
                    </span>
                  ),
                  value: dayjsTZ(createdAt).format(TIME_FORMAT),
                })),
              ],
              childrenClassName: "infoContainer",
              hasSubtitle: true,
              onDelete,
            }}
          />
          {!creatingDoc ? (
            <MondayButton
              //* you can create a new documentation
              className="newButton"
              Icon={<NewIcon />}
              onClick={() => setCreatingDoc(true)}
            >
              Dokumentacion i Ri
            </MondayButton>
          ) : (
            <NewDocPanel
              {...{
                form,
                onSubmit: onNewDocSubmit,
                onDiscard: () => setCreatingDoc(false),
              }}
            />
          )}
        </div>
        <div className="gridContainer">
          <span className="docNumber">{templatesAvailable.length}</span>
          <DocCard
            //! maps templatesAvailable, their name and documentations that are created,and shows them ate Modelet
            {...{
              title: TEMPLATES_TITLE,
              data: [
                { label: "Emri", value: "Docs" },
                ...templatesAvailable.map((template) => ({
                  label: template.templateName,
                  value: (
                    <span
                      className="docName"
                      onClick={() => setSelectedTemplate(template)}
                    >
                      {template.templateDocs?.length}
                    </span>
                  ),
                })),
              ],
              childrenClassName: "infoContainer",
              hasSubtitle: true,
              onDelete,
            }}
          />
          <MondayButton
            //* you can create a new Model
            className="newButton"
            onClick={() => setTemplateModalVisible(true)}
            Icon={<NewIcon />}
          >
            Model i Ri
          </MondayButton>
        </div>
      </div>

      <NewCustomFieldModal
        {...{
          visible: newCustomFieldModalVisible || !!selectedCustomField,
          setVisible: newCustomFieldModalVisible
            ? setNewCustomFieldModalVisible
            : setSelectedCustomField,
          editingField: selectedCustomField,
        }}
      />

      <NewTemplateModal
        {...{
          visible: templateModalVisible || !!selectedTemplate,
          setVisible: templateModalVisible
            ? setTemplateModalVisible
            : setSelectedTemplate,
          editingTemplate: selectedTemplate,
        }}
      />

      <ConfirmationModal
        {...{
          title: "Fshije",
          visible: !!recordToDelete,
          text: "Jeni të sigurt që dëshironi ta fshini këtë rekord?",
          setVisible: () => setRecordToDelete(""),
          onConfirm: deleteRecord,
        }}
      />
    </>
  );
};

export default DocumentationsPanel;
