import React from "react";
import { useState, useEffect } from "react";
import "./Forgot.scss";
import {
  Modal,
  Form,
  message,
  TimePicker,
  Button,
  Popconfirm,
  Skeleton,
  Select,
} from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";
import { API } from "aws-amplify";
import TextArea from "antd/lib/input/TextArea";
import { getLastOnGoingShift } from "../../../helpers/apiCall";
import {
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
  getDisabledBeforeHours,
} from "../../../utils/disabledTimeFilters";
import { WarningIcon } from "../../../../../assets/icons";
import { Checkbox } from "antd";
import {
  requestAllPages,
  requestData,
} from "../../../../../helpers/API/RequestFactory";
import { useDispatch } from "react-redux";
import { clockings } from "../../../../../store/actions";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../authentication/authHelpers";

const { Option } = Select;

function Forgot({ showForgot, handleForgot, onClockForgot, apiData }) {
  const { employeeId, userRole } = useAuth();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isDarkMode } = useSelector((state) => state.usersList);

  const [spin, setSpin] = useState(false);
  const [lastShift, setLastShift] = useState(null);
  const [isAfterMidnight, setIsAfterMidnight] = useState(null);

  const onFinish = async (val) => {
    if (!!val.fillimi && !!val.mbarimi && val.shenimi !== "") {
      setSpin(true);
      let temp = {
        ...val,
        fillimi: dayjsTZ(val.fillimi)
          .set("hour", val.fillimi.get("hour"))
          .set("minute", val.fillimi.get("minute")),
        mbarimi: dayjsTZ(val.mbarimi)
          .set("hour", val.mbarimi.get("hour"))
          .set("minute", val.mbarimi.get("minute")),
        isAfterMidnight,
      };

      await onClockForgot(temp)
        .then(async () => {
          const idObj = userRole === "Employee" ? { employeeId } : null;
          await requestData("clocking", idObj).then((res) =>
            dispatch(clockings(res))
          );
          handleForgot(false);
          form.resetFields();
          setIsAfterMidnight(null);
          setLastShift(null);
          setSpin(false);
        })
        .catch((er) => {
          message.error(
            "Dicka nuk shkoi mirë. Provoni përseri ose kontaktoni supervizorin tuaj!"
          );
          console.log("err", er);
        });
    }
    // console.log("Shift", shift);
  };

  useEffect(() => {
    // console.log("api data", apiData);
    if (apiData.length > 0) {
      let lsh = getLastOnGoingShift(apiData);
      setLastShift(lsh);
    }
  }, [apiData]);

  useEffect(() => {
    if (lastShift !== null) {
      const fillimi = dayjsTZ(lastShift?.clockInDate);
      const mbarimi = isAfterMidnight
        ? dayjsTZ()
        : dayjsTZ()
            .set("month", fillimi.get("month"))
            .set("date", fillimi.get("date"));

      form.setFieldsValue({ fillimi, mbarimi });
    }
  }, [lastShift, isAfterMidnight]);

  return (
    <div>
      <Modal
        className={`forgotModal ${isDarkMode ? "dark" : ""}`}
        centered={true}
        title={"Kritike, nevojitet konfirmimi juaj!"}
        open={showForgot}
        destroyOnClose={true}
        // onOk={() => handleForgot(false)}
        onCancel={() => handleForgot(false)}
        footer={null}
        width={650}
      >
        {lastShift !== null ? (
          <div className="forgot-modal-content">
            <span className="warning-title">
              <WarningIcon />
              Ju keni harruar të mbyllni turnin e fundit. Ju lutem konfirmoni
              orarin që u larguat dhe arsyen përkatëse!
            </span>

            <br />
            <div className="forgot-form">
              <Form form={form} onFinish={onFinish}>
                <div className="first-row-forgot">
                  <Form.Item
                    className="start-shift-forgot"
                    name={"fillimi"}
                    label="Fillimi i turnit"
                    rules={[
                      {
                        required: false,
                        message: "Fillimi i turnit nevojitet",
                      },
                    ]}
                  >
                    <TimePicker
                      defaultValue={dayjsTZ(lastShift?.clockInDate)}
                      value={dayjsTZ(lastShift?.clockInDate)}
                      disabled={true}
                      popupClassName={isDarkMode ? "darkDropdown" : ""}
                      width={180}
                      format={"HH:mm | DD/MM/YYYY"}
                    />
                  </Form.Item>
                  <Form.Item
                    className="after-midnight-checkbox"
                    name="isAfterMidnight"
                    label="Lloji i turnit"
                    rules={[
                      { required: true, message: "Lloji i turnit nevojitet" },
                    ]}
                  >
                    <Select
                      placeholder="Zgjidh llojin"
                      popupClassName={isDarkMode ? "darkDropdown" : ""}
                      onChange={(e) => setIsAfterMidnight(e)}
                    >
                      <Option key={1} value={false}>
                        Turn Normal
                      </Option>
                      <Option key={2} value={true}>
                        Turn Pas Mesnate
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="end-shift-forgot"
                    name={"mbarimi"}
                    label="Mbarimi i turnit:"
                    rules={[
                      { required: true, message: "Mbarimi i turnit nevojitet" },
                      {
                        validator: (_, value) =>
                          isAfterMidnight ||
                          !value ||
                          dayjsTZ(value).isAfter(
                            dayjsTZ(lastShift?.clockInDate)
                          )
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Kjo vlerë nuk është e lejuar!")
                              ),
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="Zgjidh orarin"
                      format={"HH:mm"}
                      disabled={isAfterMidnight === true ? true : false}
                      popupClassName={isDarkMode ? "darkDropdown" : ""}
                      value={isAfterMidnight === true ? dayjsTZ() : null}
                      defaultValue={dayjsTZ()}
                      disabledTime={() => ({
                        disabledHours: !isAfterMidnight
                          ? () =>
                              getDisabledBeforeCustomHours(
                                lastShift?.clockInDate
                              )
                          : () => [],
                        disabledMinutes: !isAfterMidnight
                          ? (hour) =>
                              getDisabledBeforeCustomMinutes(
                                lastShift?.clockInDate,
                                hour
                              )
                          : () => [],
                      })}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  className="note-forgot"
                  name={"shenimi"}
                  label="Arsyeja/Shënimi"
                  rules={[{ required: true, message: "Shënimi nevojitet" }]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MondayButton
                    className="mondayButtonGreen"
                    htmlType="submit"
                    spin={spin}
                  >
                    Konfirmo
                  </MondayButton>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <Skeleton />
        )}
      </Modal>
    </div>
  );
}

const layout = {
  // labelCol: {
  //   span: 12,
  // },
  // wrapperCol: {
  //   span: 16,
  // },
};
export default Forgot;
