import React from "react";
import { Button, Checkbox, Tooltip } from "antd";
import { ExcelIcon, IconPdf } from "../../../../assets/icons";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

function ZbritjetViewCard({ handleShowEditView, Data, id }) {
  const exportPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#pagat-zbritjet" });
    doc.save(`${Data.deductionName}.pdf`);
  };

  return (
    <div className="Fitimet-view-card">
      {/* <div>
        <h3 style={{ width: "100%" }}>Lloji i zbritjes:</h3>
        <div className="krijo-data">{Data.deductionType}</div>
      </div>

      <div>
        <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
        <div className="krijo-data">{Data.deductionFormula}</div>
      </div>
      <div>
        <h3>Raporti:</h3>
        <div className="krijo-data">{Data.deductionRate}</div>
      </div> */}
      <div>
        <h3>Rregulli:</h3>
        <div className="krijo-data">{Data.deductionRule}</div>
      </div>
      <div>
        <h3>Vlera:</h3>
        <div className="krijo-data">{Data.deductionAmount}</div>
      </div>

      <Checkbox
        defaultChecked={Data.excludeFromTotalHours}
        className="festat-zyrtare-checkbox"
        style={{ width: "100%" }}
      >
        <h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
      </Checkbox>

      <div className="festimer-view-card-footer">
        {/* <div className="icon-foter">
          <ExcelIcon width={18} height={14} />
        </div> */}
        <ReactHtmlTableToExcel
          id="fitimet-table-xls-button"
          className="fitimet-table-xls-button"
          table="pagat-zbritjet"
          filename={Data.deductionName}
          sheet={Data.deductionName}
          buttonText={
            <Tooltip
              placement="top"
              classNames={{ root: "global-icon-tooltip" }}
              title={"Eksporto në eksel"}
            >
              <ExcelIcon />
            </Tooltip>
          }
        />
        <div className="pagat-fitimet-excel-container">
          <table id="pagat-zbritjet">
            <thead>
              <tr>
                <th>Titulli i zbritjes</th>
                <th>Rregulli</th>
                <th>Vlera</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{Data.deductionName}</td>
                <td>{Data.deductionRule}</td>
                <td>{Data.deductionAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <MondayButton
          className="mondayButtonGreen"
          onClick={() => handleShowEditView(id)}
        >
          Ndrysho
        </MondayButton>
        <div className="icon-foter">
          <IconPdf onClick={() => exportPdf()} width={18} height={14} />
        </div>
      </div>
    </div>
  );
}

export default ZbritjetViewCard;
