import { useMemo } from "react";
import { getObjectChanges } from "../../../../utils";
import "./SettingsFooter.scss";
import { useSelector } from "react-redux";

const SettingsFooter = ({
  children,
  visible,
  previousData = {},
  updatedData = {},
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const isSame = useMemo(() => {
    if (!!visible) return false;
    else {
      return !Object.keys(getObjectChanges(previousData, updatedData)).length;
    }
  }, [visible, previousData, updatedData]);

  return isSame ? null : (
    <div className={`settingsFooter ${isDarkMode ? "dark" : ""}`}>
      {children}
    </div>
  );
};

export default SettingsFooter;
