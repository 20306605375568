import "./EditDayPay.scss";
import { message, Modal } from "antd";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { AmountCard } from "../../wages/warnings/CustomWarnings";
import { getSummary } from "../../wages/editables/deductAdd/calculations";
import { add, subtract } from "lodash";
import Swal from "sweetalert2";
import { useAuth } from "../../../../authentication/authHelpers";
import { API } from "aws-amplify";
import DayPayHeader from "./DayPayHeader/DayPayHeader";
import Additionals from "../../wages/editables/deductAdd/Additionals";
import Deductions from "../../wages/editables/deductAdd/Deductions";
import Summary from "../../wages/editables/deductAdd/Summary";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import EditWageDetails from "../../wages/editables/deductAdd/EditWageDetails/EditWageDetails";
import { useSelector } from "react-redux";

function EditDayPay({ open, setOpen, shift, refreshData }) {
  const { user } = useAuth();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [deductionsData, setDeductionsData] = useState([]);
  const [additionsData, setAdditionsData] = useState([]);
  const [salaryPerHour, setSalaryPerHour] = useState(0);
  const [spin, setSpin] = useState(false);
  const [rowObj, setRowObj] = useState({
    type: null,
    hours: null,
    days: null,
    note: "",
    rate: 1,
    amount: null,
  });
  const [amounts, setAmounts] = useState({
    normal: 0,
    late: 0,
    extra: 0,
    additions: 0,
    deductions: 0,
    totalDay: 0,
    savedTotalDay: 0,
  });

  const resetFields = () => {
    setRowObj({
      type: null,
      hours: null,
      days: null,
      note: "",
      rate: 1,
      amount: null,
    });
  };

  //region summaryData
  const summaryData = useMemo(() => {
    let tempSummary = getSummary(deductionsData, additionsData);

    setAmounts((prev) => ({
      ...prev,
      additions: tempSummary?.additions?.totalities?.amount || 0,
      deductions: tempSummary?.deductions?.totalities?.amount,

      normal: prev?.normal || shift?.totalities?.normal?.pay,
      late: shift?.totalities?.late?.pay,
      extra: shift?.totalities?.extra?.pay,
      totalDay: shift?.totalities?.totalPay || 0,
      lastGeneratedBy: shift?.totalities?.lastGeneratedBy || null,
      savedTotalDay:
        shift?.totalities?.normal?.pay +
        shift?.totalities?.late?.pay +
        shift?.totalities?.extra?.pay,

      totalDay: subtract(
        add(
          prev?.savedTotalDay || 0,
          tempSummary?.additions?.totalities?.amount || 0
        ),
        tempSummary?.deductions?.totalities?.amount || 0
      ),
    }));
    return tempSummary;
  }, [deductionsData, additionsData, shift]);

  //region onAdd()
  const onAdd = (currentMode) => {
    let { type, hours, days, rate } = rowObj || {};
    if (
      !!currentMode &&
      !!type &&
      !!hours &&
      hours >= 0 &&
      !!days &&
      days >= 0 &&
      !!rate
    ) {
      if (currentMode === "additions") {
        setAdditionsData((prev) => [...prev, rowObj]);
        resetFields();
      } else if (currentMode === "deductions") {
        setDeductionsData((prev) => [...prev, rowObj]);
        resetFields();
      }
    } else {
      message.warning({
        key: "onAdd",
        content: "Ju lutem plotësoni të gjitha fushat!",
      });
    }
  };

  //region ON FINISH
  const onFinish = async () => {
    setSpin(true);
    let toSend = {
      totalities: {
        ...shift?.totalities,
        employeeRate: parseFloat(salaryPerHour),
        totalPay: amounts?.totalDay,
        additions: amounts?.additions,
        deductions: amounts?.deductions,
        summaryData: summaryData,
        lastGeneratedBy: user?.given_name + " " + user?.family_name,
      },
    };
    const arr = ["normal", "late", "extra"];
    arr.forEach((el) => {
      toSend.totalities[el]["pay"] = amounts?.[el];
    });

    await API.put("clocking", `/clocking/${shift?.clockingId}`, {
      body: { ...toSend },
    })
      .then(() => {
        !!refreshData && refreshData([{ ...shift, ...toSend }]);
        Swal.fire({
          title: "U ruajt me sukses!",
          icon: "success",
          customClass: {
            popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
          },
        }).then(() => setOpen(false));
      })
      .catch((err) => {
        message.error("Gabim, provoni përseri!");
        console.log("Ndodhi nje gabim ne shtimin e add/deductions", err);
      })
      .finally(() => setSpin(false));
  };

  useLayoutEffect(() => {
    if (!!shift?.totalities) {
      setSalaryPerHour(shift?.totalities?.employeeRate?.toFixed(2));
      setAmounts({
        normal: shift?.totalities?.normal?.pay,
        late: shift?.totalities?.late?.pay,
        extra: shift?.totalities?.extra?.pay,
        additions: shift?.totalities?.additions || 0,
        deductions: shift?.totalities?.deductions || 0,
        totalDay: shift?.totalities?.totalPay || 0,
        lastGeneratedBy: shift?.totalities?.lastGeneratedBy || null,
        savedTotalDay:
          shift?.totalities?.normal?.pay +
          shift?.totalities?.late?.pay +
          shift?.totalities?.extra?.pay,
      });
      setDeductionsData(shift?.totalities?.summaryData?.deductionsArr || []);
      setAdditionsData(shift?.totalities?.summaryData?.additionsArr || []);
    }
  }, [shift?.totalities]);

  //region RETURN
  return (
    <Modal
      destroyOnClose={true}
      className={`editPayDayModal ${isDarkMode ? "darkHeader" : "lightHeader"}`}
      title={`Ndrysho Pagesën Ditore | ${
        shift?.employee_details?.employeeFirstName +
        " " +
        shift?.employee_details?.employeeLastName
      }`}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered={true}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => setOpen(false)}
        >
          Mbyll Faqen
        </MondayButton>,
        <MondayButton
          spin={spin}
          className="mondayButtonGreen"
          onClick={onFinish}
        >
          Ndrysho
        </MondayButton>,
      ]}
    >
      <div className="editPayDayWrapper">
        <DayPayHeader
          {...{
            shift,
            setAmounts,
            salaryPerHour,
            setSalaryPerHour,
          }}
        />
        <div className="actions-ded-add">
          <EditWageDetails
            {...{
              rowObj,
              setRowObj,
              onAdd,
              resetFields,
              salaryPerHour,
            }}
          />
          <div className="wrapTable">
            {!!additionsData.length ? (
              <div className="deductions-table">
                <Additionals {...{ additionsData, setAdditionsData }} />
              </div>
            ) : null}
            {!!deductionsData.length ? (
              <div className="additions-table">
                <Deductions {...{ deductionsData, setDeductionsData }} />
              </div>
            ) : null}
            {!!(summaryData?.rowData || []).length ? (
              <div className="summary-table">
                <Summary {...{ summaryData }} />
              </div>
            ) : null}
          </div>
          <div className="amountCards">
            <AmountCard
              title={"Para 19:00"}
              color={"#1da193"}
              textColor={"#fff"}
              amount={amounts?.normal || 0}
            />
            <AmountCard
              title={"19:00 - 22:00"}
              color={"#57A773"}
              textColor={"#fff"}
              amount={amounts?.late || 0}
            />{" "}
            <AmountCard
              title={"22:00 - 07:30"}
              color={"#832232"}
              textColor={"#fff"}
              amount={amounts?.extra || 0}
            />{" "}
            <AmountCard
              title={"Pagesa Oret"}
              color={"#A7BBEC"}
              textColor={"#fff"}
              amount={amounts?.savedTotalDay || 0}
            />{" "}
            <AmountCard
              title={"Shtimet"}
              color={"#9AC4F8"}
              textColor={"#fff"}
              amount={amounts?.additions || 0}
            />
            <AmountCard
              title={"Zbritjet"}
              color={"#FF6663"}
              textColor={"#fff"}
              amount={amounts?.deductions || 0}
            />
            <AmountCard
              title={"Pagesa Ditore"}
              color={"#0B3954"}
              textColor={"#fff"}
              amount={amounts?.totalDay || 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditDayPay;
