import { Card } from "antd";
import { API } from "aws-amplify";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, EditIcon, TotalNumber } from "../../assets/icons";
import GlobalPopupKonfirm from "../../components/commonComponents/GlobalPopupKonfirm";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../components/Documentation/utils/Messages";
import {
  CivilEngineeringIcon,
  FinanceIcon,
  FlexDepartmentIcon,
  GraphicDesign,
  HRIcon,
  InternalStaffIcon,
  SalesManagementIcon,
  SchedulingIcon,
  SoftwareDevelopmentIcon,
} from "../assets";
import DepartmentEmployeeViewModal from "./DepartmentEmployeeView/DepartmentEmployeeViewModal";
import LoadableComp from "../../components/commonComponents/LoadableComp/LoadableComp";
import { notificationUserToAdmin } from "../../utils/sendNotification";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import { useAuth } from "../../authentication/authHelpers";
import GeneralViewModal from "../../components/commonComponents/GeneralViewModal/GeneralViewModal";

const DepartmentCardView = ({
  department,
  showEditView,
  setDepData,
  socketHandler,
}) => {
  //* sends you at employee table
  const navigate = useNavigate();
  //* gets employeeList with redux
  const { employees } = useSelector((state) => state.employeesList);
  const [visible, setVisible] = useState(false);
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const userData = useAuth();

  //region DELETE DEP
  const deleteDep = async () => {
    showLoadingMsg({ content: "Duke fshirë..." });
    await API.del("departments", `/departments/${department.departmentId}`)
      .then(() => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        //   socketHandler.send(
        //     JSON.stringify({
        //       request: "department_deleted",
        //       message: "A department was deleted.",
        //     })
        //   );
        // }
        setDepData(
          (prev) =>
            prev.filter((e) => e.departmentId !== department.departmentId),
          showSuccessMsg({ content: "U fshi!" })
        );

        window.localStorage.removeItem("draggableOrderDepartments");
        window.localStorage.removeItem("draggableSetingsPozicionet");
        notificationUserToAdmin(
          {
            notificationTitle: "Fshirje departamenti",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationPriority: false,
            notificationCategory: "Info",
            notificationBody: `${userData?.userAccess[0]?.given_name} ${userData?.userAccess[0]?.family_name}, fshiu departamentin, ${department?.departmentName}.`,
            isAnnouncement: false,
            notificationUrl: "/departamentet",
            notificationFirstName: userData?.userAccess[0]?.given_name,
            notificationLastName: userData?.userAccess[0]?.family_name,
          },
          users?.allUsers?.Items
        );
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };
  //region FILTER BY DEP
  const departmentEmployees = useMemo(() => {
    if (!employees?.length || !Object.keys(department)?.length) {
      return [];
    }
    return employees?.filter(
      (el) =>
        el?.employeeDepartmentName === department?.departmentName &&
        el?.employeeStatus === "Aktiv"
    );
  }, [employees, department]);

  const departmentIcons = {
    "Graphic Design": <GraphicDesign />,
    "Finance & Accounting": <FinanceIcon />,
    "Architecture & Engineering": <CivilEngineeringIcon />,
    "IT & Software Development": <SoftwareDevelopmentIcon fill="#fff" />,
    "Human Resources": <HRIcon />,
    Administration: <FlexDepartmentIcon />,
    "Project Monitoring & Coordinating": <SchedulingIcon />,
    "Sales Management": <SalesManagementIcon />,
    "Internal Staff": <InternalStaffIcon />,
  };

  //region RETURN
  return (
    <LoadableComp loading={!department}>
      <Card
        key={department.departmentId}
        title={
          <div className="card-header">
            <div className="departmentHeaderIcons">
              {departmentIcons[department.departmentName]}

              <span
                className="header"
                onClick={() =>
                  navigate("/punonjesit", {
                    state: department?.departmentName,
                  })
                }
              >
                {department.departmentName}
              </span>
            </div>
            <div className="headerIcons">
              <GlobalPopupKonfirm
                Button={
                  <span className="deleteDepartmentIcon">
                    <DeleteIcon className="deleteDepartmentIconD" />
                  </span>
                }
                title={
                  "Ti po fshin një departament,a je i sigurt për këtë veprim?"
                }
                onConfirm={deleteDep}
                okText={"Po"}
                cancelText={"Jo"}
              />
              <div className="editIconE">
                <EditIcon
                  onClick={() => showEditView(department.departmentId)}
                  className="editIcon"
                />
              </div>
            </div>
          </div>
        }
        className="card-view"
      >
        <span className="card-content">
          <div className="card-data">
            <div className="employee-view">
              <div className="title">
                <TotalNumber
                  {...(isDarkMode
                    ? {
                        fill: "none",
                        stroke: "#fbfbfb",
                        strokeWidth: 10,
                      }
                    : {})}
                />
                <p className="supervisorTitle">Supervizor</p>
                <p className="supervisorName">
                  {department?.departmentManagerName}
                </p>
                {!!employees?.length ? (
                  <p
                    className="employee-number"
                    onClick={() => setVisible(true)}
                  >
                    Shiko {departmentEmployees?.length} Punonjës
                  </p>
                ) : null}
              </div>
            </div>
            <div className="roles">
              <p className="role-title">Rolet e departamentit:</p>
              {department?.departmentRoles?.map((role) => {
                //* maps departmentRoles and shows role name
                return (
                  <p className="roleNames">
                    <span className="roleNumber">
                      {
                        departmentEmployees?.filter(
                          (employee) => employee.employeeRoleName === role.name
                        )?.length
                      }
                    </span>{" "}
                    {role?.name}
                  </p>
                );
              })}
            </div>
          </div>
        </span>
        <GeneralViewModal
          {...{
            visible,
            setVisible,
            tableDefs,
          }}
          dataList={users?.allUsers?.Items}
          subDataList={departmentEmployees}
          title={`Lista e punonjësve për departamentin ${department?.departmentName}`}
          pagination={10}
        />
      </Card>
    </LoadableComp>
  );
};
export default DepartmentCardView;

const tableDefs = [
  {
    header: "Punonjësit",
    key: "concatKeys",
    keys: ["employeeFirstName", "employeeLastName"],
    badge: true,
  },
  { header: "Roli", key: "employeeRoleName" },
  { header: "Role të tjera", key: "employeeOtherPosition" },
  { header: "Data e punësimit", key: "employeeHireDate" },
  { header: "Dega", key: "employeeTeam" },
];
