import React from "react";
import dayjsTZ from "../../../../utils/dayjs";
import "./ShiftLogCard.scss";
import { htmlParser } from "../../../../utils/htmlParser";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

function ShiftLogCard({ content }) {
  const { isDarkMode } = useSelector(state => state.usersList);

  return (
    <div className={`shift-log-card-wrapper ${isDarkMode ? "dark" : ""}`}>
      <div className="header">
        <span className="header-title">
          {content?.username}{" "}
          {!!content?.userRole ? `(${content?.userRole})` : null}
          <Tooltip
            title={`${dayjsTZ(content?.createdAt).format(
              "HH:mm | DD/MM/YYYY"
            )}`}
          >
            <span style={{ marginLeft: "5px" }}>
              ndryshoi turnin {dayjsTZ(content?.createdAt).fromNow()}
            </span>
          </Tooltip>
          {!!content?.ipResponse && (
            <Tooltip
              title={`IP: ${content?.ipResponse?.IPv4}, ${content?.ipResponse?.city}, ${content?.ipResponse?.country_name} `}
            >
              <span style={{ marginLeft: "5px" }}>
                {" "}
                ({content?.ipResponse?.IPv4})
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <div className="content">
        <div className="content-shifts">
          <div className="shift-detail">
            <span style={{ display: "flex", justifyContent: "center" }}>
              Turni Origjinal
            </span>
            <span
              className="shift-format"
              style={{ backgroundColor: "#ea3943" }}
            >
              {dayjsTZ(content.oldShift[0].clockInDate).format("HH:mm")} -{" "}
              {dayjsTZ(content.oldShift[0].clockOutDate).format(
                "HH:mm | DD/MM/YYYY"
              ) !== "Invalid Date"
                ? dayjsTZ(content.oldShift[0].clockOutDate).format(
                    "HH:mm | DD/MM/YYYY"
                  )
                : "--:--"}
            </span>
            {content?.oldShift[0]?.dayType !== content?.newShift[0]?.dayType &&
              !!content?.oldShift[0]?.dayType &&
              !!content?.newShift[0]?.dayType && (
                <span
                  className="shift-format"
                  style={{ backgroundColor: "#1d3943", marginTop: "5px" }}
                >
                  {getTypeTranslation(content?.oldShift[0]?.dayType)}
                </span>
              )}
          </div>
          <div className="shift-detail">
            <span style={{ display: "flex", justifyContent: "center" }}>
              Turni i ri
            </span>
            <span
              className="shift-format"
              style={{ backgroundColor: "#1da193" }}
            >
              {content.hasOwnProperty("newShift")
                ? dayjsTZ(content?.newShift[0]?.clockInDate).format("HH:mm") +
                  "  -  " +
                  `${
                    !!content?.newShift[0]?.clockOutDate
                      ? dayjsTZ(content?.newShift[0]?.clockOutDate).format(
                          "HH:mm | DD/MM/YYYY"
                        )
                      : "--:--"
                  }`
                : null}
            </span>
            {content?.oldShift[0]?.dayType !== content?.newShift[0]?.dayType &&
              !!content?.oldShift[0]?.dayType &&
              !!content?.newShift[0]?.dayType && (
                <span
                  className="shift-format"
                  style={{ backgroundColor: "#1d3943", marginTop: "5px" }}
                >
                  {getTypeTranslation(content?.newShift[0]?.dayType)}
                </span>
              )}
          </div>
        </div>
        {!!content?.note && !content?.employee && (
          <div className="content-note">
            <span className="sticky-title">Shënim</span>
            {/* <span className="note-text">{content?.note.trim()}</span> */}
            <span className="note-text">
              {!!content?.note && htmlParser(content?.note.trim())}
            </span>
          </div>
        )}
        {!!content?.employee && (
          <div className="content-note">
            <span className="sticky-title">PUNONJESI</span>
            {/* <span className="note-text">{content?.note.trim()}</span> */}
            <span className="note-text"> {content?.employee}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShiftLogCard;

const getTypeTranslation = (type) => {
  switch (type) {
    case "Regular":
      return "Normal";
    case "Sick":
      return "Sëmurë";
    case "Maternity":
      return "Leje Lindje";
    case "NationalHoliday":
      return "Festë Zyrtare";
    case "HolidayRequest":
      return "Kërkesë Leje";
    default:
      return "";
  }
};
