import { Form, message, Modal, Tooltip } from "antd";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { AnimatedCheckBox } from "../../../../InputComponent/components";
import "./TemplateSelectorModal.scss";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import API from "@aws-amplify/api";
import { apiRoutes } from "../../../../../apiRoutes";
import { uniqBy } from "lodash";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../utils/Messages";
import { getRandomColor } from "../../../utils/getRandomColor";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { notificationUserToAdmin } from "../../../../../utils/sendNotification";
import { useAuth } from "../../../../../authentication/authHelpers";
import { useSelector } from "react-redux";
import { ModalXIcon } from "../../../../../assets/icons";
import { useContext, useEffect } from "react";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";

const DOC_TYPE = "docType";

const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
};

const TemplateSelectorModal = ({
  visible,
  setVisible,
  recordId,
  docStatus,
  recordName,
}) => {
  const userData = useAuth();
  const [form] = Form.useForm();
  const { employee, setDocumentation, categoryConfiguration } =
    useContext(PunonjesitContext);
  const { users, isDarkMode } = useSelector((state) => state.usersList);

  const {
    categoryName = "",
    templatesAvailable = [],
    documentationsAvailable = [],
  } = categoryConfiguration;

  const onCancel = () => setVisible(false);

  const onSave = async () => {
    showLoadingMsg();
    const selectedTemplates = templatesAvailable.filter(
      ({ templateName }) => !!form.getFieldValue(templateName)
    );

    //! maps templates from docConfiguration and  shows it at template selector modal
    if (!!selectedTemplates.length) {
      showLoadingMsg();
      const documentations = uniqBy(
        selectedTemplates.flatMap(({ templateDocs = [] }) =>
          templateDocs.map((docType) => ({
            docType,
            categoryName,
            docStatus,
            recordId,
            recordName,
            docObject: documentationsAvailable?.find(
              ({ docName }) => docName === docType
            )?.docObject,
          }))
        ),
        DOC_TYPE
      );
      await API.post(apiRoutes.documentation, `/${apiRoutes.documentation}`, {
        body: { documentations },
      })
        .then((r) => {
          showSuccessMsg();
          setVisible(false);
          setDocumentation(r);
          notificationUserToAdmin(
            {
              notificationTitle: "Dokumentacione të reja",
              createdAt: dayjsTZ().format("DD/MM/YYYY"),
              notificationPriority: false,
              notificationCategory: "Info",
              notificationBody: `${userData?.userAccess[0]?.given_name} ${
                userData?.userAccess[0]?.family_name
              }, krijoi dokumentacione, për punonjësin ${
                employee?.employeeFirstName
              } ${employee?.employeeLastName}, bazuar në ${
                templatesAvailable[templatesAvailable.length - 1]?.templateName
              }.`,
              isAnnouncement: false,
              notificationUrl: "/dokumentacione",
              notificationFirstName: userData?.userAccess[0]?.given_name,
              notificationLastName: userData?.userAccess[0]?.family_name,
            },
            users?.allUsers?.Items
          );
        })
        .catch((e) => {
          console.error(e);
          showErrorMsg();
        });
    } else {
      message.warning("Ju lutemi zgjidhni një model!");
    }
  };

  return (
    <Modal
      {...{
        ...antdModalProps,
        visible,
        setVisible,
        title: "Zgjidhni një model",
        wrapClassName: "cardLayoutModal documentationTemplatesModal",
        onCancel,
      }}
      closeIcon={<ModalXIcon />}
      className={isDarkMode ? "darkHeader" : "lightHeader"}
    >
      <Form {...{ form, preserve: false }}>
        <div className={`documentationTemplatesModalContainer ${isDarkMode ? "dark" : ""}`}>
          <div className="checkboxesContainer">
            {templatesAvailable?.map(({ templateName, templateDocs }) => {
              let color = getRandomColor();
              return (
                <AnimatedCheckBox
                  {...{
                    label: (
                      <div className="checkboxLabel">
                        <div className="templateLabel">{`${templateName}`}</div>
                        <Tooltip
                          title={
                            <div className="tooltipContent">
                              {templateDocs.map((docName) => (
                                <div>{docName}</div>
                              ))}
                            </div>
                          }
                          {...{
                            placement: "right",
                            color,
                            mouseEnterDelay: 0.5,
                            mouseLeaveDelay: 0,
                          }}
                        >
                          <div className="docsLengthLabel">{` | ${templateDocs.length} DOC`}</div>
                        </Tooltip>
                      </div>
                    ),
                    form,
                    formItemName: templateName,
                    containerClassName: "templateCheckbox",
                    tooltipVisible: false,
                    primaryStyle: { backgroundColor: color },
                    secondaryStyle: { backgroundColor: "#FFF" },
                  }}
                />
              );
            })}
          </div>
          <div className="templateModalActionButtons">
            <MondayButton
              {...{
                className: "rejectButton",
                Icon: <CloseOutlined />,
                onClick: onCancel,
              }}
            >
              Unë nuk dua të zgjedh një
            </MondayButton>
            <MondayButton
              {...{
                className: "submitButton",
                Icon: <CheckOutlined />,
                onClick: onSave,
              }}
            >
              Paraqisni
            </MondayButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default TemplateSelectorModal;
