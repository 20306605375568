import { useEffect, useState, useCallback, useLayoutEffect } from "react";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs.js";
import "./../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftGrid.scss";
// import InnerModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/InnerModal";
import "../approvals/Approvals.scss";
import "../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftGrid.scss";
import { DatePicker, Select, message } from "antd";
import LoadableComp from "./../../commonComponents/LoadableComp/LoadableComp";
import { ClearIcon, IconCalendar } from "../../dashboard/assets";
import { useLocation, useNavigate } from "react-router-dom";
import { onEmployeeWageTypeFilter } from "../approvals/gridApiMethods/filtering";
import { useSelector } from "react-redux";
import GenerateModal from "./GenerateModal";
import EditEmployeeWage from "./editables/EditEmployeeWage";
import { retrieveRelevantWages } from "./utils/retrieve";
import MultiGenerate from "./multiGenerate/MultiGenerate";
import WagesHeader from "./WagesHeader";
import { wagesColumnDefs } from "./utils/columnDefs";
import { useAuth } from "../../../authentication/authHelpers";
import PageNotFound from "../../commonComponents/PageNotFound";
import { useDispatch } from "react-redux";
import { requestDataWithParams } from "../../../helpers/API/RequestFactory";
import { useMediaQuery } from "react-responsive";
import FooterCounter from "../../AG-grid/FooterCounter/FooterCounter.jsx";
import MondayButton from "../../commonComponents/MondayButton/MondayButton.jsx";

const FILTER = {
  filterKeys: [
    {
      key: "employeeDepartmentName",
      label: "Departamenti",
      type: "string",
    },
    {
      key: "employeeFullName",
      label: "Punonjësi",
      type: "string",
    },
    {
      key: "employeePayrollType",
      label: "Lloji i pagesës",
      type: "string",
    },
    {
      key: "wageStatus",
      label: "Statusi",
      type: "string",
    },
    // {
    //   key: "muaji",
    //   label: "Filtro Muajin",
    //   type: "datepicker",
    //   picker: 'month',
    //   format: 'MMMM YYYY'
    // },
  ],
};
const TEAM_FIELD_ID = "2e9f7112-1775-4b69-b55e-3134333bfdf4";

function Wages() {
  const auth = useAuth();

  const { departments } = useSelector((state) => state.departments);
  const { wages } = useSelector((state) => state.wages);
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );

  const [aggridData, setAggridData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [clockings, setClockings] = useState(null);
  const location = useLocation();
  const [generateVisib, setGenerateVisib] = useState(false);
  const [multiGenVis, setMultiGenVis] = useState(false);
  const [multiEmpl, setMultiEmp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editWageVisib, setEditWageVisib] = useState({
    visibility: false,
    toEdit: null,
  });

  const onEditWage = (data) => {
    setEditWageVisib({ toEdit: data, visibility: true });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const categorizeStatus = (type) => {
    if (type === "Pending") {
      return "Ne pritje";
    } else if (type === "Draft") {
      return "Draft";
    } else if (type === "Finalized") {
      return "Finalizuar";
    } else {
      return "I pallogaritur";
    }
  };

  //region >getShiftData()
  const getShiftData = useCallback(async () => {
    message.loading({
      key: "fetching",
      content: "Fetching the latest records...",
    });
    // let processedHours = calculateWagePerHours(
    //   eligibleEmployeesClockIn,
    //   clockings,
    //   location?.state
    // );
    // console.log("process", processedHours);
    let monthlyInstance = location?.state?.data?.muaji;
    // console.log("Monthly Instance", monthlyInstance);
    const momentInstance = {
      start: !!monthlyInstance
        ? dayjsTZ(location?.state?.data?.muaji).startOf("month")
        : dayjsTZ().startOf("month"),
      end: !!monthlyInstance
        ? dayjsTZ(location?.state?.data?.muaji).endOf("month")
        : dayjsTZ().endOf("month"),
    };
    setAggridData(
      eligibleEmployeesClockIn.map((empl, idx) => {
        let resu = retrieveRelevantWages(
          wages,
          empl.employeeId,
          momentInstance
        );
        // console.log("resu", resu);
        return {
          ...empl,
          employeeFullName:
            empl?.employeeFirstName + " " + empl?.employeeLastName,
          oret_totale: resu?.allhours?.totalHours,
          finalized: !!resu ? resu?.finalized : false,
          calculatedWage: !!resu ? resu?.taxes?.netoPay?.netoPay || 0 : 0,
          periudha: dayjsTZ(momentInstance?.start).format("MMMM YYYY"),
          time: dayjsTZ(momentInstance?.start).valueOf(),
          timeStance: dayjsTZ(resu?.momentInstance?.start).format("MM/DD/YYYY"),
          wageStatus: categorizeStatus(resu?.wageStatus),
        };
      })
    );
    message.success({
      key: "fetching",
      content: "The records fetched successfully!",
    });
    //   setClockins(res);
  }, [eligibleEmployeesClockIn, clockings, wages, location?.state]);

  const onReload = (emplId, newValues) => {
    let tempRowData = aggridData;
    tempRowData = tempRowData.map((row) => {
      if (row?.employeeId === emplId) {
        return { ...row, ...newValues };
      } else return { ...row };
    });
    setAggridData(tempRowData);
    // console.log("Ag grid Data", aggridData);
  };

  //testing.. to delete

  const onCellValueChanged = useCallback((event) => {
    if (event?.colDef?.field === "employeeSalaryPerHour") {
      return undefined;
    }
  }, []);

  const onMultiGen = () => {
    setMultiGenVis(true);
    let selectedNodes = gridApi?.getSelectedNodes() || [];
    let selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setMultiEmp(
        selectedData?.map((sd) => ({
          employeeId: sd?.employeeId,
          fullName: `${sd?.employeeFirstName} ${sd?.employeeLastName}`,
        }))
      );
    } else {
      setMultiEmp([]);
    }
  };
  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  useEffect(() => {
    if (!!eligibleEmployeesClockIn && !!departments && !!auth && !!clockings) {
      getShiftData();
    }
  }, [
    eligibleEmployeesClockIn,
    departments,
    location,
    wages,
    location,
    clockings,
  ]);

  const getData = async () => {
    // requestData("employees").then((res) => dispatch(employees(res, "GET_ACTIVE_EMPLOYEES")));
    // requestData("users").then((res) => dispatch(users(res)));
    // requestData("employeeRequests").then((res) => dispatch(employeeRequests(res)));
    // requestData("earnings").then((res) => dispatch(earnings(res)));
    // requestData("deductions").then((res) => dispatch(deductions(res)));
    // requestData("prepayments").then((res) => dispatch(prepayments(res)));
    // requestData("wages").then((res) => dispatch(wages(res)));
    message.loading({
      key: "fetching",
      content: "Fetching the latest records...",
    });
    if (!clockings) {
      setLoading(true);
    }
    return await requestDataWithParams(
      "clocking",
      dayjsTZ().subtract(1, "month").startOf("month").valueOf(),
      dayjsTZ().endOf("month").valueOf()
    ).then((res) => {
      console.log("Clockings", res);
      setClockings(res || []);
      setLoading(false);
      message.success({
        key: "fetching",
        content: "The records fetched successfully!",
      });
      // dispatch(clockingsAction(res || []));
    });

    // await requestData("clocking").then((res) => {
    // 	// message.success("Record Updated...");
    // 	dispatch(clockingsAction(res || []));
    // });
  };
  useLayoutEffect(() => {
    getData();
  }, []);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });
  //region ITEMS
  const ITEMS = {
    search: {},
    icons: {
      excel: {},
      pdf: {},
      print: {},
      refresh: { onClick: getShiftData },
      filter: {
        ...FILTER,
        children: [
          <div className="filterItem">
            <h3>Zgjidh muajin</h3>
            <DatePicker
              popupClassName={isDarkMode ? "darkDropdown" : ""}
              onChange={
                () => {}
                // set filter state so get data
                // (e) => generalFilter(e, key, filterType || "inRange")
              }
              allowClear={{
                clearIcon: (
                  <ClearIcon fill={isDarkMode ? "#e6e6e6" : "#323232"} />
                ),
              }}
              picker={"month"}
              format={"MMMM YYYY"}
            />
          </div>,
        ],
      },
    },
  };

  //region RETURN
  return (
    <LoadableComp loading={loading && !aggridData}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          onCellValueChanged={onCellValueChanged}
          rowData={aggridData}
          rowSelection={{ mode: "multiRow", enableClickSelection: true }}
          onGridReady={onGridReady}
          paginationPageSize={20}
          columnDefs={wagesColumnDefs(onEditWage)}
          paginator={true}
          headerProps={{
            items: ITEMS,
            exportTitle: `Pagat - ${dayjsTZ(location?.state?.data?.muaji)
              .locale("al")
              .format("MMMM YYYY")}`,
            children: [
              <div style={{ display: "flex", gap: 10 }}>
                <MondayButton
                  onClick={() => setGenerateVisib(true)}
                  className="mondayButtonGreen"
                >
                  Gjenero Individualisht
                </MondayButton>
                <MondayButton
                  onClick={onMultiGen}
                  className="mondayButtonGreen"
                >
                  Gjenero te selektuarat
                </MondayButton>
              </div>,
            ],
          }}
          collapsible={{
            content: [
              <WagesHeader
                onFilter={(e) =>
                  !isMobileView
                    ? onEmployeeWageTypeFilter(e, gridApi, "wageStatus")
                    : {}
                }
                statuses={aggridData?.map(({ wageStatus }) => ({
                  wageStatus,
                }))}
              />,
            ],
            label: "Filtra të statuseve",
          }}
          onRowClick={(_, row) => onEditWage(row)}
          idKey={"employeeId"}
        />
      </div>
      {multiGenVis ? (
        <MultiGenerate
          {...{ multiGenVis, setMultiGenVis, multiEmpl, clockings }}
        />
      ) : null}
      {editWageVisib?.visibility ? (
        <EditEmployeeWage
          editable={editWageVisib}
          setVisib={setEditWageVisib}
          onSave={onReload}
        />
      ) : null}
      {generateVisib && clockings ? (
        <GenerateModal {...{ generateVisib, setGenerateVisib, clockings }} />
      ) : null}
    </LoadableComp>
  );
}

export default Wages;
