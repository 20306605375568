import { getTextFromHTML, htmlParser } from "../../../../utils";
import dayjsTZ from "../../../../utils/dayjs";
import { formatChanges } from "../../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";

export const valueFormatter = (col, value, includeStyles) =>
  `${
    value === false || !!value
      ? !!col.hasOwnProperty("replace")
        ? col.replace[value]
        : Array.isArray(value)
        ? value.join(col?.join || ", ")
        : !!col.hasOwnProperty("format")
        ? dayjsTZ(value).locale("al").format(col.format)
        : typeof value === "string" || value instanceof String
        ? /<\/?[a-z][\s\S]*>/i.test(value)
          ? getTextFromHTML(value, includeStyles)
          : formatChanges(value)
        : formatChanges(value)
      : ""
  }`;
