import { CaretDownOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import { useContext, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";
import { ModalLogo } from "../../../../../assets/icons";

const { Option } = Select;

const FIELD_IDS = {
  genderOptions: "eb5833c8-06b5-47a2-bc68-7beb9570880c",
  rolesOptions: "c0156b01-161f-4333-97f3-9f2bdd6bc1fc",
};

const UserInformations = ({ step, missingData }) => {
  const { employee } = useContext(PunonjesitContext);
  const { departments } = useSelector((state) => state.departments);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const { genderOptions, rolesOptions } = useMemo(() => {
    if (!programFields) return null;

    let toReturn = {};
    Object.entries(FIELD_IDS).forEach(([key, id]) => {
      const idx = programFields.findIndex((el) => el?.fieldId === id);
      if (idx !== -1) {
        toReturn[key] = programFields[idx].fieldOptions.map((el, i) => (
          <Option key={i} value={el}>
            {el}
          </Option>
        ));
      }
    });

    return toReturn;
  }, [programFields]);

  const positionOptions = useMemo(() => {
    if (!departments || !selectedDepartment) return null;

    const { key } = selectedDepartment;
    const idx = departments.findIndex((el) => el?.departmentId === key);
    if (idx === -1) return null;

    return (departments[idx].departmentRoles || []).map((el, i) => (
      <Option key={i} value={el?.name}>
        {el?.name}
      </Option>
    ));
  }, [selectedDepartment, departments]);

  const customStyles = (key) =>
    Object.keys(missingData).includes(key)
      ? { border: "1px solid #ea3943" }
      : {};

  return (
    <div className="userInformations">
      <div className="userInformationsForm">
        {step === 1 ? (
          <>
            <Form.Item
              name="given_name"
              label="Emri"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruani emrin e përdoruesit!",
                },
              ]}
            >
              <Input
                style={customStyles("given_name")}
                autoComplete="off"
                placeholder="Shkruaj emrin e përdoruesit"
                disabled={employee}
              />
            </Form.Item>
            <Form.Item
              name="family_name"
              label="Mbiemri"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruani mbiemrin e përdoruesit!",
                },
              ]}
            >
              <Input
                style={customStyles("family_name")}
                autoComplete="off"
                placeholder="Shkruaj mbiemrin e përdoruesit"
                disabled={employee}
              />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Gjinia"
              rules={[
                {
                  required: true,
                  message: "Ju lutem selekto gjininë e përdoruesit!",
                },
              ]}
            >
              <Select
                style={customStyles("gender")}
                placeholder="Selekto gjininë e përdoruesit"
                variant={false}
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                suffixIcon={<CaretDownOutlined />}
                disabled={employee}
              >
                {genderOptions}
              </Select>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruani emailin e përdoruesit!",
                },
              ]}
            >
              <Input
                style={customStyles("email")}
                type="email"
                autoComplete="off"
                placeholder="Shkruaj emailin e përdoruesit"
                disabled={employee}
              />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label="Nr.Tel"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruani numrin e telefonit!",
                },
              ]}
            >
              <PhoneInput
                style={customStyles("phone_number")}
                country={"al"}
                autoComplete="off"
                placeholder="Shkruaj Nr.Tel e përdoruesit"
                masks={{ al: "... ... ..." }}
                disabled={employee}
              />
            </Form.Item>
            <Form.Item
              name="department"
              label="Departamenti"
              rules={[
                {
                  required: true,
                  message: "Ju lutem selekto departamentin e përdoruesit!",
                },
              ]}
            >
              <Select
                style={customStyles("department")}
                placeholder="Selekto Departamentin e përdoruesit"
                onChange={(_, option) => setSelectedDepartment(option)}
                variant={false}
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                suffixIcon={<CaretDownOutlined />}
                disabled={employee}
              >
                {departments?.map((data) => (
                  <Option value={data.departmentName} key={data.departmentId}>
                    {data.departmentName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="departmentRole"
              label="Pozicioni:"
              rules={[
                {
                  required: true,
                  message: "Ju lutem selekto rolin e përdoruesit!",
                },
              ]}
            >
              <Select
                style={customStyles("departmentRole")}
                placeholder={
                  !positionOptions
                    ? "Selekto departamentin fillimisht"
                    : "Selekto Rolin e përdoruesit"
                }
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                disabled={!positionOptions || employee}
                variant={false}
                suffixIcon={<CaretDownOutlined />}
              >
                {positionOptions ? positionOptions : null}
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              label="Fjalëkalimi:"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruani fjalëkalimin e përdoruesit!",
                },
                {
                  pattern:
                    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!#$@%\-_=+<>])([a-zA-Z0-9!#$@%\-_=+<>]+)$/,
                  message:
                    "Fjalëkalimi duhet të përmbajë të paktën 8 karaktere, shkronja të mëdha, të vogla, numra dhe simbole",
                },
              ]}
            >
              <Input.Password
                type="password"
                autoComplete="off"
                placeholder="Shkruaj fjalëkalimin e përdoruesit"
              />
            </Form.Item>
            <Form.Item
              name="konfirmopassword"
              label="Konfirmo Fjalëkalimin"
              rules={[
                {
                  required: true,
                  message: "Ju lutem konfirmo fjalëkalimin e përdoruesit!",
                },
                {
                  pattern:
                    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!#$%@\-_=+<>])([a-zA-Z0-9!#$@%\-_=+<>]+)$/,
                  message:
                    "Fjalëkalimi duhet të përmbajë të paktën 8 karaktere, shkronja të mëdha, të vogla, numra dhe simbole",
                },
              ]}
            >
              <Input.Password
                type="password"
                autoComplete="off"
                placeholder="Konfirmo fjalëkalimin e përdoruesit"
              />
            </Form.Item>
            <Form.Item
              name="userRole"
              label="Roli i userit:"
              rules={[
                {
                  required: true,
                  message: "Ju lutem selekto rolin e userit !",
                },
              ]}
            >
              <Select
                placeholder="Selekto rolin e userit"
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                variant={false}
                suffixIcon={<CaretDownOutlined />}
              >
                {rolesOptions}
              </Select>
            </Form.Item>
          </>
        ) : step === 2 ? (
          <>
            <Form.Item name="email" label="Email">
              <Input disabled />
            </Form.Item>
            <Form.Item name="verifyCode" label="Kodi i verifikimit">
              <Input placeholder="Konfirmo kodin e verifikimit" />
            </Form.Item>
          </>
        ) : null}
      </div>
      <div className="image">
        <ModalLogo />
      </div>
    </div>
  );
};

export default UserInformations;
