import { Checkbox } from "antd";
import getAgThemePreference from "../../../../utils/getAgThemePreference";
import hexToRgba from "../../../../utils/hexToRgba";
import "./Row.scss";
import {
  CaretDownFilled,
  CaretRightFilled,
  CaretRightOutlined,
  CaretUpFilled,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { onlyText } from "react-children-utilities";

const Row = ({ data, setData }) => {
  const { content, selected, collapsed, rowId, onClick } = data || {};
  const { backgroundColor, foregroundColor, mode } = getAgThemePreference();

  const [collapse, setCollapse] = useState(!!collapsed);

  const onChange = (e) => {
    const val = e.target.checked;
    setData(rowId, { selected: val });
  };
  const onLinkClick = (e, callback) => {
    e.preventDefault();
    e.stopPropagation();
    !!callback && callback(data);
  };

  const rowContent = useMemo(() => {
    return (
      <div
        className={`contentWrapper ${collapse ? "" : "appearSoftly"}`}
        data-testid={`contentWrapper-${rowId}`}
        onClick={onClick}
      >
        {collapse ? (
          <span>
            {Object.entries(content || {})
              .filter(([_, obj]) => obj.hasOwnProperty("isCollapseTitle"))
              .map(([_, obj]) =>
                typeof obj?.value === "object"
                  ? onlyText(obj?.value)
                  : obj?.value
              )
              .join(" | ")}
          </span>
        ) : (
          Object.entries(content || {}).map(([_, obj]) => (
            <div className="row">
              <span>{obj?.label}:</span>
              <span
                className={!!obj?.onClick ? "link" : ""}
                onClick={
                  !!obj?.onClick ? (e) => onLinkClick(e, obj?.onClick) : null
                }
              >
                {(obj?.value || "").length > 100
                  ? obj?.value.slice(0, 100) + "..."
                  : obj?.value}
              </span>
            </div>
          ))
        )}
      </div>
    );
  }, [content, collapse]);

  useEffect(() => {
    setCollapse(!!collapsed);
  }, [collapsed]);

  return (
    <div
      className={`rowWrapper ${collapse ? "hide" : "show"}`}
      data-testid={`rowWrapper-${rowId}`}
      style={{
        background: mode.includes("dark")
          ? hexToRgba(backgroundColor, 0.8)
          : "#fff",
        // : hexToRgba(foregroundColor, 0.05),
        color: foregroundColor,
        borderColor: foregroundColor,
      }}
    >
      <Checkbox checked={selected} onChange={onChange} />
      {rowContent}
      <CaretUpFilled
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCollapse((prev) => !prev);
        }}
        data-testid="collapseIcon"
        className={collapse ? "rotate180" : "rotate-180"}
      />
    </div>
  );
};

export default Row;
