import React, { useState } from "react";
import {
  getDisabledAfterHours,
  getDisabledAfterMinutes,
} from "../../utils/disabledTimeFilters";
import { Input, Typography, TimePicker, message } from "antd";
import moment from "moment-timezone";
import { ClockInIcon } from "../../assets";
import Clock from "react-live-clock";
import { Ice1, Ice2, WitchIcon } from "../../../../assets/holidays/icons";
import { useAuth } from "../../../../authentication/authHelpers";
import dayjsTZ from "../../../../utils/dayjs";
import { useMediaQuery } from "react-responsive";
import { eventTracker } from "../../../../utils/eventTracker";

const { TextArea } = Input;
const { Text } = Typography;

// const iceStates = {
//   0: null,
//   1: <Ice1 height={65} />,
//   2: <Ice2 height={75} />,
// };

function ClockInActive({
  editedTime,
  setShowConfirm,
  setShowYesterday,
  notes,
  onShiftNote,
}) {
  const auth = useAuth();
  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  // const [iceState, setIceState] = useState(2);

  const currEmployeePayroll = auth.employee?.employeePayrollType;

  const invertBinaryTree = (tree) => {};

  const handleClockIn = () => {
    if (currEmployeePayroll === "Page bazuar ne ore") {
      // if (iceState > 0) {
      // setIceState((prev) => prev - 1);
      // } else {
      setShowConfirm(true);
      eventTracker("Clock In", "click", "Opened clockin modal");
      !isMobileView && navigator.clipboard.writeText(dayjsTZ().format("HH:mm"));
      // }
    } else {
      message.error(
        "Ju nuk mundeni të filloni turnin pasi nuk keni një pagë të bazuar në orë!"
      );
    }
  };

  return (
    <div className="clock-in-active">
      {" "}
      <div className="time-indicator">
        {/* <h4 style={{ color: "#ea3943" }}>{moment(time).format("HH:mm")}</h4>{" "} */}
        {/* <h4 style={{ color: "#ea3943" }}>
          {" "}
          <Clock format={"HH:mm"} ticking={true} timezone={"Europe/Tirane"} />
        </h4> */}
        {/* <span className="live-icon">
            <LiveIcon />
        </span> */}
      </div>
      <div
        className="clock-in-content"
        // style={{ marginTop: editedTime !== null ? "0px" : "0px" }}
      >
        {/* <div className="clock-in-logo-container"></div> */}

        <div className="actionful">
          <div className="start-shift-cont">
            <span className="welcomeMessage">
              <h1>Përshëndetje!</h1>
            </span>
            {/* <TimePicker
                className="timepick"
                defaultValue={time}
                disabled
                value={
                  customTime ? null : editedTime !== null ? editedTime : time
                }
                format={"HH:mm"}
                allowClear={false}
                onChange={onCustomStartTime}
                onOpenChange={(boolVal) => setCustomTime(boolVal)}
                disabledHours={getDisabledAfterHours}
                disabledMinutes={getDisabledAfterMinutes}
              /> */}
            <span
              className="start-shift-btn"
              style={{
                width: "160px",
                //winter
                // ...(iceState === 1 ? { background: '#016a85' } : iceState === 2 ? {background: '#006cab'} : {}),
              }}
              onClick={handleClockIn}
            >
              {/* {iceStates[iceState]} */}
              Fillo Turnin
            </span>
            <div className="currentTime">
              Ora Tani:{" "}
              <Clock
                format={"HH:mm"}
                ticking={true}
                timezone={"Europe/Tirane"}
              />
            </div>
            <span
              className="yesterday-shift-btn"
              style={{ textAlign: "center" }}
              onClick={() => setShowYesterday(true)}
            >
              Shikoni prezencën në të kaluarën{" "}
            </span>
            {/* <span className="start-shift-btn" onClick={() => setShowConfirm(true)}> */}
            {/* <Popconfirm
title={`A jeni të sigurt që dëshironi të filloni turnin në këtë orar ${
editedTime
  ? moment(editedTime).format("HH:mm")
  : moment(time).format("HH:mm")
} ?`}
// onConfirm={onClockIn}
onConfirm={() => setShowConfirm(true)}
// onConfirm={() => setShowForgot(true)}
// onCancel={cancel}
okText="Po, fillo."
cancelText="Jo, anullo."
> */}
            {/* </Popconfirm> */}
          </div>
        </div>
      </div>
      {editedTime && (
        <div className="justify-note-wrapper" style={{ marginTop: "10px" }}>
          <TextArea
            // autoSize={false}
            value={notes?.clockInNote}
            onChange={(e) => onShiftNote(e, "clockInNote")}
            classname="justify-note"
            placeholder="Shkruaj një shënim këtu..."
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        </div>
      )}
    </div>
  );
}

export default ClockInActive;
