import React, { useEffect, useState } from "react";
import { Modal, message, Spin, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjsTZ from "../../../../../utils/dayjs";
import "./LeaveModal.scss";
import { API } from "aws-amplify";
import { htmlParser } from "../../../../../utils";
import { notificationToAllAdminAndSecifikUser } from "../../../../../utils/sendNotification";
import { useAuth } from "../../../../../authentication/authHelpers";
import { employeeRequests as employeeRequestsReducer } from "../../../../../store/actions";
import { DeleteIcon, ModalXIcon } from "../../../../../assets/icons";
import { driveApi } from "../../../../DriveRequest";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { LogsIcon } from "../../../../Documentation/View/assets";
import ActivityModal from "../../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import { v4 as uuidv4 } from "uuid";

const LeaveModal = ({
  isModalVisible,
  setIsModalVisible,
  content,
  getIcon,
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const { employees } = useSelector((state) => state.employeesList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { accessToken } = useSelector((state) => state.accessToken);

  const driveRequest = driveApi({ accessToken });

  const [currentFile, setCurrentFile] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [openActivity, setOpenActivity] = useState(false);

  //region >updateReduxRequests()
  const updateReduxRequests = (obj = {}, id, remove) => {
    let newRequests = [...(employeeRequests || [])];

    if (!!id) {
      const idx = newRequests.findIndex((el) => el?.requestId === id);
      if (idx > -1) {
        if (!!remove) {
          newRequests.splice(idx, 1);
        } else {
          newRequests[idx] = {
            ...newRequests[idx],
            ...obj,
          };
        }
      }
    } else {
      newRequests.push(obj);
    }
    dispatch(employeeRequestsReducer(newRequests));
  };

  const updateRequest = async (requestStatus, remove) => {
    if (!!content?.requestId) {
      const keylog = {
        id: uuidv4(),
        activity: "Ndryshuar",
        author: user?.given_name + " " + user?.family_name,
        userSub: user?.userSub,
        changeDate: dayjsTZ().valueOf(),
        oldValue: content?.requestStatus,
        newValue: requestStatus,
        field: "Statusi i lejes",
      };
      //will ignore second parameter on delete
      await API[!!remove ? "del" : "put"](
        "employeeRequests",
        `/employeeRequests/${content.requestId}`,
        {
          body: {
            requestStatus,
            requestLogs: [...(content?.requestLogs || []), keylog],
          },
        }
      ).then((res) => {
        updateReduxRequests(res, content.requestId, remove);
        setIsModalVisible(false);
        message.success({
          key: "leaveModal",
          content: `${
            !!remove ? "Kërkesa u fshi" : "Statusi i kërkesës u përditësua"
          } me sukses!`,
        });
        if (!!(users?.allUsers?.Items || []).length) {
          const notifs = {
            Refuzuar: {
              notificationTitle: "Refuzim leje",
              notificationBody: `${user?.given_name}  ${user?.family_name} refuzoi lejen për ${content.given_name} ${content.family_name}`,
            },
            Pending: {
              notificationTitle: "Kthim ne gjendjen fillestare",
              notificationBody: `${user?.given_name}  ${user?.family_name} ktheu ne gjendjen fillestare lejen për ${content.given_name} ${content.family_name}`,
            },
            Aprovuar: {
              notificationTitle: "Aprovim Leje",
              notificationBody: `${user?.given_name}${" "}${
                user?.family_name
              } aprovoi lejen për ${content.given_name} ${content.family_name}`,
            },
            Deleted: {
              notificationTitle: "Fshirje leje",
              notificationBody: `${user?.given_name}  ${user?.family_name} fshiu lejen për ${content.given_name} ${content.family_name}`,
            },
          };
          notificationToAllAdminAndSecifikUser(
            {
              ...notifs[requestStatus],
              createdAt: dayjsTZ().format("DD/MM/YYYY"),
              notificationPriority: false,
              isAnnouncement: false,
              notificationUrl: "/kryefaqja",
              notificationCategory: "Critical",
              notificationFirstName: user?.given_name,
              notificationLastName: user?.family_name,
            },
            users?.allUsers?.Items?.filter(
              (el) =>
                el?.userSub === content?.userSub ||
                el?.userRole === "Admin" ||
                el?.userRole === "Super Admin"
            ),
            users?.allUsers?.Items?.find(
              (el) => el?.userSub === content?.userSub
            )
          );
        }
      });
    } else {
      message.error({
        key: "leaveModal",
        content: "Dicka shkoi gabim!",
      });
    }
  };

  useEffect(() => {
    let timeout;
    if (!!employees && !!Object.keys(content || {}).length) {
      const idx = employees.findIndex((el) => el?.userSub === content?.userSub);

      const id = employees[idx]?.googleDriveFileId;
      if (id) {
        driveRequest.getImageSrc(id).then((image) => {
          setCurrentFile(image?.src);
          setSpinning(false);
        });
      } else {
        timeout = setTimeout(() => {
          setSpinning(false);
        }, 1000);
      }
    }
    return () => clearTimeout(timeout);
  }, [employees, content]);

  //region RETURN
  return (
    <>
      <Modal
        className={`add-leave-request-modal ${
          isDarkMode ? "dark darkHeader" : "lightHeader"
        }`}
        title={`Kërkesa për leje nga ${content?.given_name} ${content?.family_name}`}
        open={isModalVisible}
        width={550}
        centered
        onOk={() => setIsModalVisible(false)}
        destroyOnClose={true}
        onCancel={() => setIsModalVisible(false)}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            onClick={() => updateRequest("Refuzuar")}
            className="mondayButtonRed"
            disabled={content?.requestStatus === "Refuzuar" ? true : false}
          >
            {content?.requestStatus === "Refuzuar" ? "E refuzuar" : "Refuzo"}
          </MondayButton>,
          <MondayButton
            onClick={() => updateRequest("Pending")}
            className="mondayButtonYellow"
            disabled={content?.requestStatus === "Pending" ? true : false}
          >
            {content?.requestStatus === "Pending"
              ? "Në pritje"
              : "Kthe në pritje"}
          </MondayButton>,
          <MondayButton
            onClick={() => updateRequest("Aprovuar")}
            className="mondayButtonGreen"
            disabled={content?.requestStatus === "Aprovuar" ? true : false}
          >
            {content?.requestStatus === "Aprovuar" ? "E pranuar" : "Prano"}
          </MondayButton>,
          !!content?.requestLogs?.length ? (
            <MondayButton
              onClick={() => setOpenActivity(true)}
              className="mondayButtonBlue"
              Icon={<LogsIcon width={20} height={20} />}
            />
          ) : null,
          <Popconfirm
            classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
            title={"Jeni i sigurt?"}
            onConfirm={() => updateRequest("Deleted", true)}
            okText={"Po"}
            cancelText={"Jo"}
          >
            <MondayButton
              className="mondayButtonRed"
              Icon={<DeleteIcon width={20} height={20} />}
            />
          </Popconfirm>,
        ]}
        wrapClassName="request"
      >
        <div className="req-container">
          <div>
            <Spin spinning={spinning}>
              {!!currentFile ? (
                <img
                  className="user-img"
                  src={currentFile}
                  alt="Flex"
                  style={{ borderRadius: 5 }}
                />
              ) : null}
              {!spinning && !currentFile ? <div>No photo uploaded</div> : null}
            </Spin>
            <p style={{ font: "normal normal 600 16px/22px Open Sans" }}>
              {content?.given_name + " " + content?.family_name}
            </p>
          </div>
          <div>
            <div className="start-date">
              Fillimi
              <p>{dayjsTZ(content?.requestPeriod[0]).format("DD/MM/YYYY")}</p>
            </div>
            <div className="end-date">
              Mbarimi
              <p>
                {dayjsTZ(content?.requestPeriod.slice(-1)[0]).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="title-container">
          <div
            className="icon-container"
            style={{
              backgroundColor: "#1d3943",
              borderRadius: 2,
              height: 20,
              width: 20,
              position: "relative",
            }}
          >
            {getIcon(content?.requestType, iconStyle)}
          </div>
          <div
            className="request-title"
            style={{ color: "white", width: "100%" }}
          >
            Kërkesa për{" "}
            <span style={{ textTransform: "lowercase" }}>
              {content?.requestType}{" "}
            </span>
            është krijuar më {dayjsTZ(content?.createdAt).format("DD/MM/YYYY")}
          </div>
          <div
            className="icon-container"
            style={{
              backgroundColor: "#1d3943",
              borderRadius: 2,
              height: 20,
              width: 20,
              position: "relative",
            }}
          >
            {getIcon(content?.requestType, iconStyle)}
          </div>
        </div>
        <div className="request-description">
          {htmlParser(content?.requestDescription)}
        </div>
      </Modal>
      {openActivity ? (
        <ActivityModal
          keylog={content?.requestLogs}
          setIsModalVisible={setOpenActivity}
        />
      ) : null}
    </>
  );
};
export default LeaveModal;

const iconStyle = {
  marginTop: 2,
  marginLeft: 3,
  width: 13,
  height: 14,
};
