import "./AccessConfig.scss";
import { Switch } from "antd";
import { ADMIN_USER } from "../../../../../../constantsFactory/accessRights";
import { REGULAR_USER } from "../../../../../../constantsFactory/regularUser";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AccessConfig = ({ configs, userRole, setConfigs, setDefaultConfigs }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const onSwitch = (key, auth, idx) => {
    setConfigs((prev) => ({
      ...(prev || {}),
      accessConfig: {
        ...(prev?.accessConfig || {}),
        [key]: (prev?.accessConfig?.[key] || []).map((el, i) =>
          i === idx ? { ...el, auth } : el
        ),
      },
    }));
  };

  const getDefaultConfigs = () => {
    let configurations = {};

    Object.entries(accessRights[userRole] || {}).forEach(([key, array]) => {
      configurations[key] = [];
      const matchingKey = key === "routeConfig" ? "path" : "to";

      const toFind = Array.isArray(configs?.[key]?.[0])
        ? configs?.[key]?.[0]
        : configs?.[key];

      (array || []).forEach((el) => {
        const accessObj = (toFind || []).find(
          (c) => c?.[matchingKey] === el?.[matchingKey]
        );
        // && (c?.id == el?.id || c?.key == el?.key || c?.path === el?.path)

        configurations[key].push({ ...el, auth: !!accessObj?.auth });
      });
    });

    setDefaultConfigs((prev) => ({
      ...(prev || {}),
      accessConfig: configurations,
    }));
    setConfigs((prev) => ({ ...(prev || {}), accessConfig: configurations }));
  };

  useEffect(() => {
    getDefaultConfigs();
  }, []);

  return (
    <div className={`accessConfigWrapper ${isDarkMode ? "dark" : ""}`}>
      {Object.entries(configs || {}).map(([key, array], i) => (
        <div key={i}>
          <h1>{TITLES[key]}</h1>
          <div className="accessConfigContent">
            {(array || []).map((el, idx) => (
              <div
                className="configWrapper"
                onClick={() => onSwitch(key, !el?.auth, idx)}
              >
                <span>{el?.title}</span>
                <Switch checked={!!el?.auth} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccessConfig;

const TITLES = {
  settingsConfig: "Konfigurimi i Settings",
  sidebarConfig: "Konfigurimi i Sidebar",
  routeConfig: "Konfigurimi i Routes",
};
const accessRights = {
  [ADMIN_USER.userRole]: ADMIN_USER.accessConfig,
  [REGULAR_USER.userRole]: REGULAR_USER.accessConfig,
};
