import {
  PaymentBrutoIcon,
  FrekuencaPages,
  PaymentNetoIcon,
  TurniPerkatesIcon,
  PagesPerOreIcon,
  LlojiPages,
} from "../../../../assets/icons";
export const paymentData = (fill) => [
  {
    id: 1,
    icon: <PaymentBrutoIcon fill={fill}/>,
    label: "Shuma Bruto",
    fieldName: "employeeGrossMonthlySalary",
    format: "currency",
  },
  {
    id: 2,
    icon: <PaymentNetoIcon fill={fill}/>,
    label: "Shuma Neto",
    fieldName: "employeeMonthlyNetSalary",
    format: "currency",
  },
  {
    id: 3,
    icon: <PagesPerOreIcon fill={fill}/>,
    label: "Pagesa per ore",
    fieldName: "employeeSalaryPerHour",
  },
  {
    id: 4,
    icon: <LlojiPages fill={fill}/>,
    label: "Lloji i pages",
    fieldName: "employeePayrollType",
  },
  {
    id: 5,
    icon: <FrekuencaPages fill={fill}/>,
    label: "Frekuenca e pages",
    fieldName: "employeePayrollFrequency",
  },
  {
    id: 6,
    icon: <TurniPerkatesIcon fill={fill}/>,
    label: "Turni perkates",
    fieldName: "employeeShiftId",
    format: "shift",
  },
];
