import { Form, Button, Modal, Select, DatePicker } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { RequestChair } from "../../../../dashboard/assets";
import CancelAddEmployeeModalPopover from "../../../AddEmployeeModal/Popover";
import "./EmployeeReasonModal.scss";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { ModalXIcon } from "../../../../../assets/icons";
const { Option } = Select;

const EmployeeReasonModal = ({
  employee,
  changeEmployeeStatus,
  modalVisible,
  setModalVisible,
}) => {
  const termination = modalVisible === "shkarko" ? true : false;
  const [form] = Form.useForm();

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [spin, setSpin] = useState(false);

  //* gets Arsyeja e perfundimit from programFields
  const employeeReasons = useMemo(() => {
    if (!programFields?.length) return [];

    const employeeReason = programFields?.find(
      (res) => res.fieldName === "Arsyeja e perfundimit"
    );
    return employeeReason?.fieldOptions?.filter(
      (item) => item?.name !== "Logs"
    );
  }, [programFields]);

  const onSubmit = async () => {
    setSpin(true);
    const reason = form.getFieldValue("employeeReason");
    const date = form.getFieldValue("employeeContractEndDate") || dayjsTZ();
    await changeEmployeeStatus({
      data: {
        employeeStatus: `${!!termination ? "Shkarkuar" : "Larguar"}`,
        ...(!!termination
          ? { employeeReasonforTermination: reason }
          : { employeeReasonforLeaving: reason }),
        employeeContractEndDate: date?.valueOf(),
      },
    });
    setModalVisible(false);
    form.resetFields();
    setSpin(false);
  };

  return (
    <Modal
      title={`${
        employee?.employeeFirstName + " " + employee?.employeeLastName
      } / ${!!termination ? "Shkarko" : "Largo"} punonjësin`}
      className={`reasonModal ${
        isDarkMode ? "dark darkHeader" : "lightHeader"
      }`}
      open={!!modalVisible}
      onCancel={() => {
        setPopoverVisible(true);
      }}
      maskClosable={false}
      centered
      closeIcon={<ModalXIcon />}
      data-testid="modal-header-title"
      footer={[
        <MondayButton
          className="mondayButtonRed"
          data-testid="anulo-btn"
          onClick={() => setPopoverVisible(true)}
        >
          Anulo
        </MondayButton>,
        <MondayButton
          className="mondayButtonGreen"
          data-testid="konfirmo-btn"
          onClick={onSubmit}
          spin={spin}
        >
          Konfirmo
        </MondayButton>,
      ]}
    >
      {/* <Form form={form} onFinish={changeEmployeeStatus}> */}
      <Form form={form}>
        <div className="reasonModalContainer">
          <div className="reasonModalComponents">
            <Form.Item
              className="terminationLabel"
              label="Arsyeja"
              data-testid="reason-form-item"
              name={"employeeReason"}
              // name={`${
              //   !!termination
              //     ? "employeeReasonforTermination"
              //     : "employeeReasonforSuspension"
              // }`}
              rules={[
                {
                  required: true,
                  message: `${
                    !!termination
                      ? "Përshkrimi i shkarkimit të punonjësit është i detyrueshëm"
                      : "Arsyeja e largimit të punonjësit është e detyrueshme!"
                  }`,
                },
              ]}
            >
              <Select
                className="inputReason"
                popupClassName={isDarkMode ? "darkDropdown" : "lightDropdown"}
                placeholder={`Selekto arsyen e ${
                  !!termination ? "shkarkimit" : "largimit"
                }`}
              >
                {employeeReasons?.map(({ name }) => (
                  <Option value={name} key={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="terminationLabel"
              label="Data e përfundimit"
              data-testid="reason-form-item"
              name="employeeContractEndDate"
              rules={[
                {
                  required: true,
                  message: `Data e ${
                    !!termination ? "shkarkimit" : "largimit"
                  } të punonjësit është e detyrushme!`,
                },
              ]}
            >
              <DatePicker
                allowClear
                format={"DD/MM/YYYY"}
                popupClassName={isDarkMode ? "darkDropdown" : "lightDropdown"}
                defaultValue={dayjsTZ()}
                className="reasonDate"
                placeholder="Selekto datën"
              />
            </Form.Item>
          </div>
          <RequestChair className="suspensionChair" />
        </div>
      </Form>
      {popoverVisible ? (
        <CancelAddEmployeeModalPopover
          title={`Ti nuk ke përfunduar, a je i sigurt që dëshiron të largohesh nga ${
            !!termination ? "shkarkimi" : "largimi"
          } i punonjësit?`}
          form={form}
          // classNames={"reasonPopover"}
          visible={popoverVisible}
          setVisible={setPopoverVisible}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          onClose={() => setModalVisible(false)}
        />
      ) : null}
    </Modal>
  );
};

export default EmployeeReasonModal;
