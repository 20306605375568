import { InputNumber } from "antd";
import "./CustomAgPaginator.scss";
import { memo } from "react";
import { useSelector } from "react-redux";

const CustomAgPaginator = ({ gridApi, style }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const totalPages = gridApi?.paginationGetTotalPages();

  return totalPages > 0 ? (
    <div className="customAgPaginator">
      <span>Go to Page:</span>
      <InputNumber
        min={1}
        style={{ ...style, ...(isDarkMode ? { border: "none" } : {}) }}
        max={totalPages}
        onChange={(e) => gridApi?.paginationGoToPage(e - 1)}
      />
    </div>
  ) : null;
};

export default memo(CustomAgPaginator);
