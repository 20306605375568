import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, message } from "antd";
import dayjsTZ from "../../../utils/dayjs";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import PunonjesitContext from "../store/PunonjesitContext";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import colorShade from "../../../utils/colorShade";
import { colDefs } from "./colDefs";
import { requestDataWithParams } from "../../../helpers/API/RequestFactory";

const EXPORT_COLS = [
  { en: "clockInDate", al: "Data", format: "DD/MM/YYYY" },
  { en: "clockInDate", al: "Fillimi", format: "HH:mm" },
  { en: "clockOutDate", al: "Mbarimi", format: "HH:mm" },
  {
    en: "approved",
    al: "Statusi",
    replace: {
      true: "I aprovuar",
      false: "I pa aprovuar",
    },
  },
  { en: "duration", al: "Kohëzgjatja (orë)" },
];
const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};
const { mode, accentColor, foregroundColor } = getAgThemePreference();

const ClockIns = () => {
  const { employee } = useContext(PunonjesitContext);

  const { clockings } = useSelector((state) => state.clockings);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const currentMonthClockings = useMemo(() => {
    if (!clockings || !employee?.employeeId) return [];
    return clockings.filter(
      (el) =>
        el.employeeId === employee?.employeeId &&
        el.clockInDate >= dayjsTZ().startOf("month").valueOf() &&
        el.clockInDate <= dayjsTZ().endOf("month").valueOf()
    );
  }, [clockings, employee?.employeeId]);

  const fetchMonth = async (val, array) => {
    !!val &&
      message.loading({
        key: "fetching",
        content: "Duke ngarkuar të dhënat...",
      });
    let data = array;
    if (!data) {
      if (!!val) {
        data = await requestDataWithParams(
          "clocking",
          dayjsTZ(val).startOf("month").valueOf(),
          dayjsTZ(val).endOf("month").valueOf(),
          { employeeId: employee?.employeeId }
        );
      } else {
        data = currentMonthClockings;
      }
    }

    const sorted = (data || [])
      .map((el) => ({
        ...el,
        fillim_turni: el?.clockInDate,
        mbarim_turni: el?.clockOutDate,
        duration: !!el?.clockOutDate
          ? dayjsTZ(el?.clockOutDate)
              .diff(dayjsTZ(el?.clockInDate), "hours", true)
              .toFixed(2)
          : 0,
      }))
      .sort((a, b) => b?.clockInDate - a?.clockInDate);

    setRowData(sorted);
    !!val &&
      message.success({
        key: "fetching",
        content: "Të dhënat u përditësuan me sukses!",
        duration: 1.5,
      });
  };

  useEffect(() => {
    if (!!employee && Array.isArray(clockings)) {
      fetchMonth(null, currentMonthClockings);
    }
  }, [clockings, employee]);

  //region RETURN
  return (
    <div className="globalPageWrapper">
      <AgGridComponent
        gridApi={gridApi}
        rowData={rowData}
        rowSelection={{ mode: "multiRow" }}
        onGridReady={(p) => setGridApi(p.api)}
        columnDefs={colDefs}
        idKey={"clockingId"}
        headerProps={{
          items: ITEMS,
          exportTitle: `Oraret - ${employee?.employeeFirstName} ${employee?.employeeLastName}`,
          children: [
            <DatePicker
              style={{
                backgroundColor: mode.includes("dark")
                  ? colorShade(accentColor, 40)
                  : null,
                color: foregroundColor,
              }}
              popupClassName={isDarkMode ? "darkDropdown" : ""}
              defaultValue={dayjsTZ()}
              onChange={(val) => fetchMonth(val)}
              picker="month"
              allowClear
              format={"MMMM"}
              placeholder="Zgjidh Muajin"
            />,
          ],
        }}
        counterProps={{
          className: "rowFlex",
          title: "Totali i orareve (Aprovuar)",
          data: rowData?.map((el) =>
            !!el.clockOutDate
              ? dayjsTZ(el.clockOutDate)
                  .diff(dayjsTZ(el.clockInDate), "hours", true)
                  .toFixed(2)
              : 0
          ),
          derivedData: rowData?.map((el) =>
            !!el.clockOutDate && !!el?.approved
              ? dayjsTZ(el.clockOutDate)
                  .diff(dayjsTZ(el.clockInDate), "hours", true)
                  .toFixed(2)
              : 0
          ),
        }}
      />
    </div>
  );
};

export default ClockIns;
