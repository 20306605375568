import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Skeleton, Tooltip } from "antd";
import "./TimeSheet.scss";
import { calculatePercentages } from "./utils/filters";
import { useAuth } from "./../../../authentication/authHelpers";
import { Chart } from "react-google-charts";
import { getShiftChart } from "./utils/gcharts";
import {
  ApprovedIcon,
  BreakTimeIcon,
  BreakTimeIconSmall,
  ClearIconDrawer,
  ClockOutIndicator,
  UnapprovedIcon,
} from "../../../assets/icons";
import { paySubFactory } from "../../Pagat/PaySub/employeeCalc/employeeSubFactory";
import { useSelector } from "react-redux";
import InfoModal from "./InfoModal";
import { useMediaQuery } from "react-responsive";
import { ClockOutIcon } from "../../dashboard/assets";
import OnGoingShift from "./OnGoingShift";
import { getTranslation } from "../../Reports/generate/translations";
import { htmlParser } from "../../../utils";
import { useNavigate } from "react-router";
import { eventTracker } from "../../../utils/eventTracker";

export const data = [
  [
    { type: "string", id: "turni_mundshem" },
    { type: "string", id: "turni_aktual" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
  ],
  [
    "Turni i mundshëm",
    "",
    new Date(0, 0, 0, 10, 0, 0),
    new Date(0, 0, 0, 21, 30, 0),
  ],

  [
    "Turni Aktual",
    "Turn Normal",
    new Date(0, 0, 0, 13, 30, 0),
    new Date(0, 0, 0, 21, 30, 0),
  ],
];
function ProgressShift({ processedShift, tourRef, shiftsConfig, totalHours }) {
  const navigate = useNavigate();
  const { userRole } = useAuth();

  const { employees } = useSelector((state) => state.employeesList);

  const [toVisualize, setToVisualize] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [info, setInfo] = useState([]);
  console.log(toVisualize);
  const handleShiftPreview = (el) => {
    setInfo(el);
    setIsVisible(true);
    eventTracker("Liste Prezencat", "click", "Previewed a shift");
  };
  const toggleVisible = () => {
    setIsVisible(!isVisible);
    setInfo([]);
  };
  useEffect(() => {
    if (processedShift.length > 0) {
      setToVisualize(processedShift.filter((el) => el.percentage > 0.1));
    }
  }, [processedShift, employees, info]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });
  const { employeeFirstName, employeeLastName } =
    processedShift?.[0]?.employeeInfo || {};

  return (
    <>
      {processedShift?.length > 0 && employees?.length > 0 && (
        <div className="progress-shift" data-testid="progress-shift">
          <span className="shift-person">
            {employeeFirstName +
              " " +
              employeeLastName +
              " - " +
              dayjsTZ(processedShift[0]?.clockInDate).format("DD/MM/YYYY")}
          </span>
          <div
            className="progress-shift-bar"
            data-testid="progress-shift-bar"
            style={{ flex: "1.2", width: "auto" }}
          >
            {!!toVisualize.length ? (
              toVisualize
                .filter((el) => el.percentage > 0)
                ?.map((el, idx) => {
                  return (
                    <Tooltip
                      key={idx}
                      style={{ zIndex: 9999999999999 }}
                      // open={el?.percentage < 8 ? true : false}
                      title={`${
                        el?.percentage < 8 && el?.isBreakTime !== true
                          ? `${dayjsTZ(el?.clockInDate).format(
                              "HH:mm"
                            )} - ${dayjsTZ(el?.clockOutDate).format(
                              "HH:mm"
                            )} |  ${
                              !el?.approved ? "I Pa Aprovuar" : "Aprovuar"
                            }`
                          : el?.isBreakTime
                          ? `Pushimi: ${dayjsTZ(el?.clockInDate).format(
                              "HH:mm"
                            )} - ${dayjsTZ(el?.clockOutDate).format("HH:mm")} `
                          : `${!el?.approved ? "I Pa Aprovuar" : "Aprovuar"}`
                      } | (Turn ${getTranslation(el?.dayType.toLowerCase())})`}
                    >
                      <div
                        key={idx}
                        className="load-progress"
                        onClick={() => handleShiftPreview(toVisualize)}
                        style={{
                          backgroundColor: `${statusColors[el.type]}`,
                          width: `${el?.percentage}%`,
                          height: "100%",
                          borderRadius: calculateBorderRad(toVisualize, idx),
                        }}
                      >
                        {" "}
                        <span className="time-label">
                          {el?.percentage > 8 && el?.isBreakTime !== true
                            ? `${dayjsTZ(el?.clockInDate).format(
                                "HH:mm"
                              )} - ${dayjsTZ(el?.clockOutDate).format("HH:mm")}`
                            : ""}{" "}
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {el?.isBreakTime && <BreakTimeIconSmall />}
                          </span>
                        </span>
                      </div>
                    </Tooltip>
                  );
                })
            ) : (
              <Skeleton.Input active size="small" block />
            )}
          </div>
          <div className="progressShiftData">
            <Tooltip
              placement="top"
              title={
                !toVisualize?.some((el) => el?.approved === false)
                  ? "Aprovuar"
                  : "I Pa Aprovuar"
              }
            >
              <span
                ref={tourRef}
                className="approved-ind-icons"
                style={{
                  backgroundColor:
                    toVisualize?.length > 0 &&
                    toVisualize?.some((el) => el?.approved === false)
                      ? "#ea3943"
                      : "#1da193",
                }}
                onClick={
                  userRole === "Admin" ||
                  userRole === "Super Admin" ||
                  userRole === "Supervisor"
                    ? () =>
                        navigate("/aprovimet", {
                          state: {
                            search: employeeFirstName + " " + employeeLastName,
                            //use this structure in order to fetch shifts by ID
                            idsArray: [processedShift[0]?.clockingId],
                            openEditModal: processedShift[0]?.clockingId,
                          },
                        })
                    : () => {}
                }
              >
                {toVisualize?.length > 0 &&
                !toVisualize?.some((el) => el?.approved === false) ? (
                  <ApprovedIcon />
                ) : (
                  <UnapprovedIcon />
                )}
              </span>
            </Tooltip>
            <span className="clock-out-ind">
              {toVisualize?.length > 0 &&
                dayjsTZ(
                  toVisualize[toVisualize.length - 1]?.clockOutDate
                ).format("HH:mm")}
            </span>
            <span className="total-hours">
              {isMobileView ? "Totali: " : ""}
              {totalHours || 0} orë
            </span>
          </div>
          {isVisible && (
            <InfoModal
              {...{ isVisible, toggleVisible, info, totalHours, shiftsConfig }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ProgressShift;

const calculateBorderRad = (arr, id) => {
  if (arr.length === 1) {
    return "5px 5px 5px 5px";
  } else if (arr.length > 1) {
    if (id === 0) {
      return "5px 0px 0px 5px";
    } else if (id === arr.length - 1) {
      return " 0px 5px 5px 0px";
    } else {
      return "0px";
    }
  }
};

//region SHIFT CARD
export const ShiftCard = ({ info, lastShift }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const {
    city: cityIn = "",
    country_name: country_nameIn = "",
    IPv4: IPv4In = "",
  } = lastShift?.clockingLogs[0]?.ipResponse || {};
  const {
    city: cityOut = "",
    country_name: country_nameOut = "",
    IPv4: IPv4Out = "",
  } = lastShift?.clockingLogs[1]?.ipResponse || {};

  return (
    <div className={`shift-card ${isDarkMode ? "dark" : ""}`}>
      <div className="shifts-container">
        <div className="shift-card-time">
          <span className="label-card">Fillimi</span>
          {!!lastShift?.clockingLogs[0]?.ipResponse ? (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={`${IPv4In || ""}${!!cityIn ? " | " + cityIn : ""}${
                !!country_nameIn ? "," + country_nameIn : ""
              }`}
            >
              <span
                className="shift-indicator"
                style={{ backgroundColor: "#1da193" }}
              >
                {dayjsTZ(info?.clockInDate).format("HH:mm")}
              </span>
            </Tooltip>
          ) : (
            <span
              className="shift-indicator"
              style={{ backgroundColor: "#1da193" }}
            >
              {dayjsTZ(info?.clockInDate).format("HH:mm")}
            </span>
          )}
        </div>
        <div className="shift-card-time">
          <span className="label-card">Mbarimi</span>
          {!!lastShift?.clockingLogs[1]?.ipResponse ? (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={`${IPv4Out || ""} ${!!cityOut ? " | " + cityOut : ""} ${
                !!country_nameOut ? "," + country_nameOut : ""
              }`}
            >
              <span
                className="shift-indicator"
                style={{ backgroundColor: "#ea3943" }}
              >
                {dayjsTZ(info?.clockOutDate).format("HH:mm")}
              </span>
            </Tooltip>
          ) : (
            <span
              className="shift-indicator"
              style={{ backgroundColor: "#ea3943" }}
            >
              {dayjsTZ(info?.clockOutDate).format("HH:mm")}
            </span>
          )}
        </div>
      </div>
      <span
        className="shift-card-total-hours"
        style={{ color: statusColors[info?.type] }}
      >
        {info?.duration} orë {statusTitles[info?.type]}
      </span>
    </div>
  );
};

const statusColors = {
  usual: "#1da193",
  missing: "#ea3943",
  noInfoColor: "#F5F5F7",
  extra: "#058C42",
  normalHours: "#69c050",
  lateHours: "#1da193",
  extraHours: "#832232",
  breakTime: "#FCC94A",
};

const statusTitles = {
  usual: "normale",
  missing: "bosh",
  noInfo: "noInfo",
  extra: "shtesë",
  normalHours: "(Para orës 19:00)",
  lateHours: "(Midis 19:00 - 22:00)",
  extraHours: "(Pas orës 22:00)",
};

// const statusColors = {
//   usual: "linear-gradient(90deg, hsla(152, 100%, 50%, 1) 0%, hsla(157, 75%, 38%, 1) 100%)",
//   missing: "linear-gradient(90deg, hsla(52, 82%, 52%, 1) 0%, hsla(1, 92%, 47%, 1) 100%)",
//   noInfoColor: "linear-gradient(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)",
//   extra: "linear-gradient(90deg, hsla(186, 100%, 69%, 1) 0%, hsla(217, 100%, 50%, 1) 100%)",
// };
