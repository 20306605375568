// import { message } from "antd";
import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import swal from "sweetalert";
import {
  requestAllPages,
  requestData,
} from "../../../../../../helpers/API/RequestFactory";
import { dayCalculateFactory } from "../../../../../Pagat/PaySub/employeeCalc/dayCalculateFactory";
import axios from "axios";
export const editShift = async (
  clockingId,
  shiftData,
  employeeId,
  adminLog
) => {
  // console.log("to send", dayjsTZ(shiftData.clockInDate).format("DD/MM/YYYY HH:mm"));
  // console.log("Adming Log", adminLog);
  let valid = await validateCrashingShifts(shiftData, employeeId, clockingId);
  const res = await axios.get("https://api.ipify.org/?format=json");
  // console.log("valid", valid);

  let clockInDate = dayjsTZ(shiftData.clockInDate);
  clockInDate = clockInDate
    .set("hour", clockInDate.get("hour"))
    .set("minute", clockInDate.get("minute"))
    .set("second", "00");

  let clockOutDate = dayjsTZ(shiftData.clockOutDate);
  clockOutDate = clockOutDate
    .set("hour", clockOutDate.get("hour"))
    .set("minute", clockOutDate.get("minute"))
    .set("second", "00");

  if (valid.value === true) {
    let amounts = await dayCalculateFactory({
      ...shiftData,
      employeeId,
      clockingId,
      clockInDate: dayjsTZ(clockInDate).valueOf(),
      clockOutDate: dayjsTZ(clockOutDate).valueOf(),
    });
    console.log({ amounts });
    const clockingToSend = {
      ...shiftData,
      clockInDate: dayjsTZ(clockInDate).valueOf(),
      clockOutDate: dayjsTZ(clockOutDate).valueOf(),
      totalities: amounts || {},
    };
    await API.put("clocking", `/clocking/${clockingId}`, {
      body: { ...clockingToSend },
    })
      .then(async () => {
        await API.post("clockingLogs", "/clockingLogs", {
          body: {
            ...adminLog,
            clockingId,
            ipResponse: { ...adminLog.ipResponse, IPv4: res?.data?.ip },
            newShift: [
              {
                clockInDate: dayjsTZ(clockInDate).valueOf(),
                clockOutDate: dayjsTZ(clockOutDate).valueOf(),
                dayType: shiftData?.dayType,
                clockingCategory: shiftData?.clockingCategory,
              },
            ],
          },
        }).then((res) => console.log("Success log", res));
      })
      .catch((err) => {
        console.log(err);
      });
    return { ...clockingToSend, clockingId };
  } else {
    return Promise.reject([valid.crashShift, shiftData]);
  }
};
export const getShift = async (clockingId) => {
  return await API.get("clocking", `/clocking/${clockingId}`);
};
export const validateCrashingShifts = async (
  shiftData,
  employeeId,
  clockingId
) => {
  console.log("Shift Data", shiftData);
  let clockings;
  let res = { value: true, crashShift: {} };
  let startTimeInstance = dayjsTZ(shiftData.clockInDate)
    .startOf("day")
    .valueOf();
  let endTimeInstance = dayjsTZ(shiftData.clockInDate).endOf("day").valueOf();
  await requestData("clocking", { employeeId })
    .then((res) => {
      clockings = res.filter(
        (el) =>
          el.clockInDate > startTimeInstance &&
          el.clockInDate < endTimeInstance &&
          el.clockingId !== clockingId
      );
    })
    .catch((err) => console.log("error on requestData", err));
  if (clockings.length > 0) {
    clockings.forEach((el) => {
      if (
        (el.clockInDate <= shiftData.clockInDate &&
          shiftData.clockInDate <= el.clockOutDate) ||
        (el.clockInDate <= shiftData.clockOutDate &&
          shiftData.clockOutDate <= el.clockOutDate) ||
        (shiftData.clockInDate <= el.clockInDate &&
          shiftData.clockOutDate >= el.clockInDate) ||
        (el.clockInDate <= shiftData.clockInDate &&
          el?.clockOutDate === null &&
          !shiftData.clockOutDate)
      ) {
        res = { value: false, crashShift: el }; // new edited shift is between an old shift, return false & shift (one shift)
      }
    });
    return res;
  } else {
    return { value: true };
  }
};
export const approveShift = async (clocking, adminLog) => {
  return new Promise(async (resolve, reject) => {
    let amounts = await dayCalculateFactory({
      ...clocking,
      clockInDate: clocking?.clockInDate,
      clockOutDate: dayjsTZ(clocking?.clockOutDate).valueOf(),
    });
    amounts.adminLog = adminLog;
    const toSend = {
      approved: true,
      totalities: amounts || {},
    };
    await API.put("clocking", `/clocking/${clocking?.clockingId}`, {
      body: { ...toSend },
    });
    resolve({ ...clocking, ...toSend });
  });
};
export const disapproveShift = async (clocking, adminLog) => {
  const toSend = {
    approved: false,
    totalities: {
      adminLog: adminLog,
    },
  };
  await API.put("clocking", `/clocking/${clocking?.clockingId}`, {
    body: { ...toSend },
  });
  return { ...clocking, ...toSend };
};
//List of Promises
export const approveShifts = async (shiftsArr, adminLog) => {
  // console.log(shiftsArr);
  let promises;
  if (shiftsArr?.length > 0 && shiftsArr?.length > 10) {
    promises = await shiftsArr.map(async (el) => {
      return await approveShift(el, adminLog);
    });

    return await Promise.all(promises).then((res) => {
      swal({
        title: `${
          shiftsArr.length === 1
            ? "Turni u aprovua me sukses!"
            : "Turnet u aprovuan me sukses!"
        }`,
        icon: "success",
        className: "custum-swal",
      });
      return res;
    });
  } else {
    promises = shiftsArr.map(async (el) => {
      return await approveShift(el, adminLog);
    });
    return await Promise.all(promises).then((res) => {
      swal({
        title: `${
          shiftsArr.length === 1
            ? "Turni u aprovua me sukses!"
            : "Turnet u aprovuan me sukses!"
        }`,
        icon: "success",
        className: "custum-swal",
      });
      return res;
    });
  }
};
export const disapproveShifts = async (shiftsArr, adminLog) => {
  // console.log(shiftsArr);
  let promises = shiftsArr.map(
    async (el) => await disapproveShift(el, adminLog)
  );
  return await Promise.all(promises).then((res) => {
    swal({
      title: `${
        shiftsArr.length === 1
          ? "Turnit ju hoq aprovimi me sukses!"
          : "Turneve u hoqën aprovimet me sukses!"
      }`,
      icon: "success",
      className: "custum-swal",
    });
    return res;
  });
};
// Logs for Shift!
export const getLogsPerShift = async (clockingId) => {
  // const config = {
  // 	queryStringParameters: {
  // 		tableName: process.env.NODE_ENV === "production" ? "clockingLogs-prod" : "clockingLogs",
  // 		filterKey: "clockingId",
  // 		filterValue: `${clockingId}`,
  // 	},
  // };
  return await API.get("clockingLogs", "/clockingLogs").then((res) =>
    res?.filter((el) => el?.clockingId === clockingId)
  );
};
export const getAllUsers = async () => {
  return await API.get("users", "/users").then((res) => res);
};
export const deleteShift = async (clockingId) => {
  await API.del("clocking", `/clocking/${clockingId}`).catch((err) =>
    console.log("Error: ", err)
  );
};
export const deleteClockIns = async (shiftsArr, callback) => {
  // console.log(shiftsArr);
  if (!!shiftsArr && Array.isArray(shiftsArr)) {
    const promises = shiftsArr
      .filter((el) => !!el?.clockingId)
      .map(async (el) => await deleteShift(el.clockingId));
    Promise.all(promises).then(() => {
      callback && callback();
      swal({
        title: `${
          shiftsArr.length === 1
            ? "Turni u fshi me sukses!"
            : "Turnet u fshinë me sukses!"
        }`,
        icon: "success",
        className: "custum-swal",
      });
    });
  }
  // let promises = await shiftsArr.map(async (el) =>
  // 	setTimeout(async () => {
  // 		return await deleteShift(el.clockingId);
  // 	}, 500)
  // );
  // Promise.all(promises).then(() =>
  // 	swal({
  // 		title: `${shiftsArr.length === 1 ? "Turni u fshi me sukses!" : "Turnet u fshine me sukses!"}`,
  // 		icon: "success",
  // 		className: "custum-swal",
  // 	})
  // );
};
