import { message } from "antd";
import moment from "moment-timezone";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
} from "../helpers/API/RequestFactory";
import { sendNotificationToEmployee } from "../utils/sendNotification";
import dayjsTZ from "../utils/dayjs";

export const automateClockInReminder = async (user) => {
  const userEmployee = user?.employeeId || null;
  const localSave = localStorage.getItem("clockInReminder");
  // message.info("U THIRR");
  const sendReminder =
    !!localSave &&
    dayjsTZ().diff(dayjsTZ(JSON.parse(localSave)), "minutes", true) > 60
      ? true
      : localSave === null
      ? true
      : false;
  console.log("send reminder", sendReminder);
  // console.log("localSave", localSave);
  if (!!userEmployee) {
    const today = dayjsTZ().format("DD/MM/YYYY");
    const clockings = await requestDataWithParams(
      "clocking",
      dayjsTZ().subtract(2, "days").valueOf(),
      null,
      { employeeId: userEmployee }
    ).then((res) =>
      res.find((el) => dayjsTZ(el?.clockInDate).format("DD/MM/YYYY") === today)
    );
    if (!clockings && sendReminder) {
      localStorage.setItem("clockInReminder", dayjsTZ().valueOf());
      return sendNotificationToEmployee(
        {
          notificationTitle: "Ju keni harruar të bëni clock in!",
          createdAt: dayjsTZ().format("DD/MM/YYYY"),
          notificationPriority: false,
          notificationBody: `${user?.userAccess[0]?.given_name} ${user?.userAccess[0]?.family_name}, clock In përpara se të filloni punen!`,
          isAnnouncement: false,
          notificationUrl: "/kryefaqja",
          notificationCategory: "Critical",
          notificationFirstName: user?.userAccess[0]?.given_name,
          notificationLastName: user?.userAccess[0]?.family_name,
        },
        user?.userAccess[0] || {}
      );
    }
  }
};
