import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../components/DriveRequest";

function ReturnImg({
  googleDriveFileId,
  width,
  height,
  borderRadius,
  defaultImg,
}) {
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const [img, setImg] = useState(defaultImg);

  const getImage = async () => {
    if (!!googleDriveFileId?.length) {
      try {
        await driveRequest.getImageSrc(googleDriveFileId).then((image) => {
          if (!!image?.src) {
            setImg(
              <img
                width={width || 50}
                height={height || 50}
                style={{ borderRadius: borderRadius || 0 }}
                src={image?.src}
                alt=""
              />
            );
          }
        });
      } catch {
        console.log("Something went wrong while displaying the picture");
      }
    }
  };
  useEffect(() => {
    getImage();
  }, [googleDriveFileId]);

  return (
    <div
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius || 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {img}
    </div>
  );
}

export default ReturnImg;
