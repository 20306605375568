import { isNumber } from "lodash";
import React, { useMemo, useState, useEffect } from "react";
import PayStubTable from "../../../PaySub/payStubTable/PayStubTable";
import { prepareDeductions } from "./calculations";
import "./Deductions.scss";
import { DeleteFilled } from "@ant-design/icons";
import { noSignFormatter } from "../../warnings/CustomWarnings";

function Deductions({ deductionsData, setDeductionsData }) {
	const [tableData, setTableData] = useState({});
	useMemo(() => setTableData(prepareDeductions(deductionsData)), [deductionsData]);

	const deleteRow = (data, index) => {
		// setTableData((prev) => ({ ...prev, rowData: tableData?.rowData?.filter((el) => el !== data) }));
		setDeductionsData(deductionsData.filter((el, idx) => idx !== index));
		// console.log("deductionsData", deductionsData);
	};

	return (
		<div className="deductions">
			{/* <br />
			<h2>Zbritje</h2> */}
			<Table
				deletable={true}
				columnsDef={tableData?.columnsDef}
				rowData={tableData?.rowData}
				deleteRow={deleteRow}
			/>
		</div>
	);
}

export default Deductions;

function Table({ columnsDef, rowData, deleteRow, deletable, color }) {
	return (
		<div className="deductions-table">
			<table>
				<tr>
					{columnsDef?.map((el) => (
						<th>{el}</th>
					)).concat(<th></th>)}
				</tr>
				{rowData?.length > 0 ? (
					rowData?.map((rd, idx) => (
						<tr>
							{rd?.map((trd) => (
								<td>
                  {" "}
                  {isNumber(trd)
                    ? noSignFormatter.format(trd).replace("ALL", "")
                    : trd}
                </td>
							))}

							{idx !== rowData?.length - 1 && deletable ? (
								<td>
									<DeleteFilled
										onClick={() => deleteRow(rd, idx)}
										style={{
											fontSize: "18px",
											color: "red",
											cursor: "pointer",
										}}
									/>
								</td>
							) : (
								<td></td>
							)}
						</tr>
					))
				) : (
					<strong style={{ display: "flex", justifyContent: "center", color: "red" }}>
						{/* Nuk ka te dhena per te shfaqur */}
					</strong>
				)}
			</table>
		</div>
	);
}
