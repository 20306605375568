import React, { useEffect, useMemo, useState } from "react";
import { Select, Form, message, Spin, Switch, Skeleton } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import "./te_dhenat_akses.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../DriveRequest";
import { useMediaQuery } from "react-responsive";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";
import { useDispatch } from "react-redux";
import { users as usersReducer } from "../../../../../store/actions";
import AccessConfig from "./AccessConfig/AccessConfig";
import SettingsFooter from "../../../dynamic components/SettingsFooter/SettingsFooter";
import { TickIcon, XIcon } from "../../../../../assets/icons";

const USER_ROLES_FIELD_ID = "c0156b01-161f-4333-97f3-9f2bdd6bc1fc";

function PerdoruesitTeDhenatAksesit() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { identityId } = useParams();

  const { programFields } = useSelector((state) => state.programFields);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const [currentFile, setCurrentFile] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [userConfig, setUserConfig] = useState({});
  const [defaultUserConfig, setDefaultUserConfig] = useState({});

  const user = useMemo(() => {
    if (!identityId || !users?.allUsers?.Items) return {};

    const foundUser =
      (users?.allUsers?.Items || []).find(
        (el) => el?.identityId === identityId
      ) || {};
    setLoading(false);
    const { userRole, auth2Fa, accessConfig } = foundUser || {};
    setUserConfig({ userRole, auth2Fa, accessConfig });
    setDefaultUserConfig({ userRole, auth2Fa, accessConfig });

    return foundUser;
  }, [identityId, users]);

  const currentEmpl = useMemo(() => {
    if (!activeEmployees) return {};

    return activeEmployees.find?.((el) => el?.userSub === user?.userSub) || {};
  }, [activeEmployees, user]);

  const handleHeaderSave = () => {
    form
      .validateFields()
      .then(async (res) => {
        const toSend = {
          userRole: res?.userRole,
          auth2Fa: { ...(user?.auth2Fa || {}), is2FaActive: res?.is2FaActive },
        };
        await API.put("users", `/users/${user.identityId}`, {
          body: { ...toSend },
        })
          .then(() => {
            let newUsers = [...(users.allUsers.Items || [])];

            const index = newUsers.findIndex(
              ({ identityId }) => identityId === user.identityId
            );
            if (index > -1) {
              newUsers[index] = {
                ...newUsers[index],
                ...toSend,
              };
            }
            const toDispatch = {
              ...users,
              allUsers: { ...users.allUsers, Items: [...newUsers] },
            };
            dispatch(usersReducer(toDispatch));
            message.success({
              key: "headerSave",
              content: "Statusi u ndryshua me sukses",
            });
          })
          .catch((err) => console.log("Error PUT /users: ", err));
      })
      .catch((err) => console.log("Error validating fields: ", err));
  };

  const handleConfigSave = async () => {
    setSaving(true);

    await API.put("users", `/users/${user?.identityId}`, {
      body: { ...userConfig },
    })
      .then(async () => {
        let newUsers = [...(users.allUsers.Items || [])];
        const index = newUsers.findIndex(
          ({ identityId }) => identityId === user.identityId
        );
        if (index > -1) {
          newUsers[index] = {
            ...newUsers[index],
            ...userConfig,
          };
        }
        const toDispatch = {
          ...users,
          ...userConfig,
          allUsers: { ...users.allUsers, Items: [...newUsers] },
        };

        dispatch(usersReducer(toDispatch));
        message.success({
          key: "configSave",
          content: "Aksesi u ndryshua me sukses!",
        });
        setDefaultUserConfig(userConfig);
      })
      .catch((err) =>
        message.error({ key: "configSave", content: "Ndodhi një gabim!" })
      );
    setSaving(false);
  };

  // const deleteItems = () => {
  //   API.del("users", `/users/${user.identityId}`).then((r) => {
  //     window.location.pathname = "/konfigurimet/siguria/perdoruesit";
  //   });
  // };

  const getImage = async () => {
    await driveRequest
      .getImageSrc(currentEmpl?.googleDriveFileId)
      .then((image) => {
        setCurrentFile(image?.src);
        setSpinning(false);
      });
  };

  useEffect(() => {
    let timeout;
    if (!!currentEmpl?.googleDriveFileId) {
      getImage();
    } else {
      timeout = setTimeout(() => {
        setSpinning(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [currentEmpl]);

  const userRoles = useMemo(() => {
    if (!programFields) return [];
    const field = programFields?.find(
      ({ fieldId }) => fieldId === USER_ROLES_FIELD_ID
    );
    if (!field) return [];
    return field?.fieldOptions;
    return field?.fieldOptions?.filter((el) => el !== user?.userRole);
  }, [programFields]);

  const { dates, employeeName } = useMemo(
    () => ({
      dates: (
        <>
          <div className="dataBlock">
            <h3>Data e regjistrimit në portal</h3>
            <h1>{dayjsTZ(user.createdAt).format("DD/MM/YYYY")}</h1>
          </div>
          <div className="dataBlock">
            <h3>Data e fundit në portal</h3>
            <h1>{dayjsTZ(user?.lastLoggedIn).format("DD/MM/YYYY , HH:mm")}</h1>
          </div>
        </>
      ),
      employeeName: (
        <div className="employeeName">
          <h3>{user?.given_name + " " + user?.family_name}</h3>
          <h1>{user?.userRole}</h1>
        </div>
      ),
    }),
    [user]
  );
  const isMobileView = useMediaQuery({
    query: "(max-width: 850px)",
  });
  console.log(user);
  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className={`employeeAccesses ${isDarkMode ? "dark" : ""}`}>
          <div className="accessesHeader">
            <div className="employeePfp">
              <Spin spinning={spinning}>
                {!!currentFile && <img src={currentFile} alt="Flex" />}
                {!spinning && !currentFile && "No photo uploaded"}
              </Spin>
              {isMobileView ? employeeName : null}
              {isMobileView ? dates : null}
            </div>
            <div className="employeeData">
              <div className="dataBlock">
                {!isMobileView ? employeeName : null}
                <Select
                  placeholder="Selekto Rolin"
                  variant={false}
                  popupClassName={isDarkMode ? "darkDropdown" : ""}
                  suffixIcon={<CaretDownOutlined />}
                  value={userConfig?.userRole}
                  onSelect={(val) =>
                    setUserConfig((prev) => ({
                      ...(prev || {}),
                      userRole: val,
                    }))
                  }
                >
                  {userRoles?.map((el) => (
                    <Option key={el} value={el}>
                      {el}
                    </Option>
                  ))}
                </Select>
                <div
                  className="enable2FaSwitch"
                  onClick={() =>
                    setUserConfig((prev) => ({
                      ...(prev || {}),
                      auth2Fa: {
                        ...(prev.auth2Fa || {}),
                        is2FaActive: !prev?.auth2Fa?.is2FaActive,
                      },
                    }))
                  }
                >
                  <span>Mundëso 2FA</span>
                  <Switch checked={userConfig?.auth2Fa?.is2FaActive} />
                </div>
                {/* <MondayButton
                  className="mondayButtonGreen"
                  onClick={handleHeaderSave}
                >
                  Konfirmo
                </MondayButton> */}
              </div>
              <div className="dataBlock">
                <h3>Faqja më aktive</h3>
                <Link to="/kryefaqja">
                  <h1>Kryefaqja</h1>
                </Link>
                <div className="flexButtons">
                  <MondayButton
                    className="mondayButtonBlue"
                    onClick={() => navigate("/konfigurimet/perdoruesit")}
                  >
                    Të gjithë përdoruesit
                  </MondayButton>
                  <MondayButton disabled={true} className="mondayButtonRed">
                    Fshij përdoruesin
                  </MondayButton>
                </div>
              </div>
              {!isMobileView ? dates : null}
            </div>
          </div>
          <div className="accessesData">
            {Object.keys(userConfig?.accessConfig || {}).length ? (
              <AccessConfig
                configs={userConfig?.accessConfig}
                userRole={user?.userRole}
                setConfigs={setUserConfig}
                defaultConfigs={defaultUserConfig?.accessConfig}
                setDefaultConfigs={setDefaultUserConfig}
              />
            ) : (
              <Skeleton />
            )}
          </div>
          <SettingsFooter
            previousData={defaultUserConfig}
            updatedData={userConfig}
            children={[
              <MondayButton
                {...{
                  className: "mondayButtonRed",
                  Icon: <XIcon height={10} width={10} />,
                  onClick: () => setUserConfig(defaultUserConfig),
                }}
              >
                Discard Changes
              </MondayButton>,
              <MondayButton
                {...{
                  className: "mondayButtonGreen",
                  spin: saving,
                  Icon: <TickIcon height={17} width={17} />,
                  onClick: handleConfigSave,
                }}
              >
                Save Changes
              </MondayButton>,
            ]}
          />
        </div>
      </div>
    </LoadableComp>
  );
}

export default PerdoruesitTeDhenatAksesit;
