import { Row, Col, Calendar, Select, Radio, Tooltip } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import { memo, useState } from "react";
import { getRandomColor } from "../../../../../../utils";

const NextBirthdayCalendar = ({ nextRenderdBirthdays }) => {
  function dateCellRender(momentDate) {
    const ts = dayjsTZ(momentDate).format("DD/MM");
    // get last 42 birthdays in the employee table
    // setRenderedDays([ts]);
    const birthdays = nextRenderdBirthdays?.filter(
      ({ employeeDayOfBirth }) =>
        dayjsTZ(employeeDayOfBirth)?.format("DD/MM") === ts
    );

    return (
      <div className="custom-date-wrapper">
        {!!birthdays?.length ? (
          <Tooltip
            style={{ zIndex: "5" }}
            className="calendar-tooltip"
            classNames={{ root: "calendar-tooltip" }}
            placement="top"
            title={
              <div className="toptil-calendar-content">
                {birthdays?.map(
                  ({
                    employeeFirstName,
                    employeeLastName,
                    employeeId,
                    employeeDayOfBirth,
                  }) => (
                    <div key={employeeId}>
                      {employeeFirstName} {employeeLastName}
                    </div>
                  )
                )}
              </div>
            }
          >
            <div
              className="event-calendar"
              style={{ backgroundColor: getRandomColor() }}
            >
              {momentDate.format("DD")}
            </div>
          </Tooltip>
        ) : (
          momentDate.format("DD")
        )}
      </div>
    );
  }

  return (
    <div className="site-calendar-customize-header-wrapper">
      <Calendar
        dateFullCellRender={dateCellRender}
        fullscreen={false}
        defaultValue={dayjsTZ().add(1, "M")}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          const current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.months(current));
          }

          for (let index = start; index < end; index++) {
            monthOptions.push(
              <Select.Option className="month-item" key={`${index}`}>
                {months[index]}
              </Select.Option>
            );
          }
          const month = value.month();

          const year = value.year();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            );
          }
          return (
            <div style={{ padding: 13 }}>
              <Row gutter={8}>
                <div className="my-year-select">
                  <span>{dayjsTZ(value).format("YYYY")}</span>
                  <span>{dayjsTZ(value).format("MMMM")}</span>
                </div>

                <Col style={{ marginLeft: 130 }}>
                  <Radio.Group
                    size="small"
                    // onChange={(e) => onTypeChange(e.target.value)}
                    value={type}
                  >
                    {/* <Radio.Button value="month">Muaj</Radio.Button>
                    <Radio.Button value="year">Viti</Radio.Button> */}
                  </Radio.Group>
                </Col>
              </Row>
            </div>
          );
        }}
      />
    </div>
  );
};

export default memo(NextBirthdayCalendar);
