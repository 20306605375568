import React from "react";
import { ChristmasLights, SpiderWeb } from "../../../assets/holidays/icons";
import { BreakTimeIcon } from "../../../assets/icons";
import { useAuth } from "../../../authentication/authHelpers";
import "./MoreInformationCard.scss";
import moment from "moment";
import { useSelector } from "react-redux";

const MoreInformationCard = () => {
  const { employee } = useAuth();
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <>
      <div className={`moreInformationCard ${isDarkMode ? "dark" : ""}`}>
        <div className="wrapperIcon">
          <BreakTimeIcon fill={isDarkMode ? "#d7d7d7" : "#121212"} />
          {/* <div style={{ width: "100px", marginTop: "-20px", position: "relative" }}>
						<ChristmasLights />
					</div>*/}
        </div>
        <div className="breakContent">
          <span className="breakText">Koha e pushimit</span>
          {employee?.breakTime?.length ? (
            <p className="oret">{`${employee?.breakTime[0]}-${employee?.breakTime[1]}`}</p>
          ) : (
            <p className="text">Nuk ka të dhëna</p>
          )}
        </div>
      </div>
    </>
  );
};

export default MoreInformationCard;
