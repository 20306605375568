import { useState, useEffect, useMemo } from "react";
// import HorizontalCard from "../dashboard/components/horizontalCard/HorizontalCard";
import TimeSheet from "./timesheet/TimeSheet";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { API } from "aws-amplify";
import { Alert, Button, message } from "antd";
import "./listPrezenca.scss";
import FullCard from "../commonComponents/FullCard/FullCard";
import { useAuth } from "../../authentication/authHelpers";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import MoreView from "./MoreView/MoreView";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
} from "../../helpers/API/RequestFactory";

function ListPrezenca() {
  const auth = useAuth();
  const { employees } = useSelector((state) => state.employeesList);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [displayClockings, setDisplayClockings] = useState(false);

  const getClocking = async () => {
    const isLastWorkingDay = shouldDisplayClockings();

    let idObj =
      auth?.userRole === "Employee"
        ? {
            employeeId: auth?.employeeId,
            ...(isLastWorkingDay ? {} : { modeType: "quiet" }),
          }
        : false;

    let threeMonthsAgo = !idObj
      ? dayjsTZ().subtract(1, "months").startOf("month").valueOf()
      : dayjsTZ().subtract(8, "months").startOf("month").valueOf();

    await requestDataWithParams("clocking", threeMonthsAgo, null, idObj)
      .then((res) =>
        setData(
          res
            .map((el) => {
              const employee = getEmployee(el?.employeeId);
              return {
                ...el,
                employeeDepartmentId: employee?.employeeDepartmentId,
                employeeInfo: employee,
                employeeDepartmentName: employee?.employeeDepartmentName,
                // clockOutDate: !!el?.clockOutDate ? el.clockOutDate : dayjsTZ(),
                // onGoing: !!el?.clockOutDate ? false : true,
              };
            })
            .filter((r) => r.clockOutDate !== null)
        )
      )
      .catch((err) => {
        console.log(err);
        message.error("Ndodhi një problem!");
      })
      .finally(() => {
        setLoading(false);
        setDisplayClockings(isLastWorkingDay);
      });
  };

  const shouldDisplayClockings = () => {
    if (auth?.userRole !== "Employee") return true;

    const businessDays = dayjsTZ().businessDaysInMonth();
    const lastWorkingDay = businessDays[businessDays.length - 1];

    if (dayjsTZ().isSame(lastWorkingDay, "day")) {
      return true;
    }
    return false;
  };

  const getEmployee = (id) => {
    if (!id) return {};

    const idx = (employees || []).findIndex((el) => el?.employeeId === id);
    if (idx === -1) return {};

    return employees[idx];
  };

  useEffect(() => {
    if (!!employees && !!auth?.userRole) {
      getClocking();
    }
  }, [employees, auth]);

  const description = {
    true: "Nuk ka të dhëna",
    false:
      "Turnet tuaja janë regjistruar me sukses dhe do të shfaqen automatikisht ditën e fundit të muajit. Faleminderit për durimin.",
  };

  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        {/* <MoreView /> */}
        {!!employees && data.length > 0 ? (
          <FullCard contentChild={<TimeSheet key={1} data={data} />} />
        ) : (
          <div className="listePrezencaAlertWrapper">
            <Alert description={description[displayClockings]} />
          </div>
        )}
      </div>
    </LoadableComp>
  );
}

export default ListPrezenca;
