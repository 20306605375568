import { DeleteIcon, IconNdrysho } from "../../../../assets/icons";
import { htmlParser } from "../../../../utils";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";

export const colDefs = ({ handleUpdate, handleRequestDelete }) => [
  {
    headerName: "Pajisjet e orarit",
    field: "name",
    filter: "agTextColumnFilter",
    flex: 3,
    mobile: true,
    isCollapseTitle: true,
    isExported: true,
  },
  {
    headerName: "Pershkrimi per pajisjen",
    field: "description",
    filter: "agTextColumnFilter",
    flex: 5,
    cellRenderer: ({ data }) => htmlParser(data?.description),
    mobile: true,
    isExported: true,
  },
  {
    headerName: "Ip për pajisjen",
    field: "ip",
    filter: "agTextColumnFilter",
    flex: 5,
    mobile: true,
    isExported: true,
  },
  {
    headerName: "Sistemi",
    field: "sistemi",
    filter: "agTextColumnFilter",
    flex: 5,
    mobile: true,
    isExported: true,
  },
  {
    headerName: "Shfletuesi",
    field: "shfletuesi",
    filter: "agTextColumnFilter",
    flex: 5,
    mobile: true,
    isExported: true,
  },
  {
    headerName: "",
    field: "",
    filter: "agTextColumnFilter",
    flex: 3,
    // displays the icons in ag grid and with params.data we take the object of the clicked row
    cellRenderer: ({ data }) => (
      <div className="iconat-paisjet">
        <div variant="outlined" cursor="pointer" className="icon-edit">
          <IconNdrysho
            onClick={() => handleUpdate(data)}
            width={14}
            height={14}
          />
        </div>
        <GlobalPopupKonfirm
          Button={
            <div variant="outlined" cursor="pointer" className="icon-delete">
              <DeleteIcon
                width={17}
                height={17}
                // onClick={() => handleRequestDelete(node.data.id)}
              />
            </div>
          }
          title={"Jeni të sigurt që do të fshini këtë rekord?"}
          onConfirm={() => handleRequestDelete(data?.id)}
          okText={"Po"}
          cancelText={"Jo"}
        />
      </div>
    ),
    mobile: true,
  },
];
