import {
  Alert,
  Button,
  DatePicker,
  message,
  Modal,
  Select,
  TimePicker,
} from "antd";
import React, { useLayoutEffect, useState, useEffect, useMemo } from "react";
import { useAuth } from "../../../../../authentication/authHelpers";
import { WarningIcon } from "../../../../../assets/icons";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";
import { RichTextEditor } from "../../../../commonComponents";
import {
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
} from "../../../utils/disabledTimeFilters";
import { useDispatch, useSelector } from "react-redux";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import Forgot from "../forgot/Forgot";
import {
  endDoubleShift,
  endShift,
  getLastOnGoingShift,
} from "../../../helpers/apiCall";
import swal from "sweetalert";
import axios from "axios";
import { getSocketUrl } from "../../../../../utils/websocketConfig";
import { notificationUserToAdminNoDbPost } from "../../../../../utils/sendNotification";
import { debounce } from "lodash";
import { editClocking } from "../../../../../store/actions/addClockings";
import { getAppliedShifts } from "./utils";
import HappyHolidaysModal from "../../../../../assets/holidays/Modals/HappyHolidaysModal";
import { ModalXIcon } from "../../../../../assets/icons";
import useReactIpLocation from "react-ip-details";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { eventTracker } from "../../../../../utils/eventTracker";
const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

const { Option } = Select;

function ClockOutModal({
  clockOutVisible,
  setClockOutVisible,
  onSave,
  lastShift,
  apiData,
  setLoading,
  setLoadingColor,
  loadShiftData,
  setBarStart,
  resetValues,
  // onClockForgot,
  setNewShift,
  setLastShift,
  shiftConfiguration,
}) {
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const auth = useAuth();
  const [newShift, setNewSh] = useState({ note: "" });
  const { programFields } = useSelector((state) => state.programFields);
  const [graceWarnings, setGraceWarning] = useState({
    enable: false,
    text: "",
  });
  const [showForgot, setShowForgot] = useState(false);
  const [gracePeriod, setGracePeriod] = useState(
    0
    // programFields?.find((el) => el.fieldName === "Politika e oreve jashte orarit")?.fieldOptions?.find((el) => el.name === "Grace Period")?.formula || 0
  );
  const [editedTime, setEditedTime] = useState(null);
  const [saving, setSaving] = useState(false);
  const [holidayModal, setHolidaysModal] = useState(false);
  const dispatch = useDispatch();
  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });

  //region ip
  const ip = useMemo(async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (!!res?.data?.ip) return res?.data?.ip;
    else return "";
  }, []);

  //region ONCHANGE
  const onChange = (e) => {
    const time = dayjsTZ(e)
      .set("hour", e.get("hour"))
      .set("minute", e.get("minute"));
    // if (dayjsTZ(e).isBefore(dayjsTZ(lastShift.clockInDate))) {
    //   message.error({
    //     key: "clockout",
    //     content:
    //       "Nuk mund të zgjedhësh një kohë para orës së fillimit të turnit!",
    //   });
    //   setNewSh((prev) => ({
    //     ...prev,
    //     clockOutDate: getAutoSuggest(dayjsTZ()),
    //   }));
    // } else
    if (dayjsTZ().isBefore(time)) {
      message.error({
        key: "clockout",
        content: "Nuk mund të zgjedhësh një kohë në të ardhmen!",
      });
      setNewSh((prev) => ({
        ...prev,
        clockOutDate: getAutoSuggest(dayjsTZ()),
      }));
      setGraceWarning({
        enable: false,
        text: "",
      });
    } else {
      if (gracePeriod > 0) {
        if (checkGracePeriod(time)) {
          setNewSh((prev) => ({
            ...prev,
            clockOutDate: getAutoSuggest(time),
          }));
        } else {
          message.error({
            key: "clockout",
            content:
              "Aktualisht, nuk lejohet ndryshimi i orës së daljes. Kontaktoni me supervizorin përkatës!",
          });
        }
      } else {
        message.info({
          key: "clockout",
          content: "Juve nuk ju lejohet të ndryshoni kohën e mbarimit!",
        });
        setNewSh((prev) => ({
          ...prev,
          clockOutDate: getAutoSuggest(dayjsTZ()),
        }));
      }
    }
  };

  //region AUTO SUGGEST
  const getAutoSuggest = (time) => {
    // let time = moment(time);
    let l_dayjs = dayjsTZ();
    l_dayjs = l_dayjs.set("hour", "21").set("minute", "30").set("second", "00");

    let h_dayjs = dayjsTZ();
    h_dayjs = h_dayjs.set("hour", "21").set("minute", "45").set("second", "30");

    let autoSuggestion = dayjsTZ();
    autoSuggestion.set("hour", "21").set("minute", "30").set("second", "00");

    let lower_bound = dayjsTZ(l_dayjs).valueOf();
    let upper_bound = dayjsTZ(h_dayjs).valueOf();

    // Morning Shift
    let l_dayjs2 = dayjsTZ();
    l_dayjs2 = l_dayjs2
      .set("hour", "18")
      .set("minute", "30")
      .set("second", "00");

    let h_dayjs2 = dayjsTZ();
    h_dayjs2 = h_dayjs2
      .set("hour", "18")
      .set("minute", "32")
      .set("second", "30");

    let lower_bound2 = dayjsTZ(l_dayjs2).valueOf();
    let upper_bound2 = dayjsTZ(h_dayjs2).valueOf();

    if (
      dayjsTZ(time).valueOf() > lower_bound2 &&
      dayjsTZ(time).valueOf() < upper_bound2
    ) {
      // setShift((prev) => ({ ...prev, clockOutDate: autoSuggestion }));
      autoSuggestion = autoSuggestion
        .set("hour", "18")
        .set("minute", "30")
        .set("second", "00");
      return autoSuggestion;
    } else {
      if (
        dayjsTZ(time).valueOf() > lower_bound &&
        dayjsTZ(time).valueOf() < upper_bound
      ) {
        autoSuggestion = autoSuggestion
          .set("hour", "21")
          .set("minute", "30")
          .set("second", "00");
        return autoSuggestion;
      } else {
        return dayjsTZ(time);
      }
      // return autoSuggestion;
    }
  };

  //region GRACE PERIOD
  const checkGracePeriod = (date) => {
    const cond = dayjsTZ().diff(date, "minutes", true);
    console.log("cond", cond);
    let h_dayjs = dayjsTZ();
    h_dayjs = h_dayjs.set("hour", "21").set("minute", "30").set("second", "30");

    if (dayjsTZ().diff(date, "minutes", true) <= gracePeriod) {
      if (dayjsTZ().diff(date, "minutes", true) < 1) {
        setGraceWarning({
          text: "",
          enable: false,
        });
        return true;
      } else {
        setGraceWarning({
          text: "Nevojitet një shënim për këtë ndryshim!",
          enable: true,
        });
        return true;
      }
    } else {
      setGraceWarning({
        text: "",
        enable: false,
      });
      return false;
    }
  };

  //region CLOCKOUT SEND
  const onClockOutSend = async () => {
    const isGraced = checkGracePeriod(newShift?.clockOutDate);
    const toSend = newShift;
    if (
      dayjsTZ(newShift?.clockOutDate).diff(
        dayjsTZ(lastShift?.clockInDate),
        "minutes",
        true
      ) > 2
    ) {
      eventTracker("Clock Out", "click", "Ended shift");
      message.loading({
        key: "clockout",
        content: "Duke ruajtur të dhënat...",
      });
      if (isGraced) {
        toSend.clockOutDate = getAutoSuggest(newShift?.clockOutDate);
        setNewSh((prev) => ({
          ...prev,
          clockOutDate: toSend.clockOutDate,
          clockingLogs: [
            ...(prev?.clockingLogs || []),
            {
              createdAt: dayjsTZ().valueOf(),
              note: "Krijuar nga vetë punonjësi.",
              newShiftByAdmin: false,
              newShiftByUser: true,
              username: auth?.user?.given_name + " " + auth?.user?.family_name,
              userSub: auth?.user?.userSub,
              ipResponse: {
                ...ipResponse,
                IPv4: ip,
              },
            },
          ],
        }));
        console.log("setNewSh1", newShift);
        const temp = newShift?.note.replace(/<[^>]+>/g, "");
        const noteLen = temp.replace(/\s/g, "").length;
        const noteReq = graceWarnings.enable && noteLen < 5 ? false : true;
        if (noteReq) {
          setLoadingColor("#ea3943");
          setLoading(true);
          await onClockOut()
            .then(() => setLoading(false))
            .catch((err) => {
              console.error(err);
              setLoading(false);
            });
        } else {
          message.error({
            key: "clockout",
            content: "Shënimi duhet të ketë të paktën 5 karaktere!",
          });
        }
      } else {
        toSend.clockOutDate = getAutoSuggest(dayjsTZ());
        setNewSh((prev) => ({
          ...prev,
          clockOutDate: toSend.clockOutDate,
          clockingLogs: [
            ...(prev?.clockingLogs || []),
            {
              createdAt: dayjsTZ().valueOf(),
              note: "Krijuar nga vetë punonjësi.",
              newShiftByAdmin: false,
              newShiftByUser: true,
              username: auth?.user?.given_name + " " + auth?.user?.family_name,
              userSub: auth?.user?.userSub,
              ipResponse: {
                ...ipResponse,
                IPv4: ip,
              },
            },
            ,
          ],
        }));
        console.log("setNewSh2", newShift);
        setLoadingColor("#ea3943");
        setLoading(true);
        await onClockOut()
          .then(() => setLoading(false))
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    } else {
      message.info({
        key: "clockout",
        content: "Ju nuk mund të mbyllni një turn më të vogël se 2 minuta!",
      });
    }
    console.log("final shift on clockout", newShift);
  };

  //region ON CLOCKOUT
  const onClockOut = async () => {
    if (
      dayjsTZ(lastShift?.clockInDate).format("DD/MM/YYYY") ===
      dayjsTZ().format("DD/MM/YYYY")
    ) {
      let clockOut;
      if (editedTime !== null) {
        if (newShift?.note !== "") {
          clockOut = {
            category: "manual",
            employeeId: lastShift.employeeId,
            clockOutNote: newShift?.note || null,
            editedClockOut: true,
            finishedAt: dayjsTZ().valueOf(),
            approved: false,
          };
          await handleClockOut(clockOut, newShift?.clockOutDate, "single");
        } else {
          message.error({
            key: "clockout",
            content: "Ju duhet të vendosni një shënim.",
          });
        }
      } else if (editedTime === null) {
        clockOut = {
          category: "automatic",
          editedClockOut: false,
          employeeId: lastShift.employeeId,
          clockOutNote: newShift?.note || null,
          // finishedAt:  dayjsTZ().valueOf(),
          finishedAt: dayjsTZ().valueOf(),
          approved: false,
        };
        await handleClockOut(clockOut, newShift?.clockOutDate, "single");
      }
      // console.log("Clock out!", clockOut);
    } else if (
      dayjsTZ(lastShift?.clockOutDate).format("DD/MM/YYYY") !==
      dayjsTZ().format("DD/MM/YYYY")
    ) {
      let today = dayjsTZ();
      today = today.set("hour", "08").set("minute", "00").set("second", "00");

      if (dayjsTZ().valueOf() < dayjsTZ(today).valueOf()) {
        let clockOut;
        if (editedTime !== null) {
          if (newShift?.note !== "") {
            clockOut = {
              category: "manual",
              employeeId: lastShift.employeeId,
              clockOutNote: newShift?.note,
              editedClockOut: true,
              finishedAt: dayjsTZ().valueOf(),
              approved: false,
            };
            await handleClockOut(clockOut, newShift?.clockOutDate, "double");
          } else {
            message.error({
              key: "clockout",
              content: "Ju duhet të vendosni një shënim.",
            });
          }
        } else if (editedTime === null) {
          clockOut = {
            category: "automatic",
            editedClockOut: false,
            employeeId: lastShift.employeeId,
            clockOutNote: newShift?.note || null,
            finishedAt: dayjsTZ().valueOf(),
            approved: false,
          };
          await handleClockOut(clockOut, newShift?.clockOutDate, "double");
        }
      } else {
        setShowForgot(true);
        setClockOutVisible(false);
      }
    }
  };

  //region >handleClockOut()
  const handleClockOut = async (shift, val, type) => {
    // let lastShift = await getLastShift();

    if (!!shift?.employeeId) {
      let lastShift = getLastOnGoingShift(apiData);
      lastShift?.clockingLogs?.push({
        createdAt: dayjsTZ().valueOf(),
        note: "Krijuar nga vetë punonjësi.",
        newShiftByAdmin: false,
        newShiftByUser: true,
        username: auth?.user?.given_name + " " + auth?.user?.family_name,
        userSub: auth?.user?.userSub,
        ipResponse: {
          ...ipResponse,
          IPv4: ip,
        },
      });
      if (!!lastShift.clockingId) {
        if (type === "single") {
          setSaving(true);
          await endShift(lastShift, shift, val);
          await loadShiftData(lastShift.employeeId);
          setNewSh(false);
          setNewShift(false);
          setSaving(false);
          setLastShift({ ...lastShift, clockOutDate: dayjsTZ(val).valueOf() });

          swal({
            title: `Turni u përfundua me sukses në orën ${dayjsTZ(val).format(
              "HH:mm"
            )}!`,
            icon: "success",
            className: isDarkMode ? "custum-swal-dark" : "custum-swal",
            // confirmButtonText
          }).then(() => {
            setBarStart(true);
          });
          /**
           * * @EneaXharau - Added socket emitter
           * * .send() takes -> { request: String, body: any} -> stringified
           */
          dispatch(
            editClocking({
              ...lastShift,
              clockOutDate: dayjsTZ(val).valueOf(),
            })
          );
          resetValues();
          // setHolidaysModal(true);
          setLoading(false);
          setClockOutVisible(false);
          if (socketHandler.readyState === socketHandler.OPEN) {
            socketHandler.send(
              JSON.stringify({
                request: "user_clockOut",
                body: { ...lastShift, clockOutDate: dayjsTZ(val).valueOf() },
                id: lastShift.employeeId,
              })
            );
          }
        } else {
          setSaving(true);
          await endDoubleShift(lastShift, shift, val);
          await loadShiftData(shift.employeeId);
          setLastShift({ ...lastShift, clockOutDate: dayjsTZ(val).valueOf() });
          setNewSh(false);
          setNewShift(false);
          setSaving(false);
          setLoading(false);
          setClockOutVisible(false);

          swal({
            title: `Turni u përfundua me sukses në orën ${dayjsTZ(val).format(
              "HH:mm"
            )}!`,
            icon: "success",
            className: isDarkMode ? "custum-swal-dark" : "custum-swal",
          }).then(() => {
            setBarStart(true);
          });

          // setHolidaysModal(true);
          resetValues();
        }
        notificationUserToAdminNoDbPost(
          {
            notificationTitle: "Mbaroi Turni",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationCategory: "Info",
            notificationPriority: false,
            notificationBody: `${auth?.userAccess[0]?.given_name} ${
              auth?.userAccess[0]?.family_name
            } mbaroi turnin në ${dayjsTZ(val).format("HH:mm")} `,
            isAnnouncement: false,
            notificationUrl: "/aprovimet",
            notificationFirstName: auth?.userAccess[0]?.given_name,
            notificationLastName: auth?.userAccess[0]?.family_name,
          },
          users?.allUsers.Items
        );
      }
    }
  };

  //region CLOCK FORGOR
  const onClockForgot = async (obj) => {
    const { isAfterMidnight } = obj;
    let clockForgot = {
      employeeId: lastShift.employeeId,
      editedClockOut: !isAfterMidnight,
      forgotClockOut: !isAfterMidnight,
      clockOutNote: obj.shenimi,
      finishedAt: dayjsTZ().valueOf(),
      approved: false,
    };
    // let diff = moment.duration(obj.mbarimi.diff(obj.fillimi)).asDays();
    let date = dayjsTZ(obj.fillimi);
    const time = dayjsTZ(obj.mbarimi);

    date = date
      .set("hour", time.get("hour"))
      .set("minute", time.get("minute"))
      .set("second", time.get("second"));
    // console.log("Diferenca", diff);
    // let editedClockOut = moment(obj.mbarimi).subtract(diff, "days").valueOf();
    if (isAfterMidnight) {
      eventTracker("Clock Out", "click", "Ended midnight shift");

      await handleClockOut(clockForgot, time, "double");
    } else {
      eventTracker("Clock Out", "click", "Ended forgotten shift");
      await handleClockOut(clockForgot, date, "single");
    }
  };

  useLayoutEffect(() => {
    if (
      dayjsTZ(lastShift?.clockInDate).format("DD/MM/YYYY") !==
      dayjsTZ().format("DD/MM/YYYY")
    ) {
      setShowForgot(true);
      setClockOutVisible(false);
    }
  }, [lastShift, clockOutVisible]);

  //region RETURN
  return (
    <>
      {!!clockOutVisible ? (
        <Modal
          // destroyOnClose={true}
          width={918}
          className={`new-shift ${isDarkMode ? "darkHeader" : "lightHeader"}`}
          title={"Konfirmo Mbarimin e Turnit"}
          open={clockOutVisible}
          onOk={() => setClockOutVisible(false)}
          onCancel={() => setClockOutVisible(false)}
          centered={true}
          closeIcon={<ModalXIcon />}
          footer={[
            <MondayButton
              className="mondayButtonRed"
              onClick={() => setClockOutVisible(false)}
            >
              Mbyll Faqen
            </MondayButton>,
            <MondayButton
              className="mondayButtonGreen"
              onClick={onClockOutSend}
            >
              Përfundo Turnin
            </MondayButton>,
          ]}
        >
          {!!lastShift?.clockInDate && (
            <div className={`new-shift-wrapper ${isDarkMode ? "dark" : ""}`}>
              <div className="warning-title">
                <span className="warn-icon">
                  <WarningIcon />
                </span>

                <span>
                  Përshëndetje,{" "}
                  <strong>
                    {auth?.userAccess[0]?.given_name +
                      " " +
                      auth?.userAccess[0]?.family_name}
                  </strong>
                  . Ju jeni duke përfunduar punën, siguroni të dhënat përpara se
                  të largoheni!{" "}
                  <p style={{ margin: 0 }}>
                    Turni i përcaktuar:{" "}
                    {`${
                      shiftConfiguration?.appliedShifts?.length > 0
                        ? getAppliedShifts(shiftConfiguration?.appliedShifts)
                        : getAppliedShifts(shiftConfiguration?.applicableShifts)
                    }
                )`}
                  </p>
                </span>
              </div>{" "}
              {gracePeriod > 0 && (
                <div>
                  <br />
                  <Alert
                    message={`Ju mund të ndryshoni orarin e mbarimit duke lënë një shënim të vlefshëm! `}
                    type="info"
                    showIcon
                  />
                </div>
              )}
              <div className="to-edit-fields">
                {" "}
                <div
                  className="shift-field-start"
                  style={{ marginLeft: "30px" }}
                >
                  <label style={{ fontWeight: "600" }}>
                    <strong style={{ color: "red" }}>*</strong>Fillimi i Turnit:
                  </label>
                  <TimePicker
                    defaultValue={
                      !!lastShift?.clockInDate
                        ? dayjsTZ(lastShift?.clockInDate)
                        : null
                    }
                    popupClassName={isDarkMode ? "darkDropdown" : ""}
                    placeholder="Fillimi"
                    // inputReadOnly
                    format="HH:mm"
                    // onChange={onEditClockIn}
                    disabled={true}
                    // disabledHours={() => getDisabledAllHours()}
                    // disabledMinutes={(val) => getDisabledButGracePeriodMins(newShift.clockOutDate, val)}
                    // disabledHours={() => getDisabledAfterCustomHours()}
                    // disabledMinutes={(val) => getDisabledAfterMinutes(val)}
                    // showNow={false}
                    // allowClear={false}
                  />
                </div>
                <div style={{ marginLeft: "20px" }} className="shift-field-end">
                  <label style={{ fontWeight: "600" }}>
                    Mbarimi i Turnit:{" "}
                  </label>
                  <TimePicker
                    value={
                      !!newShift?.clockOutDate
                        ? getAutoSuggest(dayjsTZ(newShift?.clockOutDate))
                        : getAutoSuggest(dayjsTZ())
                    }
                    placeholder="Mbarimi"
                    // disabled={true}
                    popupClassName={isDarkMode ? "darkDropdown" : ""}
                    format="HH:mm"
                    disabledTime={() => ({
                      disabledHours:
                        lastShift.clockInDate !== null
                          ? () =>
                              getDisabledBeforeCustomHours(
                                lastShift.clockInDate
                              )
                          : () => [],
                      disabledMinutes:
                        lastShift.clockInDate !== null
                          ? (hour) =>
                              getDisabledBeforeCustomMinutes(
                                lastShift.clockInDate,
                                hour
                              )
                          : () => [],
                    })}
                    showNow={false}
                    allowClear={false}
                    onChange={onChange}
                  />
                </div>
                <div style={{ marginLeft: "20px" }} className="shift-field-end">
                  {/* <Alert message="Warning" type="warnings" showIcon /> */}
                  <label style={{ fontWeight: "600", marginLeft: "0px" }}>
                    <strong style={{ color: "red" }}>*</strong>Data e Turnit:
                  </label>

                  <span
                    style={{
                      width: "100%",
                      color: "white",
                      height: "32px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      marginLeft: "0px",
                      cursor: "pointer",
                    }}
                  >
                    <DatePicker
                      allowClear={false}
                      format={"DD/MM/YYYY"}
                      popupClassName={isDarkMode ? "darkDropdown" : ""}
                      disabled={true}
                      defaultValue={
                        !!lastShift?.clockInDate
                          ? dayjsTZ(lastShift?.clockInDate)
                          : null
                      }
                      //   value={dateMoment}
                      // onChange={onCalendarPick}
                      placeholder="Zgjidh Datën"
                      // disabledDate={(current) => {
                      // 	return moment() < current;
                      // }}
                    />
                  </span>
                </div>
                <div className="shift-field-end" style={{ marginLeft: "20px" }}>
                  <label style={{ fontWeight: "600" }}>
                    <strong style={{ color: "red" }}>*</strong>Lloji i turnit:{" "}
                  </label>

                  <Select
                    className="daytype-select"
                    disabled
                    popupClassName={isDarkMode ? "darkDropdown" : ""}
                    showSearch
                    value={lastShift?.dayType}
                    placeholder="Zgjidh nje opsion"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {programFields
                      ?.find((el) => el.fieldName === "ClockIn Types")
                      ?.fieldOptions?.map((el, idx) => (
                        <Option key={`shiftType-${idx}`} value={el?.name}>
                          {el?.description}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="to-note">
                {graceWarnings.enable && (
                  <>
                    <Alert
                      message={graceWarnings?.text}
                      type="warning"
                      showIcon
                    />
                    <br />
                  </>
                )}
                <label style={{ fontWeight: "600" }}>Shënimi:</label>

                <RichTextEditor
                  className={"to-note-textarea"}
                  value={newShift.note}
                  onChange={(e) => setNewSh((prev) => ({ ...prev, note: e }))}
                />
              </div>
              <div className="hours-generation">
                <span style={{ fontWeight: "600" }}>
                  Orët totale:{" "}
                  {dayjsTZ(newShift.clockOutDate)
                    .diff(dayjsTZ(lastShift?.clockInDate), "hours", true)
                    .toFixed(2) > 0
                    ? dayjsTZ(getAutoSuggest(dayjsTZ(newShift?.clockOutDate)))
                        .diff(dayjsTZ(lastShift.clockInDate), "hours", true)
                        .toFixed(2)
                    : 0}{" "}
                  orë
                </span>
                <div className="animated-hours">
                  <div
                    className="calculated-hours"
                    style={{
                      width: `${
                        dayjsTZ(newShift.clockOutDate)
                          .diff(dayjsTZ(lastShift.clockInDate), "hours", true)
                          .toFixed(2) > 0 &&
                        dayjsTZ(newShift.clockOutDate)
                          .diff(dayjsTZ(lastShift.clockInDate), "hours", true)
                          .toFixed(2) <= 8
                          ? (dayjsTZ(newShift.clockOutDate)
                              .diff(
                                dayjsTZ(lastShift.clockInDate),
                                "hours",
                                true
                              )
                              .toFixed(2) /
                              8) *
                            100
                          : dayjsTZ(newShift.clockOutDate)
                              .diff(
                                dayjsTZ(lastShift.clockInDate),
                                "hours",
                                true
                              )
                              .toFixed(2) > 8
                          ? 100
                          : 0
                      }%`,
                    }}
                  ></div>
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  {saving && (
                    <BreedingRhombusSpinner size={40} color="#1da193" />
                  )}
                </div>
              </div>{" "}
            </div>
          )}
        </Modal>
      ) : null}
      {showForgot ? (
        <Forgot
          handleForgot={(val) => setShowForgot(val)}
          {...{ shift: lastShift, apiData, onClockForgot, showForgot }}
        />
      ) : null}
    </>
  );
}

export default ClockOutModal;
