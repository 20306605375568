import { Button } from "antd";
import React from "react";
import { htmlParser } from "../../../../utils";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

//nuk perdoret
function MobilePolitikatJashtOrarit({ tableData, setVisible }) {
  return (
    <div className="base-mobile-with-header">
      <div className="header-div">
        {" "}
        <Button onClick={() => setVisible(true)} className="btn-header">
          Shto një politike të re
        </Button>{" "}
      </div>
      <MobileHtmlTabel
        tableData={tableData?.map(({ name, description, formula }) => ({
          name,
          description: htmlParser(description),
          formula,
        }))}
        header={[
          "Emri politikës",
          "Përshkrimi i politikës",
          "Formula e politikës",
        ]}
        isLink={false}
      />
    </div>
  );
}

export default MobilePolitikatJashtOrarit;
