import React from "react";
import "./User.scss";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { updateArrayElement } from "../../../../../helpers/utils/updateArrayElement";
import { message } from "antd";
import { API } from "aws-amplify";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

function SessionTable({ sessions, id, onLogOff }) {
  return (
    <div className="session-table">
      <div className="session-table-header">
        <span className="session__os">Sistemi</span>
        <span className="session__browser">Shfletuesi</span>
        <span className="session__location">Vendndodhja</span>
        <span className="session__time">Koha e hyrjes</span>
        <span className="session__action">Veprim</span>
      </div>{" "}
      <div className="session-table-cont">
        <hr color="lightGray" />
        {!!sessions &&
          sessions.map((el, idx) => (
            <div key={`tblrow-${idx}`}>
              <div className="session-table-row">
                <span className="session__os">{el?.osVersion}</span>
                <span className="session__browser">{el?.browserName}</span>
                <span className="session__location">{el?.ipv4}</span>
                <span className="session__time">
                  {dayjsTZ(el?.loggedIn).fromNow()}
                </span>
                <span className="session__action">
                  {" "}
                  {el?.logout === true ? (
                    <MondayButton className="mondayButtonGreen" disabled>
                      I Hequr
                    </MondayButton>
                  ) : (
                    <MondayButton
                      className="mondayButtonRed"
                      onClick={() => onLogOff(el.sessionId)}
                    >
                      Hiq
                    </MondayButton>
                  )}
                </span>
              </div>{" "}
              <hr color="#f5f5f7 " />
            </div>
          ))}{" "}
      </div>
    </div>
  );
}

export default SessionTable;
