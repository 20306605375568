import { Form, Modal, message } from "antd";
import ConfirmationModal from "../../../../ConfirmationModal/ConfirmationModal";
import Stepper from "../../../../Stepper/Stepper";
import { useContext, useEffect, useState } from "react";
import RenderDynamicComponents from "../../../../RenderDynamicComponents/RenderDynamicComponents";
import {
  DocumentationLogsModal,
  ActionButtons,
  DocUploader,
} from "./components";
import API from "@aws-amplify/api";
import uniq from "lodash/uniq";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../utils/Messages";
import { getObjectChanges } from "../../../utils/getObjectChanges";
import { apiRoutes } from "../../../../../apiRoutes";
import { validateForm } from "../../../utils/validateForm";
import { driveApi } from "../../../../DriveRequest";
import { expirationDateObject } from "../../../../DocumentationConfiguration/View/utils";
import { docObjectLabels } from "../../documentationViewData";
import { useUploadedFiles } from "../../../hooks/useUploadedFiles";
import "./DocumentationModal.scss";
import { useAuth } from "../../../../../authentication/authHelpers";
import { notificationUserToAdmin } from "../../../../../utils/sendNotification";
import { useSelector } from "react-redux";
import { getSocketUrl } from "../../../../../utils/websocketConfig";
import { ModalXIcon } from "../../../../../assets/icons";
import { useNavigate } from "react-router";
import ActivityModal from "../../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";
import { createUserDriveFolders } from "./createUserDriveFolders";

// * @EneaXharau - Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/documentation/sockets"));

const CONFIRMATION_MODAL_TEXT =
  "Ky veprim do të fshijë të gjithë skedarët e ngarkuar në Google Drive. Jeni i/e sigurt që dëshironi të vazhdoni?";

const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
};

const DocumentationModal = ({
  doc,
  docType, //from DocumentationSelectorModal
  configExpirationDate: cardHeaderExpirationDate,
  visible,
  setVisible,
  docStatuses = [],
}) => {
  const {
    docId,
    docStatus,
    docObject = [],
    expirationDate,
    googleDriveUploads = [],
  } = doc || {};
  const {
    employee,
    setEmployee,
    categoryName,
    accessToken,
    setDocumentation,
    getEmployeeDocs,
  } = useContext(PunonjesitContext);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);

  const userData = useAuth();
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);

  const [
    uploadedFiles,
    setUploadedFiles,
    formattedDriveFiles,
    onPickerSuccess,
  ] = uploadHandlers;
  const [filesToBeDeleted, _, onDelete] = deleteHandlers;
  const [folderId, setFolderId] = useState("");
  const [confirmationModalVisible, setConfirmationModalVisible] = useState();
  const [configExpirationDate, setConfigExpirationDate] = useState(
    cardHeaderExpirationDate
  );
  const [logsModalVisible, setLogsModalVisible] = useState();

  const driveRequest = driveApi({ accessToken });
  const user = `${userData?.user?.given_name} ${userData?.user?.family_name}`;

  const fieldsJSON = configExpirationDate
    ? [expirationDateObject, ...docObject]
    : docObject;
  // console.log(fieldsJSON);
  //! maps statusName and statusColor from DocumentationConfiguration and shows it in the step
  const steps = docStatuses?.map(({ statusName, statusColor }) => ({
    title: statusName,
    statusColor,
  }));

  const formattedDocObject = () =>
    docObject?.map((obj = {}) => {
      const { formItemName, type } = obj;
      const fieldValue = form.getFieldValue(formItemName);
      return {
        ...obj,
        value:
          type !== "datepicker" && type !== "timepicker"
            ? fieldValue
            : !!fieldValue
            ? type === "datepicker"
              ? dayjsTZ(fieldValue).valueOf()
              : dayjsTZ(fieldValue).valueOf()
            : fieldValue,
      };
    });
  const formatDocObjectToCompare = (arrayToFormat = []) =>
    arrayToFormat?.reduce(
      (acc, { value, formItemName }) => ({ ...acc, [formItemName]: value }),
      {}
    );

  //region DELETE DOC
  const deleteDocumentation = async () => {
    showLoadingMsg({ content: "Duke fshirë..." });
    await API.del(
      apiRoutes.documentation,
      `/${apiRoutes.documentation}/${docId}`
    )
      .then(async () => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        //   socketHandler.send(
        //     JSON.stringify({
        //       request: "document_removed",
        //       message: "Documentation deleted",
        //     })
        //   );
        // }
        showSuccessMsg({ content: "U fshi!" });

        // we still keep drive folder for future uploads
        // two docs with the same name will have a joint folder
        // if one doc is deleted, only its items are deleted from the folder
        uploadedFiles?.forEach(
          async ({ id }) => await driveRequest.deleteDriveItem(id)
        );
        setDocumentation((prev) =>
          prev?.filter(({ docId: arrDocId }) => arrDocId !== docId)
        );
        resetForm();
        notificationUserToAdmin(
          {
            notificationTitle: "Fshirje dokumentacioni",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationPriority: false,
            notificationCategory: "Info",
            notificationBody: `${userData?.userAccess[0]?.given_name} ${
              userData?.userAccess[0]?.family_name
            }, fshiu dokumentin ${doc?.docType || docType}, për punonjësin ${
              employee?.employeeFirstName
            } ${employee?.employeeLastName}.`,
            isAnnouncement: false,
            notificationUrl: "/dokumentacione",
            notificationFirstName: userData?.userAccess[0]?.given_name,
            notificationLastName: userData?.userAccess[0]?.family_name,
          },
          users?.allUsers?.Items
        );
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };
  //* saves documentation after you have selected from TemplateSelectorModal
  //region ON SAVE
  const onSave = () => {
    // !!uploadedFiles.length
    // ?
    validateForm(form, !docId ? saveDocumentation : updateDocumentation).catch(
      (e) => console.error(e)
    );
    // : message.warning("Ju lutemi ngarkoni të paktën një skedar!");
  };

  //region >saveDoc()
  const saveDocumentation = async () => {
    let res;
    showLoadingMsg();
    await API.post("documentation", "/documentation", {
      body: {
        categoryName,
        recordId: employee?.employeeId,
        recordName: `${
          employee?.employeeFirstName + " " + employee?.employeeLastName
        }`,
        docType: doc?.docType || docType,
        docObject: formattedDocObject(),
        docStatus: docStatuses[currentStep]?.statusName,
        googleDriveUploads: formattedDriveFiles,
        expirationDate: !!form.getFieldValue("expirationDate")
          ? dayjsTZ(form.getFieldValue("expirationDate")).valueOf()
          : "",
        // requestedDate: !!form.getFieldValue("requestedDate")
        // 	? dayjsTZ(form.getFieldValue("requestedDate")).valueOf()
        // 	: "",
        docId,
        folderId,
      },
    })
      .then(async (r) => {
        res = r;
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "document_added",
        // 			message: "Documentation added",
        // 		})
        // 	);
        // }
        filesToBeDeleted?.forEach(
          async (id) => await driveRequest.deleteDriveItem(id)
        );
        showSuccessMsg();
        setDocumentation((prev) => [r, ...prev]);
        resetForm();
        notificationUserToAdmin(
          {
            notificationTitle: "Dokumentacion i ri",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationPriority: false,
            notificationCategory: "Info",
            notificationBody: `${userData?.userAccess[0]?.given_name} ${
              userData?.userAccess[0]?.family_name
            }, krijoi dokumentin ${doc?.docType || docType}, për punonjësin ${
              employee?.employeeFirstName
            } ${employee?.employeeLastName}.`,
            isAnnouncement: false,
            notificationUrl: "/dokumentacione",
            notificationFirstName: userData?.userAccess[0]?.given_name,
            notificationLastName: userData?.userAccess[0]?.family_name,
          },
          users?.allUsers?.Items
        );
        getEmployeeDocs();
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
    return res;
  };

  //region >updateDoc()
  const updateDocumentation = async () => {
    showLoadingMsg();

    //! gets the updatedValues when you make changes at DocumentationModal
    const updatedDocumentation = {
      folderId,
      docObject: formattedDocObject(),
      docStatus: docStatuses[currentStep]?.statusName,
      googleDriveUploads: formattedDriveFiles,
      expirationDate: !!form.getFieldValue("expirationDate")
        ? dayjsTZ(form.getFieldValue("expirationDate")).valueOf()
        : null,
      // requestedDate: !!form.getFieldValue("requestedDate")
      //   ? dayjsTZ(form.getFieldValue("requestedDate")).valueOf()
      //   : null,
    };

    const updatedKeys = getObjectChanges(doc, updatedDocumentation);
    let changes = [];
    let constants = {
      activity: "Ndryshuar",
      author: user,
      changeDate: dayjsTZ().valueOf(),
    };

    for (const key in updatedKeys) {
      if (key === "docObject") {
        changes.push({
          ...constants,
          field: doc[key]?.[0]?.label,
          oldValue: dayjsTZ(doc[key]?.[0]?.value),
          newValue: dayjsTZ(updatedKeys[key]?.[0]?.value),
        });
      } else if (key === "googleDriveUploads") {
        const oldDocNames = doc[key]?.map((el) => el?.name);
        const newDocNames = updatedKeys[key]?.map((el) => el?.name);
        changes.push({
          ...constants,
          field: "Skedarë",
          oldValue: oldDocNames?.join(", ") || oldDocNames,
          newValue: newDocNames?.join(", ") || newDocNames,
        });
      } else if (key !== "googleDriveFolderId") {
        changes.push({
          ...constants,
          field: key === "docStatus" ? "Statusi" : "Data e Skadencës",
          oldValue: doc[key],
          newValue: updatedKeys[key],
        });
      }
    }

    await API.put(
      apiRoutes.documentation,
      `/${apiRoutes.documentation}/${docId}`,
      {
        body: {
          ...updatedKeys,
          keylog: [...(doc?.keylog || []), ...changes],
        },
      }
    )
      .then(async () => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "document_changed",
        // 			message: "Documentation changed",
        // 		})
        // 	);
        // }

        showSuccessMsg();
        filesToBeDeleted?.forEach(
          async (id) => await driveRequest.deleteDriveItem(id)
        );
        setDocumentation((prev) =>
          prev?.map((item) =>
            item?.docId === docId
              ? {
                  ...item,
                  ...updatedDocumentation,
                  keylog: !!googleDriveUploads ? changes : [],
                }
              : item
          )
        );
        resetForm();
        if (docStatus !== docStatuses[currentStep]?.statusName) {
          notificationUserToAdmin(
            {
              notificationTitle: "Ndryshim statusi",
              createdAt: dayjsTZ().format("DD/MM/YYYY"),
              notificationPriority: false,
              notificationCategory: "Info",
              notificationBody: `${userData?.userAccess[0]?.given_name} ${
                userData?.userAccess[0]?.family_name
              }, ndryshoi statusin e dokumentit ${
                doc?.docType || docType
              }, nga ${docStatus} në ${
                docStatuses[currentStep]?.statusName
              }, për punonjësin ${employee?.employeeFirstName} ${
                employee?.employeeLastName
              }.`,
              isAnnouncement: false,
              notificationUrl: "/dokumentacione",
              notificationFirstName: userData?.userAccess[0]?.given_name,
              notificationLastName: userData?.userAccess[0]?.family_name,
            },
            users?.allUsers?.Items
          );
        }
        getEmployeeDocs();
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

  //region ON CANCEL
  const onCancel = () => {
    resetForm();
    uploadedFiles
      ?.filter(({ newDocFile }) => !!newDocFile)
      ?.map(async ({ id }) => await driveRequest.deleteDriveItem(id));
  };

  //region >resetForm()
  const resetForm = () => {
    form.resetFields();
    setCurrentStep(0);
    setConfigExpirationDate("");
    setUploadedFiles([]);
    setVisible(false);
    navigate(".", { replace: true });
    if (!doc?.folderId) {
      addFolderId();
    }
  };

  //region GET FOLDER
  const getDocumentFolder = async () => {
    if (!!accessToken && !!Object.keys(employee)?.length) {
      if (!!doc?.folderId) {
        setFolderId(doc?.folderId);
      } else if (!!employee?.googleDriveFolderIds?.docFolderId) {
        const defaultPermissions = await getDefaultPermissions(
          doc?.docType || docType
        );
        await driveRequest
          .getFolderIdOrCreate({
            name: doc?.docType || docType,
            parent: employee?.googleDriveFolderIds?.docFolderId,
            defaultPermissions,
          })
          .then(async (res) => {
            setFolderId(res);
          })
          .catch((err) => console.log("Error: ", err));
      } else {
        // manage old users
        try {
          const defaultPermissions = await getDefaultPermissions(
            doc?.docType || docType
          );
          const folderIds = await createUserDriveFolders({
            hotCredentials,
            employee,
            driveRequest,
            defaultPermissions,
          });
          await API.put("employees", `/employees/${employee?.employeeId}`, {
            body: {
              googleDriveFolderIds: { ...folderIds },
            },
          })
            .then(async (res) => {
              const { docFolderId } = res?.employee?.Item?.googleDriveFolderIds;
              await driveRequest
                .getFolderIdOrCreate({
                  name: doc?.docType || docType,
                  parent: docFolderId,
                  defaultPermissions,
                })
                .then((docTypeFolderId) => {
                  setEmployee((prev) => ({
                    ...prev,
                    googleDriveFolderIds: { ...folderIds },
                  }));
                  setFolderId(docTypeFolderId);
                })
                .catch((err) => console.log("err: ", err));
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        } catch (err) {
          console.log("err: ", err);
        }
      }
    }
    if (!accessToken) {
      message.error("Invalid Google Drive access token");
    }
  };

  //region >getDocConfig()
  const getDefaultPermissions = async (docTypeName) => {
    let permissions = [];
    await API.get("docConfiguration", "/docConfiguration").then(async (r) => {
      permissions =
        r[0]?.documentationsAvailable?.find(
          ({ docName }) => docName === docTypeName
        )?.docPermissions || [];
    });
    return permissions;
  };

  //region >addFolderId()
  const addFolderId = async () => {
    await API.put(
      apiRoutes.documentation,
      `/${apiRoutes.documentation}/${docId}`,
      {
        body: {
          folderId,
        },
      }
    )
      .then(() => {
        setDocumentation((prev) =>
          prev?.map((item) =>
            item?.docId === docId
              ? {
                  ...item,
                  folderId,
                }
              : item
          )
        );
      })
      .catch((err) => console.log("Error: ", err));
  };

  useEffect(() => {
    if (!folderId?.length > 0) getDocumentFolder();
  }, [accessToken, employee]);

  useEffect(() => {
    if (!!docId) {
      form.setFieldsValue({
        ...docObject?.reduce(
          (acc, { formItemName, value, type }) => ({
            ...acc,
            [formItemName]:
              type !== "datepicker" && type !== "timepicker"
                ? value
                : !!value
                ? dayjsTZ(value)
                : value,
          }),
          {}
        ),
        expirationDate: !!expirationDate ? dayjsTZ(expirationDate) : "",
      });
      setUploadedFiles((prev) => uniq([...prev, ...googleDriveUploads]));
      !!expirationDate && setConfigExpirationDate(true);
    }
  }, [docId]);

  useEffect(() => {
    !!docStatus &&
      setCurrentStep(steps?.map(({ title }) => title).indexOf(docStatus));
  }, [docStatus, docStatuses]);

  //region RETURN
  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          visible,
          onCancel,
          title: doc?.docType || docType,
          wrapClassName: "cardLayoutModal dynamicDocumentationModal",
          className: isDarkMode ? "dark darkHeader" : "lightHeader",
        }}
        closeIcon={<ModalXIcon />}
      >
        <Form {...{ form }}>
          <div className="dynamicDocModalBodyContainer">
            <Stepper
              {...{
                steps,
                currentStep,
                setCurrentStep,
                stepRenderer: false,
                stepperClassName: "docStatusStepper",
              }}
            />
            <div className="docContent">
              <DocUploader
                {...{
                  doc,
                  folderId,
                  onDelete,
                  accessToken,
                  uploadedFiles,
                  onPickerSuccess,
                  docType: doc?.docType || docType,
                }}
              />
              <div className="gradientDivider" />
              <div className="formSection">
                <div className="formContainer">
                  {RenderDynamicComponents(fieldsJSON, { form })}
                </div>
                <ActionButtons
                  {...{
                    onCancel,
                    onDelete: () => setConfirmationModalVisible(true),
                    onKeylogs: () => setLogsModalVisible(true),
                    onSave,
                    isEditing: !!docId,
                  }}
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal>
      <ConfirmationModal
        {...{
          title: "Fshije",
          visible: confirmationModalVisible,
          setVisible: setConfirmationModalVisible,
          text: CONFIRMATION_MODAL_TEXT,
          onConfirm: deleteDocumentation,
        }}
      />
      {!!logsModalVisible ? (
        <ActivityModal
          setIsModalVisible={setLogsModalVisible}
          keylog={doc?.keylog}
        />
      ) : null}
    </>
  );
};
export default DocumentationModal;
