import { Button } from "antd";
import React from "react";
import { htmlParser } from "../../../../utils";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

//nuk perdoret
function MobilePolitikatDitePushim({ tableData, setVisible }) {
  return (
    <div className="base-mobile-with-header">
      <div className="header-div">
        {" "}
        <Button onClick={() => setVisible(true)} className="btn-header">
          Shto një ditë pushimi
        </Button>{" "}
      </div>
      <MobileHtmlTabel
        tableData={tableData?.map?.(({ name, description, formula }) => ({
          name,
          description: htmlParser(description),
          formula,
        }))}
        header={[
          "Dita e pushimit",
          "Përshkrimi ditës pushim",
          "Formula ditës pushim",
        ]}
        isLink={false}
      />
    </div>
  );
}

export default MobilePolitikatDitePushim;
