/**Antd Form Validator
 *
 * @param {Object} from The form instance.
 * @param {Function} [onSuccessFunc] Callback function.
 * @param {Function} [onErrorFunc] Callback function on error.
 * @param {Array} [fieldNames] Form.Item names of the inputs you want to validate.
 */

export const validateForm = async (
  form,
  onSuccessFunc = () => {},
  onErrorFunc = () => {},
  fieldNames = []
) => {
  await form
    .validateFields(fieldNames)
    .then(async (res) => await onSuccessFunc(res))
    .catch((e) => {
      console.error(e);
      onErrorFunc();
      try {
        const el = document.getElementById(e?.errorFields[0]?.name[0]);
        el.scrollIntoView({ block: "start", behavior: "smooth " });
      } catch (err) {
        console.error(err);
      }
    });
};
