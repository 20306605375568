import React, { useEffect } from "react";
import { Button, Input, Form, message, Modal } from "antd";
import "./kerkeseModal.scss";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../../../authentication/authHelpers";
import { RichTextEditor } from "../../../../commonComponents";
import { useSelector } from "react-redux";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

const KerkeseModal = ({
  isRequestModalVisible,
  setIsRequestModalVisible,
  requestData,
  setTableData,
  tableData,
}) => {
  const auth = useAuth();
  const [form] = Form.useForm();

  const { isDarkMode } = useSelector((state) => state.usersList);

  const handleOk = () => {
    setIsRequestModalVisible(false);
  };

  const handleCancel = () => {
    setIsRequestModalVisible(false);
  };

  useEffect(() => {
    if (isRequestModalVisible) {
      form.setFieldsValue({
        statusDescription: requestData.statusDescription,
        statusTitle: requestData.statusTitle,
      });
    }
  }, [isRequestModalVisible, requestData, form]);

  const handleSave = (val) => {
    //shto statusName dhe statusCreator te nje object dhe shtoji kur ben edit
    let theData = {
      statusId: uuidv4(),
      statusTitle: form.getFieldsValue().statusTitle,
      statusDescription: form.getFieldsValue().statusDescription,
      statusCreator:
        auth?.userAccess[0]?.given_name +
        " " +
        auth?.userAccess[0]?.family_name,
      statusName: requestData.statusName,
    };

    API.put(
      "programFields",
      "/programFields/e480aa5f-a671-4781-a8bb-89876006cc90",
      {
        body: {
          fieldOptions: [
            ...tableData.filter((el) => el.statusId !== requestData.statusId),
            theData,
          ],
        },
      }
    ).then(() => {
      message.success("Të dhënat u ndryshuan me sukses!", 10);
      setTableData([
        ...tableData.filter((el) => el.statusId !== requestData.statusId),
        theData,
      ]);
      setIsRequestModalVisible(false);
    });
  };

  return (
    <div>
      <Modal
        className={`request-modal ${
          isDarkMode ? "dark darkHeader" : "lightHeader"
        }`}
        title="Editimi i kërkesës"
        open={isRequestModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            onClick={() => setIsRequestModalVisible(false)}
          >
            Mbyll
          </MondayButton>,
          <MondayButton className="mondayButtonGreen" onClick={handleSave}>
            Ruaj Ndryshimet
          </MondayButton>
        ]}
      >
        <div className="modal-status-text">
          <Form autocomplete="off" form={form}>
            <p className="label-text">Shkruaj llojin e kërkesës:</p>
            <Form.Item name="statusTitle">
              <Input />
            </Form.Item>
            <p className="label-text">Përshkrimi i llojit të kërkesës:</p>
            <Form.Item name="statusDescription">
              <RichTextEditor className="lloj-kerkesash-quill" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default KerkeseModal;
