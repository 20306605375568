import { Checkbox } from "antd";
import "./TransferLabel.scss";
import { useSelector } from "react-redux";

const TransferLabel = ({
  title,
  transferred,
  checked,
  onChange,
  onClick,
  disabled,
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  return (
    <div
      className={`transferLabelContainer ${isDarkMode ? "dark" : ""} ${
        !!disabled ? "disabledTransferLabel" : ""
      }`.trim()}
    >
      <div
        className={`marker ${!!transferred ? "transferred" : ""} ${
          !!checked ? "checked" : ""
        }`.trim()}
        onClick={() => onChange(!checked)}
      >
        {!!checked ? (
          <Checkbox
            {...{
              checked,
              onChange: ({ target: { checked: eventChecked } }) =>
                onChange(eventChecked),
            }}
          />
        ) : (
          <div className="innerMarker" />
        )}
      </div>
      <div className="titleContainer" {...{ onClick }}>
        {title}
      </div>
    </div>
  );
};

export default TransferLabel;
