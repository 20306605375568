import { Alert, Button, Popconfirm, Skeleton, Select, message } from "antd";
import { FulfillingSquareSpinner } from "react-epic-spinners";
import React from "react";
import { FlexLogo } from "../assets/logo";
import PayStubFinalTable from "../payStubTable/PayStubFinalTable";
import PayStubTable from "../payStubTable/PayStubTable";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { Watermark } from "@hirohe/react-watermark";
import Timesheet from "../payStubTable/Timesheet";
import { API } from "aws-amplify";
import { currencyformatter } from "./format";
import { RichTextEditor } from "../../../commonComponents";
import { htmlParser } from "../../../../utils";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";
const { Option } = Select;

function NewPayStub({
  newObj,
  payStubObj,
  calculated,
  componentRef,
  firstTable,
  secondTable,
  thirdTable,
  fourthTable,
  fifthTable,
  finalTable,
  alreadyHas,
  previousPaySub,
  onRegenerate,
  onGenerateFinal,
  timesheetTable,
  statusTypes,
  onChangeStatus,
  wageStatus,
  onDelete,
  viewType,
}) {
  const { isDarkMode } = useSelector((state) => state.usersList);
  console.log("payStubobj", payStubObj);

  return (
    <div className="payStub-print" ref={componentRef}>
      {calculated ? (
        <>
          {viewType !== "Employee" && (
            <div className="unprintable-warning">
              {payStubObj?.valid === false && (
                <>
                  <Alert
                    type="error"
                    showIcon
                    description={
                      "Gjenerimi për këtë punonjës nuk është i vlefshëm pasi ky punonjës nuk është aktiv!"
                    }
                  />
                  <br />
                </>
              )}
              {alreadyHas ? (
                <Alert
                  message="Version i vjeter i pages gjeneruar!"
                  description={
                    <span>
                      Paga është e gjeneruar në:{" "}
                      {!!previousPaySub?.issuedAt && (
                        <strong>
                          {dayjsTZ(previousPaySub?.issuedAt).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </strong>
                      )}{" "}
                      nga{" "}
                      <strong> {previousPaySub?.issuedBy?.full_name}.</strong>
                      <br />
                      <span>
                        Dëshironi ta rigjeneroni?
                        <button
                          style={{
                            height: 27,
                            marginLeft: 5,
                            border: "none",
                            background: "#1da193",
                            borderRadius: 5,
                            color: "white",
                            width: 76,
                            cursor: "pointer",
                          }}
                          onClick={() => onRegenerate()}
                        >
                          Gjenero
                        </button>
                        <Popconfirm
                          classNames={{
                            root: `globalPopover ${isDarkMode ? "dark" : ""}`,
                          }}
                          title={
                            <span>
                              A jeni të sigurt që dëshironi të fshini gjenerimin
                              e pagës?
                            </span>
                          }
                          onConfirm={() => onDelete()}
                        >
                          <button
                            style={{
                              height: 27,
                              marginLeft: 5,
                              border: "none",
                              background: "red",
                              borderRadius: 5,
                              color: "white",
                              width: 76,
                              cursor: "pointer",
                            }}
                          >
                            Fshi
                          </button>
                        </Popconfirm>
                      </span>
                      {/* {payStubObj?.taxes?.netoPay?.netoPay !==
													newObj?.taxes?.netoPay?.netoPay && (
													<span>
														Deshironi ta rigjeneroni?
														<Button onClick={() => onRegenerate()}>Po</Button>
													</span>
												)} */}
                    </span>
                  }
                  type="info"
                  showIcon
                />
              ) : (
                <div
                  className="paystub-first-page"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Popconfirm
                    classNames={{
                      root: `globalPopover ${isDarkMode ? "dark" : ""}`,
                    }}
                    title={
                      <span>
                        A jeni të sigurt që dëshironi të finalizoni pagën?
                      </span>
                    }
                    onConfirm={() => onGenerateFinal()}
                    disabled={payStubObj?.valid === false ? true : false}
                  >
                    <MondayButton
                      className="mondayButtonGreen"
                      disabled={payStubObj?.valid === false ? true : false}
                    >
                      Finalizo Gjenerimin
                    </MondayButton>
                  </Popconfirm>{" "}
                  <Select
                    className="pick-empl"
                    showSearch
                    popupClassName={isDarkMode ? "darkDropdown" : ""}
                    placeholder="Ndrysho Satusin"
                    style={{ width: 220 }}
                    value={wageStatus}
                    disabled={payStubObj?.valid === false ? true : false}
                    onChange={(val) => onChangeStatus(val)}
                    // optionFilterProp="children"
                    // filterOption={(input, option) =>
                    // 	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {}
                    {/* {employees?.map((empl, idx) => (
												<Option value={empl?.employeeId}>
													{empl?.employeeFirstName + " " + empl?.employeeLastName}
												</Option>
											))} */}
                    {statusTypes?.map((el, idx) => (
                      <Option value={el?.name} key={`${el?.name}status`}>
                        {el?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
          )}
          <Watermark
            fontFamily="Arial, Helvetica, sans-serif"
            opacity={0.1}
            rotate={-45}
            show
            text={wageStatus !== "Finalized" ? wageStatus : ""}
            textColor={statusTypes.find((el) => el.name === wageStatus)?.color}
            textSize={wageStatus === "Draft" ? 480 : 240}
            gutter={wageStatus === "Draft" ? 50 : 100}
          >
            <div className="paystub-first-page">
              <div className="payStub-header">
                <div className="header-logo">
                  <FlexLogo />
                </div>
                <div className="employeer-info">
                  <div className="employeer-info-label">PUNËDHËNËSI</div>
                  <div className="employeer-info-label">
                    Flex Business Solutions
                  </div>
                  <div className="employeer-info-label">developers@flex.al</div>
                  <div className="employeer-info-label"> Tiranë, Albania</div>
                </div>
              </div>
              <br />
              <span className="payStub-header-divider"></span>
              <div className="payStub-Info">
                <div className="payStub-info__employee">
                  <div className="employeer-info-label">PUNËMARRËSI</div>
                  <div className="employeer-info-label">
                    {payStubObj?.employeeInfo?.employeeFirstName}{" "}
                    {payStubObj?.employeeInfo?.employeeLastName}
                  </div>
                  <div className="employeer-info-label">
                    {" "}
                    {payStubObj?.employeeInfo?.employeeEmailWork}
                  </div>
                  <div className="employeer-info-label">
                    {" "}
                    {payStubObj?.employeeInfo?.employeeAddress}
                  </div>
                </div>
                <div className="payStub-info__timely">
                  {" "}
                  <div className="employeer-info-label">PERIUDHA E PAGESËS</div>
                  <div className="employeer-info-label-double">
                    <span>Fillimi i periudhës:</span>{" "}
                    <span>
                      {" "}
                      {dayjsTZ(payStubObj?.momentInstance?.start).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                  <div className="employeer-info-label-double">
                    <span>Mbarimi i periudhës: </span>{" "}
                    <span>
                      {" "}
                      {dayjsTZ(payStubObj?.momentInstance?.end).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                  {/* <div className="employeer-info-label-double">
										<span>Data e pagesës: </span> <span>{dayjsTZ().format("DD/MM/YYYY")}</span>
									</div> */}
                  <div
                    className="employeer-info-label-double"
                    style={{ color: "green" }}
                  >
                    <span>Kategori:</span>{" "}
                    <span>{payStubObj?.employeeInfo?.employeePayrollType}</span>
                  </div>
                  <div className="employeer-info-label">
                    <strong>
                      Orët totale:{" "}
                      {payStubObj?.allhours?.totalHours?.toFixed(2) ||
                        payStubObj?.allhours?.totalHours}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="payStub-table-hourly-pay">
                <PayStubTable
                  columnsDef={firstTable?.columnsDef}
                  rowData={firstTable?.rowData}
                />
                <PayStubTable
                  columnsDef={secondTable?.columnsDef}
                  rowData={secondTable?.rowData}
                />
                <PayStubTable
                  columnsDef={fourthTable?.columnsDef}
                  rowData={fourthTable?.rowData}
                />
                <PayStubTable
                  columnsDef={fifthTable?.columnsDef}
                  rowData={fifthTable?.rowData}
                />
                <PayStubTable
                  columnsDef={thirdTable?.columnsDef}
                  rowData={thirdTable?.rowData}
                />
              </div>
              <div className="payStub-footer">
                <br /> <br />
                <div className="payStub-footer-memo">
                  <span
                    className="payStub-footer-memo__content"
                    style={{ fontStyle: "italic" }}
                  >
                    {!!payStubObj?.note && (
                      <>
                        <strong>Shënim: </strong>
                        <br />
                        {htmlParser(payStubObj?.note)}
                      </>
                    )}
                    *Llogaritjet financiare të paraqitura në këtë dokument
                    elektronik janë paraqitur vizualisht të përafruara në nivel
                    të dytë numerik dhjetor, ndërkohë që shuma totale është si
                    rezultat i veprimeve fikse.
                  </span>
                </div>
                <div className="payStub-footer-table">
                  <PayStubFinalTable
                    columnsDef={finalTable?.columnsDef}
                    rowData={finalTable?.rowData}
                  />

                  <div className="payStub-footer-amount">
                    <span>*PAGESA NETO: </span>
                    <span>
                      {isNaN(payStubObj?.taxes?.netoPay?.netoPay) ||
                      payStubObj.taxes?.netoPay?.netoPay < 0
                        ? "0.00"
                        : currencyformatter.format(
                            payStubObj?.taxes?.netoPay?.netoPay
                          )}{" "}
                    </span>
                    <span></span>
                  </div>
                  <div className="payStub-footer-amount">
                    <span>*PAGESA BRUTO: </span>
                    <span>
                      {isNaN(payStubObj?.bruto?.totalBruto)
                        ? "0.00"
                        : currencyformatter.format(
                            payStubObj?.bruto?.totalBruto
                          )}{" "}
                    </span>
                    <span></span>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="timesheet-table">
              <Timesheet {...{ timesheetTable }} />
            </div>
          </Watermark>
        </>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FulfillingSquareSpinner color="#1da193" size={100} />
          </div>
          <Skeleton />
          <Skeleton />
        </>
      )}
    </div>
  );
}

export default NewPayStub;

const getTextColor = (statusTypes, wageStatus) => {
  const temp = statusTypes.find((el) => el.name === wageStatus)?.color;
  return temp;
};
