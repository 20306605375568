import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, message } from "antd";
import "./KalkulatoriPages.scss";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import CalculatorButtons from "./CalculatorButtons";
import { add, multiply } from "lodash";
import { useSelector } from "react-redux";

const KalkulatoriPages = () => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [amount, setAmount] = useState("");
  const [hide, setHide] = useState(true);
  const [form] = Form.useForm();

  const switchPagat = () => {
    setHide(!hide);
    handleRemove();
  };

  const key = "kalkulimi";
  const currencyformatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "ALL",
    maximumFractionDigits: 2,
  });

  const handleEqual = () => {
    if (!!amount && amount > 0) {
      message.loading({
        content: "Duke kalkuluar shumen tuaj ...",
        key,
      });
      setTimeout(() => {
        message.success({
          content: hide
            ? "Shuma juaj bruto u kalkulua me sukses!"
            : "Shuma juaj neto u kalkulua me sukses!",
          key,
        });
      }, 1500);
    } else {
      message.error("Nuk mund te kalkulojme nje shume boshe !");
      handleRemove();
    }
    if (!!amount && amount > 0) {
      setTimeout(() => {
        if (amount <= 40000 && hide) {
          form.setFieldsValue({ taksaProgresive: 0 });
        } else if (amount <= 50000 && hide) {
          form.setFieldsValue({
            taksaProgresive: (amount - 30000) * 0.13 * 0.5,
          });
        } else if (amount <= 200000 && hide) {
          form.setFieldsValue({ taksaProgresive: (amount - 30000) * 0.13 });
        } else {
          form.setFieldsValue({
            taksaProgresive: hide && (amount - 200000) * 0.23 + 22100,
          });
        }
        if (amount > 141133 && hide) {
          form.setFieldsValue({ kontributet: 141133 });
        } else if (amount < 40000 && hide) {
          form.setFieldsValue({ kontributet: 40000 });
        } else {
          form.setFieldsValue({ kontributet: hide && amount });
        }
        form.setFieldsValue({
          sigurimetShoqerore:
            hide &&
            currencyformatter.format(form.getFieldValue("kontributet") * 0.095),
        });

        let sigShen = parseInt(amount * 0.017);

        form.setFieldsValue({
          sigurimetShendetesore: hide && currencyformatter.format(sigShen),
        });
        let totSig =
          parseFloat(form.getFieldValue("kontributet") * 0.095) +
          parseFloat(amount * 0.017);

        let sigTotal =
          parseFloat(form.getFieldValue("kontributet") * 0.095) +
          parseFloat(amount * 0.017);

        form.setFieldsValue({
          totalSigurime: hide && currencyformatter.format(totSig),
        });

        form.setFieldsValue({
          kostoTotale:
            hide &&
            currencyformatter.format(
              parseFloat(amount) +
                form.getFieldValue("kontributet") * 0.15 +
                parseFloat(amount) * 0.017
            ),
        });

        if (amount !== "") {
          form.setFieldsValue({
            brutOrNet:
              hide &&
              currencyformatter.format(
                parseFloat(amount) -
                  sigTotal -
                  form.getFieldValue("taksaProgresive")
              ),
          });
        }

        if (!hide) {
          if (amount <= 35520) {
            form.setFieldsValue({ brutOrNet: amount / 0.888 });
          } else if (amount <= 43100) {
            form.setFieldsValue({ brutOrNet: (amount - 1950) / 0.823 });
          } else if (amount <= 110882) {
            form.setFieldsValue({ brutOrNet: (amount - 3900) / 0.758 });
          } else if (amount > 110879 && amount <= 161092) {
            form.setFieldsValue({ brutOrNet: (amount + 9507.635) / 0.853 });
          } else {
            form.setFieldsValue({ brutOrNet: (amount - 10492.365) / 0.753 });
          }
          if (amount > 141133 && !hide) {
            form.setFieldsValue({ kontributet: 141133 });
          } else if (amount < 40000 && !hide) {
            form.setFieldsValue({ kontributet: 40000 });
          } else {
            form.setFieldsValue({ kontributet: !hide && amount });
          }
          form.setFieldsValue({
            sigurimetShoqerore: currencyformatter.format(
              form.getFieldValue("kontributet") * 0.095
            ),
          });
          form.setFieldsValue({
            sigurimetShendetesore: currencyformatter.format(amount * 0.017),
          });
          form.setFieldsValue({
            totalSigurime: currencyformatter.format(
              form.getFieldValue("kontributet") * 0.095 + amount * 0.017
            ),
          });
          if (amount <= 40000) {
            form.setFieldsValue({ taksaProgresive: 0 });
          } else if (amount <= 50000) {
            form.setFieldsValue({
              taksaProgresive: (amount - 30000) * 0.13 * 0.5,
            });
          } else if (amount <= 200000) {
            form.setFieldsValue({ taksaProgresive: (amount - 30000) * 0.13 });
          } else {
            form.setFieldsValue({
              taksaProgresive: (amount - 200000) * 0.23 + 22100,
            });
          }
          form.setFieldsValue({
            kostoTotale: currencyformatter.format(
              parseFloat(amount) +
                form.getFieldValue("kontributet") * 0.15 +
                parseFloat(amount) * 0.017
            ),
          });
          form.setFieldsValue({
            brutOrNet: currencyformatter.format(
              form.getFieldValue("brutOrNet")
            ),
          });
        }

        form.setFieldsValue({
          taksaProgresive: currencyformatter.format(
            form.getFieldValue("taksaProgresive")
          ),
        });
        form.setFieldsValue({
          kontributet: currencyformatter.format(
            form.getFieldValue("kontributet")
          ),
        });
      }, 1500);
    }
  };
  const handleRemove = () => {
    setAmount("");
    form.resetFields();
  };
  const handleClick = (e) => {
    setAmount(amount.concat(e.target.name));
  };

  return (
    <div className={hide ? "pagaCardBruto" : "pagaCardNeto"}>
      <Card
        className={`calculator ${isDarkMode ? "dark" : ""}`}
        title={hide ? "Paga bruto" : "Paga neto"}
      >
        <div className="pagatHeader">
          <label style={{ fontSize: "20px", fontWeight: "bold" }}>
            {hide ? "Paga Bruto" : "Paga Neto"}
          </label>
          <Input
            placeholder="Shkruaj shifren"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            onPressEnter={handleEqual}
          />
        </div>
        <div className="formInp">
          <Form form={form}>
            <Form.Item
              name="kontributet"
              label="Llogaritja e pagës për kontributet:"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="sigurimetShoqerore"
              label="Paga për llogaritjen e sigurimeve shoqerore te punonjesit:"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="sigurimetShendetesore"
              label="Paga për llogaritjen e sigurimeve shendetesore te punonjesit:"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="totalSigurime"
              label="Totali i sigurimeve per punonjesin:"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item label="Taksa progresive:" name="taksaProgresive">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="kostoTotale"
              label="Kostoja totale e punëdhënësit:"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className="brutOrNet"
              name="brutOrNet"
              label={hide ? "Paga Neto" : "Paga Bruto"}
            >
              <Input
                disabled
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  background: "#1d3943",
                  color: "#fff",
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          <div className="calcComponent">
            <CalculatorButtons
              {...{ handleEqual, handleClick, handleRemove }}
            />
          </div>
          <div className="icona">
            <Button
              onClick={switchPagat}
              icon={
                hide ? (
                  <DoubleRightOutlined
                    style={{
                      color: "black",
                      position: "relative",
                      top: "2px",
                    }}
                  />
                ) : (
                  <DoubleLeftOutlined
                    style={{ color: "black", position: "relative", top: "2px" }}
                  />
                )
              }
            >
              {hide ? "Paga Neto" : "Paga Bruto"}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default KalkulatoriPages;
