import {
  Alert,
  Button,
  Flex,
  Form,
  Modal,
  Space,
  Switch,
  Typography,
} from "antd";
import { useTwoFactorAuthentication } from "../../../../hooks/useTwoFactorAuthentication";
import { useState } from "react";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { allSteps } from "./mfaSteps";
import { QuestionsMark } from "../../../Intervistat/Kandidatet/ShtoKandidat/Image";
import ConfirmCodeModal from "./ConfirmCodeModal";
import { useSelector } from "react-redux";

const { Text } = Typography;

const MfaToggler = () => {
  const [form] = Form.useForm();
  const {
    setupUri,
    currentStep,
    isEnabled,
    verifyCode,
    disableTotp,
    handleStepChange,
    setUpTotpHandler,
    qrErrorMessage,
  } = useTwoFactorAuthentication();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [open, setOpen] = useState(false);
  const [confirmCodeOpen, setConfirmCodeOpen] = useState(false);

  const confirmDisable = () => {
    Modal.confirm({
      title: "A jeni të sigurt?",
      icon: <QuestionsMark />,
      content: (
        <Space direction="vertical">
          <Text>Ju po caktivizoni 2FA me applikacion.</Text>
          <Text>
            Nëse jeni të sigurt për këtë veprim, klikoni
            <span className="text-bold">"Po, vazhdo"</span>
          </Text>
        </Space>
      ),
      okText: "Po, vazhdo",
      cancelText: "Jo, anullo",
      width: "660px",
      className: `cancelModal ${isDarkMode ? "dark" : ""}`,
      centered: true,
      cancelButtonProps: {
        type: "primary",
        style: {
          background: "#ea3943",
          borderColor: "#ea3943",
          borderRadius: "5px",
        },
      },
      okButtonProps: {
        className: "ant-btn-confirm",
      },
      onOk() {
        setConfirmCodeOpen(true);
      },
      onCancel() {},
    });
  };

  const steps = allSteps(setupUri);
  const { Component } = steps[currentStep];

  const isLastStep = steps.length - 1 === currentStep;

  return (
    <>
      <div className="profileFormItem">
        <span>2FA with app</span>
        <Switch
          onChange={(value) => {
            if (value) {
              setUpTotpHandler();
              setOpen(true);
            } else {
              confirmDisable();
            }
          }}
        />
      </div>
      {open ? (
        <Modal
          className={isDarkMode ? "dark darkHeader" : "lightHeader"}
          title="Two-factor authentication (2FA)"
          open={open}
          setOpen={setOpen}
          footer={[
            currentStep !== 0 ? (
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => handleStepChange(-1)}
              >
                Back
              </MondayButton>
            ) : null,
            isLastStep ? (
              <MondayButton
                className="mondayButtonGreen"
                onClick={() =>
                  verifyCode(form.getFieldValue("code")).then(() =>
                    setOpen(false)
                  )
                }
              >
                Verify
              </MondayButton>
            ) : (
              <>
                <MondayButton
                  onClick={() => setOpen(false)}
                  className="mondayButtonRed"
                >
                  Cancel
                </MondayButton>
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => handleStepChange(1)}
                >
                  Next Step
                </MondayButton>
              </>
            ),
          ]}
        >
          <Form form={form}>
            <Flex>
              {!qrErrorMessage ? (
                <Component />
              ) : (
                <Alert
                  message={qrErrorMessage}
                  type="error"
                  action={
                    <Button
                      size="small"
                      type="text"
                      danger
                      onClick={setUpTotpHandler}
                    >
                      Try again
                    </Button>
                  }
                />
              )}
            </Flex>
          </Form>
        </Modal>
      ) : null}
      {confirmCodeOpen && (
        <ConfirmCodeModal
          onConfirm={disableTotp}
          onCancel={() => setConfirmCodeOpen(false)}
        />
      )}
    </>
  );
};

export default MfaToggler;
