import { Modal, DatePicker, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./MultiGenerate.scss";
import { paySubFactory } from "../../PaySub/employeeCalc/employeeSubFactory";
import { HeaderStepper } from "../../..";
import { FulfillingSquareSpinner } from "react-epic-spinners";
import PayStub from "../../PaySub/PaySub";
import { useReactToPrint } from "react-to-print";
import MultiGenerateTable from "./MultiGenerateTable";
import { useLocation } from "react-router";
import { exportPagatExcel } from "./excel";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import dayjsTZ from "../../../../utils/dayjs";
import PickEmployee from "./components/PickEmployee";

function MultiGenerate({ setMultiGenVis, multiGenVis, multiEmpl, clockings }) {
  const { state } = useLocation();

  const { flexEligibleEmployees } = useSelector((state) => state.employeesList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { prepayments } = useSelector((state) => state.prepayments);
  const { wages } = useSelector((state) => state.wages);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  // const { clockings } = useSelector((state) => state.clockings);
  const [current, setCurrent] = useState(0);
  const [toGenerate, setToGenerate] = useState({
    timeframe: {
      start: dayjsTZ(state?.data?.muaji || dayjsTZ()).startOf("month"),
      end: dayjsTZ(state?.data?.muaji || dayjsTZ()).endOf("month"),
    },
    employees: [],
  });
  const [payStubObj, setPayStubObj] = useState(null);
  // const [form] = For.useForm();
  const [existUnapproved, setExistUnapproved] = useState(false);
  const [finalized, setFinalized] = useState(false);
  const [savedPaySttubs, setSavedPayStubs] = useState([]);
  const [spin, setSpin] = useState(false);
  //

  const onReset = () => {
    setMultiGenVis(false);
    setPayStubObj(null);
    setExistUnapproved(false);
    setCurrent(0);
    setToGenerate({
      timeframe: {
        start: dayjsTZ().startOf("month"),
        end: dayjsTZ().endOf("month"),
      },
      employees: [],
    });
  };

  //region >next()
  const next = () => {
    setCurrent(current + 1);
    if (current === steps.length - 2) {
      if (
        !!toGenerate?.timeframe &&
        !!programFields &&
        toGenerate?.employees.length > 0 &&
        !!prepayments
      ) {
        const approvals = clockings
          .filter(
            (el) =>
              dayjsTZ(el?.clockInDate).valueOf() >=
                dayjsTZ(toGenerate?.timeframe?.start).valueOf() &&
              dayjsTZ(el?.clockOutDate).valueOf() <=
                dayjsTZ(toGenerate?.timeframe?.end).valueOf()
          )
          .find((el) => el?.approved === false);
        if (!!approvals) {
          setExistUnapproved(true);
        }
        /**
         * DO NOT CHANGE
         */
        const appliedEmployees = toGenerate?.employees;
        setSavedPayStubs([]);
        if (!finalized) {
          appliedEmployees?.map((empl) => {
            const appliedEmployee = flexEligibleEmployees.find(
              (el) => el.employeeId === empl.employeeId
            );
            const appliedClockings = clockings.filter(
              (c) => c.employeeId === empl.employeeId
            );
            const approvedClockings = appliedClockings.filter(
              (el) => el.approved === true
            );

            const test13 = new paySubFactory({
              type: "allEarnings",
              employee: appliedEmployee,
              clockings: approvedClockings,
              employeeRequests: employeeRequests,
              momentInstance: toGenerate?.timeframe,
              prepayments: prepayments,
              programFields: programFields,
            });
            setSavedPayStubs((prev) => (!!prev ? [...prev, test13] : [test13]));
          });
        } else {
          appliedEmployees?.map((empl) => {
            const appliedWage = wages.find(
              (el) =>
                el.employeeId === empl.employeeId &&
                dayjsTZ(el?.startPeriod).format("DD/MM/YYYY") ===
                  dayjsTZ(toGenerate.timeframe.start).format("DD/MM/YYYY") &&
                el?.wageStatus === "Finalized"
            );
            if (!!appliedWage) {
              // console.log("Applied Wage", appliedWage);
              setSavedPayStubs((prev) =>
                !!prev ? [...prev, appliedWage] : [appliedWage]
              );
            } else {
              // WARNING
              // console.log("Selected employee doesn`t have a finalized wage record, continue! ");
            }
          });
        }
      }
    }
  };

  //region >prev()
  const prev = () => {
    if (payStubObj !== null) {
      setPayStubObj(null);
      setExistUnapproved(false);
    }
    setCurrent(current - 1);
  };

  const componentRef = useRef("payStub-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //region STEP 1
  const PickTime = () => (
    <div
      className="pick-month"
      style={{
        display: "flex",
        justifyContent: "center",
        height: "200px",
        alignItems: "center",
        gap: "7px",
      }}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <strong style={{ color: "red" }}>*</strong>
        Zgjidh Muajin:
      </span>{" "}
      <DatePicker
        width={300}
        defaultValue={toGenerate.timeframe.start}
        popupClassName={isDarkMode ? "darkDropdown" : ""}
        picker="month"
        allowClear={false}
        format={"MMMM YYYY"}
        onChange={(val) =>
          setToGenerate((prev) => ({
            ...prev,
            timeframe: {
              start: val.startOf("month"),
              end: val.endOf("month"),
            },
          }))
        }
      />
      <span style={{ marginLeft: 50 }}>
        <Checkbox
          checked={finalized}
          onChange={(e) => setFinalized(e.target.checked)}
        >
          Shfaq vetëm të finalizuarat
        </Checkbox>
      </span>
    </div>
  );

  //region STEP 3
  const PrintTable = () => {
    return (
      <div style={{ width: 1160, maxHeight: 700, overflowY: "auto" }}>
        <MultiGenerateTable {...{ savedPaySttubs, componentRef, toGenerate }} />
      </div>
    );
  };
  const steps = [
    {
      title: "Zgjidh periudhën",
      Comp: PickTime,
      disabled: true,
    },
    {
      title: "Punonjësit",
      Comp: PickEmployee,
      disabled: true,
      toGenerate,
      setToGenerate,
      clockings,
    },
    {
      title: "Gjenero",
      Comp: PrintTable,
      disabled: true,
    },
  ];

  useEffect(() => {
    if (!!multiEmpl) {
      setToGenerate((prev) => ({ ...prev, employees: multiEmpl }));
    }
  }, [multiEmpl]);

  //region RETURN
  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      // width={1500}
      className={`multi-generate-modal ${
        isDarkMode ? "dark darkHeader" : "lightHeader"
      }`}
      title="Shiko Pagën për disa punonjës"
      open={multiGenVis}
      onOk={() => setMultiGenVis(false)}
      onCancel={onReset}
      centered={true}
      footer={[
        <MondayButton className="mondayButtonRed" onClick={onReset}>
          Mbyll Faqen
        </MondayButton>,
        <div className="finished-gen">
          {current !== 0 ? (
            <MondayButton
              className="mondayButtonGrey"
              style={{ margin: "0 8px" }}
              onClick={() => prev()}
            >
              Kthehu mbrapa
            </MondayButton>
          ) : null}
          {current !== steps.length - 1 ? (
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => next()}
              disabled={
                !!toGenerate?.timeframe?.start && !!toGenerate?.timeframe?.end
                  ? false
                  : true
              }
              style={{
                cursor:
                  !!toGenerate?.timeframe?.start && !!toGenerate?.timeframe?.end
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              Vazhdo më tej
            </MondayButton>
          ) : (
            <>
              <MondayButton
                className="mondayButtonYellow"
                onClick={() => handlePrint()}
              >
                Printo
              </MondayButton>
              <MondayButton
                className="mondayButtonGreen"
                onClick={async () => {
                  setSpin(true);
                  await exportPagatExcel(savedPaySttubs, toGenerate);
                  setSpin(false);
                }}
                spin={spin}
              >
                Shkarko Excel
              </MondayButton>
            </>
          )}
        </div>,
      ]}
    >
      <div className="generate-modal-container">
        <HeaderStepper
          currentStep={current}
          setCurrentStep={setCurrent}
          steps={steps}
        />
      </div>
    </Modal>
  );
}

export default MultiGenerate;
