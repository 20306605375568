import "./ApplicantCard.scss";
import { Badge } from "antd";
import { ClockIcon, InfoBluecIcon } from "../../../assets/icons";
import dayjsTZ from "../../../utils/dayjs";
import { useContext } from "react";
import { InterviewsContext } from "../data";
import { useSelector } from "react-redux";

const colors = {
  "Listuar për intervistë": "#FA8532",
  "Në proçes": "#FCC94A",
  Pranuar: "#20AC9D",
  Skualifikuar: "#ea3943",
  Draft: "#787677",
};

const ApplicantCard = ({
  responsiveClasses,
  intervista,
  onClick = () => {},
  badge,
  view,
  cardRef,
  ...rest
}) => {
  const { kandidatiSelektuar } = useContext(InterviewsContext);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const {
    applicantFirstName = "",
    applicantLastName = "",
    applicantStatus = "",
    applicantDepartment = "",
    createdAt = "",
  } = intervista || {};
  let applicantFullName = applicantFirstName + " " + applicantLastName;
  let textFormat =
    applicantFullName.length > 30
      ? applicantFullName.slice(0, 30) + "..."
      : applicantFullName;

  return (
    <div
      ref={cardRef}
      {...rest}
      className={`applicantCard ${isDarkMode ? "dark" : ""} ${
        kandidatiSelektuar?.applicantId === intervista?.applicantId
          ? "selected"
          : ""
      }`}
      onClick={() => onClick(intervista)}
    >
      <div className="applicantNameStatus">
        <span
          className="applName"
          style={{
            fontSize:
              view === "kanban" ||
              kandidatiSelektuar?.applicantId === intervista?.applicantId
                ? 18
                : "",
          }}
        >
          {textFormat}
        </span>
        {!!badge ? (
          <Badge
            text={responsiveClasses ? "" : applicantStatus}
            color={colors?.[applicantStatus]}
          />
        ) : null}
      </div>
      {responsiveClasses ? null : (
        <>
          <span>{applicantDepartment}</span>
          <div className="interviewDate">
            <span className="clockIcon">
              {<ClockIcon fill={isDarkMode ? "#e6e6e6" : "#323338"} />}
            </span>
            <span>
              Ka aplikuar në datën{" "}
              {createdAt && dayjsTZ(createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
          {!intervista?.applicantReApplied ? (
            <div className="interviewDate">
              <InfoBluecIcon
                height={12}
                width={12}
                fill={isDarkMode ? "#e6e6e6" : "#323338"}
              />
              <span>Aplikimi i parë</span>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default ApplicantCard;
