import React, { useContext, useEffect, useState } from "react";
import "./timeDate.scss";
import { Button, InputNumber } from "antd";
import moment from "moment";
import dayjsTZ from "../../../../../utils/dayjs";
import { InterviewsContext } from "../../../data";
import { useSelector } from "react-redux";

const Time = ({ time, selectedTime, setSelectedTime }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const { kandidatiSelektuar, isEditable, temporaryArray } =
    useContext(InterviewsContext);

  // const [time, setTime] = useState({ hour: 10, minute: 0 });
  // const [hour, setHour] = useState(0);
  // const [minute, setMinute] = useState(0);
  // const [timeType, setTimeType] = useState("");

  // useEffect(() => {
  //   setTimeType(hour > 12 ? "pm" : "am");
  // }, [hour]);

  const handleTimeChange = (name, value) => {
    time({ name, value });
    // setTime((prev) => ({ ...prev, [name]: value }));
    // if (name === "minute") {
    //   setMinute(value);
    //   form.setFieldValue(
    //     "applicantInterviewDate",
    //     moment(selectedDate).minute(value)
    //   );
    // } else {
    //   setHour(value);
    //   if (timeType === "pm") {
    //     form.setFieldValue(
    //       "applicantInterviewDate",
    //       moment(selectedDate).hour(value + 12)
    //     );
    //   } else {
    //     form.setFieldValue(
    //       "applicantInterviewDate",
    //       moment(selectedDate).hour(value)
    //     );
    //   }
    // }
  };

  const handleHourOnWheel = (e) => {
    // console.log("hour", e);
    let currentValue = time?.hour;
    const newValue = e.deltaY < 0 ? ++currentValue : --currentValue;

    const clampedValueHour = Math.min(Math.max(newValue, 10), 20);
    time({ hour: clampedValueHour });
    // setTime((prev) => ({ ...prev, hour: clampedValueHour }));
    // form.setFieldValue(
    //   "applicantInterviewDate",
    //   moment(selectedDate).hour(clampedValueHour)
    // );
  };

  const handleMinuteOnWheel = (e) => {
    let currentValue = time?.minute;
    const newValue = e.deltaY < 0 ? currentValue + 1 : currentValue - 1;

    const clampedValueMinute = Math.min(Math.max(newValue, 0), 59);
    time({ minute: clampedValueMinute });
    // setTime((prev) => ({ ...prev, minute: clampedValueMinute }));
    // form.setFieldValue(
    //   "applicantInterviewDate",
    //   moment(selectedDate).minute(clampedValueMinute)
    // );
  };

  // const handleTimeType = (name) => {
  //   console.log("timetype", name);
  //   // if (name === "pm" && timeType !== name) {
  //   //   setTimeType(name);
  //   //   form.setFieldValue(
  //   //     "applicantInterviewDate",
  //   //     moment(selectedDate).add(12, "hour")
  //   //   );
  //   // } else if (name === "am" && timeType !== name) {
  //   //   setTimeType(name);
  //   //   form.setFieldValue(
  //   //     "applicantInterviewDate",
  //   //     moment(selectedDate).subtract(12, "hour")
  //   //   );
  //   // }
  // };

  const numberFormater = (num) => {
    const numberString = num && num.toString();

    const lengthOfNumber = numberString.length;

    return lengthOfNumber;
  };

  return (
    <div className={`interviewTime ${isDarkMode ? "dark" : ""}`}>
      <div className="labeledInputContainer">
        <div className="labeledInputLabel">
          <span style={{ color: "red" }}>*</span>
          Selektoni orën e intervistës
        </div>
      </div>
      <div className="time-container">
        <InputNumber
          maxLength={2}
          max={20}
          min={10}
          formatter={(value) =>
            numberFormater(value) === 1 ? `0${value}` : value
          }
          controls={false}
          // keyboard={false}
          changeOnWheel
          defaultValue={
            !!kandidatiSelektuar?.applicantInterviewDate && !!isEditable
              ? dayjsTZ(
                  temporaryArray?.applicantInterviewDate ||
                    kandidatiSelektuar?.applicantInterviewDate
                )?.hour()
              : selectedTime?.hour()
          }
          onWheel={handleHourOnWheel}
          onChange={(value) => handleTimeChange("hour", value)}
        />
        <div className="divider">:</div>
        <InputNumber
          maxLength={2}
          max={59}
          min={0}
          formatter={(value) =>
            numberFormater(value) === 1 ? `0${value}` : value
          }
          defaultValue={
            !!kandidatiSelektuar?.applicantInterviewDate && !!isEditable
              ? dayjsTZ(
                  temporaryArray?.applicantInterviewDate ||
                    kandidatiSelektuar?.applicantInterviewDate
                )?.minute()
              : selectedTime?.minute()
          }
          changeOnWheel
          controls={false}
          // keyboard={false}
          onWheel={handleMinuteOnWheel}
          onChange={(value) => handleTimeChange("minute", value)}
        />
        {/* <div className="am-or-pm">
          <Button
            className={timeType === "am" ? "selected" : ""}
            type="button"
            onClick={() => handleTimeType("am")}
          >
            Am
          </Button>
          <Button
            className={timeType === "pm" ? "selected" : ""}
            type="button"
            onClick={() => handleTimeType("pm")}
          >
            Pm
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default Time;
