import React, { useMemo } from "react";
import { List, Badge } from "antd";
import "./Active.scss";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { useNavigate } from "react-router";
import Avatar from "react-avatar";
import { HollowDotsSpinner } from "react-epic-spinners";
import _ from "lodash";
import ReturnImg from "../../../../../utils/ReturnImg";

// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

function Active() {
  const navigate = useNavigate();

  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const { activeEmployees } = useSelector((state) => state.employeesList);

  const mergedData = useMemo(() => {
    if (!users?.allUsers?.Items || !activeEmployees) return [];
    const mergedByKey = _.merge(
      _.keyBy(users?.allUsers?.Items, "userSub"),
      _.keyBy(activeEmployees, "userSub")
    );
    const merged = _.values(mergedByKey);

    const filteredUsers = merged
      .filter(
        (el) =>
          !!el?.employeeId &&
          el?.identityId !== "undefined" &&
          !el?.disabled &&
          (users?.allUsers?.Items || []).some(
            (user) => el?.userSub === user?.userSub
          )
      )
      .map((e) => {
        let lastSeen = e?.lastLoggedIn;

        if (!!lastSeen) {
          let isInactive = dayjsTZ().diff(lastSeen, "minutes", true) > 15;
          if (isInactive) {
            return { ...e, active: false };
          } else {
            return { ...e, active: true };
          }
        }
        return { ...e, active: false };
      });

    return filteredUsers;
  }, [users?.allUsers?.Items, activeEmployees]);

  return (
    <>
      {!!activeEmployees && mergedData?.length > 0 ? (
        <div className={`active-users-wrapper ${isDarkMode ? "dark" : ""}`}>
          <List
            itemLayout="horizontal"
            dataSource={mergedData?.sort(
              (a, b) => b?.lastLoggedIn - a?.lastLoggedIn
            )}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  style={{ alignItems: "Center" }}
                  avatar={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Badge status={item.active ? "success" : "error"} />
                      {
                        <ReturnImg
                          width={35}
                          height={35}
                          borderRadius={50}
                          googleDriveFileId={item?.googleDriveFileId}
                          defaultImg={
                            <Avatar
                              name={item?.given_name + " " + item?.family_name}
                              size="30"
                              round={true}
                            />
                          }
                        />
                      }
                    </div>
                  }
                  title={
                    <a
                      onClick={() =>
                        navigate(`/punonjesit/specifikat/${item.employeeId}`)
                      }
                    >
                      {item?.given_name} {item?.family_name}
                    </a>
                  }
                  description={
                    "Parë për herë të fundit: " +
                      dayjsTZ(item?.lastLoggedIn).fromNow() !==
                    "Invalid Date"
                      ? dayjsTZ(item?.lastLoggedIn).fromNow()
                      : "Asnjëherë"
                  }
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </>
  );
}

export default Active;
