import Table from "./Table";

function Summary({ summaryData }) {
	return (
    <div>
      <h2>Përmbledhja</h2>
      <Table
        deletable={false}
        columnsDef={summaryData?.columnsDef}
        rowData={summaryData?.rowData}
      />
    </div>
  );
}

export default Summary;
