import { IconKerko } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import { Input } from "antd";
import MondayButton from "../../MondayButton/MondayButton";

const SearchAgGrid = ({
  gridApi,
  setActiveFilter,
  handleClean,
  className,
  style,
  initialValue = null,
}) => {
  const [input, setInput] = useState(initialValue);

  const resetSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClean();
    setInput(null);

    //resetCustomSearch
    if (!!setActiveFilter && typeof setActiveFilter === "function") {
      setActiveFilter({});
    }
  };

  const handleSearchChange = (val) => {
    if (!!gridApi) {
      gridApi.setGridOption("quickFilterText", val);
    } else if (!!setActiveFilter && typeof setActiveFilter === "function") {
      setActiveFilter((prev) => ({ ...prev, search: val }));
    }
    setInput(val);
  };

  useEffect(() => {
    if (!!initialValue) handleSearchChange(initialValue);
  }, [initialValue, gridApi]);

  return (
    <div
      className={`headerSearch ${className}`}
      data-testid="headerSearch"
      style={style}
    >
      <div className="icon">
        <IconKerko width={16} height={16} fill={style?.fill || "#111"} />
      </div>
      <Input
        className="headerInput"
        placeholder="Kërko"
        onChange={(e) => handleSearchChange(e.target.value)}
        value={input}
        style={style}
        autoComplete="off"
      />
      <MondayButton
        className="mondayButtonRed headerButton"
        onClick={resetSearch}
      >
        Pastro
      </MondayButton>
    </div>
  );
};

export default SearchAgGrid;
