export const REGULAR_USER = {
  userRole: "Employee",
  accessConfig: {
    routeConfig: [
      {
        exact: false,
        path: "/kryefaqja",
        title: "Kryefaqja",
        auth: true,
        write: true,
      },
      {
        exact: false,
        path: "/punonjesit",
        title: "Punonjësit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/departamentet",
        title: "Departamentet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/dokumentacione",
        title: "Dokumentacione",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/punonjesit/specifikat/:employeeId",
        title: "Specifikat e Punonjësit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/pagat",
        title: "Pagat",
        auth: true,
        write: true,
      },
      {
        exact: true,
        path: "/pagat/llogaritja",
        title: "Llogaritja e Pagave",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/prezencat",
        title: "Prezencat",
        auth: true,
        write: true,
      },
      {
        exact: false,
        path: "/raportet",
        title: "Raportet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/raportet/gjenero/:reportType",
        title: "Gjenerimi Raporteve",
        auth: false,
        write: true,
      },

      {
        exact: false,
        path: "/aprovimet",
        title: "Aprovimet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet",
        title: "Konfigurimet",
        auth: true,
        write: true,
      },
      {
        exact: false,
        path: "/intervistat",
        title: "Intervistat",
        auth: true,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/preferencat",
        title: "Preferencat",
        auth: true,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/kompania/info/rreth/komapnise",
        title: "Informacione Rreth Kompanisë",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/kompania/pozicionet",
        title: "Pozicionet e Kompanisë",
        auth: false,
        write: true,
      },
      {
        exact: true,
        path: "/konfigurimet/kompania/rolet/:departmentId",
        title: "Rolet e Kompanisë",
        auth: false,
        write: true,
      },
      {
        exact: true,
        path: "/konfigurimet/kompania/profili",
        title: "Profili",
        auth: true,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/pajisjet/orarit",
        title: "Sistemi: Pajisjet e orarit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/oraret/punes",
        title: "Sistemi: Oraret e punës",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/turnet",
        title: "Sistemi: Turnet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/statuset/punojesve",
        title: "Sistemi: Statuset e Punonjësve",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/arsyet/largimit",
        title: "Sistemi: Arsyet e Largimit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/llojet/kerkesave",
        title: "Sistemi: Llojet e kërkesave",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/adresatIp",
        title: "Konfigurimi i Adresave IP",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/sistemi/dokumentacionet",
        title: "Sistemi: Dokumentacionet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/politikat/mungesave",
        title: "Politikat: Mungesat",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/politikat/ditet/pushimit",
        title: "Politikat: Ditët pushim",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/politikat/festave/zyrtare",
        title: "Politikat: Festat zyrtare",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/politikat/menaxhimi/orve",
        title: "Politikat: Menaxhimi i orëve",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/politikat/oreve/jashte/orarit",
        title: "Politikat: Orët jashtë orarit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/politikat/rumbullakimit",
        title: "Politikat e rrumbullakimit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/pagat/fitimet",
        title: "Pagat: Fitimet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/pagat/zbritje",
        title: "Pagat: Zbritjet",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/pagat/pagesat",
        title: "Mënyrat e pagesës",
        auth: false,
        write: true,
      },
      {
        exact: true,
        path: "/konfigurimet/pagat/kalkulimi",
        title: "Pagat: Eksportet e listës",
        auth: false,
        write: true,
      },
      //Access Rights UI :::: Limited Access!
      {
        exact: false,
        path: "/konfigurimet/perdoruesit/:identityId",
        title: "Specifikat e Përdoruesit",
        auth: false,
        write: true,
      },
      {
        exact: false,
        path: "/konfigurimet/perdoruesit",
        title: "Përdoruesit",
        auth: false,
        write: true,
      },
    ],
    sidebarConfig: [
      { title: "Kryefaqja", id: 1, auth: true, to: "/kryefaqja" },
      { title: "Punonjësit", id: 2, auth: false, to: "/punonjesit" },
      { title: "Listë Prezencat", id: 3, auth: true, to: "/prezencat" },
      { title: "Pagat", id: 4, auth: true, to: "/pagat" },
      {
        title: "Raportet",
        id: 5,
        auth: false,
        to: "/raportet",
      },
      { title: "Dokumentacione", id: 6, auth: false, to: "/dokumentacione" },
      { title: "Departamentet", id: 8, auth: false, to: "/departamentet" },
      { title: "Aprovimet", id: 9, auth: false, to: "/aprovimet" },
      {
        title: "Konfigurimet",
        auth: true,
        id: 55,
        to: "/konfigurimet",
      },
      {
        title: "Intervistat",
        auth: false,
        id: 11,
        to: "/intervistat",
      },
    ],
    settingsConfig: [
      {
        title: "Profili",
        auth: true,
        SubmenuKey: "sub0",
        icon: "ProfileIcon",
        to: "profile",
        submenu: [],
        parent: false,
      },
      {
        title: "Kompania",
        auth: true,
        SubmenuKey: "sub1",
        submenu: [
          {
            id: 1,
            auth: false,
            to: "kompania/info/rreth/komapnise",
            title: "Informacione rreth kompanisë",
          },
          {
            key: 2,
            auth: false,
            to: "kompania/pozicionet",
            title: "Pozicionet",
          },
        ],
      },
      {
        title: "Sistemi",
        SubmenuKey: "sub2",
        auth: false,
        submenu: [
          {
            key: "3",
            auth: false,
            to: "sistemi/pajisjet/orarit",
            title: "Pajisjet e orarit",
          },
          {
            key: "4",
            auth: false,
            to: "sistemi/turnet",
            title: "Turnet",
          },
          {
            key: 7,
            auth: false,
            to: "sistemi/arsyet/largimit",
            title: "Arsyet e largimeve",
          },
          {
            key: 8,
            auth: false,
            to: "sistemi/statuset/punojesve",
            title: "Statuset e punojësve",
          },
          {
            key: 9,
            auth: false,
            to: "sistemi/llojet/kerkesave",
            title: "Llojet e kërkesave",
          },
          // {
          //   key: 10,
          //   auth: false,
          //   to: "sistemi/dokumentacionet",
          //   title: "Konfigurimi i Dokumentacioneve",
          // },
          {
            key: 11,
            auth: false,
            title: "Konfigurimi i Adresave IP",
            to: "sistemi/adresatIp",
          },
        ],
      },
      {
        title: "Politikat",
        SubmenuKey: "sub3",
        auth: false,
        submenu: [
          {
            key: 12,
            auth: false,
            to: "politikat/ditet/pushimit",
            title: "Politikat për ditët e pushimit",
          },
          {
            key: 13,
            auth: false,
            to: "politikat/festave/zyrtare",
            title: "Politikat e festave zyrtare",
          },
          {
            key: 15,
            auth: false,
            to: "politikat/oreve/jashte/orarit",
            title: "Politikat e orëve jashtë orarit",
          },
        ],
      },
      {
        title: "Pagat",
        SubmenuKey: "sub4",
        auth: false,
        submenu: [
          {
            key: 17,
            to: "pagat/fitimet",
            title: "Fitimet",
            auth: false,
          },
          {
            key: 18,
            to: "pagat/zbritje",
            auth: false,
            title: "Zbritje",
          },
          {
            key: 19,
            auth: false,
            to: "pagat/pagesat",
            title: "Mënyrat e pagesës",
          },
          {
            key: "20",
            auth: false,
            to: "pagat/kalkulimi",
            title: "Pagat Eksportet Listes",
          },
        ],
      },
      {
        title: "Përdoruesit",
        SubmenuKey: "sub5",
        auth: false,
        icon: "SiguriaIcon",
        submenu: [],
        parent: false,
        to: "perdoruesit",
      },
      {
        title: "Konfigurimi i Kandidateve",
        SubmenuKey: "sub6",
        icon: "PerdoruesitKofigIcon",
        auth: false,
        submenu: [],
        parent: false,
        to: "konfigurimiKandidateve",
      },
      {
        title: "Preferencat",
        SubmenuKey: "sub6",
        icon: "PerdoruesitKofigIcon",
        auth: true,
        submenu: [],
        parent: false,
        to: "preferencat",
      },
    ],
  },
};
