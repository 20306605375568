import { Badge, Popconfirm, Tooltip } from "antd";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  DocumentationWarningIcon,
  GoBack,
  HeaderUser,
  IconDetyrat,
  IconDil,
  Iconkonfigurimet,
  IconNojftimet,
  LogoFlex,
  IntervistatIcon,
  KonfigurimetIcon,
} from "../../../assets/icons";
import HamburgerMenu from "../../../utils/MobileComponents/HamburgerMenu";
import DynamicHeaderTitle from "../DynamicHeaderTitle";
import { useAuth } from "../../../authentication/authHelpers";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import ThemeToggle from "./ThemeToggle";

function MobileHeader({
  location,
  handleLogOut,
  setRequestsActive,
  requestsActive,
  pendingRequest,
  setNotifsActive,
  notifsActive,
  count,
  userPhoto,
  problemsActive,
  setProblemsActive,
  problematikatCount,
  setInterviewActive,
  interviewActive,
  candidatesCount,
}) {
  const auth = useAuth();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const [isConfig, isKryefaqja] = useMemo(() => {
    return [
      location.pathname.startsWith("/konfigurimet"),
      location.pathname.startsWith("/kryefaqja"),
    ];
  }, [location.pathname]);

  const renderPfp = useMemo(
    () =>
      userPhoto?.length > 0 && userPhoto !== null ? (
        <img
          src={userPhoto}
          alt="Flex"
          width={32}
          height={32}
          style={{ borderRadius: 5 }}
        />
      ) : (
        <HeaderUser />
      ),
    [userPhoto]
  );

  return (
    <div
      className={`header-app ${isDarkMode ? "dark" : ""}`}
      style={{
        width: "100%",
        ...(isDarkMode ? {} : { background: isConfig ? "#b31312" : "#1d3445" }),
      }}
    >
      <HamburgerMenu data={auth} />
      {isConfig ? <DynamicHeaderTitle /> : null}

      <div className="icons">
        {isKryefaqja ? (
          <>
            <Link to="/konfigurimet/kompania/profili">{renderPfp}</Link>
            <ThemeToggle />
          </>
        ) : null}

        {isConfig ? (
          <Link to="/kryefaqja" style={{ margin: 0, padding: 0 }}>
            <GoBack fill="#fff" />
          </Link>
        ) : null}

        {!isKryefaqja && !isConfig ? (
          <>
            <Link to="/konfigurimet/kompania/profili">{renderPfp}</Link>
            <ThemeToggle />
            <Link to="/kryefaqja" style={{ margin: 0, padding: 0 }}>
              <GoBack fill="#fff" />
            </Link>
          </>
        ) : null}
        {!isConfig ? (
          <>
            {auth?.user?.userRole === "Admin" ||
            auth?.user?.userRole === "Super Admin" ? (
              <div onClick={() => setRequestsActive(!requestsActive)}>
                <Badge
                  className="notification-badge"
                  count={pendingRequest?.length}
                >
                  <IconDetyrat />
                </Badge>
              </div>
            ) : null}{" "}
            {auth?.user?.userRole === "Admin" ||
            auth?.user?.userRole === "Super Admin" ? (
              <div onClick={() => setInterviewActive(!interviewActive)}>
                <Badge className="notification-badge" count={candidatesCount}>
                  <IntervistatIcon fill="white" />
                </Badge>
              </div>
            ) : null}
            {auth?.user?.userRole === "Admin" ||
            auth?.user?.userRole === "Super Admin" ? (
              <div onClick={() => setProblemsActive(!problemsActive)}>
                <Badge
                  className="notification-badge"
                  count={problematikatCount}
                >
                  <DocumentationWarningIcon fill={"white"} />
                </Badge>
              </div>
            ) : null}
            <div onClick={() => setNotifsActive(!notifsActive)}>
              <Badge className="notification-badge" count={count?.length}>
                <IconNojftimet />
              </Badge>
            </div>
          </>
        ) : null}

        <Popconfirm
          placement="bottom"
          title={"A jeni të sigurt që dëshironi të dilni nga programi?"}
          onConfirm={handleLogOut}
          okText="Po"
          cancelText="Jo"
          classNames={{
            root: `popup-logout-modal globalPopover ${
              isDarkMode ? "dark" : ""
            }`,
          }}
          icon={<LogoFlex width={45} height={45} />}
        >
          <IconDil />
        </Popconfirm>
      </div>
    </div>
  );
}

export default MobileHeader;
