import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { callGmailAPI } from "../../../../../../utils";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjsTZ from "../../../../../../utils/dayjs";
import { ModalXIcon } from "../../../../../../assets/icons";
import "./AddLeaveSendEmail.scss";
import QuillRenderer from "../../../../../Intervistat/Kandidatet/Kategorite/Components/QuillRenderer";
import { useAuth } from "../../../../../../authentication/authHelpers";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";

function AddLeaveSendEmail({ open, setOpen, setEmailObject, emailObject }) {
  const { user } = useAuth();

  const { isDarkMode } = useSelector((state) => state.usersList);
  const { programFields } = useSelector((state) => state.programFields);
  const [emailTemplate, setEmailTemplate] = useState(null);

  //Get the all the email Templates from the program fields
  const TableData = programFields?.find(
    (el) => el?.fieldName === "Email Templates"
  );

  //filter throught the item and find the Add leave ones
  const emailField = TableData?.fieldOptions?.filter(
    (items) => items.templateCategory === "addLeave"
  );

  //function to replace the values of the email template in ${} with the email object that matches the key
  const replacedStringFunc = (item, values) => {
    const replacedString = item?.templateBodyValues.replace(
      /\$\{([^{}]+)\}/g,
      (match, group) => {
        // Here, you can implement your logic
        switch (group.trim()) {
          case "admin":
            return `${user?.given_name} ${user?.family_name}`;
          case "startDate":
            return dayjsTZ(values?.startDate).format("DD/MM/YYYY");
          case "endDate":
            return dayjsTZ(values?.endDate).format("DD/MM/YYYY");
          case "user":
            return `${values?.user?.given_name} ${values?.user?.family_name}`;
          default:
            return match; // Return the original match if no condition matches
        }
      }
    );
    return replacedString;
  };

  //The default template when we dont have any custom one
  const defaultTemlate = {
    templateBodyValues:
      '<p>Përshëndetje,</p><p><br></p><p>${admin} bëri kërkesë për leje nga data ${startDate} deri më ${endDate} për punonjësin ${user}.</p><br><p>Per te pare lejen kliko me posht:</p><p><a href="https://portal.flex.al/kryefaqja"> Portal.</a></p><br><p>Best Regards,</p><p>Flex Team</p>',
  };

  //Function to sent the eamil with the template
  function sendEmail(value) {
    callGmailAPI("sendEmail", {
      to: "developers@flex.al",
      subject: "U krijua nje leje e re! ",
      body: value,
      attachments: [],
      category: "error",
      authData: false,
    })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }

  //when we change the selected template
  const handleChange = (e) => {
    const changedValue = emailField.find(
      (items) => items.emailTemplateId === e
    );
    const test = replacedStringFunc(changedValue, emailObject);
    setEmailTemplate(test);
  };

  //submit the modal
  const handleSubmit = () => {
    sendEmail(emailTemplate);
    setEmailObject({});
    setOpen(false);
  };

  //when the modal first renders
  useEffect(() => {
    if (emailField?.length !== 0) {
      setEmailTemplate(replacedStringFunc(emailField[0], emailObject));
    } else {
      setEmailTemplate(replacedStringFunc(defaultTemlate, emailObject));
    }
  }, []);

  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={() => setOpen(false)}
      className={`addLeaveEmailContainer ${
        isDarkMode ? "dark darkHeader" : "lightHeader"
      }`}
      closeIcon={<ModalXIcon />}
      centered
      width={800}
      title="Dergoni Email"
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => setOpen(false)}
        >
          Anullo Dërgimin
        </MondayButton>,
        <MondayButton className="mondayButtonGreen" onClick={handleSubmit}>
          Konfirmo Dërgimin
        </MondayButton>,
      ]}
    >
      <h1 className="modalHeader">
        {emailField?.length !== 0 && !!emailField
          ? "Zgjidhni template per Emailin"
          : "A doni të dërgoni Email-in?"}
      </h1>

      {emailField?.length !== 0 && !!emailField && (
        <Select
          style={{ width: "50%" }}
          popupClassName={isDarkMode ? "darkDropdown" : ""}
          defaultValue={{
            value: emailField[0]?.emailTemplateId,
            label: emailField[0]?.templateName,
          }}
          onSelect={handleChange}
          options={emailField?.map((item) => {
            return {
              value: item.emailTemplateId,
              label: item?.templateName,
            };
          })}
        />
      )}

      {!!emailTemplate && <QuillRenderer value={emailTemplate} />}
    </Modal>
  );
}

export default AddLeaveSendEmail;
