import { createContext, useState, useContext, useEffect, useMemo } from "react";
import { Layout, message } from "antd";
import Hader from "../components/header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { API, Auth } from "aws-amplify";
import LoaderComponent from "../components/commonComponents/LoadableComp/LoadableComp";
import { useNavigate, useLocation } from "react-router-dom";
import KonfigurimetMenu from "../components/Konfigurimet/KonfigurimetMenu/KonfigurimetMenu";
import AutumnLeaves from "../components/commonComponents/LoadableComp/Autumn/AutumnLeaves";
import { ErrorLoginImage, LoginImage } from "../assets/images";
import { useUserSession } from "../hooks/useUserSession";
import swal from "sweetalert";
import dayjsTZ from "../utils/dayjs";
import { callGmailAPI } from "../utils";
import { compareToken, generateToken } from "./handle2FaToken";
import { v4 } from "uuid";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { editTheme, employees, users } from "../store/actions";
import { useDispatch } from "react-redux";
import { requestDataWithParams } from "../helpers/API/RequestFactory";

const { Header, Sider, Content, Footer } = Layout;

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const userSession = useUserSession();
  const [initializedData, setInitializedData] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [is2FaActive, setIs2FaActive] = useState(false);

  //region INITIALIZE DATA
  const initializeData = async (user, action) => {
    setDarkMode(!!user?.isDarkMode);
    let toReturn = {
      user,
      userAccess: [{ ...user }],
      userRole: user?.userRole,
    };
    const { userSub = null } = user || {};

    await requestDataWithParams("employees", null, null, {
      filterKey: "userSub",
      filterValue: userSub,
    })
      .then(async ([employee]) => {
        if (!!employee && !!userSub) {
          const { employeeId = null } = employee || {};
          toReturn = { ...toReturn, employeeId, employee };
        } else {
          toReturn = { ...toReturn, employeeId: null, employee: null };
        }

        setInitializedData((prev) => ({ ...prev, ...toReturn }));

        let auth2FaObj = {};
        let codeSentOn = dayjsTZ().format();
        if (action === "signin") {
          if (!!user?.auth2Fa?.is2FaActive) {
            let require2Fa;

            const session = (user?.auth2Fa?.active2FaSessions || [])?.find(
              (el) => compareToken("2FaSessionToken", el?.sessionId)
            );

            if (!user?.auth2Fa?.last2FAuthed || !session) {
              require2Fa = true;
            } else {
              require2Fa =
                dayjsTZ()
                  .startOf("day")
                  .diff(
                    dayjsTZ(user?.auth2Fa?.last2FAuthed).startOf("day"),
                    "days"
                  ) >= 30;
            }
            if (!!require2Fa) {
              const successOTP = generateCode();
              await sendOtpEmail(
                {
                  name: user.given_name,
                  code: successOTP,
                  email: user.email,
                },
                user
              );
              console.log(successOTP);
              auth2FaObj = {
                codeSent: true,
                is2FaActive: true,
                successOTP,
                active2FaSessions: user?.auth2Fa?.active2FaSessions || [],
              };
              await updateUserConfig(
                { auth2Fa: { ...auth2FaObj } },
                user?.identityId
              ).then(() => {
                codeSentOn = dayjsTZ().format();
                setInitializedData((prev) => ({
                  ...prev,
                  ...toReturn,
                  auth2Fa: { ...auth2FaObj, codeSentOn },
                }));
              });
            } else {
              const auth2Fa = {
                ...user?.auth2Fa,
                active2FaSessions: (user?.auth2Fa?.active2FaSessions || []).map(
                  (el) =>
                    el?.sessionId === session?.sessionId
                      ? { ...el, isAuthenticated: true }
                      : el
                ),
              };
              await updateUserConfig(
                {
                  auth2Fa,
                  lastLoggedIn: dayjsTZ().valueOf(),
                },
                user?.identityId
              ).then(async () => {
                setInitializedData((prev) => ({
                  ...prev,
                  ...toReturn,
                  auth2Fa,
                }));

                await userSession.open(user);
                navigate("/kryefaqja");
              });
            }
          } else {
            await updateUserConfig(
              { lastLoggedIn: dayjsTZ().valueOf() },
              user?.identityId
            ).then(async () => {
              setInitializedData((prev) => ({
                ...prev,
                ...toReturn,
                auth2Fa: { ...user?.auth2Fa },
              }));

              await userSession.open(user);
              navigate("/kryefaqja");
            });
          }
        } else {
          setInitializedData((prev) => ({
            ...prev,
            ...toReturn,
            auth2Fa: { ...user?.auth2Fa },
          }));
        }
      })
      .catch((err) => console.log("Error initializing employees: ", err));
  };
  //region MFA INITIALIZE DATA
  //to work on this again
  // const initializeData = async (authUser, action) => {
  //   const user = await API.get("users", "/users");

  //   let toReturn = {
  //     user,
  //     userAccess: [{ ...user }],
  //     userRole: user?.userRole,
  //   };
  //   const { userSub = null } = user || {};

  //   await requestDataWithParams("employees", null, null, {
  //     filterKey: "userSub",
  //     filterValue: userSub,
  //   })
  //     .then(async ([employee]) => {
  //       if (!!employee && !!userSub) {
  //         const { employeeId = null } = employee || {};
  //         toReturn = { ...toReturn, employeeId, employee };
  //       } else {
  //         toReturn = { ...toReturn, employeeId: null, employee: null };
  //       }

  //       if (action === "signin") {
  //         checkMFAStatus(authUser);
  //         setInitializedData((prev) => ({
  //           ...prev,
  //           ...toReturn,
  //           authUser,
  //           // auth2Fa: {
  //           // ...(prev?.auth2Fa || {}),
  //           // codeSent: true,
  //           //tesing only
  //           // is2FaActive: true,
  //           // },
  //         }));
  //       }
  //       // dispatch(employees([employee], "GET_ACTIVE_EMPLOYEES"));
  //     })
  //     .catch((err) => console.log("Error initializing employees: ", err));

  //   // dispatch(users(user));
  // };

  //region >generateCode()
  const generateCode = (prev) => {
    let min = Math.ceil(100000);
    let max = Math.floor(999999);
    const code = Math.floor(Math.random() * (max - min + 1)) + min;
    if (code !== prev) {
      return code.toString();
    }
    return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
  };

  const setDarkMode = (value) => {
    const lsTheme = JSON.parse(localStorage.getItem("isDarkMode"));
    if (lsTheme === null) {
      localStorage.setItem("isDarkMode", JSON.stringify(value));
      const { theme } = JSON.parse(sessionStorage.getItem("agThemePreference"));
      sessionStorage.setItem(
        "agThemePreference",
        JSON.stringify({
          theme,
          mode: value ? "dark" : "light",
          accentColor: value ? "#213c4f" : "#1d3445",
        })
      );
      dispatch(editTheme(value));
    }
  };

  //region >sendEmail()
  const sendOtpEmail = async ({ email, name, code }, user) => {
    const emailBody = {
      to: email,
      subject: "One time verification code",
      body: `<div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 10px; box-shadow: 0 4px 10px rgba(0,0,0,0.1); padding: 30px;">
              <h2 style="color: #1d3445; text-align: center;">One time verification code</h2>
              <p style="color: #555;">Hi ${name},</p>
              <p style="color: #555;">You are receiving this email because a request was made for a one-time code that can be used for authentication.</p>
              <p style="color: #555;">Please enter the following code for verification:</p>
              <h1 style="font-size: 28px; color: #02686b; text-align: center; background: #f7f7f7; padding: 15px; border-radius: 5px;">${code}</h1>
              <p style="color: #555;">If you believe you have received this email in error, please reach out to your supervisor.</p>
              <footer style="margin-top: 20px; text-align: center; color: #777">
                <p>&copy; Flex Business Solutions</p>
              </footer>
            </div>`,
      attachments: [],
      category: "error",
      authData: false,
    };
    let isPending = true;
    const gmailRequest = callGmailAPI("sendEmail", { ...emailBody });
    // const gmailRequest = new Promise((resolve, reject) => {});

    setTimeout(() => {
      if (isPending) {
        isPending = false;
        Swal.fire({
          title: "Dërgimi i kodit dështoi.",
          icon: "error",
          timer: 5000,
          text: "U çaktivizua 2FA për këtë identifikim dhe ju po ridrejtoheni në kryefaqe. Për të përdorur 2FA sërish ju duhet ta aktivizoni atë në Settings.",
          content: ErrorLoginImage,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: () => handle2FaFailure(user),
        }).then(() => navigate("/kryefaqja"));
      }
    }, 5000);

    await gmailRequest
      .then(() => {
        isPending = false;
      })
      .catch((err) => {
        isPending = false;
        console.log("err: ", err);
      });
  };

  //region >handle2FaFailure()
  const handle2FaFailure = async (user) => {
    if (!!user?.identityId) {
      const toSend = {
        auth2Fa: {
          ...(user?.auth2Fa || {}),
          is2FaActive: false,
        },
      };
      await updateUserConfig(toSend, user.identityId)
        .then(() =>
          setInitializedData((prev) => ({
            ...prev,
            ...toSend,
            user: { ...(prev?.user || {}), ...toSend },
          }))
        )
        .catch((err) => console.log("Error: ", err));
      await userSession.open(user);
    }
  };

  //region >redirectToLogin()
  const redirectToLogin = async (callback) => {
    navigate("/login");
    !!callback && callback();
    setInitializedData({});
  };

  //ky nuk duhet ne MFA
  //region >onRefresh()
  const onRefresh = async (callback = () => {}, location) => {
    await API.get("users", "/users")
      .then(async (res) => {
        const { active2FaSessions, is2FaActive } = res?.auth2Fa || {};
        const session = (active2FaSessions || [])?.find((el) =>
          compareToken("2FaSessionToken", el?.sessionId)
        );
        if (!!is2FaActive && !session?.isAuthenticated) {
          redirectToLogin(callback);
        } else {
          await initializeData(res);
          navigate(location || "/kryefaqja");
          !!callback && callback();
        }
      })
      .catch((err) => {
        console.log("Error fetching /users: ", err);
        redirectToLogin(callback);
      });
  };

  // region SEND NEW CODE
  const sendNewCode = async () => {
    const toReturn2Fa = {
      ...initializedData?.auth2Fa,
      codeSent: true,
      codeSentOn: dayjsTZ().format(),
      successOTP: generateCode(initializedData?.auth2Fa?.successOTP),
    };
    await sendOtpEmail(
      {
        email: initializedData.user.email,
        code: toReturn2Fa.successOTP,
        name: initializedData.user.given_name,
      },
      initializedData.user
    );

    setInitializedData((prev) => ({ ...prev, auth2Fa: { ...toReturn2Fa } }));
  };

  //region SIGNIN/OUT
  const signin = async (newUser) => {
    await initializeData(newUser, "signin");
  };
  //region MFA SIGNIN
  // const signin = async (authUser) => {
  //   console.log({ authUser });
  //   //will check if preferred auth is 2fa or not
  //   if (authUser?.challengeName === "SOFTWARE_TOKEN_MFA") {
  //     setInitializedData((prev) => ({ ...prev, codeSent: true }));
  //     return;
  //   }

  //   // if(newUser?.auth2Fa?.is2FaActive){
  //   // setInitializedData((prev) => ({ ...(prev || {}), codeSent: true }));
  //   await initializeData(authUser, "signin");
  //   // }
  // };
  const signout = async (callback) => {
    const { is2FaActive, last2FAuthed, active2FaSessions } =
      initializedData?.auth2Fa || {};

    const session = (active2FaSessions || [])?.find((el) =>
      compareToken("2FaSessionToken", el?.sessionId)
    );

    let new2FaSessions = [...(active2FaSessions || [])].map((s) =>
      s?.sessionId === session?.sessionId ? { ...s, isAuthenticated: false } : s
    );
    if (session?.expirationDate < dayjsTZ().valueOf()) {
      new2FaSessions = new2FaSessions?.filter(
        (el) => el?.sessionId !== session?.sessionId
      );
      localStorage.removeItem("2FaSessionToken");
    }

    await updateUserConfig({
      auth2Fa: {
        is2FaActive,
        last2FAuthed,
        active2FaSessions: new2FaSessions,
      },
    });
    await Auth.signOut();

    setInitializedData({});
    callback();
  };

  //region CHECK MFA STATUS
  const checkMFAStatus = async (newUser) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        return Auth.getPreferredMFA(user);
      })
      .then((mfaType) => {
        console.log("Preferred MFA:", mfaType);
        const activeMfa = mfaType === "SOFTWARE_TOKEN_MFA";
        setIs2FaActive(activeMfa);
        console.log(activeMfa);
        if (!activeMfa) navigate("/2fa");
      })
      .catch((err) => {
        console.error("Error while retrieving preferred MFA:", err);
        setIs2FaActive(false);
      });
  };

  //region CHECK USER
  const checkUser = async () => {
    // await Auth.signOut();

    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      console.log(authUser);

      if (!authUser) {
        navigate("/login");
        setInitializedData({});

        setIsAuthenticated(false);
        return;
      }

      await checkMFAStatus(authUser);
      setInitializedData((prev) => ({ ...prev, authUser }));
      // will setinitialize data here!!!
      // await updateCurrentUserState(newUser, "checkUser");

      setIsAuthenticated(true);
    } catch (error) {
      navigate("/login");
      setInitializedData({});

      setIsAuthenticated(false);
    }
  };
  const checkAuthSession = async () => {
    // await Auth.signOut();

    try {
      const session = await Auth.currentSession();

      const refreshToken = session.getRefreshToken();

      if (!session.isValid()) await Auth.refreshSession(refreshToken);
    } catch (error) {
      console.error("Error checking auth session: ", { error });
      navigate("/login");
      setInitializedData({});
      setIsAuthenticated(false);
    }
  };

  //region MFA CONFIRM SIGNIN
  // const confirmSignIn = async (values) => {
  //   const { code } = values;
  //   const { authUser } = initializedData || {};

  //   try {
  //     await Auth.confirmSignIn(authUser, code, "SOFTWARE_TOKEN_MFA");
  //     // await initializeData(authUser, "signin");
  //     // await getUserData();
  //     setInitializedData((prev) => ({ ...prev, codeSent: false }));
  //     return true;
  //   } catch (error) {
  //     console.error("Error confirming sign in: ", error);
  //     // showSwalErrorMessage({
  //     //   text: "There was a problem confirming your sign in, please try again. If this issue persists, please contact the administrator.",
  //     //   timer: 10000,
  //     // });
  //   }
  // };

  //region CONFIRM SIGNIN
  const confirmSignIn = async (code) => {
    if (code === initializedData?.auth2Fa?.successOTP) {
      let SuccessImg = document.createElement("img");
      SuccessImg.src = LoginImage;

      const newSession = {
        sessionId: v4(),
        expirationDate: dayjsTZ().add(30, "day").valueOf(),
        isAuthenticated: true,
      };
      const active2FaSessions = [
        ...(initializedData?.user?.auth2Fa?.active2FaSessions || []),
        { ...newSession },
      ];

      const toSend = {
        is2FaActive: true,
        last2FAuthed: dayjsTZ().format(),
        active2FaSessions,
      };

      const lastLoggedIn = dayjsTZ().valueOf();
      await API.put("users", `/users/${initializedData?.user?.identityId}`, {
        body: {
          lastLoggedIn,
          auth2Fa: { ...toSend },
        },
      }).then(async () => {
        swal({
          title: `Identifikimi u krye me sukses! ${"    "}
                      Mirë se erdhe, ${initializedData?.user?.given_name} ${
            initializedData?.user?.family_name
          }! `,
          icon: "success",
          content: SuccessImg,
          className: initializedData?.user?.isDarkMode
            ? "custum-swal-loginSuccess-dark"
            : "custum-swal-loginSuccess",
        });
        generateToken("2FaSessionToken", newSession.sessionId);
        navigate("/kryefaqja");

        setInitializedData((prev) => ({
          ...prev,
          auth2Fa: { ...prev?.auth2Fa, ...toSend },
        }));

        await userSession.open(initializedData?.user, lastLoggedIn);
      });
    } else {
      message.error({
        content: "Kodi i vendosur nuk eshte i sakte!",
        key: "login",
      });
    }
  };

  //region UPDATE /users
  const updateUserConfig = async (body, id) => {
    // flex us-east-1:b292bd41-8007-c722-d0e9-021c0e2f4573
    // lona us-east-1:b692b4b7-8931-482b-bc61-e9f4f4393f26
    // aldo us-east-1:b292bd41-8048-c949-6b83-b644c6d05227
    if (!!id || !!initializedData?.user?.identityId) {
      await API.put(
        "users",
        `/users/${id || initializedData?.user?.identityId}`,
        {
          // await API.put(
          //   "users",
          //   "/users/us-east-1:7f8848cf-210f-46fd-b206-7a06023a0058",
          //   {
          body: {
            ...body,
          },
        }
      )
        .then(() => {})
        .catch((err) => console.log("Error: ", err));
    }
  };

  const value = useMemo(
    () => ({
      signin,
      signout,
      onRefresh,
      is2FaActive,
      sendNewCode,
      confirmSignIn,
      isAuthenticated,
      redirectToLogin,
      updateUserConfig,
      updateAuthCtx: setInitializedData,
      ...(initializedData || {}),
    }),
    [initializedData]
  );

  //on MFA
  // useEffect(() => {
  //   checkUser();
  //   checkAuthSession();
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   updateUserConfig(
  //     { auth2Fa: { is2FaActive: true } },
  //     "us-east-1:b292bd41-8007-c722-d0e9-021c0e2f4573"
  //   );
  // }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  return useContext(AuthContext);
};
/**
 * @returns instance of auth status
 */
export const AuthStatus = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isDarkMode } = useSelector((state) => state.usersList);

  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  useEffect(() => {
    if (!!Object.keys(auth || {}).length && !!auth?.userAccess?.length) {
      setData(auth.userAccess);
      setLoading(false);
    }
  }, [auth]);

  // const specifikatRoute = {
  //   auth: true,
  //   exact: false,
  //   path: "punonjesit/specifikat/:employeeId",
  //   title: "Specifikat Punonjesit",
  //   write: true,
  // };

  // if (loading === false && data.length > 0) {
  //   //&& location.pathname.startsWith("/konfigurimet") === false
  //   //&& auth.userAccess[0].userRole !== "Admin"
  //   if (location.pathname !== null) {
  //     let filter =
  //       data[0].accessConfig?.routeConfig
  //         .concat([specifikatRoute])
  //         ?.filter((el) => {
  //           return (
  //             (el.path === location.pathname ||
  //               el.path.includes("punonjesit/specifikat/")) &&
  //             el?.auth !== false
  //           );
  //         }) || [];
  //     if (filter.length === 0) {
  //       return <PageNotFound />;
  //     }
  //   }
  // }
  // if (!auth.user) {
  //   return <Login />;
  // }

  useEffect(() => {
    const lsTheme = JSON.parse(localStorage.getItem("isDarkMode"));
    if (lsTheme !== null) {
      dispatch(editTheme(lsTheme));
    }
  }, []);

  return (
    <LoaderComponent loading={loading}>
      {/* <AutumnLeaves /> */}

      <div>
        <Layout>
          <Sider
            style={{
              position: "sticky",
              zIndex: 1,
              ...(isDarkMode ? { background: "#121212" } : {}),
            }}
            width="270px"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            breakpoint={"xl"}
          >
            {/* winter */}
            {/* {new Array(100).fill(<div className="snowflake" />).map((el) => el)} */}
            {location.pathname.startsWith("/konfigurimet") === true ? (
              <KonfigurimetMenu data={data} />
            ) : (
              <Sidebar data={data} collapsed={collapsed} />
            )}
          </Sider>
          <Layout>
            <Header
              style={{
                position: "sticky",
                zIndex: 1,
                width: "100%",
                ...(isDarkMode ? { background: "#121212" } : {}),
              }}
            >
              <Hader />
            </Header>
            <Content style={{ width: "100%", height: "calc(100dvh - 70px)" }}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>
    </LoaderComponent>
  );
};
