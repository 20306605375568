import { Tooltip } from "antd";
import { IconExcel } from "../../../../assets/icons";
import * as XLSX from "xlsx";
import { valueFormatter } from "../utils/valueFormatter";

const AgGridToExcel = ({
  gridApi,
  rowData,
  title,
  tableCols,
  modifyRows,
  className,
  style,
  idKey,
}) => {
  //if export certain cols
  const handleClick = () => {
    let filteredRows = [];
    if (!!gridApi) {
      const selectedNodes = gridApi
        .getSelectedNodes()
        .map((node) => node.data?.[idKey]);
      gridApi.forEachNodeAfterFilter((node) => {
        if (!!selectedNodes?.length) {
          if (selectedNodes.includes(node.data?.[idKey]))
            filteredRows.push(node.data);
        } else {
          filteredRows.push(node.data);
        }
      });
    } else if (!!rowData && Array.isArray(rowData)) {
      filteredRows = [...rowData];
    }
    if (!filteredRows || filteredRows.length === 0) {
      return;
    }

    if (!!modifyRows && typeof modifyRows === "function") {
      filteredRows = modifyRows(filteredRows);
    }

    const rows = [];
    filteredRows?.forEach((row) => {
      let tableRow = {};
      tableCols?.forEach((col) => {
        let value = row[col?.en || col];
        let formattedValue = valueFormatter(col, value, false);
        tableRow = { ...tableRow, [col?.al || col]: formattedValue };
      });
      rows.push(tableRow);
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);

    // worksheet["!cols"] = excelCellFitToColumn([rows]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, title);

    // Save the workbook to a file
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };
  return (
    <div
      className={`headerIcon ${className}`}
      data-testid="headerExcel"
      onClick={handleClick}
      style={style}
    >
      <Tooltip
        placement="top"
        classNames={{ root: "global-icon-tooltip" }}
        title={"Eksporto në Excel"}
      >
        <IconExcel width={20} height={20} fill={style?.fill || "#323338"} />
      </Tooltip>
    </div>
  );
};

export default AgGridToExcel;
