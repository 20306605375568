import React from "react";
import { Drawer, List, Typography, Divider } from "antd";
import {
  ClearIconDrawer,
  HiddenEye,
  ModalXIcon,
  VisibleIcon,
} from "../../../../../assets/icons";
import "./HiddenDrawer.scss";
import { InfoIcon } from "../../../components/notifications/notificationList/filterHeader/src";
import { useSelector } from "react-redux";

function HiddenDrawer({
  setHiddenDrawer,
  hiddenDrawerVisib,
  drawerContent,
  onUnhide,
}) {
  const { isDarkMode } = useSelector((state) => state.usersList);
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];
  return (
    <Drawer
      title="Kartat e fshehura"
      placement="right"
      onClose={() => setHiddenDrawer(false)}
      open={hiddenDrawerVisib}
      closeIcon={<ClearIconDrawer fill={isDarkMode ? "#e6e6e6" : "#1d3445"} />}
      className={`hidden-cards-drawer ${isDarkMode ? "dark darkDrawer" : ""}`}
      width={550}
    >
      <List
        size="small"
        locale={{ emptyText: "Nuk ka karta te fshehura" }}
        header={
          <span>
            <InfoIcon fill={isDarkMode ? "#e6e6e6" : "#1da193"} /> Klikoni
            butonat në cepin e djathtë për të rishfaqur kartat e fshehura....
          </span>
        }
        bordered
        dataSource={
          drawerContent !== null
            ? drawerContent?.filter((el) => el?.data?.hidden === true)
            : null
        }
        renderItem={(item) => {
          return (
            <List.Item>
              <div className="card-item">
                {item?.data?.emri}
                <span className="card-icon" onClick={() => onUnhide(item?.i)}>
                  <VisibleIcon height={16} width={16} />
                </span>
              </div>
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
}

export default HiddenDrawer;
