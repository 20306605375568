import React from "react";
import { Row, Col } from "antd";
import { BirthdayCake } from "../../../../../../assets/images";
import { BirthdayCakeIcon } from "../../../../../../assets/icons";
import "./ditlindje.scss";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import { BirthdayCalendar } from "..";
import { useSelector } from "react-redux";
import NextDitlindje from "./NextDitlindje";
import { useMediaQuery } from "react-responsive";
import { HollowDotsSpinner } from "react-epic-spinners";
import ReturnImg from "../../../../../../utils/ReturnImg";
import DitlindjeMobile from "./DitlindjeMobile";

function Ditlindje() {
  // get active employes from redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { isDarkMode } = useSelector((state) => state.usersList);
  // get current date
  let date = new Date();
  let longYear = date?.toLocaleString("en-us", { year: "numeric" });
  let longMonth = date?.toLocaleString("en-us", { month: "2-digit" });
  let momentData = dayjsTZ().add(1, "months").format("MM");

  // get created at field form emplyes and filter the employes with current month birthday return array
  const renderdBirthdays = activeEmployees?.filter((data) => {
    if (!!data?.employeeDayOfBirth) {
      return dayjsTZ(data?.employeeDayOfBirth).format("MM") === longMonth;
    } else {
      return "";
    }
  });

  // get created at field form emplyes and filter the employes with next month birthday return array
  const nextRenderdBirthdays = activeEmployees?.filter((data) => {
    if (!!data?.employeeDayOfBirth) {
      return dayjsTZ(data?.employeeDayOfBirth).format("MM") === momentData;
    } else {
      return "";
    }
  });

  // sort the birthdays by date
  const filterFoter = renderdBirthdays?.sort(
    (a, b) => b?.employeeDayOfBirth - a?.employeeDayOfBirth
  );
  // time now to calculate the age
  const now = dayjsTZ().add(2, "M");
  function compare(a, b) {
    if (
      dayjsTZ(a.employeeDayOfBirth).format("DD") <
      dayjsTZ(b.employeeDayOfBirth).format("DD")
    ) {
      return -1;
    }
    if (
      dayjsTZ(a.employeeDayOfBirth).format("DD") >
      dayjsTZ(b.employeeDayOfBirth).format("DD")
    ) {
      return 1;
    }
    return 0;
  }

  //for screen smaller than 825 return boolean(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 825px)",
  });

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return !!activeEmployees ? (
    isDesktopOrLaptop ? (
      <Row className={`ditlindje-row ${isDarkMode ? "dark" : ""}`}>
        {/* return JSX first container list of employes with birthday event */}
        <Col span={6}>
          <Row style={{ width: "100%", display: "flex" }}>
            <Col flex="auto">
              <div className="ditlindjet-container">
                {renderdBirthdays?.length > 0 ? (
                  <div className="ditlindjet-header">
                    {dayjsTZ(renderdBirthdays[0]?.employeeDayOfBirth).format(
                      "MMMM"
                    )}{" "}
                    {longYear} - {renderdBirthdays?.length} punonjës
                  </div>
                ) : (
                  <div className="ditlindjet-header">
                    Në këtë muaj nuk ka asnjë ditëlindje
                  </div>
                )}
                <div className="ditlindjet-body">
                  {filterFoter?.sort(compare).map((Data) => (
                    <div
                      key={Data?.employeeId}
                      className="ditlindjet-card-content"
                    >
                      {
                        <ReturnImg
                          height={35}
                          width={35}
                          borderRadius={50}
                          googleDriveFileId={Data?.googleDriveFileId}
                          defaultImg={<BirthdayCakeIcon />}
                        />
                      }
                      &nbsp;
                      <div className="ditlindjet-card-info">
                        {Data?.employeeFirstName}&nbsp;&nbsp;
                        {Data?.employeeLastName} -{" "}
                        {dayjsTZ(Data?.employeeDayOfBirth).format("DD/MM/YYYY")}
                        <div className="bold">
                          (
                          {moment
                            .duration(now.diff(Data?.employeeDayOfBirth))
                            .years()}{" "}
                          Vjec )
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col flex="10px">
              <div className="line"></div>
            </Col>
          </Row>
        </Col>
        {/* return JSX second container calendar current month */}
        <Col span={6} className="calendar-col">
          <Row style={{ width: "100%", display: "flex" }}>
            <Col flex="450" style={{ marginRight: 20 }}>
              <div className="calendar-container">
                <BirthdayCalendar renderdBirthdays={renderdBirthdays} />
              </div>
            </Col>
            <Col flex="10px">
              <div className="line"></div>
            </Col>
          </Row>
        </Col>
        {/* return JSX third container calendar next month */}
        <Col span={6}>
          <NextDitlindje {...{ nextRenderdBirthdays }} />
        </Col>
        {/* return JSX fourth container list of employes with birthday event*/}
        <Col span={6}>
          <div className="ditlindjet-container">
            {nextRenderdBirthdays?.length > 0 ? (
              <div className="ditlindjet-header">
                {dayjsTZ(nextRenderdBirthdays[0]?.employeeDayOfBirth).format(
                  "MMMM"
                )}{" "}
                {longYear} - {nextRenderdBirthdays?.length} punonjës
              </div>
            ) : (
              <div className="ditlindjet-header">
                Në {dayjsTZ().add(1, "months").format("MMMM")} nuk ka asnjë
                ditëlindje
              </div>
            )}
            <div className="ditlindjet-body">
              {nextRenderdBirthdays?.sort(compare).map((Data) => (
                <div key={Data?.employeeId} className="ditlindjet-card-content">
                  {
                    <ReturnImg
                      height={35}
                      width={35}
                      googleDriveFileId={Data?.googleDriveFileId}
                      borderRadius={50}
                      defaultImg={<BirthdayCakeIcon />}
                    />
                  }
                  &nbsp;
                  <div className="ditlindjet-card-info">
                    {Data?.employeeFirstName}&nbsp;&nbsp;
                    {Data?.employeeLastName} -{" "}
                    {dayjsTZ(Data?.employeeDayOfBirth).format("DD/MM/YYYY")}
                    <div className="bold">
                      (
                      {moment
                        .duration(now.diff(Data?.employeeDayOfBirth))
                        .years()}{" "}
                      Vjec )
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    ) : (
      <DitlindjeMobile
        renderdBirthdays={renderdBirthdays}
        longYear={longYear}
        filterFoter={filterFoter}
      />
    )
  ) : (
    <HollowDotsSpinner
      color="#1da193"
      size={24}
      style={{ position: "relative", top: "50%", left: "45%" }}
    />
  );
}
export default Ditlindje;
