import React, { useEffect, useMemo, useState } from "react";
import { ClockInIcon, ClockOutIcon } from "../../assets";
import { Input, Popconfirm, TimePicker } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import {
  getDisabledAfterHours,
  getDisabledAfterMinutes,
} from "../../utils/disabledTimeFilters";
import ClockOutModal from "./clockOutModal/ClockOutModal";
import { HollowDotsSpinner } from "react-epic-spinners";
import { ConfirmIcon, HomeIcon } from "../../../../assets/icons";
import { HomeOutlined } from "@ant-design/icons";
import Clock from "react-live-clock";
import { Ice1, Ice2 } from "../../../../assets/holidays/icons";
import { eventTracker } from "../../../../utils/eventTracker";

const { TextArea } = Input;
// const iceStates = {
//   0: null,
//   1: <Ice1 height={65} />,
//   2: <Ice2 height={75} />,
// };

function ClockOutActive({
  time,
  editedTime,
  apiData,
  customTime,
  autoSuggestion,
  onCustomEndTime,
  setCustomTime,
  notes,
  onClockOut,
  onShiftNote,
  setLoadingColor,
  loadShiftData,
  resetValues,
  setNewShift,
  shiftConfiguration,
}) {
  const [clockOutVisible, setClockOutVisible] = useState(false);
  const [barWidth, setBarWidth] = useState(100);
  const [barStart, setBarStart] = useState(false);
  // const [startTime, setStartTime] = useState(
  //   apiData[apiData.length - 1]?.clockInDate
  // );
  const [lastShift, setLastShift] = useState(
    apiData[apiData.length - 1] || null
  );
  const [loading, setLoading] = useState(false);
  // const [iceState, setIceState] = useState(2);

  const handleClockOut = () => {
    // if (iceState > 0) {
    // setIceState((prev) => prev - 1);
    // } else {
    eventTracker("Clock Out", "click", "Opened clockout modal");
    setClockOutVisible(true);
    // }
  };

  useEffect(() => {
    let interval;
    if (!loading && barStart) {
      interval = setInterval(() => {
        setBarWidth((prev) => prev - 0.25);
      }, 10);
    }
    if (barWidth < 1) {
      setNewShift(true);
      setBarStart(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [loading, barWidth, barStart]);

  return (
    <>
      {loading === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "250px",
          }}
        >
          <HollowDotsSpinner color={"red"} size={24} />
        </div>
      ) : (
        <div className="clock-out-active">
          <div className="time-indicator">
            {/* <h4 style={{ color: "#ea3943" }}>{dayjsTZ(time).format("HH:mm")}</h4> */}
            <h4 style={{ color: "#ea3943" }}>
              {" "}
              {/* <Clock
                format={"HH:mm"}
                ticking={true}
                timezone={"Europe/Tirane"}
              /> */}
            </h4>
          </div>
          {!(lastShift?.clockOutDate === null && loading === false) && (
            <ConfirmIcon style={{ marginBottom: "10px" }} />
          )}
          {lastShift?.clockOutDate === null && loading === false && (
            <div
              className="clock-in-info"
              style={{
                // marginTop: editedTime ? "-10px" : "10px",
                justifyContent: "center",
              }}
            >
              Turni ka filluar në orën
              <strong>
                {dayjsTZ(apiData[apiData.length - 1]?.clockInDate).format(
                  "HH:mm"
                )}
                !
              </strong>
            </div>
          )}
          {/* <i className="relativeTime">

({dayjsTZ(apiData[apiData.length - 1]?.clockInDate).fromNow()})
</i> */}
          {lastShift?.clockOutDate === null && loading === false ? (
            <div className="clock-out-action">
              <div className="clock-out">
                {/* <ClockOutIcon className="clock-out-icon" /> */}
                {/* <Popconfirm
							title={`A jeni të sigurt që dëshironi të përfundoni turnin në këtë orar?`}
							onConfirm={onClockOut}
							// onCancel={cancel}
							disabled={
								dayjsTZ().diff(dayjsTZ(lastShift?.clockInDate), "minutes", true) < 5 ? true : false
							}
							okText="Po, përfundo."
							cancelText="Jo, anullo."
						> */}
                <button
                  className="clock-out-btn"
                  // style={{
                  //   ...(iceState === 1
                  //     ? { background: "#9b4044" }
                  //     : iceState === 2
                  //     ? { background: "#4e5677" }
                  //     : {}),
                  // }}
                  onClick={handleClockOut}
                  // style={
                  // 	dayjsTZ().diff(dayjsTZ(lastShift?.clockInDate), "minutes", true) < 5
                  // 		? { cursor: "not-allowed" }
                  // 		: null
                  // }
                >
                  {/* {iceStates[iceState]} */}
                  Mbaro Turnin
                </button>

                <div className="start-shift-cont">
                  {/* <span>
										<TimePicker
											className="timepick"
											defaultValue={time}
											value={customTime ? null : editedTime !== null ? editedTime : autoSuggestion}
											format={"HH:mm"}
											allowClear={false}
											onChange={onCustomEndTime}
											onOpenChange={(boolVal) => setCustomTime(boolVal)}
											disabledHours={getDisabledAfterHours}
											disabledMinutes={getDisabledAfterMinutes}
											disabled={
												dayjsTZ(apiData[apiData.length - 1]?.clockInDate).format("DD/MM/YYYY") ===
												dayjsTZ().format("DD/MM/YYYY")
													? false
													: true
											}
										/>
									</span> */}
                </div>
              </div>
              <div
                style={{
                  width: "160px",
                  backgroundColor: "#1d3943",
                  marginTop: "10px",
                  height: "30px",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Ora Tani:{" "}
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/Tirane"}
                />
              </div>
              {editedTime && (
                <span className="warning-clock-out-edited">
                  Orari i mbarimit të turnit është ndryshuar.
                </span>
              )}
              {!editedTime && <span>Punë të mbarë!</span>}

              {editedTime && (
                <div
                  className="justify-note-wrapper"
                  style={{ marginTop: "-5px" }}
                >
                  <TextArea
                    // autoSize={false}
                    value={notes?.clockOutNote}
                    onChange={(e) => onShiftNote(e, "clockOutNote")}
                    classname="justify-note"
                    placeholder="Shkruaj një shënim këtu..."
                    autoSize={{ minRows: 2, maxRows: 2 }}
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="clock-out-action-finish">
                <div className="clock-out-info">
                  {/* <ClockOutIcon className="clock-out-icon" /> */}
                  <span>
                    {" "}
                    Turni u mbyll në orën{" "}
                    <strong>
                      {/* {apiData[apiData.length - 1]?.clockOutDate !== null
                        ? dayjsTZ(
                            apiData[apiData.length - 1]?.clockOutDate
                          ).format("HH:mm")
                        : "--:--"} */}
                      {lastShift?.clockOutDate !== null
                        ? dayjsTZ(lastShift?.clockOutDate).format("HH:mm")
                        : "--:--"}
                      !
                    </strong>
                  </span>
                </div>
                <h1>
                  Mirupafshim!
                  {/* <HomeOutlined style={{ color: "#FCC94A" }} /> */}
                </h1>
              </div>
              <span className="clockoutBar" style={{ width: `${barWidth}%` }} />
            </>
          )}
        </div>
      )}
      <ClockOutModal
        {...{
          clockOutVisible,
          setClockOutVisible,
          lastShift,
          apiData,
          setLoading,
          setLoadingColor,
          loadShiftData,
          resetValues,
          setNewShift,
          setLastShift,
          setBarStart,
          shiftConfiguration,
        }}
      />
    </>
  );
}

export default ClockOutActive;
