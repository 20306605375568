import { DatePicker, Input, Select } from "antd";
import dayjsTZ from "../../../../utils/dayjs";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { FilterIconButton } from "../../../../assets/icons";
import "./DrawerFilter.scss";
import { useSelector } from "react-redux";
const { Option } = Select;

const DrawerFilter = ({
  activeFilter,
  setActiveFilter,
  onFinish,
  filterKeys,
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <div className={`drawerFilterContainer ${isDarkMode ? "dark" : ""}`}>
      <div className="filterSelectors">
        {(filterKeys?.input || []).map((el) => (
          <Input
            placeholder="Kërko..."
            allowClear
            value={activeFilter?.[el]}
            onChange={(e) =>
              setActiveFilter((prev) => ({
                ...(prev || {}),
                [el]: e.target.value,
              }))
            }
          />
        ))}
        {(filterKeys?.date || []).map((el) => (
          <DatePicker
            allowClear
            popupClassName={isDarkMode ? "darkDropdown" : ""}
            placeholder="Zgjidh datën"
            value={!!activeFilter?.[el] ? dayjsTZ(activeFilter?.[el]) : null}
            onChange={(val) =>
              setActiveFilter((prev) => ({
                ...(prev || {}),
                [el]: !!val ? dayjsTZ(val).valueOf() : null,
              }))
            }
          />
        ))}
        {Object.entries(filterKeys?.select || {}).map(([el, options]) => (
          <Select
            placeholder="Zgjidh opsion"
            popupClassName={isDarkMode ? "darkDropdown" : ""}
            value={activeFilter?.[el]}
            onChange={(val) =>
              setActiveFilter((prev) => ({
                ...(prev || {}),
                [el]: val,
              }))
            }
          >
            {(options || []).map((option, i) => (
              <Option key={i} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        ))}
      </div>
      {!!onFinish ? (
        <MondayButton className="mondayButtonGreen" onClick={onFinish}>
          Filtro
        </MondayButton>
      ) : null}
      <MondayButton
        className="mondayButtonRed"
        hasIcon={true}
        Icon={<FilterIconButton />}
        onClick={() => setActiveFilter({})}
      />
    </div>
  );
};

export default DrawerFilter;
