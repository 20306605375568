import dayjsTZ from "../../../utils/dayjs";

export const colDefs = [
  {
    headerName: "Clocking Id",
    field: "clockingId",
    hide: true,
  },
  {
    headerName: "Fillimi",
    field: "fillim_turni",
    flex: 3,
    mobile: true,
    filter: "agTextColumnFilter",
    format: "HH:mm",
    valueGetter: ({ data }) => dayjsTZ(data?.fillim_turni).format("HH:mm"),
    cellRenderer: ({ data }) => dayjsTZ(data?.fillim_turni).format("HH:mm"),
  },
  {
    headerName: "Mbarimi",
    field: "mbarim_turni",
    flex: 3,
    mobile: true,
    filter: "agTextColumnFilter",
    format: "HH:mm",
    valueGetter: ({ data }) =>
      !!data?.mbarim_turni
        ? dayjsTZ(data?.mbarim_turni).format("HH:mm")
        : "I pacaktuar",
    cellRenderer: ({ data }) =>
      !!data?.mbarim_turni ? (
        dayjsTZ(data?.mbarim_turni).format("HH:mm")
      ) : (
        <strong style={{ color: "red" }}>I pacaktuar</strong>
      ),
  },
  {
    headerName: "Data",
    field: "clockInDate",
    flex: 3,
    filter: "agTextColumnFilter",
    mobile: true,
    format: "DD/MM/YYYY",
    isCollapseTitle: true,
    valueGetter: ({ data }) => dayjsTZ(data?.clockInDate).format("DD/MM/YYYY"),
  },
  {
    headerName: "Statusi",
    field: "approved",
    flex: 3,
    mobile: true,
    filter: "agTextColumnFilter",
    valueGetter: ({ data }) => {
      if (data?.approved === true) {
        return "Aprovuar";
      } else {
        return "Pa Aprovuar";
      }
    },
    cellRenderer: ({ data }) => renderStatus(data?.approved),
  },
  {
    headerName: "Kohëzgjatja",
    field: "duration",
    flex: 3,
    filter: "agTextColumnFilter",
    mobile: true,
    valueGetter: ({ data }) => data?.duration,
  },
];

//region >renderStatus()
const renderStatus = (val) => {
  const statuses = {
    true: {
      background: "#1da193",
      label: "Aprovuar",
    },
    false: {
      background: "#ea3943",
      label: "Pa Aprovuar",
    },
    default: {
      background: "grey",
      label: "Pa Përcaktuar",
    },
  };

  const { background, label } = statuses?.[val] || statuses.default;
  return (
    <div className="status-column">
      <span style={{ background }}>{label}</span>
    </div>
  );
};
