import React, { useState, useEffect, useMemo } from "react";
import { API } from "aws-amplify";
import { Button, Card, Drawer, Form, Col, Row } from "antd";
import { Locked, ModalXIcon, UnLocked } from "../../assets/icons";
import NewDepartmentForm from "../NewDepartment/NewDepartmentForm";
import "./Departmentcard.scss";
import DepartmentCardView from "./DepartmentCardView";
import { v4 } from "uuid";
import CancelAddEmployeeModalPopover from "../../components/punonjesit/AddEmployeeModal/Popover";
import { useDispatch, useSelector } from "react-redux";
import DragContainer from "../../components/dashboard/AdminDashboard/Draggable/DragContainer";
import EditDepartment from "./EditDepartment";
import { AddDepartmentIcon, GreenAddDepartmentIcon } from "../assets";
import LoadableComp from "../../components/commonComponents/LoadableComp/LoadableComp";
import { requestData } from "../../helpers/API/RequestFactory";
import { getSocketUrl } from "../../utils/websocketConfig";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import { notificationUserToAdmin } from "../../utils/sendNotification";
import { useAuth } from "../../authentication/authHelpers";
import MondayButton from "../../components/commonComponents/MondayButton/MondayButton";

// * @EneaXharau - Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/departments/sockets"));

const DepartmentetCard = () => {
  const { departments } = useSelector((state) => state.departments);
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const [depData, setDepData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  //* state to make card draggable
  const [toggle, setToggle] = useState(false);
  const [form] = Form.useForm();
  const [showEditCard, setShowEditCard] = useState(false);
  const [toBeEdited, setToBeEdited] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const userData = useAuth();

  //* opens the card where you can edit that specific department
  const showEditView = (departmentId) => {
    setShowEditCard(true);
    setToBeEdited(departmentId);
  };

  //* closes the card that you are editing
  const hideEditView = (departmentId) => {
    setShowEditCard(false);
    if (departmentId === toBeEdited) {
      setToBeEdited(null);
    }
  };

  //* saves departments
  const onFinish = async (values) => {
    const { given_name, family_name } = user.find(
      ({ identityId }) => identityId === values.departmentManagerId
    );
    //* shows departmentName departmentRole and departmentManagerName in departmentCard
    await API.post("departments", "/departments", {
      body: {
        departmentName: values.departmentName,
        departmentRoles: values.roles.map((r) => {
          return { name: r, id: v4() };
        }),
        departmentManagerId: values.departmentManagerId,
        departmentManagerName: `${given_name} ${family_name}`,
      },
    }).then((res) => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "department_added",
      // 			message: "A new department was added.",
      // 		})
      // 	);
      // }
      setDepData((prev) => [...prev, res]);
      setVisible(false);
      window.localStorage.removeItem("draggableOrderDepartments");
      window.localStorage.removeItem("draggableSetingsPozicionet");
      form.resetFields();
      notificationUserToAdmin(
        {
          notificationTitle: "U krijua një departament i ri",
          createdAt: dayjsTZ().format("DD/MM/YYYY"),
          notificationPriority: false,
          notificationCategory: "Info",
          notificationBody: `${userData?.userAccess[0]?.given_name} ${userData?.userAccess[0]?.family_name}, krijoi një departament të ri, ${values?.departmentName}.`,
          isAnnouncement: false,
          notificationUrl: "/departamentet",
          notificationFirstName: userData?.userAccess[0]?.given_name,
          notificationLastName: userData?.userAccess[0]?.family_name,
        },
        users?.allUsers?.Items
      );
    });
  };

  //* opens the drawer where you can add a new department
  const showDrawer = () => {
    setVisible(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  //* closes the drawer
  const close = () => {
    setVisible(false);
  };

  /**
   * * @EneaXharau - Async function taking no parameters
   * * it awaits for redux to fetch data from AWS database
   * * then sets in state which also re-renders component to display new info
   */
  const refreshRequests = async () => {
    return await requestData("departments").then((res) =>
      dispatch(setDepData(res))
    );
  };

  // * @EneaXharau - Added departments dependency to update on change
  useEffect(() => {
    if (!!departments) {
      setTimeout(() => {
        setDepData(departments);
        setLoading(false);
      }, 70);
    }
  }, [departments]);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "department_added_received": {
  // 				refreshRequests();
  // 				break;
  // 			}
  // 			case "department_deleted_received": {
  // 				refreshRequests();
  // 				break;
  // 			}
  // 			case "department_change_received": {
  // 				refreshRequests();
  // 				break;
  // 			}
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  const newDepCard = (
    <Card overlayClassName="add-card">
      <div className="addDepartment">
        <GreenAddDepartmentIcon
          className="addDepartmentIcon"
          width={73}
          height={73}
        />
        <span className="main-text">
          Këtu ti mund të krijosh një departament të ri.
        </span>
        <div>
          <MondayButton className="mondayButtonGreen" onClick={showDrawer}>
            Shto Departament
          </MondayButton>
        </div>
      </div>
    </Card>
  );

  return (
    <LoadableComp loading={!!depData ? false : true}>
      <div className={`departmentsWrapper ${isDarkMode ? "dark" : ""}`}>
        <div className="background-toggle">
          <div
            className={toggle === true ? "Border-toggle" : "Border-toggle-on"}
          >
            <div className="switch-toggle" onClick={() => setToggle(!toggle)}>
              {toggle === true ? (
                <div className="off-div">
                  <div className="des">On</div>
                  <div className="logo">
                    <UnLocked />
                  </div>
                </div>
              ) : (
                <div className="on-div">
                  <div className="logo">
                    <Locked />
                  </div>
                  <div className="des">Off</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="globalPageWrapper">
          {toggle === true ? (
            <DragContainer
              view={"departments"}
              dynamicMap={depData}
              floatingHeader={true}
            />
          ) : (
            <div className="viewMode">
              {newDepCard}
              {(depData || []).map((department) =>
                toBeEdited === department?.departmentId ? (
                  <EditDepartment
                    department={department}
                    hideEditView={hideEditView}
                    setDepData={setDepData}
                    refreshRequests={refreshRequests}
                    socketHandler={() => {}}

                    // socketHandler={socketHandler}
                  />
                ) : (
                  <DepartmentCardView
                    showEditView={showEditView}
                    setDepData={setDepData}
                    department={department}
                    socketHandler={() => {}}

                    // socketHandler={socketHandler}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
      <Drawer
        className="departmentDrawer"
        title="Po krijon një departament të ri"
        placement="right"
        onClose={() => {
          setPopoverVisible(true);
        }}
        width={500}
        closeIcon={<ModalXIcon />}
        maskClosable={false}
        open={visible}
        size="default"
      >
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <NewDepartmentForm getUsersList={(e) => setUser(e)} />
          <div className="drawer-btn">
            <CancelAddEmployeeModalPopover
              title="Ti nuk ke përfunduar,a je i sigurt që dëshiron të largohesh nga krijimi i një departamenti?"
              form={form}
              classNames="departmentpopover"
              visible={popoverVisible}
              setVisible={setPopoverVisible}
              setModalVisible={setVisible}
              modalVisible={visible}
              onClose={close}
            />
            <MondayButton
              onClick={() => setPopoverVisible(true)}
              className="mondayButtonRed"
            >
              Anulo
            </MondayButton>
            <Button
              className="save-btn"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    onFinish(form.getFieldsValue());
                  })
                  .catch((e) => {
                    onFinishFailed(e);
                  });
              }}
            >
              Ruaj ndryshimet
            </Button>
          </div>
        </Form>
      </Drawer>
    </LoadableComp>
  );
};
export default DepartmentetCard;
