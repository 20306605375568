import { Modal, message, Input, Alert } from "antd";
import { API } from "aws-amplify";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./DetailedFestaModal.scss";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import AgGridComponent from "../../../../AG-grid/AgGridComponent";
import { columnDefs } from "./colDefs";
import { programFields as programFieldsReducer } from "../../../../../store/actions";

const DetailedFestaModal = ({ singleFesta, initialFestat, setSingleFesta }) => {
  const dispatch = useDispatch();

  const { departments } = useSelector((state) => state.departments);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [deps, setDeps] = useState([]);
  const [spin, setSpin] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  //region DEPARTMENT
  const onDepartmentSearch = (e) => {
    const value = !!e ? e.target.value : "";
    if (!!value) {
      const filteredDeps = (departments || []).filter((dep) =>
        (dep?.departmentName || "").toLowerCase().includes(value.toLowerCase())
      );
      setDeps(filteredDeps);
    } else {
      setDeps(departments);
    }
  };
  const onDepartmentSelect = (departmentId) => {
    const { selected, unselected } = getNodeTypes();

    setSelectedIds((prev) => getSelected(prev, selected, unselected));
    setSelectedDepartment(departmentId);
  };

  //region NODES
  //get node types from current department
  const getNodeTypes = () => {
    const selected = [];
    const unselected = [];
    gridApi?.forEachNode((node) => {
      if (node.isSelected()) {
        selected.push(node.id);
      } else {
        unselected.push(node.id);
      }
    });
    return { selected, unselected };
  };
  const getSelected = (prev, add, remove) => {
    const allSelected = [...new Set([...(prev || []), ...(add || [])])];
    return allSelected.filter((el) => !remove.includes(el));
  };

  //region SAVE
  const onSave = async () => {
    setSpin(true);
    const { selected, unselected } = getNodeTypes();
    //all nodes excluding the just unselected ones
    const users = getSelected(selectedIds, selected, unselected);
    const toSend = {
      fieldOptions: [
        ...initialFestat?.fieldOptions?.filter(
          (el) => el?.id !== singleFesta?.id
        ),
        {
          ...singleFesta,
          users,
        },
      ],
    };
    await API.put("programFields", `/programFields/${initialFestat?.fieldId}`, {
      body: { ...toSend },
    })
      .then(async () => {
        const newProgramFields = [...programFields];
        const fieldIndex = newProgramFields.findIndex(
          ({ fieldId }) => fieldId === initialFestat?.fieldId
        );
        if (fieldIndex > -1) {
          newProgramFields[fieldIndex] = {
            ...newProgramFields[fieldIndex],
            ...toSend,
          };
          dispatch(programFieldsReducer(newProgramFields));
          message.success({ key: "onSave", content: "Te dhënat u ruajtën." });
        }
      })
      .catch((err) => console.log("Error: ", err))
      .finally(() => {
        setSpin(false);
        setSelectedIds([]);
        setSingleFesta({});
      });
  };

  //region content
  const content = useMemo(() => {
    if (!departments || !activeEmployees) return {};
    let toReturn = {};

    departments.forEach((dep) => {
      toReturn[dep?.departmentId] = {
        departmentName: dep?.departmentName,
        rowData: activeEmployees.filter(
          (el) => el?.employeeDepartmentName === dep?.departmentName
        ),
      };
    });
    setDeps(departments);

    return toReturn;
  }, [departments, activeEmployees]);

  const rowData = useMemo(
    () => content?.[selectedDepartment]?.rowData || [],
    [content, selectedDepartment]
  );

  useEffect(() => {
    if (!!gridApi) {
      gridApi.forEachNode((node) => {
        if (selectedIds.includes(node.data.employeeId)) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedDepartment, singleFesta?.users]);

  //on first render
  useEffect(() => {
    if (!!gridApi && !!singleFesta?.users?.length && !selectedIds?.length) {
      gridApi.forEachNode((node) => {
        if (singleFesta.users.includes(node.data.employeeId)) {
          node.setSelected(true);
        }
      });

      setSelectedIds(singleFesta?.users);
    }
  }, [gridApi, singleFesta?.users, selectedIds]);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  //region RETURN
  return (
    <Modal
      className={`detailedFestaModal ${
        isDarkMode ? "dark darkHeader" : "lightHeader"
      }`}
      centered
      open={Object.keys(singleFesta || {}).length}
      onCancel={() => setSingleFesta({})}
      destroyOnClose
      title={singleFesta?.title + " - " + singleFesta?.date}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => setSingleFesta({})}
        >
          Mbyll Faqen
        </MondayButton>,
        <MondayButton className="mondayButtonGreen" onClick={onSave}>
          Ruaj të dhënat
        </MondayButton>,
      ]}
    >
      <div className="festatContent">
        <div className="depsContent">
          <div className="inputSearch">
            <Input
              placeholder="Kërko departamentin..."
              onChange={onDepartmentSearch}
            />
          </div>
          <div className="allDeps">
            {(deps || []).map((dep) => {
              return (
                <div
                  className={`department-row ${
                    selectedDepartment === dep?.departmentId ? "selected" : ""
                  }`}
                  onClick={() => onDepartmentSelect(dep?.departmentId)}
                >
                  <span>{dep?.departmentName}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          {!!selectedDepartment ? (
            <AgGridComponent
              {...{
                gridApi,
                rowData,
                columnDefs,
                onGridReady,
                rowSelection: {
                  mode: "multiRow",
                  enableClickSelection: true,
                },
                rowMultiSelectWithClick: true,
                suppressCellSelection: true,
                animateRows: true,
                defaultColDef: {
                  flex: 1,
                  minWidth: 100,
                  resizable: true,
                  sortable: true,
                  filter: true,
                },
              }}
              idKey={"employeeId"}
              headerProps={{
                items: { search: {} },
                children: [
                  <Alert
                    message="Selekto punonjësit që do jenë në punë!"
                    type="warning"
                    showIcon
                  />,
                ],
              }}
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
export default DetailedFestaModal;
