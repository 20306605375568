import { QuestionCircleOutlined } from "@ant-design/icons";
import "./ClockInStats.scss";
import {
  CivilEngineeringIcon,
  FinanceIcon,
  FlexDepartmentIcon,
  GraphicDesign,
  HRIcon,
  InternalStaffIcon,
  SalesManagementIcon,
  SchedulingIcon,
  SoftwareDevelopmentIcon,
} from "../../../../../Departments/assets";

//Zgjedh icon sipas emrit te departamentit
export const iconPicker = (depName, fill) => {
  switch (depName) {
    case "IT & Software Development":
      return <SoftwareDevelopmentIcon fill={fill} />;
    case "Graphic Design":
      return <GraphicDesign width={20} height={20} fill={fill} stroke={fill} />;
    case "Finance & Accounting":
      return <FinanceIcon fill={fill} />;
    case "Human Resources":
      return <HRIcon style={{ fontSize: "20px", color: fill }} fill={fill} />;
    case "Administration":
      return <FlexDepartmentIcon width={22} height={20} fill={fill} />;
    case "Architecture & Engineering":
      return <CivilEngineeringIcon style={{ fontSize: "25px" }} fill={fill} />;
    case "Internal Staff":
      return <InternalStaffIcon width={26} height={20} fill={fill} />;
    case "Sales Management":
      return <SalesManagementIcon fill={fill} />;
    case "Project Monitoring & Coordinating":
      return <SchedulingIcon fill={fill} />;
    default:
      return <QuestionCircleOutlined />;
  }
};
