import React, { useState, useMemo } from "react";
import { Drawer, Row, Skeleton, Alert } from "antd";
import "./Drawer.scss";
import dayjsTZ from "../../../utils/dayjs";
import ProblemsModal from "../../dashboard/AdminDashboard/AdminCards/Problems/PromblemsModal/ProblemsModal";
import { useSelector } from "react-redux";
import { htmlParser } from "../../../utils";
import ProblemStatus from "../../dashboard/AdminDashboard/AdminCards/Problems/ProblemStatus/ProblemStatus";
import { ClearIconDrawer } from "../../../assets/icons";
import DrawerFilter from "./DrawerFilter/DrawerFilter";

const SELECTORS = ["ticketCategory"];

const ProblemsDrawer = ({ problemsActive, setProblemsActive }) => {
  const { problematikat } = useSelector((state) => state.problematikat);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [editData, setEditData] = useState({});
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState({});
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(true);

  const handleRowClick = (data) => {
    setEditData(data);
    setIsAddModalVisible(true);
  };

  const initialData = useMemo(() => {
    let toReturn = [];
    let options = Object.assign(
      {},
      {},
      ...SELECTORS.map((el) => ({ [el]: [] }))
    );
    const optionKeys = Object.keys(options || {});
    if (!!problematikat) {
      toReturn = problematikat.filter((el) => {
        if (el?.ticketStatus === "Open") {
          optionKeys.forEach((key) => {
            if (!options[key].includes(el?.[key])) {
              options[key].push(el?.[key]);
            }
          });
          return true;
        } else return false;
      });
      setLoading(false);
    }
    setOptions(options);

    return toReturn;
  }, [problematikat]);

  const filteredData = useMemo(() => {
    if (!Object.values(activeFilter || {}).filter(Boolean).length) return null;

    const matchingData = [];

    const entries = Object.entries(activeFilter || {}).filter(
      ([_, val]) => !!val
    );
    (initialData || []).forEach((el) => {
      const doesMatch = entries.every(([key, val]) => {
        if (key === "createdAt") {
          return (
            dayjsTZ(el?.[key]).format("DD/MM/YYYY") ===
            dayjsTZ(val).format("DD/MM/YYYY")
          );
        } else {
          return (el?.[key] || "").toLowerCase().includes(val.toLowerCase());
        }
      });
      if (doesMatch) {
        matchingData.push(el);
      }
    });

    return matchingData;
  }, [initialData, activeFilter]);

  const filterKeys = useMemo(
    () => ({
      input: ["createdBy"],
      date: ["createdAt"],
      select: options,
    }),
    [options]
  );
  const tableHeaders = [
    "Punonjësi",
    "Përshkrimi",
    "Kategoria",
    "Statusi",
    "Data e Krijimit",
  ];
  return (
    <>
      <Drawer
        rootClassName="req-drawer"
        title="Problematikat"
        placement="right"
        className={`problemsDrawer ${isDarkMode ? "dark darkDrawer" : ""}`}
        width={800}
        destroyOnClose
        onClose={() => setProblemsActive(false)}
        open={problemsActive}
        key={"Problematikat"}
        closeIcon={
          <ClearIconDrawer fill={isDarkMode ? "#e6e6e6" : "#1d3445"} />
        }
      >
        {!!(initialData || []).length ? (
          <div className="drawerContainer">
            <DrawerFilter {...{ activeFilter, setActiveFilter, filterKeys }} />
            {!!(filteredData || initialData || []).length ? (
              <div className="drawerTable">
                <div
                  className="tableHeaders"
                  style={{
                    gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr`,
                  }}
                >
                  {tableHeaders.map((el) => (
                    <h3>{el}</h3>
                  ))}
                </div>
                {loading === false ? (
                  <div className="tableContent">
                    {(filteredData || initialData).map((el) => {
                      return (
                        <Row
                          key={el?.requestId}
                          className="problemsDrawerRow"
                          onClick={() => handleRowClick(el)}
                        >
                          <span>{el?.createdBy}</span>
                          <span>
                            {Array.isArray(htmlParser(el?.ticketDescription))
                              ? htmlParser(el?.ticketDescription)[0]
                              : htmlParser(el?.ticketDescription)}
                          </span>
                          <span>{el?.ticketCategory}</span>
                          <span>
                            <ProblemStatus status={el?.ticketStatus} />
                          </span>
                          <span>
                            {dayjsTZ(el?.createdAt).format("DD/MM/YYYY")}
                          </span>
                        </Row>
                      );
                    })}
                  </div>
                ) : (
                  <Skeleton paragraph={{ rows: 5 }} />
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Alert
                  message="Nuk ka asnjë problematikë për të shfaqur!"
                  type="info"
                  showIcon
                  style={{ width: "max-content" }}
                />
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Alert
              message="Nuk ka asnjë problematikë për të shfaqur!"
              type="info"
              showIcon
              style={{ width: "max-content" }}
            />
          </div>
        )}
      </Drawer>
      {!!isAddModalVisible ? (
        <ProblemsModal
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          editData={editData}
          setEditData={setEditData}
        />
      ) : null}
    </>
  );
};
export default ProblemsDrawer;
