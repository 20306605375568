import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { API } from "aws-amplify";
import "./DocumentationEmployeeCreation.scss";
import { useGeneralInformationsContext } from "./context";
import { useSelector } from "react-redux";
const { Option } = Select;

const DocumentationEmployeeCreation = () => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const { files, setFiles } = useGeneralInformationsContext();
  const [documentationsAvailable, setDocumentationsAvailable] = useState([]);
console.log(files)
  const getDocConfiguration = async () => {
    await API.get("docConfiguration", "/docConfiguration").then((res) => {
      setDocumentationsAvailable(res[0].documentationsAvailable);
    });
  };

  //! gets documentations from docConfiguration
  useEffect(() => {
    getDocConfiguration();
  }, []);

  return (
    <div
      className={`docEmployeeCreationContainer ${
        files ? "input-border" : "input-border-size"
      }`}
    >
      <div className="border">
        <div className="info">
          <p className="text">
            Këtu ju mund të zgjidhni një nga dokumentet që dëshironi të krijoni,
            pastaj ju mund të shkoni në dritaren e punonjësit për të shtuar më
            shumë dokumentacione.
          </p>
          <div className="selectorContainer">
            <Select
              label="Lloji i Dokumentacionit"
              className="docSelector"
              popupClassName={isDarkMode ? "darkDropdown" : ""}
              onChange={(e) => setFiles(e)}
              mode="multiple"
              allowClear
              value={files}
            >
              {documentationsAvailable?.map((data) => (
                <Option key={data?.docName} value={data?.docName}>
                  {data?.docName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentationEmployeeCreation;
