import moment from "moment";
import { htmlParser } from "../../../../../utils";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";

export const formatChanges = (changedValue) => {
  if (Array.isArray(changedValue)) {
    return changedValue?.join?.(", ");
  } else if (typeof changedValue === "object") {
    return null;
  } else if (typeof changedValue === "boolean") {
    return !!changedValue ? "Po" : "Jo";
  } else if (typeof changedValue === "number") {
    return dayjsTZ(changedValue).format("DD/MM/YYYY");
  } else if (dayjs.isDayjs(changedValue)) {
    return dayjsTZ(changedValue).format("DD/MM/YYYY");
  } else if (dayjsTZ(changedValue, "MM/DD/YYYY", true).isValid()) {
    return dayjsTZ(changedValue).format("DD/MM/YYYY");
  } else if (/#(?:[0-9a-fA-F]{3}){1,2}\b/i.test(changedValue)) {
    return (
      <span style={{ background: changedValue, padding: "5px 10px" }}>
        {changedValue}
      </span>
    );
    //kontrollon hex colors
  } else if (/<\/?[a-z][\s\S]*>/i.test(changedValue)) {
    //Kontrollon nese ka elemente te html:tag
    return htmlParser(changedValue);
  } else {
    return changedValue;
  }
};
