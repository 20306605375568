import React, { useEffect, useMemo, useState, useCallback } from "react";
import LeaveModal from "./LeaveModal";
import "./LeaveRequest.scss";
import { API } from "aws-amplify";
import dayjsTZ from "../../../../../utils/dayjs";
import { Form, Input, message } from "antd";
import { HolidayIcon, DayOffIcon } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../authentication/authHelpers";
import { HollowDotsSpinner } from "react-epic-spinners";
import { lazyFetch } from "../../../../../utils/lazyFetch";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";
import AddLeaveStatus from "./AddLeaveStatus/AddLeaveStatus";
import { SearchIconInput } from "../../../../../assets/icons";
import LeaveRequestTable from "./LeaveRequestTable";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import RequestModal from "./RequestModal/RequestModal";
import { colDefs } from "./colDefs";

export const LeaveRequests = () => {
  const { user } = useAuth();
  const { employees } = useSelector((state) => state.employeesList);
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [content, setContent] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [spin, setSpin] = useState({});
  const [filterValue, setFilterValue] = useState("");
  const [editData, setEditData] = useState({});

  const rowKey = useCallback((record) => {
    return record.requestId;
  }, []);

  // const [noEmpId, setNoEmpId] = useState(false);
  // opens the modal with the clicked row state
  const getNotif = async (filterId) => {
    setSpin({ [filterId]: true });
    await API.get("employeeRequests", `/employeeRequests/${filterId}`)
      .then((el) => {
        setContent(el);
        setSpin({ filterId: false });
        setIsModalVisible(true);
      })
      .catch((err) => {
        message.error("Something went wrong!");
        setSpin({ filterId: false });
        console.log("Error: ", err);
      });
  };

  const renderStatus = (text, data) => (
    <AddLeaveStatus
      onClick={() => getNotif(data?.requestId)}
      status={
        dayjsTZ().startOf("day").valueOf() > data?.requestPeriod?.slice(-1)?.[0]
          ? "Përfunduar"
          : text
      }
      spin={spin[data?.requestId]}
    />
  );

  //region MEMOS
  //gets the values from dispatch and sorts them by created time
  const tableValues = useMemo(() => {
    if (!employeeRequests) return [];
    return employeeRequests?.sort((a, b) => b?.createdAt - a?.createdAt);
  }, [employeeRequests]);

  //filters the values depending on the input field
  const dataSource = useMemo(() => {
    return tableValues?.filter((item) => {
      return (
        item.given_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.family_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.requestStatus.toLowerCase().includes(filterValue.toLowerCase()) ||
        dayjsTZ(item.createdAt).format("DD/MM/YYYY").includes(filterValue) ||
        dayjsTZ(item.requestPeriod[0])
          .format("DD/MM/YYYY")
          .includes(filterValue) ||
        dayjsTZ(item?.requestPeriod?.slice(-1)[0])
          .format("DD/MM/YYYY")
          .includes(filterValue)
      );
    });
  }, [tableValues, filterValue]);

  const columns = useMemo(() => {
    return colDefs({
      navigate,
      renderStatus,
      setIsAddModalVisible,
      setEditData,
      userRole: user?.userRole,
    });
  }, [user, spin]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  //region RETURN
  return (
    <>
      {!!tableValues && !!employees ? (
        !isMobileView ? (
          <div className="wrap">
            <div
              className={`kerkesa-leje-container ${isDarkMode ? "dark" : ""}`}
            >
              <LeaveRequestTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={rowKey}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "50px",
              }}
            >
              <Input
                className="searchInput"
                placeholder="  Kërkoni Punonjës..."
                onChange={(e) => setFilterValue(e.target.value)}
                prefix={
                  <SearchIconInput fill={isDarkMode ? "#e6e6e6" : "#1d3943"} />
                }
              />
              <MondayButton
                className="mondayButtonGreen add-request"
                onClick={() => setIsAddModalVisible(true)}
              >
                Krijo një kërkesë për leje
              </MondayButton>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MondayButton
                className="mondayButtonGreen add-request-mobile"
                onClick={() => setIsAddModalVisible(true)}
              >
                Krijo një kërkesë për leje
              </MondayButton>
            </div>
            <MobileHtmlTabel
              header={["Emri", "Periudha", "Lloj lejes", "Krijuar", "Ditet"]}
              tableData={tableValues.map?.(
                ({
                  family_name,
                  given_name,
                  requestPeriod,
                  requestStatus,
                  createdAt,
                }) => ({
                  family_name: `${given_name} ${family_name}`,
                  requestType: `${dayjsTZ(requestPeriod[0]).format(
                    "DD/MM/YYYY"
                  )} - ${dayjsTZ(requestPeriod?.slice(-1)[0]).format(
                    "DD/MM/YYYY"
                  )}`,
                  requestStatus,
                  createdAt: dayjsTZ(createdAt).format("DD/MM/YYYY"),
                  weekEnd: `${excludeWeekends({
                    startDate: dayjsTZ(requestPeriod[0]).format("YYYY/MM/DD"),
                    endDate: dayjsTZ(requestPeriod?.slice(-1)[0]).format(
                      "YYYY/MM/DD"
                    ),
                  })}`,
                })
              )}
              isHeader={false}
              isLink={false}
            />
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
      {isAddModalVisible ? (
        <RequestModal
          open={isAddModalVisible}
          setOpen={setIsAddModalVisible}
          request={editData}
          setRequest={setEditData}
        />
      ) : null}
      {!!isModalVisible ? (
        <LeaveModal
          content={content}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          getIcon={getIcon}
        />
      ) : null}
    </>
  );
};

//Percakton ikonen ne varesi te llojit te kerkeses
const getIcon = (reqType, style) => {
  switch (reqType) {
    // case "Leje lindje":
    //   return <MaternityLeaveIcon style={style} />;
    case "Pushime":
      return <HolidayIcon width={18} height={18} style={style} />;
    case "Dite Personale":
      return <DayOffIcon style={style} />;
    default:
      return;
  }
};

//function to get only the week days
export const excludeWeekends = ({ startDate, endDate }) => {
  let count = 0;
  let curDate = +startDate;
  while (curDate <= +endDate) {
    const dayOfWeek = dayjsTZ(curDate).day();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    if (!isWeekend) {
      count++;
    }
    curDate = curDate + 24 * 60 * 60 * 1000;
  }
  return count;
};
