import React, { useEffect, useMemo, useState } from "react";
import "./ShiftLogCard.scss";
import moment from "moment-timezone";
import dayjs from "dayjs";
import dayjsTZ from "../../../../utils/dayjs";
import { htmlParser } from "../../../../utils/htmlParser";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

function CreatedShiftCard({ content, users }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const user = useMemo(() => {
    let toReturn = {};
    if (content !== null && users !== null) {
      let adminShift = content.clockingLogs[0]?.newShiftByAdmin
        ? content.clockingLogs[0]
        : null;
      // console.log("Admin Shift", adminShift);
      if (!!adminShift) {
        let userInfo = users.find((el) => el.userSub === adminShift.userSub);
        if (!!userInfo) {
          userInfo["username"] =
            userInfo?.given_name + " " + userInfo?.family_name;
        }
        toReturn = userInfo || adminShift;
      }
    }
    return toReturn;
  }, [users, content]);

  return content.clockingLogs.length > 0 ? (
    <div className={`shift-log-card-wrapper ${isDarkMode ? "dark" : ""}`}>
      <div className="header">
        <span className="header-title">
          {content?.clockingCategory === "user" ||
          content?.clockingCategory === "midnightReset" ||
          !Object.keys(user).length
            ? "Punonjësi"
            : `${user?.username} ${
                !!user?.userRole ? `(${user?.userRole})` : ""
              }`}{" "}
          krijoi këtë turn:
          <Tooltip
            title={`${dayjsTZ(content?.clockingLogs[0]?.createdAt).format(
              "HH:mm:ss |  DD/MM/YYYY"
            )}`}
          >
            <span style={{ marginLeft: "5px" }}>
              {" "}
              {" " + dayjsTZ(content?.clockingLogs[0]?.createdAt).fromNow()}
            </span>
          </Tooltip>
          .{" "}
          {!!content?.clockingLogs[0]?.ipResponse && (
            <Tooltip
              title={htmlParser(`
                    <a href="https://maps.google.com/?q=${
                      content?.clockingLogs[0]?.ipResponse?.latitude
                    },${
                content?.clockingLogs[0]?.ipResponse?.longitude
              }" target="_blank">
                          <span>
                        Clock-in: (${
                          content?.clockingLogs[0]?.ipResponse?.IPv4
                        }) ${content?.clockingLogs[0]?.ipResponse?.city}, ${
                content?.clockingLogs[0]?.ipResponse?.country_name
              } 
                  </span>
                        </a>
                  ${
                    content?.clockingLogs[1]
                      ? `<a href="https://maps.google.com/?q=${content?.clockingLogs[1]?.ipResponse?.latitude},${content?.clockingLogs[1]?.ipResponse?.longitude}" target="_blank">
                          <span>
                            Clock-out: (${content?.clockingLogs[1]?.ipResponse?.IPv4}) ${content?.clockingLogs[1]?.ipResponse?.city}, ${content?.clockingLogs[1]?.ipResponse?.country_name}
                          </span>
                        </a>`
                      : ""
                  }`)}
            >
              <span style={{ marginLeft: "5px" }}>
                {content?.clockingLogs[0]?.ipResponse?.IPv4}
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <div className="content">
        <div className="content-shifts">
          <div className="shift-detail">
            <span style={{ display: "flex", justifyContent: "center" }}>
              Turni
            </span>
            <span
              className="shift-format"
              style={{ backgroundColor: "#1da193" }}
            >
              {dayjsTZ(content?.clockInDate).format("HH:mm")} -{" "}
              {dayjsTZ(content.clockOutDate).format("HH:mm DD/MM/YYYY") !==
              "Invalid Date"
                ? dayjsTZ(content.clockOutDate).format("HH:mm | DD/MM/YYYY")
                : "--:--"}
            </span>
          </div>
          {/* <div className="new-shift">
                <span style={{ display: "flex", justifyContent: "center" }}>Turni i ri </span>
                <span className="shift-format" style={{ backgroundColor: "#1da193" }}></span>
              </div> */}
        </div>
        {!!content?.clockingLogs?.[0]?.note ? (
          <div className="content-note">
            <span className="sticky-title">Shënim</span>
            {/* <span className="note-text">{content?.note.trim()}</span> */}
            <span className="note-text">
              {htmlParser(content.clockingLogs[0].note)}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
}

export default CreatedShiftCard;
