import { useMemo, useState } from "react";
import "./User.scss";
import dayjsTZ from "../../../../../utils/dayjs";
import {
  AccessRoleIcon,
  CalendarIcon,
  DepartmentIcon,
  DesignerIcon,
  EmailIcon,
  FirstNameIcon,
  LastNameIcon,
  PhoneIcon,
} from "./assets/icons";
import SessionTable from "./SessionTable";
import { API } from "aws-amplify";
import { Alert, message, Popconfirm } from "antd";
import { updateArrayElement } from "../../../../../helpers/utils/updateArrayElement";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";

function LoadedUser({ renderUser, setRenderUser }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  //region UPDATE USER
  const updateUser = async (obj) => {
    if (!!renderUser.identityId) {
      await API.put("users", `/users/${renderUser.identityId}`, {
        body: { ...obj },
      })
        .then(() => {
          setRenderUser((prev) => ({ ...(prev || {}), ...obj }));
          message.success({
            key: "loadedUser",
            content: "Veprimi u krye me sukses!",
          });
        })
        .catch((err) => {
          console.log("Error: ", err);
          message.error({
            key: "loadedUser",
            content: "Dicka nuk shkoi mirë, provoni përsëri!",
          });
        });
    } else {
      message.error({
        key: "loadedUser",
        content: "Ky punonjës nuk ka një ID të vlefshme!",
      });
    }
  };

  //region LOGOFF
  const onLogOff = async (sessionId) => {
    if (!!sessionId) {
      const updatedActiveSessions = updateArrayElement(
        renderUser?.activeSessions,
        { ...(renderUser?.activeSessions || []), sessionId, logout: true },
        "sessionId"
      );
      if (!!renderUser?.identityId) {
        await API.put("users", `/users/${renderUser?.identityId}`, {
          body: { activeSessions: updatedActiveSessions },
        }).then(() => {
          message.success("Veprimi u ruajt me sukses!");
          setRenderUser((prev) => ({
            ...prev,
            activeSessions: updatedActiveSessions,
          }));
        });
      }
    }
  };

  const endedSessions = useMemo(() => {
    if (!renderUser?.activeSessions) return [];

    return renderUser.activeSessions.map((s) => ({ ...s, logout: true }));
  }, [renderUser?.activeSessions]);

  const fill = isDarkMode ? "#e6e6e6" : "#323338";

  //region RETURN
  return (
    <div className="container-user-employee">
      <div className="loaded-user-list">
        <div className="loaded-user-first-row">
          <span>
            <FirstNameIcon fill={fill} />
            Emri: {renderUser?.given_name}
          </span>
          <span>
            <LastNameIcon fill={fill} /> Mbiemri: {renderUser?.family_name}
          </span>
          <span>
            <EmailIcon fill={fill} /> Email: {renderUser?.email}
          </span>
          <span>
            <PhoneIcon fill={fill} /> Nr i tel: {renderUser?.phone_number}
          </span>
        </div>
        <div className="loaded-user-first-row">
          <span>
            <DepartmentIcon fill={fill} /> Departamenti:{" "}
            {renderUser?.department}
          </span>
          <span>
            <DesignerIcon fill={fill} /> Roli: {renderUser?.departmentRole}
          </span>
          <span>
            <AccessRoleIcon fill={fill} /> Roli Aksesues: {renderUser?.userRole}
          </span>
          <span>
            <CalendarIcon fill={fill} />
            {`Krijuar: ` +
              dayjsTZ(renderUser?.createdAt).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </div>
      <div className="action-btns">
        {!!renderUser?.activeSessions?.length ? (
          <Popconfirm
            classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
            title="A jeni te sigurt?"
            okText="Po."
            cancelText="Jo, anullo!"
            onConfirm={() => updateUser({ activeSessions: endedSessions })}
          >
            <MondayButton className="mondayButtonRed">Largo</MondayButton>
          </Popconfirm>
        ) : null}
        <Popconfirm
          classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
          title="A jeni te sigurt?"
          okText="Po."
          cancelText="Jo, anullo!"
          onConfirm={() => updateUser({ forceReset: !renderUser?.forceReset })}
        >
          <MondayButton className="mondayButtonGreen">
            {renderUser?.forceReset && "Hiq"} Kërko fjalëkalim të ri
          </MondayButton>
        </Popconfirm>
        <Popconfirm
          classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
          title="A jeni te sigurt?"
          okText="Po."
          cancelText="Jo, anullo!"
          onConfirm={() => updateUser({ disabled: !renderUser?.disabled })}
        >
          <MondayButton className="mondayButtonRed">
            {!renderUser?.disabled ? "Pezullo" : "Aktivizo"}
          </MondayButton>
        </Popconfirm>
        <MondayButton
          className="mondayButtonRed"
          onClick={() => updateUser({ disabled: true })}
        >
          Fshij përdoruesin
        </MondayButton>
        {!!renderUser?.activeSessions?.length ? (
          <Popconfirm
            classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
            title="A jeni te sigurt?"
            okText="Po."
            cancelText="Jo, anullo!"
            onConfirm={() => updateUser({ activeSessions: [] })}
          >
            <MondayButton className="mondayButtonRed">
              Fshij sesionet
            </MondayButton>
          </Popconfirm>
        ) : null}
      </div>
      <div className="table-sessions">
        {!!renderUser?.activeSessions?.length ? (
          <div className="table-sessions-header">
            <SessionTable
              sessions={renderUser?.activeSessions}
              id={renderUser?.identityId}
              onLogOff={onLogOff}
            />
          </div>
        ) : (
          <Alert
            style={{ width: "max-content" }}
            showIcon
            type="info"
            message="Ky përdorues nuk ka asnjë sesion aktiv"
          />
        )}
      </div>
    </div>
  );
}

export default LoadedUser;
