import React from "react";
import { Popconfirm } from "antd";
import { LogoFlex } from "../../assets/icons";
import "./global_popup_konfirm.scss";
import { useSelector } from "react-redux";
// global antd popup confirmation
const GlobalPopupKonfirm = ({
  okText,
  cancelText,
  onConfirm,
  title,
  Button,
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  // global ANTD popup confirmation withch will be given dinamicly by props tittle , konfirm text , close text , and button or icon , and onConfirm the function to delete the item
  return (
    <div style={{ zIndex: 10 }}>
      <Popconfirm
        placement="top"
        title={title}
        onConfirm={onConfirm}
        okText={okText}
        cancelText={cancelText}
        classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
        icon={<LogoFlex width={45} height={45} />}
      >
        {Button}
      </Popconfirm>
    </div>
  );
};

export default GlobalPopupKonfirm;
