import React from "react";
import { Popover } from "antd";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { DragIcon } from "../../../../../assets/icons";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../authentication/authHelpers";

const SortableItem = SortableElement((props) => {
  const {
    data: data,
    value: item,
    btns: buttons,
    id: id,
    handleDelete,
    handleBlur,
  } = props;

  return (
    <div className="sortable-item-style">
      {/* <br /> */}
      <div className="content-style">
        {item?.name === "ClockIn" ? (
          <DynamicHeader
            item={item}
            handleDelete={handleDelete}
            handleBlur={handleBlur}
            name={props?.data?.name}
          />
        ) : (
          <StaticHeader
            item={item}
            handleDelete={handleDelete}
            handleBlur={handleBlur}
            name={props?.data?.name}
          />
        )}
        <item.src
          data={item.data}
          content={<item.content />}
          blur={item?.blur}
          width={item?.width}
          height={item?.height}
        />
      </div>
    </div>
  );
});

export default React.memo(SortableItem);
const DragPopOver = ({ handleDelete, item, handleBlur }) => {
  return (
    <div className="drag-pop-over">
      <span className="action-label" onClick={() => handleDelete(item?.id)}>
        Fshih
      </span>
      <span className="action-label" onClick={() => handleBlur(item?.id)}>
        Konfidenciale
      </span>
    </div>
  );
};

const DynamicHeader = ({ item, handleDelete, handleBlur, name }) => {
  const { clockings } = useSelector((state) => state.clockings);
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { employeeId } = useAuth();
  let curr =
    clockings
      ?.filter((el) => el?.employeeId === employeeId)
      ?.sort((a, b) => a?.clockInDate - b?.clockInDate) || [];
  let condition =
    curr[curr.length - 1]?.clockInDate !== null &&
    !curr[curr.length - 1]?.clockOutDate &&
    name === "ClockIn";
  return (
    <div
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        // background: item?.name !== "ClockIn" ? "#E7EEF2" : "#1da193",
        borderRadius: "10px 10px 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: condition ? "#ea3943" : "#1da193",
      }}
    >
      <span
        className="header-dashbord-card-title"
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19.07px",
          letterSpacing: "0em",
          color: "#FBFBFB",
          marginLeft: "20px",
          textAlign: "center",
        }}
      >
        {item?.emri}
      </span>{" "}
      <Popover
        style={{
          cursor: "pointer",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        placement="rightTop"
        title={"Ndryshime"}
        classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
        content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
        trigger="hover"
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
            zIndex: 999,
          }}
        >
          <DragIcon />{" "}
        </span>{" "}
      </Popover>
    </div>
  );
};
const StaticHeader = ({ item, handleDelete, handleBlur }) => {
  const { isDarkMode } = useSelector((state) => state.users);
  return (
    <div
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        background: item?.name !== "ClockIn" ? "#E7EEF2" : "#1da193",
        borderRadius: "10px 10px 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        className="header-dashbord-card-title"
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19.07px",
          letterSpacing: "0em",
          color: item?.name === "ClockIn" ? "#FBFBFB" : "#1d3943",
          marginLeft: "20px",
        }}
      >
        {item?.emri}
      </span>{" "}
      <Popover
        style={{
          cursor: "pointer",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        placement="rightTop"
        title={"Ndryshime"}
        classNames={{ root: `globalPopover ${isDarkMode ? "dark" : ""}` }}
        content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
        trigger="hover"
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
            zIndex: 999,
          }}
        >
          <DragIcon fill="#1d3943" />{" "}
        </span>{" "}
      </Popover>
    </div>
  );
};
