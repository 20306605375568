import { Button, Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import QuillEditor from "./Components/QuillEditor";
import { InfoIcon, DownloadIcon } from "./Image";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import QuillRenderer from "./Components/QuillRenderer";
import { InterviewsContext } from "../../data";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import { useDispatch, useSelector } from "react-redux";
import { candidates as candidatesReducer } from "../../../../store/actions";
import { ModalXIcon } from "../../../../assets/icons";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

const InformacioneShtese = ({ uploadedFiles }) => {
  const { candidates } = useSelector((state) => state.candidates);
  const { isDarkMode } = useSelector((state) => state.usersList);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [applicantNotes, setApplicantNotes] = useState();
  const [spin, setSpin] = useState(false);

  const {
    kandidatiSelektuar,
    setKandidatiSelektuar,
    defaultSwalProperties,

    setIntervistat,
    intervistat,
  } = useContext(InterviewsContext);

  useEffect(() => {
    setApplicantNotes(
      kandidatiSelektuar.applicantNotes && kandidatiSelektuar.applicantNotes
    );
  }, [kandidatiSelektuar]);

  function updateCandidatet(data, newCandidad) {
    return data?.map((el) =>
      el?.applicantId === kandidatiSelektuar?.applicantId
        ? {
            ...newCandidad,
            applicantId: kandidatiSelektuar?.applicantId,
          }
        : el
    );
  }

  //logic for Submiting the applicant interview Notes

  const handleSubmit = async (e) => {
    setSpin(true);
    const temporaryArray = {
      ...kandidatiSelektuar,
      applicantNotes: applicantNotes,
    };

    const { applicantId, userId, ...rest } = temporaryArray;

    Swal.fire({
      title: "Ju lutemi prisni...",
      customClass: {
        popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await API.put(
      "interviews",
      `/interviews/${kandidatiSelektuar?.applicantId}`,
      {
        body: rest,
      }
    )
      .then(() => {
        setKandidatiSelektuar({ ...temporaryArray });
        dispatch(
          candidatesReducer(updateCandidatet(candidates, temporaryArray))
        );
        setIntervistat(updateCandidatet(intervistat, temporaryArray));
        setShowModal(false);
        setSpin(false);
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "success",
          title: "Kandidati u ndryshua!",
        });
      })
      .catch((err) => {
        setSpin(false);
        message.error("err: ", err);
        console.log("err: ", err);
      });
  };
  //logic for downloadin a file from google drive
  const handleDownload = (file) => {
    window.location.href = file.downloadUrl;
  };

  let applicantFullName =
    kandidatiSelektuar.applicantFirstName +
    " " +
    kandidatiSelektuar.applicantLastName;

  let textFormat =
    applicantFullName.length > 35
      ? applicantFullName.slice(0, 35) + "..."
      : applicantFullName;

  return (
    <div className={`info-shtese-container ${isDarkMode ? "dark" : ""}`}>
      <div className="first-content-container">
        <div className="content-header">
          <p>Shënime gjatë proçesit</p>
          <MondayButton
            className="mondayButtonBlue"
            onClick={() => setShowModal(!showModal)}
            spin={spin}
          >
            Ndryshoni
          </MondayButton>
        </div>
        <div className="content-container">
          <div className="first-container">
            <QuillRenderer value={kandidatiSelektuar?.applicantNotes || ""} />
          </div>
          <div className="second-container">
            <div className="second-container-header">
              <img src={InfoIcon} alt="Info Icon" />
              <p>
                Këto janë dokumente/foto të ndryshme për{" "}
                <span>{textFormat}</span>.
              </p>
            </div>
            <div className="second-content-container">
              {uploadedFiles &&
                uploadedFiles.map((files, index) => {
                  const fileExtension = files.name.split(".").pop();

                  return (
                    <div
                      className="upload-container"
                      key={index}
                      onClick={() => handleDownload(files)}
                    >
                      <div className="text-image">
                        <div className="uploadedFileIcon">
                          {fileIcons({ type: fileExtension }) ||
                            fileIcons({ type: "image" })}
                        </div>
                        <p>
                          {files.name.length > 23
                            ? `${files.name.slice(0, 23)}...`
                            : files.name}
                        </p>
                      </div>
                      <div className="delete-button">
                        <img src={DownloadIcon} width={12} height={14} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        centered
        destroyOnClose={true}
        className={`shenime-modal ${
          isDarkMode ? "dark darkHeader" : "lightHeader"
        }`}
        onCancel={() => setShowModal(!showModal)}
        title={`Shënime gjatë proçesit (${textFormat})`}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            onClick={() => setShowModal(!showModal)}
          >
            Mbyllni faqen
          </MondayButton>,
          <Button className="mondayButtonGreen" onClick={handleSubmit}>
            Ruani ndryshimet
          </Button>,
        ]}
      >
        <div className="shenime-modal-container">
          <div className="first-modal-content-container">
            <QuillEditor
              value={applicantNotes}
              placeholder="Shkruani shënime gjatë proçesit të aplikantit..."
              classNameStyle="quill-box"
              onChange={setApplicantNotes}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InformacioneShtese;
