import { Col } from "antd";
import React from "react";
import Cardkonfig from "../../dynamic components/Card";
import InformacioneKontakti from "./InformacioneKontakti";

function CardComponentInfoContatct() {
  return <Cardkonfig contentChild={<InformacioneKontakti />} width="100%" />;
}

export default CardComponentInfoContatct;
