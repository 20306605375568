import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { HollowDotsSpinner } from "react-epic-spinners";
import { BirthdayCakeIcon } from "../../../../../assets/icons";
import ReturnImg from "../../../../../utils/ReturnImg";

const WorkEvents = () => {
  // get emplyes data with redoux
  const { employees: activeEmployees } = useSelector(
    (state) => state.employeesList
  );
  const { isDarkMode } = useSelector((state) => state.usersList);
  //state where data is stored
  const [eventData, setEventData] = useState([]);
  // current date
  let date = new Date();
  // current month
  let longMonth = date?.toLocaleString("en-us", { month: "2-digit" });

  // filter the birthdays with the current month and store it in state
  const birthdays = () => {
    const ditlindja = activeEmployees
      ?.filter(
        (el) =>
          dayjsTZ().format("DD/MM/YYY") <
          dayjsTZ(el?.employeeDayOfBirth).format("DD/MM/YYY")
      )
      ?.filter((data) => {
        if (
          !!data?.employeeDayOfBirth &&
          data?.employeeDayOfBirth !== undefined &&
          data?.employeeStatus === "Aktiv"
        ) {
          return dayjsTZ(data?.employeeDayOfBirth).format("MM") === longMonth;
        }
      });
    setEventData(ditlindja);
  };

  //will run when page will render
  useEffect(() => {
    if (!!activeEmployees) {
      birthdays();
    }
  }, [activeEmployees]);

  // sort by date rande the birthdays
  function compare(a, b) {
    if (
      dayjsTZ(a.employeeDayOfBirth).format("DD") <
      dayjsTZ(b.employeeDayOfBirth).format("DD")
    ) {
      return -1;
    }
    if (
      dayjsTZ(a.employeeDayOfBirth).format("DD") >
      dayjsTZ(b.employeeDayOfBirth).format("DD")
    ) {
      return 1;
    }
    return 0;
  }

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!activeEmployees ? (
        <div
          className={`birthday-card-kryefaqja ${isDarkMode ? "dark" : ""}`}
          style={{
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {eventData?.length > 0 ? (
            <div className="events">
              {eventData?.sort(compare)?.map?.((data) => {
                return (
                  <div className="anniversary" key={data?.employeeId}>
                    <Row gutter={16} key={data?.employeeId}>
                      <Col>
                        <ReturnImg
                          width={40}
                          height={40}
                          borderRadius={50}
                          googleDriveFileId={data?.googleDriveFileId}
                          defaultImg={<BirthdayCakeIcon />}
                        />
                      </Col>
                      <Col>
                        <Row>
                          {" "}
                          <span>
                            {data?.employeeFirstName} {data?.employeeLastName} -
                            Ditelindje
                          </span>
                        </Row>
                        <Row>
                          <span>
                            {dayjsTZ(data?.employeeDayOfBirth).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="ska-dtl-muaj">Nuk ka ditëlindje për këtë muaj</div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </>
  );
};
export default WorkEvents;
