import React from "react";
import { Modal, Button } from "antd";
import DropdownWrapper from "./DropddownWrapper";
import "./NormalSizedModal.scss";
import { useSelector } from "react-redux";
import { ModalXIcon } from "../../../assets/icons";

const NormalSizedModal = ({
  visible,
  setVisible,
  title,
  subtitle,
  children,
  onCancel,
  centered,
  className = "",
  destroyOnClose,
  maskClosable = false,
  subtitleStyle,
  footer = [],
  style = {},
  closable = false,
  zIndex,
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <Modal
      wrapClassName={`normalSizedModal ${className} ${
        !!title ? "" : "noTitleNormalSizedModal"
      }`}
      open={visible}
      destroyOnClose={destroyOnClose}
      footer={null}
      title={title}
      centered={centered}
      keyboard
      closeIcon={<ModalXIcon />}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => {
        onCancel ? onCancel() : setVisible(false);
      }}
      style={style}
      zIndex={zIndex}
      className={isDarkMode ? "darkHeader" : "lightHeader"}
    >
      <div
        className="subtitle"
        style={!subtitle ? { display: "none" } : { ...subtitleStyle }}
      >
        {subtitle}
      </div>
      {children}
      {!!footer.length && (
        <div className="footerContainer">
          {footer?.map(
            (
              {
                text,
                onClick,
                primary,
                cancel,
                dropdownDisabled = false,
                uppercase = false,
                disabled = false,
                danger = false,
                options,
                onOptionClick,
                suffix,
              },
              i
            ) =>
              !options || !!dropdownDisabled ? (
                <Button
                  key={i}
                  danger={danger}
                  onClick={onClick}
                  disabled={disabled}
                  className={`${
                    disabled
                      ? "disabled"
                      : danger
                      ? ""
                      : primary
                      ? "primary"
                      : cancel
                      ? "cancel"
                      : "secondary"
                  }FooterButton`}
                  style={{
                    textTransform: uppercase ? "uppercase" : "capitalize",
                  }}
                >
                  {text}
                </Button>
              ) : (
                <DropdownWrapper
                  key={i}
                  open={visible}
                  dropdownOptions={options}
                  onClick={onOptionClick}
                >
                  <Button
                    key={i}
                    danger={danger}
                    onClick={onClick}
                    disabled={disabled}
                    className={`${
                      disabled
                        ? "disabled"
                        : danger
                        ? ""
                        : primary
                        ? "primary"
                        : cancel
                        ? "cancel"
                        : "secondary"
                    }FooterButton`}
                    style={{
                      textTransform: uppercase ? "uppercase" : "capitalize",
                    }}
                  >
                    {text}
                    {suffix}
                  </Button>
                </DropdownWrapper>
              )
          )}
        </div>
      )}
    </Modal>
  );
};

export default NormalSizedModal;
