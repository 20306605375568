import React from "react";
import { FlexLogo } from "../../../assets/images";
import {
  BreedingRhombusSpinner,
  PixelSpinner,
  FlowerSpinner,
} from "react-epic-spinners";
import "./FlexLogoLoader.scss";
import { useSelector } from "react-redux";
import { useAuth } from "../../../authentication/authHelpers";

const Loader = () => {
  const isDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));

  return (
    <div className={`loader-wrapper-2-parent ${!!isDarkMode ? "dark" : ""}`}>
      <div className="loader-wrapper-2">
        <span className="flex-logo-spinner">
          {/* <FlexLogo /> */}
          {/* <FlowerSpinner */}
          <BreedingRhombusSpinner
            size={130}
            color={"#1da193"}
            // winter
            // size={170}
            // color={"#CDDDF8"}
          />
        </span>
      </div>
    </div>
  );
};
export default Loader;
