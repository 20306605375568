import React from "react";
import { Tabs } from "antd";
import {
  DitlindjeIcon,
  IconNojftimet,
  PushimeIcon,
  LajmerimeIcon,
} from "../../../../assets/icons";
import "./njoftimet_card.scss";
import Lajmerime from "./components/Lajmerime/Lajmerime.jsx";
import Njoftime from "./components/NjoftimeTab/Njoftime.jsx";
import Ditlindje from "./components/Ditlindje/Ditlindje.jsx";
import Vacations from "./Vacation/Vacations";
import { useSelector } from "react-redux";

function Njoftimet() {
  const { isDarkMode } = useSelector((state) => state.usersList);
  const fill = isDarkMode ? "#e6e6e6" : "#121212";

  const tabs = [
    {
      key: "1",
      label: (
        <div className="tabLabel">
          <LajmerimeIcon fill={fill} />
          Lajmërimet
        </div>
      ),
      children: <Lajmerime />,
    },
    {
      key: "2",
      label: (
        <div className="tabLabel">
          <IconNojftimet width={20} height={18} fill={fill} />
          Njoftime
        </div>
      ),
      children: <Njoftime />,
    },
    {
      key: "3",
      label: (
        <div className="tabLabel">
          <DitlindjeIcon width={20} height={18} fill={fill} /> Ditëlindjet
        </div>
      ),
      children: <Ditlindje />,
    },
    {
      key: "4",
      label: (
        <div className="tabLabel">
          <PushimeIcon width={20} height={18} fill={fill} /> Pushimet
        </div>
      ),
      children: <Vacations />,
    },
  ];
  return (
    <div className="njoftime">
      <div className={`card-container ${isDarkMode ? "dark" : ""}`}>
        <Tabs type="card" items={tabs} />
      </div>
    </div>
  );
}

export default Njoftimet;
