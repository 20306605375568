export const getTextFromHTML = (htmlString, includeStyles) => {
  const parser = new DOMParser();

  const doc = parser.parseFromString(htmlString, "text/html");

  if (includeStyles) {
    return extractWithStyles(doc.body).trim();
  }
  return doc.body.textContent.trim();
};

const extractWithStyles = (element) => {
  let result = "";

  if (element.nodeType === Node.ELEMENT_NODE) {
    const styles = element.getAttribute("style");

    // Recursively process child nodes
    const childContent = Array.from(element.childNodes)
      .map((child) => extractWithStyles(child))
      .join("");

    if (styles) {
      result += `<span style="${styles}">${childContent}</span>`;
    } else {
      result += childContent + " ";
    }
  } else if (element.nodeType === Node.TEXT_NODE) {
    result += element.textContent;
  }

  return result;
};
