import { useSelector } from "react-redux";
import "./FullCard.scss";
import { Card } from "antd";

function FullCard({ contentChild }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <div className={`full-card ${isDarkMode ? "dark" : ""}`}>
      {contentChild}
    </div>
  );
}

export default FullCard;
