import { Modal, Button, Form, message, Input, InputNumber } from "antd";
import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { RichTextEditor } from "../../../commonComponents";
import { useProgramFields } from "../../../../hooks/useProgramFields";
import { useAuth } from "../../../../authentication/authHelpers";
import _ from "lodash";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton.jsx";
import { useSelector } from "react-redux";

const programFieldsId = {
  POLITIKAT_JASHT_ORARIT: "3d077179-a545-43ec-98be-7e1bf71b09aa",
};

function PaisjetOraritPostModal({
  setVisible,
  visible,
  request,
  setTableData,
  setRequests,
  tableData,
  dataLista,
  setError,
  error,
  socketHandler,
}) {
  const [form] = Form.useForm();
  const { POLITIKAT_JASHT_ORARIT } = programFieldsId;
  const { [POLITIKAT_JASHT_ORARIT]: records } = useProgramFields();
  const { userAccess } = useAuth();
  const { isDarkMode } = useSelector((state) => state.usersList);

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  const handleSave = () => {
    if (!dataLista) {
      const formData = { ...form.getFieldsValue(), id: uuidv4() };
      form.resetFields();
      API.put(
        "programFields",
        `/programFields/3d077179-a545-43ec-98be-7e1bf71b09aa`,
        {
          body: {
            fieldOptions: [...request.fieldOptions, formData],
          },
        }
      ).then(() => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "policy_out_added",
        // 			body: "A policy was added",
        // 		})
        // 	);
        // }
        message.success("Të dhënat u regjistruan me sukses !", 10);
        setTableData([...tableData, formData]);
        setVisible(false);
      });
      let field = [...request.fieldOptions, formData];
      setRequests((prev) => ({ ...prev, fieldOptions: field }));
    } else {
      let getData = {
        ...form.getFieldsValue(),
        id: !!dataLista?.id ? dataLista.id : uuidv4(),
      };
      let logs = compareObjects(dataLista, getData);
      getData = {
        ...getData,
        logs: !!dataLista?.logs ? [...dataLista.logs, logs] : [],
      };
      API.put(
        "programFields",
        "/programFields/3d077179-a545-43ec-98be-7e1bf71b09aa",
        {
          body: {
            fieldOptions: [
              ...request.fieldOptions.filter((el) => el.id !== dataLista.id),
              getData,
            ],
          },
        }
      ).then(() => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "policy_out_added",
        // 			body: "A policy was added",
        // 		})
        // 	);
        // }
        message.success("Të dhënat u regjistruan me sukses !", 10);
        form.resetFields();
        setTableData([
          ...request.fieldOptions.filter((el) => el.id !== dataLista.id),
          getData,
        ]);
        let field = [
          ...request.fieldOptions.filter((el) => el.id !== dataLista.id),
          getData,
        ];
        setRequests((prev) => ({ ...prev, fieldOptions: field }));
        setVisible(false);
        setError(false);
      });
    }
  };

  //function to compare 2 object and get the changes
  function compareObjects(previousObject, currentObject) {
    const keys1 = Object.keys(previousObject);
    const keys2 = Object.keys(currentObject);
    let changesArray = [];

    const commonKeys = keys1.filter((key) => keys2.includes(key));
    if (commonKeys.length === 0) {
    }
    commonKeys.forEach((key) => {
      if (typeof currentObject[key] === "object") {
      } else {
        if (previousObject[key] !== currentObject[key]) {
          let changedData = {
            id: uuidv4(),
            activity: "Ndryshuar",
            author: username,
            changeDate: Date.now(),
            field: key,
            previousData: previousObject[key],
            currentData: currentObject[key],
          };
          changesArray.push(changedData);
        }
      }
    });
    if (changesArray !== 0) {
      return changesArray;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (!dataLista) return;
    form.setFieldsValue(dataLista);
  }, [dataLista]);

  //region RETURN
  return (
    <Modal
      title="Politikat e orëve jashtë orarit"
      className={`paisjet-per-orarin-modal ${
        isDarkMode ? "dark darkHeader" : "lightHeader"
      }`}
      centered
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={550}
      footer={[
        <MondayButton
          onClick={() => setVisible(false)}
          className="mondayButtonRed"
        >
          Anuloje
        </MondayButton>,
        <MondayButton className="mondayButtonGreen" onClick={handleSave}>
          Ruaj ndryshimet
        </MondayButton>,
      ]}
    >
      <Form name="PaisejePerOrarin" form={form}>
        <h3>Emri i politikës:</h3>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruani emrin e politikës!",
            },
            {
              validator: (_, value) => {
                if (
                  error &&
                  !!value &&
                  records?.some(
                    ({ name }) =>
                      name.toLowerCase() === value.toLowerCase().trim()
                  )
                ) {
                  return Promise.reject(
                    new Error("Ekziston një fushë e tillë")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Emri i politikës" />
        </Form.Item>
        <h3>Përshkrimi i politikës</h3>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Ju lutem përshkruani politikën e orarit!",
            },
          ]}
        >
          <RichTextEditor className={"modal-quill-edit"} />
        </Form.Item>
        <h3>Shifra</h3>
        <Form.Item
          name="formula"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruani shifrën!",
            },
          ]}
        >
          <InputNumber min={0} max={60} placeholder="Shifra për politikën" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PaisjetOraritPostModal;
