import { useEffect, useState } from "react";

const useOpenOnce = (featureName, lsKey, delay = 1500) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let timeout;
    const localStorageData = localStorage.getItem(lsKey);
    const prev =
      Object.keys(localStorageData || {})?.length > 0
        ? JSON.parse(localStorageData)
        : {};

    timeout = setTimeout(() => {
      setOpen(!prev?.[featureName]);
    }, delay);

    if (!prev?.hasOwnProperty(featureName)) {
      const newName = { [featureName]: true };
      const updatedObject = { ...prev, ...newName };
      const updatedObjectString = JSON.stringify(updatedObject);
      localStorage.setItem(lsKey, updatedObjectString);
    }

    return () => clearTimeout(timeout);
  }, [featureName]);

  return [open, setOpen];
};

export default useOpenOnce;
