import { Badge, Col, Row } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import React, { useMemo } from "react";
import { HollowDotsSpinner } from "react-epic-spinners";
import { useSelector } from "react-redux";
import { getRandomColor } from "../Employees/Employees";

function LastThreeMonthEmployes() {
  // get the active employes fron redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { isDarkMode } = useSelector((state) => state.usersList);
  // current date
  const now = dayjsTZ();

  // will return an array of the filterd  employess that are hired last 3 months
  const dataEmp = useMemo(() => {
    if (!!activeEmployees) {
      let data = activeEmployees
        ?.filter?.(
          (el) =>
            dayjsTZ(now).diff(dayjsTZ(el?.employeeHireDate), "months", true) <=
              3 && el?.employeeHireDate !== undefined
        )
        ?.map?.((dco) => {
          return {
            ...dco,
            days:
              dayjsTZ(dco?.employeeHireDate).diff(dayjsTZ(now), "days") + 91,
          };
        });
      return data;
    }
  }, [activeEmployees]);

  return (
    <>
      {!!dataEmp ? (
        <div
          className={`no-clockin-container ${isDarkMode ? "dark" : ""}`}
          style={{ width: "100%" }}
        >
          {dataEmp
            ?.sort((a, b) => a.days - b.days)
            ?.map?.((dco) => {
              return (
                <div className="dep-icon" key={dco?.employeeId}>
                  <Badge
                    className="name-badger"
                    color={getRandomColor()}
                    text={`${dco?.employeeFirstName} ${dco?.employeeLastName} `}
                  />
                  <span className="line-card"></span>
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      justifyContent: "flex-end",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {dco?.days} ditë të mbetura
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 225,
            width: 385,
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </>
  );
}

export default LastThreeMonthEmployes;
