import { useEffect, useState } from "react";
import { useTwoFactorAuthentication } from "../../hooks/useTwoFactorAuthentication";
import { useNavigate } from "react-router";
import { Input, QRCode } from "antd";
import MondayButton from "../commonComponents/MondayButton/MondayButton";

const TwoFactorAuth = () => {
  const navigate = useNavigate();

  const [input, setInput] = useState("");

  const {
    is2FaActive,
    isLoading,
    setupUri,
    currentStep,
    verifyCode,
    handleStepChange,
    setUpTotpHandler,
    qrErrorMessage,
  } = useTwoFactorAuthentication();

  useEffect(() => {
    if (is2FaActive) {
      navigate("/");
    }
    setUpTotpHandler();
  }, []);

  return (
    <div>
      <QRCode size={256} value={setupUri} />
      <Input onChange={(e) => setInput(e)} />
      <MondayButton
        className="mondayButtonGreen"
        onClick={() => verifyCode(input)}
      />
    </div>
  );
};

export default TwoFactorAuth;
