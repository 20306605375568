import React, { useState } from "react";
import { Drawer, Button } from "antd";
import Notifications from "../../dashboard/components/notifications/Notifications";
import { ClearIconDrawer, ModalXIcon } from "../../../assets/icons";
import { useSelector } from "react-redux";
function NotificationsModal({ notifsActive, setNotifsActive }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const handleCancel = () => {
    setNotifsActive(false);
  };

  return (
    <>
      <Drawer
        title="Njoftimet"
        className={`notification-drawer ${isDarkMode ? "dark darkDrawer" : ""}`}
        width={900}
        height={100}
        // closable={false}
        onClose={handleCancel}
        open={notifsActive}
      >
        {/* <Drawer
          title="Two-level Drawer"
          width={320}
          closable={false}
          // onClose={this.onChildrenDrawerClose}
          open={true}
        >
          This is two-level drawer
        </Drawer> */}
        <Notifications handleCancel={handleCancel} />
      </Drawer>
    </>
  );
}

export default NotificationsModal;
