import React, { useState } from "react";
import { Button, Input, Modal, message } from "antd";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { CheckOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

function CreateNewIp({
  tableData,
  setTableData,
  gridApi,
  programFields,
  setProgramFields,
  username,
  visible,
  setVisible,
}) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [data, setData] = useState({
    ipAddress: "",
  });

  const onChanges = (key, val) => {
    let temp = { ...data };
    temp[key] = val;
    setData(temp);
  };

  const checkExisting = (val) => {
    let temp = [...tableData];
    let result = temp.filter((item) => item.value === val);
    return result.length > 0 ? true : false;
  };

  const handleOk = async () => {
    try {
      if (data.ipAddress !== "" && !checkExisting(data.ipAddress)) {
        let logs = programFields.fieldOptions.logs;
        let newLogsValue = {
          id: uuidv4(),
          activity: "U Shtua",
          author: username,
          changeDate: Date.now(),
          currentData: data.ipAddress,
        };
        if (logs) {
          logs?.push(newLogsValue);
        } else {
          logs = [newLogsValue];
        }

        setTableData([
          ...tableData,
          {
            id: tableData.length + 1,
            value: data.ipAddress,
          },
        ]);
        setProgramFields({
          ...programFields,
          fieldOptions: {
            whiteList: [
              ...tableData,
              {
                id: tableData.length + 1,
                value: data.ipAddress,
              },
            ],
            logs: logs,
          },
        });
        // let toPost = {
        // 	fieldOptions: {
        // 		whiteList: [...tableData?.map((el) => el?.value), data.ipAddress],
        // 	},
        // };
        await API.put(
          "programFields",
          "/programFields/4fgtf65fgf-mnjlsdfv-89000nmnbhujj",
          {
            body: {
              fieldOptions: {
                whiteList: [
                  ...tableData?.map((el) => el?.value),
                  data.ipAddress,
                ],
                logs: logs,
              },
            },
          }
        ).then(() => {
          Swal.fire({
            title: "Sukses",
            text: "Ip Adresa u shtua me sukses",
            customClass: {
              popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
            },
            icon: "success",
          });
        });
      } else {
        message.error("Ip Adresa eshte e zene ose jo e sakte");
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCleanUp();
    }
  };

  const handleCleanUp = () => {
    setVisible(false);
    setData({
      ipAddress: "",
    });
  };
  return (
    <Modal
      title="Krijo nje konfigurim tjeter"
      open={visible}
      className={isDarkMode ? "dark darkHeader" : "lightHeader"}
      // onOk={handleOk}
      onCancel={handleCleanUp}
      footer={null}
      centered
    >
      <Input
        placeholder="Ip Adresa"
        value={data.ipAddress}
        onChange={(e) => {
          onChanges("ipAddress", e.target.value);
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <MondayButton
          className="mondayButtonGreen"
          Icon={<CheckOutlined />}
          onClick={handleOk}
        >
          Shto Ip te lejuar
        </MondayButton>
      </div>
    </Modal>
  );
}

export default CreateNewIp;
