import { useState, useEffect } from "react";
import "./AdminShiftLogs.scss";
import { Drawer, message, Skeleton, Timeline } from "antd";
import {
  // getAllUsers,
  getLogsPerShift,
} from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
// import { useAuth } from "../../../../authentication/authHelpers";
// import moment from "moment-timezone";
import ShiftLogCard from "./ShiftLogCard";
import CreatedShiftCard from "./CreatedShiftCard";
import { ClearIconDrawer, CloseIcon } from "../../../../assets/icons";
// import { ClearIcon } from "../../../dashboard/assets";
// import { ClearOutlined, DeleteFilled } from "@ant-design/icons";
import { Alert } from "antd";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";

function AdminShiftLogs({
  shiftAdminLog,
  setShiftAdminLog,
  shiftLogsVisibility,
  setShiftLogsVisibility,
}) {
  const { users, isDarkMode } = useSelector((state) => state.usersList);

  const [shiftLogs, setShiftLogs] = useState(null);
  const [approvedEvents, setApprovedEvents] = useState(null);

  const onClose = () => {
    setShiftLogsVisibility(false);
    setShiftLogs(null);
    setShiftAdminLog(null);
  };

  const initializeLogs = async () => {
    await getLogsPerShift(shiftAdminLog.clockingId)
      .then((res) => {
        if (res.length > 0) {
          const result = res.sort((a, b) => b.createdAt - a.createdAt);
          setShiftLogs(result);
        } else {
          setShiftLogs([]);
        }
      })
      .catch((er) => {
        console.error("Something wrong happened!", er);
        setShiftLogs([]);
      })
      .finally(() => {
        if (!!shiftAdminLog?.totalities?.adminLog?.approvedBy) {
          const applicable = shiftAdminLog?.totalities?.adminLog;
          setApprovedEvents({
            type: applicable?.approved,
            text: `${
              applicable?.approved
                ? `Aprovuar nga ${applicable?.approvedBy}`
                : `${applicable?.approvedBy} hoqi Aprovimin`
            } në ${dayjsTZ(applicable?.createdAt).format("DD/MM/YYYY HH:mm")}!`,
          });
        }
      });
  };

  // const onDel = async () => {
  // 	await API.del("clockingLogs", "/clockingLogs/443d1160-1c8e-11ed-8a89-cb31ae3061bc").then(
  // 		(succ) => console.log("success", succ)
  // 	);
  // };

  useEffect(() => {
    if (!!shiftAdminLog && !!users) {
      initializeLogs();
    }
  }, [shiftAdminLog, users]);

  return (
    <Drawer
      title="Historiku i turnit"
      placement="right"
      className={isDarkMode ? "dark darkDrawer" : ""}
      onClose={onClose}
      open={shiftLogsVisibility}
      width={576}
      destroyOnClose={true}
      closeIcon={<ClearIconDrawer fill={isDarkMode ? "#e6e6e6" : "#1d3445"} />}
    >
      {Array.isArray(shiftLogs) ? (
        <div className="shift-log-cards-container">
          {!!approvedEvents ? (
            <div>
              {" "}
              <Alert
                message={approvedEvents?.text ?? ""}
                showIcon
                type={approvedEvents?.type === true ? "success" : "error"}
              />
            </div>
          ) : null}
          {shiftLogs.length > 0 ? (
            shiftLogs.map((el, idx) => <ShiftLogCard content={el} key={idx} />)
          ) : (
            <div className="alert-no-changes">
              <Alert
                message="Nuk ka ndryshime për këtë turn!"
                type="info"
                showIcon
              />
            </div>
          )}
          {!!shiftAdminLog?.clockingLogs ? (
            <CreatedShiftCard
              content={shiftAdminLog}
              users={users?.allUsers?.Items}
            />
          ) : null}
        </div>
      ) : (
        [...Array(5)].map(() => <Skeleton active />)
      )}
    </Drawer>
  );
}

export default AdminShiftLogs;
