import { Modal } from 'antd'
import React from 'react'
import { TickIcon, Warning, ModalXIcon, XIcon } from '../../../assets/icons';
import MondayButton from '../../commonComponents/MondayButton/MondayButton';
import { API } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const ConfirmModal = ({
    showClearConfirmModal,
    confirmChangesType,
    setShowClearConfirmModal,
    rowData,
    setRowData,
    setChanges,
    deletedFields,
    programField,
    setProgramField
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

    const handleSubmit = () => {
        Swal.fire({
          title: "Ju lutem prisni...",
          customClass: {
            popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
          },
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let gridRowData = [];
        rowData?.forEach((row) => {
          deletedFields?.forEach((deleted) => {
            if (row?.step === deleted?.step) {
              gridRowData.push({
                ...row,
                fields: row?.fields?.concat(deleted?.fields),
              });
            }
          });
        });

        if (confirmChangesType === "Confirm") {
            API.put("programFields", `/programFields/${programField?.fieldId}`, {
              body: {
                fieldOptions: [...gridRowData],
              },
            })
              .then(() => {
                setShowClearConfirmModal(false);
                setChanges(false);
                setProgramField(gridRowData);
                Swal.fire({
                  icon: "success",
                  title: "Ndryshimet u ruajten!",
                  customClass: {
                    popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
                  },
                });
              })
              .catch((err) => {
                console.log("Error: ", err);
              });
        } else {
            setRowData(programField?.fieldOptions)
            setShowClearConfirmModal(!showClearConfirmModal)
            setChanges(false)
            Swal.fire({
              icon: "success",
              title: "Ndryshimet u anulluan!",
              customClass: {
                popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
              },
              didOpen: () => {
                Swal.hideLoading();
              },
              // timer: 1500,
            });
        }
    }

    return (
      <Modal
        open={showClearConfirmModal}
        centered
        onCancel={() => setShowClearConfirmModal(!showClearConfirmModal)}
        title="Warning Message"
        className="confirm-modal lightHeader"
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon width={16} height={16} />}
            onClick={() => setShowClearConfirmModal(!showClearConfirmModal)}
          >
            Jo, më vonë
          </MondayButton>,
          <MondayButton
            Icon={<TickIcon width={18} height={18} />}
            onClick={handleSubmit}
            className="mondayButtonGreen"
          >
            Po,{" "}{confirmChangesType === "Confirm" ? "ruaj" : "anullo"}
          </MondayButton>,
        ]}
      >
        <div className="confirm-body">
          <Warning width={60} height={60} />
          <p>{`A jeni të sigurt që doni ti ${
            confirmChangesType === "Confirm" ? "ruani" : "anulloni"
          } ndryshimet?`}</p>
        </div>
      </Modal>
    );
}

export default ConfirmModal