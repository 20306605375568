import { Modal, Button, Form, message, Input } from "antd";
import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { RichTextEditor } from "../../../commonComponents";
import { useProgramFields } from "../../../../hooks/useProgramFields";
import { useAuth } from "../../../../authentication/authHelpers";
import _ from "lodash";
import { useSelector } from "react-redux";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

const programFieldsId = {
	POLITIKAT_DITET_PUSHIIM: "52ad6701-32b0-414c-92c6-549bc45c5656",
};

function PolitikatDitetPushimModal({
	setVisible,
	visible,
	request,
	setTableData,
	setRequests,
	tableData,
	dataLista,
	setError,
	error,
	socketHandler,
}) {
	const [form] = Form.useForm();

	const { isDarkMode } = useSelector((state) => state.usersList);

	const { POLITIKAT_DITET_PUSHIIM } = programFieldsId;
	const { [POLITIKAT_DITET_PUSHIIM]: records } = useProgramFields();
	const { userAccess } = useAuth();

	const username = {
		userId: userAccess[0].userSub,
		employeeFirstName: userAccess[0].given_name,
		employeeLastname: userAccess[0].family_name,
	};
	//save the new data



	const handleSave = () => {
		if (!dataLista) {
			const formData = { ...form.getFieldsValue(), id: uuidv4() };
			form.resetFields();
			API.put("programFields", `/programFields/52ad6701-32b0-414c-92c6-549bc45c5656`, {
				body: {
					fieldOptions: [...request.fieldOptions, formData],
				},
			}).then(() => {
				/**
				 * * @EneaXharau - Added socket emitter
				 * * .send() takes -> { request: String, body: any} -> stringified
				 */
				// if (socketHandler.readyState === socketHandler.OPEN) {
				// 	socketHandler.send(
				// 		JSON.stringify({
				// 			request: "policy_updated",
				// 			body: "A policy was added/updated",
				// 		})
				// 	);
				// }
				message.success("Të dhënat u regjistruan me sukses !", 10);
				setTableData([...tableData, formData]);
				setVisible(false);
			});
			let field = [...request.fieldOptions, formData];
			setRequests((prev) => ({ ...prev, fieldOptions: field }));
		} else {
			let getData = { ...form.getFieldsValue(), id: !!dataLista?.id ? dataLista.id : uuidv4() };
			let logs = compareObjects(dataLista, getData)
			getData = { ...getData, logs: !!dataLista?.logs ? [...dataLista.logs, logs] : [] }
			API.put("programFields", "/programFields/52ad6701-32b0-414c-92c6-549bc45c5656", {
				body: {
					fieldOptions: [...request.fieldOptions.filter((el) => el.id !== dataLista.id), getData],
				},
			}).then(() => {
				/**
				 * * @EneaXharau - Added socket emitter
				 * * .send() takes -> { request: String, body: any} -> stringified
				 */
				// if (socketHandler.readyState === socketHandler.OPEN) {
				// 	socketHandler.send(
				// 		JSON.stringify({
				// 			request: "policy_updated",
				// 			body: "A policy was added/updated",
				// 		})
				// 	);
				// }
				message.success("Të dhënat u regjistruan me sukses !", 10);
				form.resetFields();
				setTableData([...request.fieldOptions.filter((el) => el.id !== dataLista.id), getData]);
				let field = [...request.fieldOptions.filter((el) => el.id !== dataLista.id), getData];
				setRequests((prev) => ({ ...prev, fieldOptions: field }));
				setVisible(false);
				setError(false);
			});
		}
	};

	//function to compare 2 object and get the changes
	function compareObjects(previousObject, currentObject) {
		const keys1 = Object.keys(previousObject);
		const keys2 = Object.keys(currentObject);
		let changesArray = [];

		const commonKeys = keys1.filter((key) => keys2.includes(key));
		if (commonKeys.length === 0) {
		}
		commonKeys.forEach((key) => {
			if (typeof currentObject[key] === "object") {

			} else {
				if (previousObject[key] !== currentObject[key]) {
					let changedData = {
						id: uuidv4(),
						activity: "Ndryshuar",
						author: username,
						changeDate: Date.now(),
						field: key,
						previousData: previousObject[key],
						currentData: currentObject[key],
					};
					changesArray.push(changedData);
				}
			}
		});
		if (changesArray !== 0) {
			return changesArray;
		} else {
			return false;
		}
	}


	//fill in the form with data if is modal opend for edit
	useEffect(() => {
		if (!dataLista) return;
		form.setFieldsValue(dataLista);
	}, [dataLista]);
	return (
		<div>
			<Modal
				title="Politikat për ditët pushim"
				className={`paisjet-per-orarin-modal ${isDarkMode ? "dark darkHeader" : "lightHeader"}`}
				centered
				open={visible}
				onOk={() => setVisible(false)}
				onCancel={() => setVisible(false)}
				width={550}
				 footer={[
          <MondayButton
						onClick={() => setVisible(false)}
						className="mondayButtonRed"
					>
            Anuloje
          </MondayButton>,
          <MondayButton
            onClick={handleSave}
            className="mondayButtonGreen"
          >
            Ruaj ndryshimet
          </MondayButton>,
        ]}
			>
				<Form form={form}>
					<h3>Emri i dites pushim:</h3>
					<Form.Item
						name="name"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruani emrin e dites pushim!",
							},
							{
								validator: (_, value) => {
									if (
										error &&
										!!value &&
										records?.some(({ name }) => name.toLowerCase() === value.toLowerCase().trim())
									) {
										return Promise.reject(new Error("Ekziston nje dite pushimi e tille"));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input placeholder="Emri i dites pushim" />
					</Form.Item>
					<h3>Përshkrimi për ditën e pushimit:</h3>
					<Form.Item
						name="description"
						rules={[
							{
								required: true,
								message: "Ju lutem përshkruani ditën e pushimit!",
							},
						]}
					>
						<RichTextEditor
							className={"modal-quill-edit"}
						// placeholder="Përshkrimi për ditën e pushimit"
						/>
						{/* <Input placeholder="Përshkrimi për ditën e pushimit" /> */}
					</Form.Item>
					<h3>Formula:</h3>
					<Form.Item
						name="formula"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruani formulen!",
							},
						]}
					>
						<Input placeholder="Formula" />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
export default PolitikatDitetPushimModal;
