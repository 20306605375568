import "./AgGridHeader.scss";
import {
  AgGridToExcel,
  AgGridToPDF,
  AgGridToPrint,
  SearchAgGrid,
  BulkDeleteAgGrid,
  SwitchAgGrid,
  FilterAgGrid,
  DropdownAgGrid,
} from "./components";
import MondayButton from "../MondayButton/MondayButton";
import { RefreshIcon } from "../../../assets/icons";
import { LogsIcon } from "../../Documentation/View/assets";
import PropTypes from "prop-types";
import colorShade from "../../../utils/colorShade";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { Collapse, Tooltip } from "antd";
import mobileRowIdsByKey from "./mobileRowIdsByKey";
import hexToRgba from "../../../utils/hexToRgba";
import { useSelector } from "react-redux";

/**
 * @typedef tableCol
 * @property {string} en key that exists in AgGrid's columnDefs
 * @property {string} al albanian translated key that will replace 'en' at table headers on export
 */
/**
 * @typedef excel/pdf/print
 * @property {Array<Object>} tableCols provide which keys from AgGrid to export
 */
/**
 * @typedef icon
 * @property {Object} filter provide to add FilterAgGrid component to table
 * @property {Object} delete provide to add icon for AgGrid multiple rows deletion
 * @property {Object} excel provide to add icon of Excel export for AgGrid
 * @property {Object} pdf provide to add icon of PDF export for AgGrid
 * @property {Object} print provide to add icon of print export for AgGrid
 */
/**
 * @typedef item
 * @property {Object} search provide empty object of key search to add search component
 * @property {Object} switch provide empty object of key switch to add switch component
 * @property {Object} icons provide icons object to add to add specific components to header
 * @property {Array<Object>} dropdowns provide dropdowns array to add filtering dropdowns
 */
/**
 * @typedef props
 *
 *
 * @property {Object} gridApi Ag Grid API
 * @property {Array<Object>} rowData Ag Grid data
 * @property {Object<Object>} items elements of the Header
 * @property {string} exportTitle the filename to be used on export functions
 * @property {Array<ReactNodes>} children extra components from the parent to render alongside
 * @property {function} modifyRows provide this function to manipulate rowData before exporting/filtering
 */
/**
 *
 * @param {props} props
 */

const tooltipWrapper = ({ placement, className, title }, content) => (
  <Tooltip
    placement={placement || "top"}
    classNames={{ root: className || "global-icon-tooltip" }}
    title={title || ""}
  >
    {content}
  </Tooltip>
);

const AgGridHeader = ({
  gridApi,
  rowData,
  selectedObj,
  setSelectedObj,
  activeFilter,
  setActiveFilter,
  items,
  exportTitle,
  children,
  modifyRows,
  idKey,
  togglerUtils,
  columnDefs,
}) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });
  const { mode, accentColor, foregroundColor, backgroundColor } = useMemo(
    () => getAgThemePreference(),
    [isDarkMode]
  );

  const handleClean = (callback) => {
    if (!!gridApi) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged(null);
      gridApi.setGridOption("quickFilterText", null);
    }
    !!setActiveFilter && setActiveFilter({});
    !!setSelectedObj && setSelectedObj({});
    !!callback && callback();
  };

  const themeStyle = useMemo(() => {
    return {
      backgroundColor: mode.includes("dark")
        ? colorShade(accentColor, 40)
        : colorShade(accentColor, 0.95),
      color: foregroundColor,
      fill: foregroundColor,
    };
  }, [isDarkMode]);

  const collapseWrapper = (props) => {
    if (Array.isArray(props) && isMobileView) {
      let newProps = [...props];
      let search;
      const hasSearch = !!Object.keys(items || {}).includes("search");
      if (!!hasSearch) {
        search = newProps.shift();
      }

      const collapseItems = {
        key: 0,
        label: (
          <div className="collapseLabel">
            {search}
            <MondayButton
              containerClassName="mondayButtonGreen"
              onClick={(e) =>
                togglerUtils?.toggleAll(e, !togglerUtils?.toggled)
              }
              style={{
                ...themeStyle,
                fontWeight: 600,
                border: `1px solid ${accentColor}`,
                //more appealing to the eye
                width: 75,
              }}
            >
              {togglerUtils?.toggled ? "Expand" : "Collapse"}
            </MondayButton>
          </div>
        ),
        children: newProps,
      };
      return (
        <Collapse items={[collapseItems]} accordion expandIconPosition="end" />
      );
    } else {
      return props;
    }
  };

  //region RETURN
  return (
    <div
      className={`agGridHeader ${isDarkMode ? "dark" : ""}`}
      data-testid="agGridHeader"
      style={{
        background: mode.includes("dark")
          ? backgroundColor
          : isMobileView
          ? hexToRgba(foregroundColor, 0.05)
          : "#fff",
        ...(!mode.includes("dark") && isMobileView
          ? { border: `1px solid ${foregroundColor}` }
          : {}),
      }}
    >
      {collapseWrapper(
        Object.entries(items)
          ?.map(([key = "", props = {}], idx) => {
            if (key.toLowerCase() === "search") {
              return (
                <SearchAgGrid
                  key={idx}
                  {...{
                    gridApi,
                    initialValue: props?.initialValue,
                    handleClean: () => handleClean(props?.handleClean),
                    style: themeStyle,
                    setActiveFilter,
                    rowData,
                  }}
                />
              );
            } else if (key.toLowerCase() === "dropdowns") {
              return props?.map((prop, i) => (
                <DropdownAgGrid
                  {...prop}
                  key={i}
                  rowData={rowData}
                  gridApi={gridApi}
                  style={themeStyle}
                  value={activeFilter?.[prop?.keyName]}
                  setActiveFilter={setActiveFilter}
                />
              ));
            } else if (key.toLowerCase() === "icons") {
              //new keys will be added if new mobile features are created
              const selectedIds = mobileRowIdsByKey(selectedObj, "selected");
              let selectedRowData = [...(rowData || [])];
              if (!!selectedIds?.length) {
                selectedRowData = selectedRowData?.filter((el) =>
                  selectedIds.includes(el?.[idKey])
                );
              }
              const exportProps = {
                gridApi,
                rowData: selectedRowData,
                selectedIds,
                title: exportTitle,
                modifyRows,
                idKey,
              };
              return (
                <div className="headerIcons">
                  {Object.entries(props)?.map(
                    ([iconKey = "", iProps = {}], i) => {
                      //default tableCols here from coldefs
                      const tableCols = (columnDefs || [])
                        .filter(({ isExported }) => !!isExported)
                        .map((el) => {
                          const custom = iProps?.tableCols?.find(
                            (c) => c?.en === el?.field
                          );
                          return {
                            ...el,
                            ...(custom || {}),
                            en: el?.field,
                            al: el?.headerName,
                          };
                        });
                      const isExportIcon = ["excel", "pdf", "print"].includes(
                        iconKey
                      );
                      const iconProps = {
                        tableCols,
                        ...(!!tableCols.length && isExportIcon ? {} : iProps),
                        style: themeStyle,
                      };
                      if (iconKey.toLowerCase() === "filter") {
                        return (
                          <FilterAgGrid
                            key={`${idx}-${i}`}
                            gridApi={gridApi}
                            rowData={rowData}
                            setActiveFilter={setActiveFilter}
                            clearFilters={handleClean}
                            {...iconProps}
                            style={themeStyle}
                          />
                        );
                      }
                      if (iconKey.toLowerCase() === "excel") {
                        return (
                          <AgGridToExcel
                            {...exportProps}
                            {...iconProps}
                            key={`${idx}-${i}`}
                          />
                        );
                      }
                      if (iconKey.toLowerCase() === "pdf") {
                        return (
                          <AgGridToPDF
                            {...exportProps}
                            {...iconProps}
                            key={`${idx}-${i}`}
                          />
                        );
                      }
                      if (iconKey.toLowerCase() === "print") {
                        return (
                          <AgGridToPrint
                            {...exportProps}
                            {...iconProps}
                            key={`${idx}-${i}`}
                          />
                        );
                      }
                      if (iconKey.toLowerCase() === "delete") {
                        return (
                          <BulkDeleteAgGrid
                            {...exportProps}
                            {...iconProps}
                            key={`${idx}-${i}`}
                          />
                        );
                      }
                      if (iconKey.toLowerCase() === "refresh") {
                        return (
                          <div className="headerIcon" {...iconProps}>
                            {tooltipWrapper(
                              { title: "Rifresko të dhënat" },
                              <RefreshIcon
                                width={20}
                                height={20}
                                fill={themeStyle?.fill || "#323338"}
                              />
                            )}
                          </div>
                        );
                      }
                      if (iconKey.toLowerCase() === "modals") {
                        return (props[iconKey] || []).map((el, i) => (
                          <div
                            className="headerIcon"
                            {...el}
                            style={themeStyle}
                            key={`${idx}-${i}`}
                          >
                            {tooltipWrapper(
                              el,
                              el?.icon || (
                                <LogsIcon
                                  width={20}
                                  height={20}
                                  fill={themeStyle?.fill || "#323338"}
                                />
                              )
                            )}
                          </div>
                        ));
                      }
                    }
                  )}
                </div>
              );
            } else return null;
          })
          .concat(!!children ? children : [])
          .concat(
            !!items["switch"] ? (
              <SwitchAgGrid {...(items["switch"] || {})} style={themeStyle} />
            ) : (
              []
            )
          )
      )}
    </div>
  );
};

export default AgGridHeader;

AgGridHeader.propTypes = {
  gridApi: PropTypes.object,
  rowData: PropTypes.array.isRequired,
  items: PropTypes.object.isRequired,
  exportTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  modifyRows: PropTypes.func,
};
