import { useState, useEffect, useRef, useMemo } from "react";
import { DeleteFilled, EyeFilled } from "@ant-design/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { getAnnouncements, sortDates } from "./utils/methods";
import HappyHolidaysModal from "../../assets/holidays/Modals/HappyHolidaysModal";
import ModalAnnounce from "./ModalAnnounce/ModalAnnounce";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import "./Announcements.scss";
import { htmlParser } from "../../utils";
import { IconEditWhite, IconExcel, IconNgargo } from "../../assets/icons";
import AnnouncementReplies from "./AnnouncementReplies/AnnouncementReplies";
import NewAnnouncement from "./NewAnnouncement/NewAnnouncement";
import MondayButton from "../commonComponents/MondayButton/MondayButton";
import { useMediaQuery } from "react-responsive";
import { colDefs as columnDefs } from "./colDefs";
import { useSelector } from "react-redux";

const initialData = {
  announcementTitle: "",
  announcementBody: "",
  announcementUrl: "https://portal.flex.al/kryefaqja",
  announcementTarget: {
    departments: [],
  },
  announcementTeam: {
    teams: [],
  },
  endAnnouncementDate: null,
  userResponses: {},
  announcementCategory: "needAction",
  createdBy: "",
};
export const categories = {
  importantAnnouncement: "Lajmërim",
  needAction: "Lajmërim që ka nevojë për përgjigje",
  otherWish: "Urim",
};

const FILTER_KEYS = [
  { key: "createdBy", label: "Krijuar nga", type: "string" },
  { key: "announcementTitle", label: "Titulli", type: "string" },
  {
    key: "announcementCategory",
    label: "Kategoria",
    type: "string",
    filterType: "equals",
  },
  {
    key: "createdAt",
    label: "Koha e krijimit",
    type: "date-range",
  },
];
const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: FILTER_KEYS,
      replaceable: {
        announcementCategory: Object.entries(categories).map(([_, val]) => ({
          [val]: val,
        })),
      },
    },
    excel: {},
    pdf: {},
    print: {},
    delete: { endpoint: "announcements" },
  },
};

function Announcements() {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialData);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState({
    modeType: "new",
    otherData: {},
  }); // OTHER MODES: edit, view

  const retrievedData = async () => {
    await getAnnouncements().then((r) => {
      const newRowData = (r || [])
        .map((el) => {
          let toReturn = { ...(el || {}), responses: {} };
          Object.values(el?.usersResponses || {}).forEach(
            ({ response: res = "" }) => {
              if (!!res) {
                toReturn.responses = {
                  ...(toReturn.responses || {}),
                  [res]: (toReturn.responses[res] || 0) + 1,
                };
              }
            }
          );
          return toReturn;
        })
        .sort((a, b) => dayjsTZ(b.createdAt).diff(dayjsTZ(a.createdAt)));

      setRowData(newRowData);
      setLoading(false);
    });
  };

  const retrieveEditData = (data) => {
    setData(data);
    setVisible(true);
    setMode({ modeType: "edit", otherData: data });
  };

  const onReset = () => {
    setData(initialData);
    setMode({ modeType: "new", otherData: {} });
  };

  // region MODIFYROWSTOEXP
  const modifyRowsToExport = (rows) => {
    if (!rows?.length) return [];
    let modifiedRows = [];
    rows?.forEach((row) => {
      const structuredResponses = [];
      for (const [key, obj] of Object.entries(row?.usersResponses || [])) {
        structuredResponses.push({ key: key, ...obj });
      }
      let userResponses = Object.groupBy(
        structuredResponses,
        ({ response }) => response
      );
      const responses = [];
      for (const [res, arr] of Object.entries(userResponses)) {
        responses.push(`${res}: ${arr?.length}`);
      }
      const toReturn = {
        ...row,
        announcementCategory: categories[row?.announcementCategory],
        usersResponses: !!responses?.length ? responses : 0,
      };
      modifiedRows.push(toReturn);
    });
    return modifiedRows;
  };

  const sortedAnnouncements = (data = []) =>
    data?.sort(
      (a, b) =>
        dayjsTZ(a?.createdAt).valueOf() - dayjsTZ(b?.createdAt).valueOf()
    );

  useEffect(() => {
    retrievedData();
  }, []);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const colDefs = useMemo(() => {
    const cols = [
      {
        headerName: "Konfirmoi",
        field: "confirm",
        color: "#1da193",
      },
      {
        headerName: "Anashkaloi",
        field: "skip",
        color: isDarkMode ? "#717171" : "#f5f5f7",
      },
      {
        headerName: "Refuzoi",
        field: "refuse",
        color: "#e74646",
      },
    ];

    const customCols = cols.map(({ headerName, field, color }) => ({
      headerName,
      field: `responses.${field}`,
      width: 75,
      cellRenderer: ({ data }) =>
        data?.responses?.[field] ? (
          <span
            style={{
              backgroundColor: color,
              padding: "7px 10px",
              borderRadius: 5,
            }}
          >
            {data?.responses?.[field]}
          </span>
        ) : null,
    }));

    return columnDefs({
      retrieveEditData,
      isMobileView,
      customCols,
      categories,
    });
  }, [isMobileView]);

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="announcementsWrapper">
          <AgGridComponent
            gridApi={gridApi}
            rowData={sortedAnnouncements(rowData)}
            rowSelection={{ mode: "multiRow" }}
            onGridReady={(p) => setGridApi(p.api)}
            columnDefs={colDefs}
            paginator={true}
            headerProps={{
              items: ITEMS,
              exportTitle: "Lista e Lajmërimeve",
              modifyRows: modifyRowsToExport,
              children: [
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => setVisible(true)}
                >
                  Lajmërim i Ri
                </MondayButton>,
              ],
            }}
            idKey={"announcementId"}
            onRowClick={(_, row) => retrieveEditData(row)}
          />
        </div>
      </div>
      {!!visible ? (
        <NewAnnouncement
          {...{
            mode,
            data: !!Object.keys(data).length ? data : initialData,
            gridApi,
            onReset,
            visible,
            setVisible,
            retrievedData,
          }}
        />
      ) : null}
    </LoadableComp>
  );
}

export default Announcements;

export const data = [
  [
    "Lloji",
    "Numri i pergjigjeve",
    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Konfirmoi", 8.94, "#b87333", null],
  ["Anashkaloi", 10.49, "silver", null],
  ["Refuzoi", 19.3, "gold", null],
];
