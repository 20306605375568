import { useState } from "react";
import { useAuth } from "../authentication/authHelpers";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import { message } from "antd";
import { apiPut } from "../components/DocumentationConfiguration/utils/api";
import { users as usersReducer } from "../store/actions";
import { useSelector } from "react-redux";

export const useTwoFactorAuthentication = () => {
  const { user, authUser, checkMFAStatus, is2FaActive } = useAuth();

  const { users } = useSelector((state) => state.usersList);

  const [setupUri, setSetupUri] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [qrErrorMessage, setQrErrorMessage] = useState(null);

  const identityId = user?.identityId;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const setUpTotpHandler = async () => {
    if (qrErrorMessage) setQrErrorMessage(null);
    try {
      const totpSetupDetails = await Auth.setupTOTP(authUser);
      const appName = "Core Portal";
      const uri = `otpauth://totp/${appName}:${authUser?.attributes?.email}?secret=${totpSetupDetails}&issuer=${appName}`;
      setSetupUri(uri);
    } catch (error) {
      console.error("Error setting up TOTP", error);
      setQrErrorMessage("Error generating QR code.");
      throw error;
    }
  };

  const verifyCode = async (code) => {
    try {
      await Auth.verifyTotpToken(user, code);
      await Auth.setPreferredMFA(user, "TOTP");
      // await apiPut("userConfiguration", identityId, {
      //   is2FaEnabled: true,
      // });
      // dispatch(updateUserKeysUser({ identityId, is2FaEnabled: false }));
      message.success({ content: "Success! 2FA setup was successful." });
      checkMFAStatus(user);
      if (location.pathname === "/two-factor-authentication") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error verifying TOTP setup", error);
      throw error;
    }
  };

  const disableTotp = async (code) => {
    if (code) {
      try {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, "NOMFA");
        await apiPut("users", identityId, {
          is2FaEnabled: false,
        });
        dispatch(
          usersReducer(users, {
            type: "EDIT_USER",
            payload: { ...user, is2FaEnabled: false },
          })
        );
        message.success({ content: "2FA disabled!" });
        checkMFAStatus(user);
        setCurrentStep(0);
        return true;
      } catch (error) {
        console.error("Error disabling 2FA", error);
        message.error({ content: "Error disabling 2FA." });
        throw error;
      }
    }
    throw new Error({ message: "Code is required." });
  };

  const handleStepChange = (direction) => {
    setCurrentStep((prev) => prev + direction);
  };

  return {
    setupUri,
    currentStep,
    isEnabled: is2FaActive,
    verifyCode,
    disableTotp,
    handleStepChange,
    qrErrorMessage,
    setUpTotpHandler,
  };
};
