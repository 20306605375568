export const updateKeylogStructure = (array) => {
  const newArray = [];
  (array || []).forEach((el) => {
    const logs = [];
    const oldLogs = el?.logs || [];
    oldLogs.forEach((log) => {
      const author =
        !!log?.author?.employeeFirstName && log?.author?.employeeLastname
          ? log?.author?.employeeFirstName + " " + log?.author?.employeeLastname
          : log?.author;

      let obj = {
        ...(log || {}),
        newValue: log?.currentData || log?.newValue,
        oldValue: log?.previousData || log?.oldValue,
        userSub: log?.author?.userId || log?.userSub,
        author,
      };
      const keys = [
        ["currentData", "newValue"],
        ["previousData", "oldValue"],
      ];
      keys.forEach(([o, n]) => {
        if (obj.hasOwnProperty(o)) {
          Object.defineProperty(
            obj,
            n,
            Object.getOwnPropertyDescriptor(obj, o)
          );
          delete obj[o];
        }
      });
      delete obj["id"];
      logs.push(obj);
    });
    newArray.push({ ...el, logs });
  });
  return newArray;
};
