import React, { useState, useContext, useMemo } from "react";
import "./KanbanFilters.scss";
import { Button, DatePicker, Input, Modal, Select } from "antd";
import { CloseIcon, ModalXIcon } from "../../../assets/icons";
import Swal from "sweetalert2";
import { InterviewsContext } from "../data";
import { useSelector } from "react-redux";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import RenderDynamicComponents from "../../RenderDynamicComponents/RenderDynamicComponents";

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const KanbanFilters = () => {
  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const {
    activeFilters,
    openFilters,
    setOpenFilters,
    setExcecute,
    setActiveFilters,
    setIntervistat,
    defaultSwalProperties,
  } = useContext(InterviewsContext);

  const [filters, setFilters] = useState(activeFilters || {});

  const options = (key = "") => {
    let options = [];
    candidates?.forEach?.((kandidati) => {
      const { [key]: value } = kandidati;
      if (value && !options?.includes?.(value)) {
        options.push(
          Array.isArray(value) && value?.length > 0 ? value?.[0] : value
        );
      }
    });
    // return options?.map?.((option) => ({ label: option, value: option }));
    return options;
  };

  const createFilters = (value, key) => {
    if (value) {
      setFilters({ ...filters, [key]: value });
    } else {
      let fltrs = { ...filters };
      delete fltrs[key];
      delete activeFilters[key];
      setFilters(fltrs);
    }
  };
  //region ON SAVE
  const handleClick = () => {
    if (!Object.keys(filters)?.length) {
      setExcecute(false);
      setOpenFilters(false);
      setIntervistat(candidates);
      Swal.mixin({
        ...defaultSwalProperties,
      }).fire({
        icon: "question",
        title: "Nuk keni aplikuar asnje filter!",
      });
      return;
    }
    setActiveFilters({ ...activeFilters, ...filters });
    setExcecute(true);
    setOpenFilters(false);
    Swal.mixin({
      ...defaultSwalProperties,
    }).fire({
      icon: "success",
      title: "Filtrat u aplikuan me sukses!",
    });
  };

  //region filterItems
  const filterItems = useMemo(() => {
    const { fieldOptions = [] } = programFields?.find(
      (el) => el?.fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
    );
    let fields = fieldOptions
      .flatMap((step) => step?.fields)
      ?.map((field) => ({
        ...field,
        onChange: (value) => createFilters(value, field?.formItemName),
        onSelect: (value) => createFilters(value, field?.formItemName),
        options: options(field?.formItemName),
        type:
          field.type === "datepicker"
            ? "daterangepicker"
            : field.type === "timepicker"
            ? "timerangepicker"
            : "select",
      }));

    return fields;
  }, [programFields]);

  //region RETURN
  return (
    <Modal
      title="Filtroni"
      className={`kanban-filters-modal ${
        isDarkMode ? "darkHeader" : "lightHeader"
      }`}
      centered
      data-testid="kanbanFiltersModal"
      open={openFilters}
      onCancel={() => setOpenFilters(false)}
      width={870}
      closeIcon={<ModalXIcon />}
      footer={[
        <MondayButton
          onClick={() => setOpenFilters(false)}
          className="mondayButtonRed"
          testid="mbyllBtn"
        >
          Mbyllni faqen
        </MondayButton>,
        <MondayButton
          onClick={handleClick}
          className="mondayButtonGreen"
          testid="ruajBtn"
        >
          Aplikoni filtrimin
        </MondayButton>,
      ]}
    >
      <div className={`filtersContent ${isDarkMode ? "dark" : ""}`}>
        {RenderDynamicComponents(filterItems)}
      </div>
    </Modal>
  );
};

export default KanbanFilters;
