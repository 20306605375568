import React, { useEffect, useState, useMemo } from "react";
import "./AddEmployeeModal/AddEmployeeModal.scss";
import "./Punonjesit.scss";
import {
  IconPunonjesit,
  PunojsitAcitvIcon,
  PunojsitLarguarIcon,
  ShkarkuarIcon,
} from "../../assets/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import { API } from "aws-amplify";
import dayjsTZ from "../../utils/dayjs";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { useLocation, useNavigate } from "react-router";
import { GeneralInformationsContext } from "./AddEmployeeModal/context";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import AddEmployeModal from "./components/AddEmployeModal";
import MondayButton from "../commonComponents/MondayButton/MondayButton";
import getAgThemePreference from "../../utils/getAgThemePreference";
import colorShade from "../../utils/colorShade";
import colDefs from "./colDefs";
import collapseWrapper from "../AG-grid/collapseWrapper";
import { message } from "antd";

// * @EneaXharau -  Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

const EMPLOYEE_STATUSES_FIELD_ID = "ea2e2537-0b6a-4292-aca8-62eafecbfad1";

//region PUNONJESIT
const Punonjesit = () => {
  const navigate = useNavigate();

  const { stateValue, filterKey } = useLocation()?.state || {};

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [rowData, setRowData] = useState([]);
  const [filteredRowData, setFilteredRowData] = useState({});
  const [activeFilter, setActiveFilter] = useState("Aktiv");
  const [showActiveEmployees, setShowActiveEmployees] = useState(true);
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [files, setFiles] = useState([]);
  const [visible, setVisible] = useState(false);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const { themePreference, themeStyle } = useMemo(() => {
    const themePreference = getAgThemePreference();

    const { mode, headerTextColor, backgroundColor, foregroundColor } =
      themePreference || {};

    return {
      themePreference,
      themeStyle: mode.includes("dark")
        ? {
            backgroundColor: "#121212",
            color: headerTextColor,
            // fill: headerTextColor,
            fill: isDarkMode ? "#e6e6e6" : backgroundColor,
            boxShadow: "0px 0px 5px #75eade",
          }
        : {
            backgroundColor,
            color: foregroundColor,
            fill: foregroundColor,
          },
    };
  }, [isDarkMode]);

  const columnDefs = useMemo(
    () =>
      colDefs({
        navigate,
        isMobileView,
        renderConditions: {
          employeeContractEndDate: showActiveEmployees,
        },
        ...(themePreference || {}),
      }),
    [isMobileView, showActiveEmployees, themePreference]
  );

  const DROPDOWNS = [
    {
      keyName: "employeeDepartmentName",
      placeholder: "Të gjithë",
      icon: (
        <IconPunonjesit width={15} fill={themePreference?.foregroundColor} />
      ),
      style: { color: themePreference?.foregroundColor },
    },
    {
      keyName: "employeeTeam",
      placeholder: "Filtro Branch",
      icon: (
        <IconPunonjesit width={15} fill={themePreference?.foregroundColor} />
      ),
      style: { color: themePreference?.foregroundColor },
    },
  ];

  //region employeeStatuses
  const employeeStatuses = useMemo(() => {
    if (!programFields) return {};
    const filterData = programFields?.find(
      (data) => data?.fieldId === EMPLOYEE_STATUSES_FIELD_ID
    );

    if (!filterData) return {};
    const fieldOptions =
      filterData?.fieldOptions.filter((item) => item?.name !== "Logs") || [];

    let statuses = {};
    fieldOptions.forEach((el) => {
      statuses[el?.name] = el?.color;
    });

    return statuses;
  }, [programFields]);

  //region >filterByKey()
  const filterByKey = (arr, key, values) => {
    let toReturn = {};
    (arr || []).forEach((el) => {
      const value = el?.[key];

      if (values.includes(value)) {
        toReturn[value] = {
          length: (toReturn[value]?.["length"] || 0) + 1,
          data: [...(toReturn[value]?.["data"] || []), el],
        };
      }
    });
    Object.entries(toReturn).forEach(([key, obj]) => {
      toReturn[key] = {
        ...obj,
        color: employeeStatuses[key],
      };
    });
    return toReturn;
  };

  const sortByKey = (data = [], key) =>
    data?.sort(
      (a, b) => dayjsTZ(a?.[key]).valueOf() - dayjsTZ(b?.[key]).valueOf()
    );

  //region >getEmployees()
  const getEmployees = async (ignoreState) => {
    await API.get("employees", "/employees")
      .then((res) => {
        let fromState = null;
        if (!ignoreState && stateValue && filterKey) {
          fromState = res.filter((el) => el?.[filterKey] === stateValue);
        }

        const data = (fromState || res || []).map((el) => ({
          ...el,
          employeeFullName: `${el?.employeeFirstName} ${el?.employeeLastName}`,
        }));

        const sorted = sortByKey(data, "employeeHireDate");
        const filtered = filterByKey(
          sorted,
          "employeeStatus",
          Object.keys(employeeStatuses)
        );

        setRowData(sorted);
        setFilteredRowData((prev) => ({
          ...prev,
          ...filtered,
        }));
        setLoading(false);
      })
      .catch((err) => console.log("Error: ", err));
  };

  const onSwitch = () => {
    setShowActiveEmployees((prev) => {
      if (!prev) {
        //it means we want active only
        setActiveFilter("Aktiv");
      } else {
        setActiveFilter("");
      }
      return !prev;
    });
  };
  //region newEmployeeCard
  const newEmployeeCard = (
    <div
      className="employeeCard"
      data-testid="employeeCard"
      onClick={() => setVisible(true)}
      style={themeStyle}
      key={999}
    >
      <IconPunonjesit fill={themeStyle?.fill} />
      {!isMobileView ? (
        <span style={{ maxWidth: "200px" }}>
          Këtu ti mund të krijosh një profil të ri punonjësi.
        </span>
      ) : null}
      {!isMobileView ? (
        <MondayButton
          className="mondayButtonGreen"
          testid="addEmployee"
          onClick={() => setVisible(true)}
        >
          Shto Punonjës
        </MondayButton>
      ) : (
        <span>Shto Punonjës</span>
      )}
    </div>
  );

  //region employeeCards
  const employeeCards = useMemo(() => {
    let toReturn = {};
    const data = Object.entries(filteredRowData || {});
    if (!!data) {
      data.forEach(([key, obj], i) => {
        const toPush = (
          <div
            className="employeeCard"
            data-testid="employeeCard"
            onClick={() => setActiveFilter(key)}
            style={themeStyle}
            key={i}
          >
            {statusIcon(key, themeStyle?.fill)}
            <div className="cardTitle" style={{ color: obj.color }}>
              {obj.length}
              <br />
              {key}
            </div>
          </div>
        );
        toReturn[key] = toPush;
      });
    }
    return toReturn;
  }, [employeeStatuses, filteredRowData, isDarkMode]);

  useEffect(() => {
    if (!!Object.keys(employeeStatuses).length) {
      getEmployees();
    }
  }, [employeeStatuses]);

  const ITEMS = {
    search: {
      handleClean: async () => {
        if (!!stateValue && !!filterKey) {
          navigate("/punonjesit", { replace: true });
          message.loading({ key: "clear", content: "Loading..." });
          await getEmployees(true).then(() =>
            message.success({ key: "clear", content: "Filtrimet u pastruan!" })
          );
        }
      },
    },
    icons: {
      filter: {
        filterKeys: [
          { key: "employeeFullName", label: "Punonjesi", type: "string" },
          {
            key: "employeeDepartmentName",
            label: "Departamenti",
            type: "string",
          },
          { key: "employeeRoleName", label: "Roli", type: "string" },
        ],
        filterActive: showActiveEmployees,
      },
      // delete: { endpoint: "employees", idKey: "employeeId" },
      excel: {},
      pdf: {},
      print: {},
    },
    dropdowns: DROPDOWNS,
    switch: {
      checked: showActiveEmployees,
      onSwitch: onSwitch,
      onChecked: "Shiko të gjithë punonjësit",
      onUnChecked: "Shfaq vetëm punonjësit aktiv",
    },
  };

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="punonjesitPage" data-testid="punonjesitPage">
          <div className="punonjesitCards">
            {!!showActiveEmployees
              ? [employeeCards[activeFilter], newEmployeeCard]
              : collapseWrapper({
                  content: [
                    Object.values(employeeCards).concat(newEmployeeCard),
                  ],
                  label: "Employee Statuses Filters",
                  isMobileView,
                })}
          </div>
          <AgGridComponent
            gridApi={gridApi}
            rowData={
              activeFilter ? filteredRowData?.[activeFilter]?.data : rowData
            }
            rowSelection={{ mode: "multiRow" }}
            onGridReady={(p) => setGridApi(p.api)}
            columnDefs={columnDefs}
            paginator={true}
            headerProps={{
              items: ITEMS,
              exportTitle: "Lista e Punonjësve",
            }}
            onRowClick={(rowId) => navigate(`/punonjesit/specifikat/${rowId}`)}
            idKey="employeeId"
            // collapsible={
            //   !showActiveEmployees
            //     ? {
            //         content: [
            //           Object.values(employeeCards).concat(newEmployeeCard),
            //         ],
            //         label: "Employee Statuses Filters",
            //       }
            //     : null
            // }
          />
        </div>
      </div>
      <GeneralInformationsContext.Provider value={{ files, setFiles }}>
        {visible ? (
          <AddEmployeModal
            {...{
              visible,
              setVisible,
              rowData,
              setRowData,
              setFiles,
              files,
              // socketHandler,
              socketHandler: () => {},
              setFilteredRowData,
            }}
          />
        ) : null}
      </GeneralInformationsContext.Provider>
    </LoadableComp>
  );
};
const statusIcon = (status, fill) => {
  switch (status) {
    case "Aktiv":
      return (
        <PunojsitAcitvIcon width={90} height={90} fill={fill || "#1d3943"} />
      );
    case "Larguar":
      return (
        <PunojsitLarguarIcon width={90} height={90} fill={fill || "#1d3943"} />
      );
    case "Shkarkuar":
      return <ShkarkuarIcon width={90} height={90} fill={fill || "#1d3943"} />;
    default:
      return (
        <PunojsitAcitvIcon width={90} height={90} fill={fill || "#1d3943"} />
      );
  }
};

export default Punonjesit;
