import { useEffect } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";

const PathTracker = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  return null;
};

export default PathTracker;
