import { Button, Form, Modal, Space, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./AvantazhetDisavantazhetModal.scss";
import { useDispatch, useSelector } from "react-redux";
import RenderDynamicComponents from "../../../RenderDynamicComponents/RenderDynamicComponents";
import { QuestionsMark } from "../ShtoKandidat/Image";
import { candidates as candidatesReducer } from "../../../../store/actions";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { InterviewsContext } from "../../data";
import { ModalXIcon } from "../../../../assets/icons";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

function AvantazhetDisavantazhetModal({ isVisible, setIsVisible }) {
  const [form] = Form.useForm();
  
  const {
    kandidatiSelektuar,
    setKandidatiSelektuar,
    setIntervistat,
    intervistat,
    defaultSwalProperties,
  } = useContext(InterviewsContext);
  
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const Text = Typography;
  const dispatch = useDispatch();

  //Gets dynamically the inputs for the avantages disavantages
  const newInputs = programFields
    ?.find((el) => el?.fieldName === "Konfigurimi i Kandidateve 2")
    .fieldOptions?.filter(
      (el) =>
        el?.formItemName === "applicantDisadvantages" ||
        el?.formItemName === "applicantAdvantages"
    );
  //UseEffect to set the formfield values
  useEffect(() => {
    form.setFieldValue(
      "applicantAdvantages",
      kandidatiSelektuar.applicantAdvantages
    );
    form.setFieldValue(
      "applicantDisadvantages",
      kandidatiSelektuar.applicantDisadvantages
    );
  }, []);

  const handleCancel = () => {
    //checks if we made any changes to the form
    if (isFieldTouched) {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Space direction="vertical">
            <Text>Ju po anulloni ndryshimet.</Text>
            <Text>
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anulloje"</span>
            </Text>
          </Space>
        ),
        okText: "Po, anulloje",
        cancelText: "Jo, mos e anullo",
        width: "660px",
        className: `cancelModal ${isDarkMode ? "dark" : ""}`,
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#ea3943",
            borderColor: "#ea3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          setIsVisible(!isVisible);
        },
        onCancel() {},
      });
    } else {
      setIsVisible(!isVisible);
    }
  };
  //upload the changes to the db
  const onFinish = async (values) => {
    Swal.fire({
      title: "Ju lutemi prisni...",
      customClass: {
        popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await API.put(
      "interviews",
      `/interviews/${kandidatiSelektuar?.applicantId}`,
      {
        body: values,
      }
    )
      .then(() => {
        let newValues = { ...kandidatiSelektuar, ...values };
        dispatch(candidatesReducer(newValues));
        setIntervistat(
          intervistat?.map((el) =>
            el?.applicantId === kandidatiSelektuar?.applicantId
              ? {
                  ...newValues,
                  applicantId: kandidatiSelektuar?.applicantId,
                }
              : el
          )
        );

        setKandidatiSelektuar(newValues);
        setIsVisible(!isVisible);
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "success",
          title: "Të dhënat u ndryshua!",
        });
      })
      .catch((err) => {
        console.log("Pergjithshme page error: ", { err });
        message.error("Ndodhi nje gabim");
      });
  };

  return (
    <Modal
      open={isVisible}
      centered={true}
      onCancel={handleCancel}
      title="Ndryshoni Avantazhet dhe Disavantazhet"
      className="tag-modal lightHeader"
      closeIcon={<ModalXIcon />}
      footer={[
        <MondayButton className="mondayButtonRed" onClick={handleCancel}>
          Mbyllni faqen
        </MondayButton>,
        <Button
          className="mondayButtonGreen"
          htmlType="submit"
          key="submit"
          form="advantagesDisavantages"
        >
          Ruani ndryshimet
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="advantagesDisavantages"
        onFinish={onFinish}
        onFieldsChange={(a) => setIsFieldTouched(true)}
      >
        {RenderDynamicComponents(newInputs)}
      </Form>
    </Modal>
  );
}

export default AvantazhetDisavantazhetModal;
