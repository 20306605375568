import moment from "moment";
import dayjsTZ from "../../../../../../utils/dayjs";
import React from "react";
import { BirthdayCake } from "../../../../../../assets/images";
import BirthdayCalendar from "./BirthdayCalendar";
import { BirthdayCakeIcon } from "../../../../../../assets/icons";

const DitlindjeMobile = ({ renderdBirthdays, longYear, filterFoter }) => {
  const now = dayjsTZ().add(2, "M");
  function compare(a, b) {
    if (
      dayjsTZ(a.employeeDayOfBirth).format("DD") <
      dayjsTZ(b.employeeDayOfBirth).format("DD")
    ) {
      return -1;
    }
    if (
      dayjsTZ(a.employeeDayOfBirth).format("DD") >
      dayjsTZ(b.employeeDayOfBirth).format("DD")
    ) {
      return 1;
    }
    return 0;
  }
  return (
    <div className="ditlindje-mobile">
      <div className="ditlindjet-container">
        {renderdBirthdays?.length > 0 ? (
          <div className="ditlindjet-header">
            {dayjsTZ(renderdBirthdays[0]?.employeeDayOfBirth).format("MMMM")}{" "}
            {longYear} - {renderdBirthdays?.length} punonjës
          </div>
        ) : (
          <div className="ditlindjet-header">
            Në këtë muaj nuk ka asnjë ditëlindje
          </div>
        )}
        <div className="ditlindjet-body">
          {filterFoter?.sort(compare).map((Data) => (
            <div key={Data?.employeeId} className="ditlindjet-card-content">
              <BirthdayCakeIcon />
              &nbsp;
              <div className="ditlindjet-card-info">
                {Data?.employeeFirstName}&nbsp;&nbsp;
                {Data?.employeeLastName} -{" "}
                {dayjsTZ(Data?.employeeDayOfBirth).format("DD/MM/YYYY")}
                <div className="bold">
                  ({dayjsTZ(now.diff(Data?.employeeDayOfBirth), "years")} vjeç )
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="calendar-container">
        <BirthdayCalendar renderdBirthdays={renderdBirthdays} />
      </div>
    </div>
  );
};

export default DitlindjeMobile;
