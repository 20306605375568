import { Form, Modal, Select } from "antd";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { formatCategoryName } from "../../../../DocumentationConfiguration/View/utils";
import LabeledInput from "../../../LabeledInput/LabeledInput";
import { validateForm } from "../../../utils/validateForm";
import { CheckOutlined } from "@ant-design/icons";
import "./DocumentationSelectorModal.scss";
import { ModalXIcon } from "../../../../../assets/icons";
import { useContext } from "react";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";
import { useSelector } from "react-redux";

const FORM_ITEM_NAME = "docType";

const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
};

const DocumentationSelectorModal = ({ visible, setVisible, setDocType }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const { categoryConfiguration = {} } = useContext(PunonjesitContext);
  const { categoryName = "", documentationsAvailable = [] } =
    categoryConfiguration;

  const [form] = Form.useForm();

  //* maps documentation name and shows it in template
  const options = documentationsAvailable?.map(({ docName }) => ({
    label: docName,
    value: docName,
  }));

  const onNext = () => {
    validateForm(form, () => {
      setDocType(form.getFieldValue(FORM_ITEM_NAME));
      setVisible(false);
    });
  };

  return (
    <Modal
      {...{
        ...antdModalProps,
        visible,
        setVisible,
        title: `Dokumentacion ${formatCategoryName(categoryName)} i Ri`,
        wrapClassName: "cardLayoutModal documentationSelectorModal",
        onCancel: () => setVisible(false),
        className: isDarkMode ? "dark darkHeader" : "lightHeader",
      }}
      closeIcon={<ModalXIcon />}
    >
      <Form {...{ form, preserve: false }}>
        <div className="selectorContainer">
          <LabeledInput
            {...{
              label: "Lloji i Dokumentacionit",
              className: "docSelector",
              formItemName: FORM_ITEM_NAME,
              required: true,
            }}
          >
            <Select
              {...{ options }}
              popupClassName={isDarkMode ? "darkDropdown" : ""}
            />
          </LabeledInput>

          <div className="docSelectorActionButtons">
            <MondayButton
              {...{
                className: "saveButton",
                Icon: <CheckOutlined />,
                onClick: onNext,
              }}
            >
              Tjetra
            </MondayButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default DocumentationSelectorModal;
