import React, { useEffect } from "react";
import { Modal } from "antd";
import "./CrashShift.scss";
import { MotionWarningIcon } from "../../../../assets/icons";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";

const CrashShift = ({ type, open, setCrashError, crashData }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <Modal
      className={`crash-shift-modal ${isDarkMode ? "dark darkHeader" : ""}`}
      width={546}
      centered={true}
      title={<MotionWarningIcon />}
      destroyOnClose={true}
      open={open}
      footer={
        <MondayButton
          className="mondayButtonBlue"
          onClick={() => setCrashError(false)}
        >
          Kthehu Pas
        </MondayButton>
      }
      // footer={false}
    >
      {crashData.length > 0 ? (
        <div className="crash-shift-wrapper">
          <span className="date">
            {dayjsTZ(crashData?.[0].clockInDate).format("DD MMMM YYYY")}
          </span>
          <span className="warning-text">
            Ju nuk mund ta {type === "new" ? "shtoni" : "ndryshoni"} këtë turn
            sepse ka të paktën një turn që ka përplasje:
          </span>
          <div className="crash-shifts">
            <div className="crash-old-shift">
              <span className="shift-label">Turni ekzistues</span>
              <span
                className="time-shift-format"
                style={{ backgroundColor: "#ea3943" }}
              >
                {dayjsTZ(crashData?.[0]?.clockInDate).format("HH:mm")} -{" "}
                {!!crashData?.[0]?.clockOutDate
                  ? dayjsTZ(crashData?.[0]?.clockOutDate).format(
                      "HH:mm | DD/MM/YYYY"
                    )
                  : "Pa përcaktuar"}
              </span>
            </div>
            <div className="crash-new-shift">
              <span className="shift-label">Turni i dëshiruar</span>
              <span
                className="time-shift-format"
                style={{ backgroundColor: "#1da193" }}
              >
                {dayjsTZ(crashData[1]?.clockInDate).format("HH:mm")} -{" "}
                {dayjsTZ(crashData[1]?.clockOutDate).format(
                  "HH:mm | DD/MM/YYYY"
                ) !== "Invalid Date"
                  ? dayjsTZ(crashData[1]?.clockOutDate).format(
                      "HH:mm | DD/MM/YYYY"
                    )
                  : "Pa Përcaktuar"}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <span className="warning-text">
          Dicka shkoi keq, ju lutem provoni përsëri
        </span>
      )}
    </Modal>
  );
};

export default CrashShift;
