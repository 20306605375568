import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  TimePicker,
  message,
  Button,
  CheckboxDatePicker,
  Select,
  Dropdown,
  DatePicker,
  Menu,
} from "antd";
// import {
//   editShift,
//   getShift,
// } from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
// import swal from "sweetalert";
import "./NewShiftModal.scss";
import { ModalXIcon, WarningIcon } from "../../../../assets/icons";
// import TextArea from "antd/lib/input/TextArea";
import moment from "moment-timezone";
import {
  getDisabledAfterCustomHours,
  getDisabledAfterCustomMinutes,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
} from "../../../dashboard/utils/disabledTimeFilters";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import { createNewShiftByAdmin } from "../utils";
import { useAuth } from "../../../../authentication/authHelpers";
import CrashShift from "../crashShiftModal/CrashShift";
import SuccessComponent from "../../../commonComponents/SuccessComponent/SuccessComponent";
import { RichTextEditor } from "../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import { requestMultipleTables } from "../../../../helpers/API/RequestFactory";
import { clockings } from "../../../../store/actions";
import MultiDate from "react-multi-date-picker";
import useReactIpLocation from "react-ip-details";

import DatePanel from "react-multi-date-picker/plugins/date_panel";
import axios from "axios";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import dayjsTZ from "../../../../utils/dayjs";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

const { Option } = Select;

function NewShiftModal({
  selectedEmployee,
  selfType,
  open,
  setOpen,
  refreshData,
  employees,
}) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { ipResponse } = useReactIpLocation();

  const { programFields } = useSelector((state) => state.programFields);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [newShift, setNewShift] = useState({
    clockInDate: null,
    clockOutDate: null,
    note: "",
    employeeIds: [],
  });

  const [dateDayJs, setDateDayJs] = useState(dayjsTZ());
  const [saving, setSaving] = useState(false);
  const [crashError, setCrashError] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [dayType, setDayType] = useState("Regular");
  const [calendarMode, setCalendarMode] = useState("single");
  const [multiDays, setMultiDays] = useState([]);
  const [ip, setIp] = useState("");

  // region ON SAVE
  const onSave = async (type) => {
    if (
      newShift.clockInDate &&
      newShift.employeeIds.length &&
      // newShift.note.length > 5 &&
      dayType !== null
    ) {
      setSaving(true);

      const date1 = dateDayJs
        .set("hour", newShift.clockInDate.get("hour"))
        .set("minute", newShift.clockInDate.get("minute"))
        .set("second", newShift.clockInDate.get("second"));

      let date2;
      if (!!newShift?.clockOutDate) {
        date2 = dateDayJs
          .set("hour", newShift.clockOutDate.get("hour"))
          .set("minute", newShift.clockOutDate.get("minute"))
          .set("second", newShift.clockOutDate.get("second"));
      }

      const toSend = {
        clockInDate: date1.valueOf(),
        clockOutDate: newShift.clockOutDate !== null ? date2.valueOf() : null,
        // employeeId: newShift.employeeId,
        clockingCategory: "manualAdmin",
        dayType: dayType,
        clockInNote: "",
        clockOutNote: "",
        // employeeFirstName: "",
        // employeeFirstName: "",
        editedClockIn: false,
        editedClockOut: false,
        editedByAdmin: true,
        forgotClockOut: false,
        approved: false,
        clockingLogs: [
          {
            createdAt: dayjsTZ().valueOf(),
            note: newShift.note,
            newShiftByAdmin: true,
            username: auth?.user?.given_name + " " + auth?.user?.family_name,
            userSub: auth?.user?.userSub,
            ipResponse: {
              ...ipResponse,
              IPv4: ip,
            },
          },
        ],
      };

      newShift?.employeeIds?.forEach(async (id) => {
        await createNewShiftByAdmin(
          { ...toSend, employeeId: id },
          id,
          employeeRequests
        )
          .then(() => {
            if (type === "reset") {
              setSuccessVisibility(true);
            } else if (type === "continue") {
              message.success("Turni u shtua me sukses!");
              setNewShift((prev) => ({
                ...prev,
                clockInDate: null,
                clockOutDate: null,
                note: "",
                employeeIds: [],
              }));
              refreshData && refreshData();
            }
            // No need for reload because it needs to be approved! (For Employee View -> Wages!)
            // reload()
          })
          .catch((errArr) => {
            setCrashError(true);
            setCrashData(errArr);
          });
      });

      setSaving(false);
    } else {
      message.error(
        "Gabim në plotësimin e të dhënave! Ju lutem plotësoni të gjitha fushat!"
      );
    }
  };

  const handleReset = () => {
    setNewShift({
      clockInDate: null,
      clockOutDate: null,
      note: "",
      employeeIds: [],
    });
    setDateDayJs(dayjsTZ());
    setOpen(false);
    refreshData && refreshData();
  };

  //region EDIT CLOCKIN
  const onEditClockIn = (e) => {
    const date = dateDayJs
      .set("hour", e.get("hour"))
      .set("minute", e.get("minute"))
      .set("second", e.get("second"));

    if (e !== null && newShift.clockOutDate !== null) {
      if (date.valueOf() < newShift.clockOutDate.valueOf()) {
        setNewShift((prev) => ({ ...prev, clockInDate: date }));
      } else {
        message.error("Fillimi i turnit nuk mund te jete me vone se mbarimi.");
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: date }));
    }
  };

  //region EDIT CLOCKOUT
  const onEditClockOut = (e) => {
    const date2 = dateDayJs
      .set("hour", e.get("hour"))
      .set("minute", e.get("minute"))
      .set("second", e.get("second"));

    let tempClockIn;
    if (newShift?.clockInDate !== null) {
      tempClockIn = dateDayJs
        .set("hour", newShift?.clockInDate.get("hour"))
        .set("minute", newShift?.clockInDate.get("minute"))
        .set("second", newShift?.clockInDate.get("second"));
    }

    if (e !== null && newShift.clockInDate !== null) {
      // changed clockIn
      if (date2.valueOf() > tempClockIn.valueOf()) {
        setNewShift((prev) => ({ ...prev, clockOutDate: date2 }));
      } else {
        message.error(
          "Mbarimi i turnit nuk mund te jete me shpejt se fillimi."
        );
        // setNewShift((prev) => ({ ...prev, clockOutDate: date2 }));
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: date2 }));
    }
  };

  const onCalendarPick = (e) => {
    if (!isEmpty(e)) {
      setDateDayJs(e);
      setNewShift((prev) => ({
        ...prev,
        clockInDate: null,
        clockOutDate: null,
      }));
    }
  };

  const onSelectEmployee = (e) => {
    if (!!e?.length) {
      if (e.includes("selectAll")) {
        setNewShift((prev) => ({
          ...prev,
          employeeIds: employees
            ?.filter((e) => e.employeeStatus === "Aktiv" && !!e?.employeeId)
            .map((el) => el.employeeId),
        }));
      } else {
        setNewShift((prev) => ({ ...prev, employeeIds: e }));
      }
    }
  };

  const onClockinCateg = (value) => {
    setDayType(value);
  };

  // region MULTIPLE SHIFTS
  const handleMultiShift = () => {
    console.log("new shift", newShift);
    console.log("multi", multiDays);
    const { clockInDate, clockOutDate, employeeIds } = newShift;

    if (!!clockInDate && !!clockOutDate && !!employeeIds?.length) {
      setSaving(true);

      const multipleSend = multiDays?.map((day) => {
        const date1 = dayjsTZ(day)
          .set("hour", newShift.clockInDate.get("hour"))
          .set("minute", newShift.clockInDate.get("minute"))
          .set("second", newShift.clockInDate.get("second"));
        let date2;
        if (!!newShift?.clockOutDate) {
          date2 = dayjsTZ(day)
            .set("hour", newShift.clockOutDate.get("hour"))
            .set("minute", newShift.clockOutDate.get("minute"))
            .set("second", newShift.clockOutDate.get("second"));
        }
        const toSend = {
          clockInDate: date1.valueOf(),
          clockOutDate: newShift.clockOutDate !== null ? date2.valueOf() : null,
          // employeeId: newShift.employeeId,
          clockingCategory: "manualAdmin",
          dayType: dayType,
          clockInNote: "",
          clockOutNote: "",
          editedClockIn: false,
          editedClockOut: false,
          editedByAdmin: true,
          forgotClockOut: false,
          approved: false,
          clockingLogs: [
            {
              createdAt: dayjsTZ().valueOf(),
              note: newShift.note,
              newShiftByAdmin: true,
              username: auth?.user?.given_name + " " + auth?.user?.family_name,
              userSub: auth?.user?.userSub,
            },
          ],
        };
        return toSend;
      });

      const promises = [];
      multipleSend?.forEach((sendInitial) => {
        const toPush = newShift?.employeeIds?.map(
          async (id) =>
            await createNewShiftByAdmin(
              { ...sendInitial, employeeId: id },
              id,
              employeeRequests
            ).catch((errArr) => {
              setCrashError(true);
              setCrashData(errArr);
            })
        );
        promises.push(...toPush);
      });

      Promise.all(promises)
        .then((res) => {
          message.success("Turnet u shtuan me sukses!");
          setNewShift((prev) => ({
            ...prev,
            clockInDate: null,
            clockOutDate: null,
            note: "",
            // employeeId: null,
          }));
        })
        .finally(() => {
          setSaving(false);
          refreshData && refreshData();
        });
    } else {
      message.error("Ju duhet te plotesoni te gjitha fushat perkatese!");
    }
  };

  //region MENU
  const items = [
    {
      key: 1,
      label: (
        <span onClick={() => (!!saving ? {} : onSave("reset"))}>
          Shto Turnin dhe mbyll
        </span>
      ),
    },
    {
      key: 2,
      label: (
        <span onClick={() => (!!saving ? {} : onSave("continue"))}>
          Shto turnin dhe vazhdo{" "}
        </span>
      ),
    },
  ];

  //region ON SHORTCUT
  const onShortcut = (type) => {
    let punchIn;
    let punchOut;

    if (type === "afternoon") {
      punchIn = dateDayJs.set("hour", 13).set("minute", 30).set("second", 0);
      punchOut = dateDayJs.set("hour", 21).set("minute", 30).set("second", 0);
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: punchOut,
      }));
    } else if (type === "afternoon-start") {
      punchIn = dateDayJs.set("hour", 13).set("minute", 30).set("second", 0);
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: null,
      }));
    } else if (type === "morning") {
      punchIn = dateDayJs.set("hour", 10).set("minute", 0).set("second", 0);
      punchOut = dateDayJs.set("hour", 18).set("minute", 30).set("second", 0);
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: punchOut,
      }));
    } else if (type === "morning-start") {
      punchIn = dateDayJs.set("hour", 10).set("minute", 0).set("second", 0);
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: null,
      }));
    }
  };

  // region >getIp
  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log(res?.data);
    if (!!res?.data?.ip) setIp(res?.data?.ip);
  };

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    if (selfType === true && selectedEmployee !== null) {
      // console.log("selected", selectedEmployee);
      setNewShift((prev) => ({
        ...prev,
        employeeIds: [
          ...(prev?.employeeIds || []),
          selectedEmployee?.employeeId,
        ],
      }));
    }
  }, [selfType, selectedEmployee]);

  //region RETURN
  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}

      <Modal
        destroyOnClose={true}
        width={1118}
        className={`new-shift-container ${
          isDarkMode ? "darkHeader" : "lightHeader"
        }`}
        title="Shto Turn Të Ri"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        closeIcon={<ModalXIcon />}
        centered={true}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            onClick={() => setOpen(false)}
          >
            Mbyll Faqen
          </MondayButton>,
          calendarMode === "single" ? (
            <Dropdown
              overlayClassName={isDarkMode ? "darkDropdown" : ""}
              disabled={saving}
              menu={{ items }}
              placement="bottom"
            >
              <Button className="mondayButtonGreen">Shto Turnin</Button>
            </Dropdown>
          ) : (
            <MondayButton
              spin={saving}
              className="mondayButtonGreen"
              onClick={handleMultiShift}
            >
              Shto Disa Turne!
            </MondayButton>
          ),

          // <button className="start-btn" onClick={onSave}>
          // 	Shto Turnin
          // </button>,
        ]}
      >
        <div className={`new-shift-wrapper ${isDarkMode ? "dark" : ""}`}>
          <div className="warning-title">
            <WarningIcon />
            <span>
              Ju jeni duke shtuar një turn, siguroni të dhënat përpara se të
              ruani ndryshimet!
            </span>
          </div>
          <div className="shortcuts">
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("afternoon")}
            >
              Pasdite: 13:30 - 21:30
            </span>
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("afternoon-start")}
            >
              Pasdite Fillim: 13:30 -{" "}
            </span>
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("morning")}
            >
              Paradite: 10:00 - 18:30
            </span>
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("morning-start")}
            >
              Paradite Fillim: 10:00 -{" "}
            </span>
          </div>
          <div className="to-edit-fields">
            {" "}
            <div className="shift-field-end">
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Punonjësi:
              </label>

              <span
                // onClick={toggleApprove}
                style={{
                  // fontWeight: "600",
                  color: "white",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  //   marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                {selfType ? (
                  <h3
                    style={{
                      color: "#1da193",
                      font: "normal normal 600 15px/39px Open Sans",
                    }}
                  >
                    {selectedEmployee?.employeeFirstName +
                      " " +
                      selectedEmployee?.employeeLastName}
                  </h3>
                ) : (
                  <Select
                    showSearch
                    className="select-employee"
                    placeholder="Zgjidh punonjësin!"
                    optionFilterProp="children"
                    popupClassName={isDarkMode ? "darkDropdown" : ""}
                    disabled={selfType ? true : false}
                    value={newShift?.employeeIds || []}
                    onChange={onSelectEmployee}
                    mode="multiple"
                    maxTagCount="responsive"
                  >
                    {!!employees?.length > 1 ? (
                      <Option key={"selectAll"} value={"selectAll"}>
                        Të gjithë punonjësit
                      </Option>
                    ) : null}
                    {employees
                      ?.filter((e) => e.employeeStatus === "Aktiv")
                      .map((el) => (
                        <Option value={el?.employeeId} key={el?.employeeId}>
                          {el?.employeeFirstName} {el?.employeeLastName}
                        </Option>
                      ))}
                  </Select>
                )}
              </span>
            </div>
            <div className="shift-field-start">
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Fillimi i Turnit:
              </label>
              <TimePicker
                value={newShift.clockInDate}
                placeholder="Fillimi"
                format="HH:mm"
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                onChange={onEditClockIn}
                disabledTime={() => ({
                  disabledHours:
                    newShift.clockOutDate !== null
                      ? () => getDisabledAfterCustomHours(newShift.clockOutDate)
                      : () => [],
                  disabledMinutes:
                    newShift.clockOutDate !== null
                      ? (hour) =>
                          getDisabledAfterCustomMinutes(
                            newShift.clockOutDate,
                            hour
                          )
                      : () => [],
                })}
                showNow={false}
                allowClear={false}
              />
            </div>
            <div className="shift-field-end">
              <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
              <TimePicker
                value={newShift.clockOutDate}
                placeholder="Mbarimi"
                format="HH:mm"
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                disabledTime={() => ({
                  disabledHours:
                    newShift.clockInDate !== null
                      ? () => getDisabledBeforeCustomHours(newShift.clockInDate)
                      : () => [],
                  disabledMinutes:
                    newShift.clockInDate !== null
                      ? (hour) =>
                          getDisabledBeforeCustomMinutes(
                            newShift.clockInDate,
                            hour
                          )
                      : () => [],
                })}
                onChange={onEditClockOut}
                showNow={false}
                allowClear={false}
              />
            </div>
            <div className="shift-field-end">
              <label
                style={{ fontWeight: "600", marginLeft: "0px" }}
                onClick={() =>
                  setCalendarMode(
                    calendarMode === "single" ? "multiple" : "single"
                  )
                }
              >
                <strong style={{ color: "red" }}>*</strong>
                {calendarMode === "single"
                  ? "Data e Turnit:"
                  : "DATAT E TURNIT:"}
              </label>
              {calendarMode === "single" ? (
                <span
                  // onClick={toggleApprove}
                  style={{
                    // fontWeight: "600",
                    color: "white",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    //   marginTop: "20px",
                    marginLeft: "0px",
                    cursor: "pointer",
                  }}
                >
                  <DatePicker
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    defaultValue={dateDayJs}
                    //   value={dateDayJs}
                    onChange={onCalendarPick}
                    popupClassName={isDarkMode ? "darkDropdown" : ""}
                    placeholder="Zgjidh Datën"
                    disabledDate={(current) => {
                      return dayjsTZ() < current;
                    }}
                  />
                </span>
              ) : (
                <MultiDate
                  weekStartDayIndex={1}
                  format="MMMM DD YYYY"
                  sort
                  placeholder="Zgjidh Datat"
                  className={isDarkMode ? "darkDropdown" : ""}
                  containerClassName={isDarkMode ? "dark" : ""}
                  multiple
                  value={multiDays}
                  onChange={(e) => {
                    setMultiDays(e);
                  }}
                  plugins={[<DatePanel />]}
                />
              )}
            </div>
            <div className="shift-field-end">
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Lloji i turnit:
              </label>

              <Select
                className="daytype-select"
                showSearch
                value={dayType}
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                onChange={onClockinCateg}
                placeholder="Zgjidh nje opsion"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {programFields
                  ?.find((el) => el.fieldName === "ClockIn Types")
                  ?.fieldOptions?.map((el, idx) => (
                    <Option key={`shiftType-${idx}`} value={el?.name}>
                      {el?.description}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="shift-field-end">
              <span
                className="multiple-pick"
                onClick={() =>
                  setCalendarMode(
                    calendarMode === "single" ? "multiple" : "single"
                  )
                }
              >
                {calendarMode === "single" ? "Disa Turne?" : "Një Turn?"}
              </span>
            </div>
          </div>
          <div className="to-note">
            <label style={{ fontWeight: "600" }}>
              {/* <strong style={{ color: "red" }}>*</strong> */}
              Shënimi:
            </label>
            {/* <TextArea
              value={newShift.note}
              autoSize={{ minRows: 4, maxRows: 4 }}
              onChange={(e) =>
                setNewShift((prev) => ({ ...prev, note: e.target.value }))
              }
              className="to-note-textarea"
            /> */}
            <RichTextEditor
              className={"to-note-textarea"}
              value={newShift.note}
              onChange={(e) => {
                setNewShift((prev) => ({ ...prev, note: e }));
                // console.log(e);
              }}
            />
          </div>
          <div className="hours-generation">
            <span style={{ fontWeight: "600" }}>
              Orët totale:{" "}
              {!!newShift?.clockOutDate
                ? dayjsTZ(newShift.clockOutDate)
                    .diff(dayjsTZ(newShift.clockInDate), "hour", true)
                    .toFixed(2)
                : 0}
              {" ore"}
            </span>
            <div className="animated-hours">
              <div
                className="calculated-hours"
                style={{
                  width: `${
                    dayjsTZ(newShift.clockOutDate)
                      .diff(dayjsTZ(newShift.clockInDate), "hour", true)
                      .toFixed(2) > 0 &&
                    dayjsTZ(newShift.clockOutDate)
                      .diff(dayjsTZ(newShift.clockInDate), "hour", true)
                      .toFixed(2) <= 8
                      ? (dayjsTZ(newShift.clockOutDate)
                          .diff(dayjsTZ(newShift.clockInDate), "hour", true)
                          .toFixed(2) /
                          8) *
                        100
                      : dayjsTZ(newShift.clockOutDate)
                          .diff(dayjsTZ(newShift.clockInDate), "hour", true)
                          .toFixed(2) > 8
                      ? 100
                      : 0
                  }%`,
                }}
              />
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {saving && <BreedingRhombusSpinner size={40} color="#1da193" />}
            </div> */}
          </div>
        </div>
        {crashError ? (
          <CrashShift
            type={"new"}
            crashData={crashData}
            open={crashError}
            setCrashError={setCrashError}
          />
        ) : null}
        {successVisibility ? (
          <SuccessComponent
            open={successVisibility}
            setOpen={setSuccessVisibility}
            onOk={handleReset}
            text="Turni u shtua me sukses!"
          />
        ) : null}
      </Modal>
    </>
  );
}

export default NewShiftModal;
