import React, { useMemo, useState } from "react";
import { Row, Col, message } from "antd";
import { FlexLogo, IconPerdoruesi, IconFjalekalimi } from "../../assets/icons";
import { API, Auth } from "aws-amplify";
import "./auth-scss/login.scss";
import { Form, Input, Button, Checkbox } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { GoogleLogin, GoogleLogout, isSignedIn } from "react-google-login";
import { useAuth } from "../../authentication/authHelpers";
import { RadarSpinner } from "react-epic-spinners";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import swal from "sweetalert";
import { ErrorLoginImage, LoginImage } from "../../assets/images";
import { useUserSession } from "../../hooks/useUserSession";
import moment from "moment-timezone";
import { REGULAR_USER } from "../../constantsFactory/regularUser";
import { ADMIN_USER } from "../../constantsFactory/accessRights";
import dayjsTZ from "../../utils/dayjs";
import { useSelector } from "react-redux";

// import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
function Login() {
  // const clientId =
  //   "82761190819-75pb8j86qd5mqvehdil6kq0sphse8f8e.apps.googleusercontent.com";
  // const [showLoginButton, setShowLoginButton] = useState(true);
  // const [showLogoutButton, setShowLogoutButton] = useState(false);
  // const [authenticated, setAuthenticated] = useState(null);
  let auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { isDarkMode } = useSelector((state) => state.usersList);
  const userSession = useUserSession();

  const [login, setLogin] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const from = location.state?.from?.pathname || "/kryefaqja";

  const matchEmployeeSub = async (email, userSub) => {
    await API.get("employees", "/employees")
      .then(async (res) => {
        let toRe = res.find((el) => el?.userSub === userSub);
        if (!!toRe) {
          await API.put("employees", `/employees/${toRe.employeeId}`, {
            body: {
              // userSub: userSub,
              accountStatus: "registered",
            },
          });
        }
      })
      .then(() => message.success("U ruajt me sukses!"));
  };

  const onFinish = async (values) => {
    setLoading(true);
    var SuccessImg = document.createElement("img");
    SuccessImg.src = LoginImage;
    var ErrorImg = document.createElement("img");
    ErrorImg.src = ErrorLoginImage;
    // img.setAttribute("width", "100px");
    await Auth.signIn(values.username, values.password).then(
      async (r) => {
        await API.get("users", "/users")
          .then(async (res) => {
            // let currentSub = r?.attributes?.sub;
            let currentUser = res;
            if (!!currentUser) {
              if (currentUser?.disabled !== true) {
                auth.signin(currentUser);
              } else {
                setLoading(false);
                swal({
                  title:
                    "Aktualisht, ju nuk keni te drejte per aksesim! Kontaktoni me administratorin ose supervizorin perkates!",
                  icon: "error",
                  content: ErrorImg,
                  className: isDarkMode
                    ? "custum-swal-loginError-dark"
                    : "custum-swal-loginError",
                });
              }
            }
          })
          .catch(() => {
            const {
              given_name,
              family_name,
              gender,
              email,
              sub,
              phone_number,
            } = r.attributes;
            API.post("users", "/users", {
              body: {
                given_name,
                family_name,
                gender,
                email,
                phone_number,
                userSub: sub,
                department: r.attributes["custom:department"],
                departmentRole: r.attributes["custom:role"],
                userRole: r.attributes["custom:userRole"],
                accessConfig:
                  r.attributes["custom:userRole"] === "Admin" ||
                  r.attributes["custom:userRole"] === "Super Admin"
                    ? ADMIN_USER.accessConfig
                    : REGULAR_USER.accessConfig,
              },
            }).then(async (res) => {
              await matchEmployeeSub(email, sub);
              let currentUser = res;
              if (!!currentUser) {
                if (currentUser?.disabled !== true) {
                  auth.signin(currentUser);
                } else {
                  setLoading(false);
                  swal({
                    title:
                      "Aktualisht, ju nuk keni te drejte per aksesim! Kontaktoni me administratorin ose supervizorin perkates!",
                    icon: "error",
                    content: ErrorImg,
                    className: isDarkMode
                      ? "custum-swal-loginError-dark"
                      : "custum-swal-loginError",
                  });
                }
              }
            });
          });
      },
      (err) => {
        setLoading(false);
        swal({
          title: "Kredenciale te gabuara!",
          icon: "error",
          content: ErrorImg,
          className: isDarkMode
            ? "custum-swal-loginError-dark"
            : "custum-swal-loginError",
        });
        console.log("Error login", err);
      }
    );
    // setLoading(false);
    // console.log("login Success", res.profileObj);
    // setShowLoginButton(false);
    // setShowLogoutButton(true);
  };
  // const onFinishGoogle = async (values) => {
  //   console.log("from", from);
  //   console.log(values);
  //   setLoading(true);
  //   var SuccessImg = document.createElement("img");
  //   SuccessImg.src = LoginImage;
  //   var ErrorImg = document.createElement("img");
  //   ErrorImg.src = ErrorLoginImage;
  //   // img.setAttribute("width", "100px");
  //   await Auth.federatedSignIn({
  //     provider: CognitoHostedUIIdentityProvider.Google,
  //   }).then(async (r) => {
  //     Auth.currentAuthenticatedUser().then((res) =>
  //       console.log("Current thirrja", res)
  //     );
  //     await auth.signin(r, async () => {
  //       navigate(from === "/" ? "/kryefaqja" : from);
  //       // navigate("/");
  //       setLoading(false);
  //     });
  //   });
  //   // await auth.signin(async () => {
  //   //   navigate(from === "/" ? "/kryefaqja" : from);
  //   //   // navigate("/");
  //   // })
  //   // console.log("login Success", res.profileObj);
  //   // setShowLoginButton(false);
  //   // setShowLogoutButton(true);
  // };

  // const onLogin = (res) => {
  //   auth.signin(async () => {
  //     navigate(from === "/" ? "/kryefaqja" : from);
  //     // navigate("/");
  //   });
  //   console.log("login Success", res);
  //   // setShowLoginButton(false);
  //   // setShowLogoutButton(true);
  // };
  return (
    <div className="login-grid">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruani E-mailin tuaj !",
            },
          ]}
        >
          <Input
            size="medium"
            width={100}
            placeholder="Emri i Përdoruesit"
            // autoComplete="off"
            style={{ backgorung: "#F5F5F7" }}
            suffix={<IconPerdoruesi width={16} height={16} fill="#0288d2" />}
            onChange={(e) => {
              setLogin((prev) => ({
                ...prev,
                username: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          size="large"
          name="password"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruani fjalëkalimin !",
            },
          ]}
        >
          <Input.Password
            size="medium"
            type="password"
            placeholder="Fjalëkalimi"
            style={{ backgorung: "#F5F5F7" }}
            iconRender={(visible) =>
              visible ? (
                <IconFjalekalimi width={16} height={16} fill="#0288d2" />
              ) : (
                <IconFjalekalimi width={16} height={16} fill="#0288d2" />
              )
            }
            onChange={(e) => {
              setLogin((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <div className="custom-form">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Më mbaj mend!</Checkbox>
          </Form.Item>
          <Link className="login-form-forgot" to="/harruatkodin">
            Ke harruar fjalëkalimin?
          </Link>
        </div>
        {/* {showLoginButton ? (
                  <GoogleLogin
                    className="your-custom-class"
                    // clientId={clientId}
                    buttonText="Futu në adresën tënde nëpëmjet Google"
                    onSuccess={Auth.federatedSignIn({ provider: "Google" })}
                    // onFailure={onLoginFailure}
                    cookiePolicy={"single_host_origin"}
                  />
                ) : null} */}
        {/* <h4 style={{ cursor: "pointer" }} onClick={onFinishGoogle}>
                  Futu në adresën tënde nëpëmjet Google
                </h4> */}
        {/* <Button
                  onClick={() =>
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    })
                  }
                >
                  click
                </Button> */}
        <Form.Item>
          <Button
            // onClick={onLogin}
            type="primary"
            className={login.username ? "filled-button" : "login-form-button"}
            htmlType="submit"
          >
            Futu në adresën tënde!
          </Button>
        </Form.Item>
      </Form>
      <div className="loading-login">
        {loading && <RadarSpinner size={50} color="#1da193" />}
      </div>
    </div>
  );
}
export default Login;
