import React, { useState, useMemo } from "react";
import { Drawer, Col, Row, Skeleton } from "antd";
import "./Drawer.scss";
import { API } from "aws-amplify";
import dayjsTZ from "../../../utils/dayjs";
import { useDispatch, useSelector } from "react-redux";
import { HolidayIcon, DayOffIcon } from "../../dashboard/assets";
import LeaveModal from "../../dashboard/AdminDashboard/AdminCards/WorkLeave/LeaveModal";
import { employeeRequests as requestsAction } from "../../../store/actions";
import { lazyFetch } from "../../../utils/lazyFetch";
import { ClearIconDrawer, ModalXIcon } from "../../../assets/icons";
import AddLeaveStatus from "../../dashboard/AdminDashboard/AdminCards/WorkLeave/AddLeaveStatus/AddLeaveStatus";
import DrawerFilter from "./DrawerFilter/DrawerFilter";

const SELECTORS = ["requestStatus"];

const RequestsDrawer = ({ requestsActive, setRequestsActive }) => {
  const dispatch = useDispatch();

  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [spin, setSpin] = useState({});
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState({});
  const [options, setOptions] = useState({});

  const getNotif = async (filterId) => {
    setSpin({ [filterId]: true });
    await API.get("employeeRequests", `/employeeRequests/${filterId}`).then(
      (el) => {
        setContent(el);
      }
    );

    setSpin({ [filterId]: false });
    setIsModalVisible(true);
  };

  const getEmployeRequest = async () => {
    setLoading(true);
    await lazyFetch("employeeRequests", [
      "requestId",
      "family_name",
      "given_name",
      "userSub",
      "requestType",
      "requestStatus",
      "requestPeriod",
      "createdAt",
    ]).then((res) =>
      dispatch(requestsAction(res.sort((a, b) => b?.createdAt - a?.createdAt)))
    );
    setLoading(false);
  };

  const initialData = useMemo(() => {
    let toReturn = [];
    setLoading(true);
    let options = Object.assign(
      {},
      {},
      ...SELECTORS.map((el) => ({ [el]: [] }))
    );
    const optionKeys = Object.keys(options || {});
    if (!!employeeRequests) {
      toReturn = employeeRequests
        .filter((res) => res.userSub !== null && res.userSub !== "undefined")
        .map((el) => {
          const requestStatus =
            el?.requestPeriod?.length > 1
              ? dayjsTZ().valueOf() - el?.requestPeriod?.slice(-1) > 0
                ? "Përfunduar"
                : el.requestStatus
              : dayjsTZ().valueOf() - el.requestPeriod[0] > 0
              ? "Përfunduar"
              : el.requestStatus;
          optionKeys.forEach((key) => {
            const val = key === "requestStatus" ? requestStatus : el?.[key];
            if (!options[key].includes(val)) {
              options[key].push(val);
            }
          });
          return {
            ...el,
            employeeFullName: el?.given_name + " " + el?.family_name,
            requestStatus,
          };
        })
        ?.sort?.((a, b) => dayjsTZ(b?.createdAt) - dayjsTZ(a?.createdAt));
      setLoading(false);
    }
    setOptions(options);

    return toReturn;
  }, [employeeRequests]);

  const filteredData = useMemo(() => {
    if (!Object.values(activeFilter || {}).filter(Boolean).length) return null;

    const matchingData = [];
    const entries = Object.entries(activeFilter || {}).filter(
      ([_, val]) => !!val
    );
    (initialData || []).forEach((el) => {
      const doesMatch = entries.every(([key, val]) => {
        if (key === "createdAt") {
          return (
            dayjsTZ(el?.[key]).format("DD/MM/YYYY") ===
            dayjsTZ(val).format("DD/MM/YYYY")
          );
        } else {
          return (el?.[key] || "").toLowerCase().includes(val.toLowerCase());
        }
      });
      if (doesMatch) {
        matchingData.push(el);
      }
    });

    return matchingData;
  }, [initialData, activeFilter]);

  const isDisabled = (x, y) => {
    return x - y > 0;
  };

  const filterKeys = useMemo(
    () => ({
      input: ["employeeFullName"],
      date: ["createdAt"],
      select: options,
    }),
    [options]
  );
  const tableHeaders = [
    "Emri i punonjësit",
    "Data e krijimit të kërkesës",
    "Statusi i kërkesës",
  ];

  //region RETURN
  return (
    <Drawer
      rootClassName="req-drawer"
      title="Pushimet vjetore"
      className={`requestsDrawer ${isDarkMode ? "dark darkDrawer" : ""}`}
      placement="right"
      width={800}
      destroyOnClose
      onClose={() => setRequestsActive(false)}
      open={requestsActive}
      closeIcon={<ClearIconDrawer fill={isDarkMode ? "#e6e6e6" : "#1d3445"} />}
    >
      <div className="drawerContainer">
        <DrawerFilter {...{ activeFilter, setActiveFilter, filterKeys }} />
        <div className="drawerTable">
          <div
            className="tableHeaders"
            style={{
              gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr`,
            }}
          >
            {tableHeaders.map((el) => (
              <h3>{el}</h3>
            ))}
          </div>
          {loading === false ? (
            <div className="tableContent">
              {(filteredData || initialData)?.map((dt) => {
                return (
                  <Row
                    key={dt.requestId}
                    className="leave-request-row"
                    style={{
                      width: "100%",
                      paddingLeft: 15,
                      paddingRight: 15,
                      marginBottom: 5,
                    }}
                  >
                    <Col span={3}>
                      {" "}
                      <div
                        className="icon-container"
                        style={{
                          backgroundColor: isDisabled(
                            dayjsTZ().valueOf(),
                            dt?.requestPeriod?.slice(-1)
                          )
                            ? "#a4a0a0"
                            : getColor(dt?.requestStatus),
                          borderRadius: 2,
                          height: 22,
                          width: 22,
                        }}
                      >
                        {getIcon(dt?.requestType)}
                      </div>
                    </Col>
                    <Col
                      span={7}
                      style={{
                        color: isDisabled(
                          dayjsTZ().valueOf(),
                          dt?.requestPeriod?.slice(-1)
                        )
                          ? "#a4a0a0"
                          : "",
                      }}
                    >
                      {dt?.employeeFullName}
                    </Col>
                    <Col
                      span={9}
                      style={{
                        color: isDisabled(
                          dayjsTZ().valueOf(),
                          dt?.requestPeriod?.slice(-1)
                        )
                          ? "#a4a0a0"
                          : "",
                      }}
                    >
                      {dayjsTZ(dt?.createdAt).format("DD/MM/YYYY")}
                    </Col>
                    <Col span={5}>
                      <AddLeaveStatus
                        onClick={() => getNotif(dt?.requestId)}
                        spin={spin?.[dt?.requestId]}
                        status={dt?.requestStatus}
                      />
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <Skeleton paragraph={{ rows: 5 }} />
          )}
        </div>
      </div>

      {isModalVisible ? (
        <LeaveModal
          content={content}
          isModalVisible={isModalVisible}
          getEmployeRequest={getEmployeRequest}
          setIsModalVisible={setIsModalVisible}
          getIcon={getIcon}
        />
      ) : null}
    </Drawer>
  );
};
export default RequestsDrawer;

const getIcon = (reqType, styles) => {
  const style = styles || {
    marginTop: 2,
    marginLeft: 2,
    width: 18,
    height: 18,
  };
  switch (reqType) {
    // case "Leje lindje":
    //   return <MaternityLeaveIcon style={style} />;
    case "Pushime":
      return <HolidayIcon width={18} height={18} style={style} />;
    case "Dite Personale":
      return <DayOffIcon style={style} />;
    default:
      return;
  }
};
const getColor = (reqStatus) => {
  switch (reqStatus) {
    case "Aprovuar":
      return "#1da193";
    case "Refuzuar":
      return "#ea3943";
    case "Pending":
      return "#FCC94A";
    default:
      return;
  }
};
