import { useState, useEffect } from "react";
import {
  // Navigate,
  // Route,
  // useLocation,
  // useNavigate,
  Outlet,
} from "react-router-dom";
import { useAuth } from "./authentication/authHelpers";
import LoadableComp from "./components/commonComponents/LoadableComp/LoadableComp";

function PublicRoute({ children }) {
  const [isLoading, setLoading] = useState(true);
  const auth = useAuth();
  // const location = useLocation();
  // const navigate = useNavigate();

  // const redirectOnSignIn = async () => {
  //   await auth.onRefresh().then(() => {
  //     setLoading(false);
  //   });
  // await Auth.currentAuthenticatedUser()
  // .then(
  //   (res) => {
  //     setLoading(false);
  //     // navigate("/kryefaqja", { state: { from: location } });
  //     // to be checked
  //     // auth.signIn(res);
  //   },
  //   (error) => {
  //     setLoading(false);
  //     //   console.log("error signing in", error);
  //     //   navigate("/login", { state: { from: location } });
  //   }
  // )
  // .catch((err) =>
  //   console.log("An error happened with login instance", err)
  // );
  // };

  useEffect(() => {
    console.log("on public");

    auth.onRefresh(() => setLoading(false));
  }, []);

  if (isLoading) {
    return <LoadableComp loading={isLoading} />;
  }

  return <Outlet />;
}

export default PublicRoute;
