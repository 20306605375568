import { useSelector } from "react-redux";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import dayjsTZ from "../../../../../utils/dayjs";
import { Alert, Select } from "antd";
import { useMemo } from "react";
import { Delete } from "../../../../../assets/icons";
const { Option } = Select;

const PickEmployee = ({ toGenerate, setToGenerate, clockings }) => {
  const { isDarkMode } = useSelector(state => state.usersList);
  const { flexEligibleEmployees } = useSelector((state) => state.employeesList);

  //region >selectAllApproved()
  const selectAllApproved = () => {
    let appliedEmpl = [];
    if (!!flexEligibleEmployees) {
      flexEligibleEmployees.forEach((e) => {
        const approvals = (clockings || [])
          .filter(
            (cl) =>
              cl?.employeeId === e?.employeeId &&
              dayjsTZ(cl?.clockInDate).valueOf() >=
                dayjsTZ(toGenerate?.timeframe?.start).valueOf() &&
              dayjsTZ(cl?.clockOutDate).valueOf() <=
                dayjsTZ(toGenerate?.timeframe?.end).valueOf()
          )
          console.log(approvals)
          // .every((el) => el?.approved === true);
        // let appliedShifts = clockings.filter(
        //   (c) =>
        //     c.employeeId === e?.employeeId &&
        //     dayjsTZ(c?.clockInDate).valueOf() >=
        //       dayjsTZ(toGenerate?.timeframe?.start).valueOf() &&
        //     dayjsTZ(c?.clockOutDate).valueOf() <=
        //       dayjsTZ(toGenerate?.timeframe?.end).valueOf()
        // );
        if (!!approvals?.length) {
          appliedEmpl.push({
            employeeId: e.employeeId,
            fullName: e.employeeFirstName + " " + e.employeeLastName,
          });
        }
      });
    }
    if (appliedEmpl.length > 0) {
      setToGenerate((prev) => ({ ...prev, employees: appliedEmpl }));
    }
  };

  const initialOptions = useMemo(() => {
    let toReturn = {};
    (flexEligibleEmployees || []).forEach((empl) => {
      toReturn[empl?.employeeId] = (
        <Option
          value={empl?.employeeId}
          label={`${empl?.employeeFirstName} ${empl?.employeeLastName}`}
          key={`${empl?.employeeId}`}
        >
          {getDynamicWarnings(empl, clockings, toGenerate)}
        </Option>
      );
    });
    return toReturn;
  }, [flexEligibleEmployees, clockings]);

  //region employeeOptions
  const employeeOptions = useMemo(() => {
    let toReturn = [];

    Object.entries(initialOptions || {}).forEach(([id, option]) => {
      const isSelected = Object.values(
        (toGenerate?.employees || []).map((el) => el?.employeeId)
      ).includes(id);
      if (!isSelected) {
        toReturn.push(option);
      }
    });
    return toReturn;
  }, [toGenerate?.employees]);

  //region RETURN
  return (
    <div className="pickEmployeeStep">
      <div className="pickEmployee">
        <div className="selectionButtons">
          <MondayButton
            className="mondayButtonGreen"
            onClick={selectAllApproved}
          >
            Zgjidh të gjithë të aprovuarit
          </MondayButton>
          <MondayButton
            className="mondayButtonRed"
            onClick={() =>
              setToGenerate((prev) => ({ ...prev, employees: [] }))
            }
          >
            Pastro
          </MondayButton>
        </div>
        <div className="employeePicker">
          <span>
            <strong style={{ color: "red" }}>*</strong>
            Zgjidh Punonjësit:
          </span>
          <Select
            className="pick-empl"
            showSearch
            placeholder="Zgjidh Punonjesin"
            popupClassName={isDarkMode ? "darkDropdown" : ""}
            onSelect={(_, option) => {
              const { value, label } = option;
              setToGenerate((prev) => ({
                ...prev,
                employees: [
                  ...(prev?.employees || []),
                  { employeeId: value, fullName: label },
                ],
              }));
            }}
            optionFilterProp="children"
            optionLabelProp="label"
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            {employeeOptions}
          </Select>
          <label> Punonjës të zgjedhur: {toGenerate?.employees.length}</label>
        </div>
      </div>
      <div className="pickedEmployeesList">
        {toGenerate.employees.map((employee) => (
          <div className="pickedContainer">
            <span
              className="deletePicked"
              onClick={() =>
                setToGenerate((prev) => ({
                  ...prev,
                  employees: prev.employees.filter(
                    (el) => el.employeeId !== employee?.employeeId
                  ),
                }))
              }
            >
              <Delete width={22} height={22} fill="#ea3943" />
            </span>
            <span className="pickedTitle">{employee?.fullName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PickEmployee;

const getDynamicWarnings = (employee, clockings, toGenerate) => {
  let type2 = "success";
  const approvals = clockings.some(
    (el) =>
      el?.employeeId === employee?.employeeId &&
      el?.approved === false &&
      dayjsTZ(el?.clockInDate).valueOf() >=
        dayjsTZ(toGenerate?.timeframe?.start).valueOf() &&
      dayjsTZ(el?.clockOutDate).valueOf() <=
        dayjsTZ(toGenerate?.timeframe?.end).valueOf()
  );
  // console.log("Approvals", approvals);
  if (!!approvals) type2 = "warning";
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      key={employee?.employeeId}
    >
      <Alert
        showIcon
        style={{ width: "100%" }}
        type={type2}
        message={getDynamicName(employee, type2)}
      />
    </div>
  );
};

const getDynamicName = (employee, type) => {
  if (type === "warning") {
    return `${employee?.employeeFirstName}  ${employee?.employeeLastName} | ${employee?.employeeDepartmentName} | Turne të Pa Aprovuara`;
  } else {
    return `${employee?.employeeFirstName}  ${employee?.employeeLastName} | ${employee?.employeeDepartmentName} `;
  }
};
