import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
import "./Kanban.scss";
import {
  ClockIcon,
  IntervistDnDIcon,
  InfoBluecIcon,
} from "../../../assets/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { Button, Modal, message } from "antd";
import { InterviewsContext } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { candidates as candidatesReducer } from "../../../store/actions";
import KanbanConfirmModal from "./KanbanConfirmModal";
import { useAuth } from "../../../authentication/authHelpers";
import moment from "moment";
import dayjsTZ from "../../../utils/dayjs";
import { toSentenceCase } from "../../../utils";
import { translateObjectFields } from "../Kandidatet/ShtoKandidat/ShowContactDetails/ShowContactDetails";
import ApplicantCard from "../Kandidatet/ApplicantCard";

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const Kanban = forwardRef(() => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.usersList);

  const Questions = programFields?.find(
    (el) => el?.fieldName === "Arsyet e skualifikimit"
  );

  //Dynamically getting the field options for discualification
  const { fieldOptions = {} } = Questions;

  const {
    colors,
    intervistat,
    activeKey = "3",
    setCreateUserPage,
    kandidatiSelektuar,
    setKandidatiSelektuar,
    defaultSwalProperties,
  } = useContext(InterviewsContext);

  const [isVisible, setIsVisible] = useState(false);
  const [inters, setInters] = useState({});
  const dragValues = useRef();

  const statuset = [
    { name: "Listuar për intervistë", color: "#FA8532" },
    { name: "Në proçes", color: "#FCC94A" },
    { name: "Pranuar", color: "#20AC9D" },
    { name: "Skualifikuar", color: "#ea3943" },
  ];
  const handleOk = (reason) => {
    dragEndHandler(dragValues.current, reason);
    setIsVisible(false);
    dragValues.current = null;
  };
  const handleCancel = () => {
    dragValues.current = null;
    setIsVisible(false);
  };
  //region DRAG FUNCTION
  // funksion qe ndryshon statusin e kandidatit kur e zhvendosim ne kanban
  const dragEndHandler = async (result, value) => {
    if (!result?.destination) return;

    const droppableSourceIndex = result?.source?.droppableId?.split("-")?.[1];
    const itemIndexSource = result?.draggableId?.split("-")?.[2];
    const droppableDestinationIndex =
      result?.destination?.droppableId?.split("-")?.[1];
    const itemSource = inters?.[statuset?.[droppableSourceIndex]?.name]?.splice(
      itemIndexSource,
      1
    )?.[0];
    inters?.[statuset?.[droppableDestinationIndex]?.name]?.splice(
      result?.destination?.index,
      0,
      itemSource
    );
    let keylogs = [];
    if (
      itemSource?.applicantStatus !==
      statuset?.[droppableDestinationIndex]?.name
    ) {
      const color = statuset?.[droppableDestinationIndex]?.color;
      let title = document.createElement("span");
      title.innerHTML = `Kandidati <b>${itemSource?.applicantFirstName} ${itemSource?.applicantLastName}</b> u zhvendos në statusin <span style="color:${color};font-weight:600">${statuset?.[droppableDestinationIndex]?.name}</span>!`;
      try {
        Swal.fire({
          title: "Ju lutem prisni...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            popup: isDarkMode ? "custom-swal-dark" : "custom-swal",
          },
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const { fieldOptions = undefined } = programFields?.find(
          ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
        );
        const constants = {
          activity: "Ndryshuar",
          author:
            auth.userAccess[0]?.given_name +
            " " +
            auth.userAccess[0]?.family_name,
          changeDate: dayjsTZ().valueOf(),
        };
        const reasonChange = {
          ...constants,
          field: "applicantDisqualificationReason",
          oldValue: itemSource?.applicantDisqualificationReason,
          newValue: value,
        };
        const statusChange = {
          ...constants,
          field: "applicantStatus",
          oldValue: itemSource?.applicantStatus,
          newValue: statuset?.[droppableDestinationIndex]?.name,
        };
        keylogs = translateObjectFields({
          fieldOptions,
          changes: !!value ? [statusChange, reasonChange] : [statusChange],
        });
        await API.put("interviews", `/interviews/${itemSource?.applicantId}`, {
          body: {
            applicantStatus: statuset?.[droppableDestinationIndex]?.name,
            applicantDisqualificationReason: value,
            keylog: [...(itemSource?.keylog || []), ...keylogs],
          },
        });
        Swal.close();
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "success",
          title,
        });
      } catch (err) {
        message.error("Error ne ndryshimin e statusit te kandidatit!");
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "error",
          title: "Kandidati nuk u zhvendos!",
        });
        console.error("Error:", err);
      }
    }
    dispatch(
      candidatesReducer(
        (candidates || []).map((kandidati) => {
          if (kandidati?.applicantId === itemSource?.applicantId) {
            return {
              ...kandidati,
              applicantStatus: statuset?.[droppableDestinationIndex]?.name,
              applicantDisqualificationReason: value,
              keylog: [...(itemSource?.keylog || []), ...keylogs],
            };
          }
          return kandidati;
        })
      )
    );
    setInters({ ...inters });
  };

  useEffect(() => {
    if (activeKey !== "3") return;
    if (intervistat?.length > 0) {
      const showInterviews = intervistat?.reduce?.((prev, arr) => {
        return {
          ...prev,
          [arr?.applicantStatus]: [
            ...(prev?.[arr?.applicantStatus] || []),
            arr,
          ],
        };
      }, {});
      setInters(showInterviews);
    } else {
      setInters({});
    }
  }, [intervistat]);

  //check if we Disqualify a candidate, if we do a modal opens for the reason why
  const handleDisqualification = (b) => {
    if (b?.source?.droppableId === "droppable-3") {
      return;
    }
    dragValues.current = b;
    setIsVisible(true);
  };

  //region RETURN
  return (
    <DragDropContext
      onDragEnd={(b) => {
        b?.destination?.droppableId === "droppable-3"
          ? handleDisqualification(b)
          : dragEndHandler(b, "");
      }}
    >
      <div className={`kanban ${isDarkMode ? "dark" : ""}`}>
        {statuset?.map((status, index) => {
          return (
            <Droppable droppableId={`droppable-${index}`} key={index}>
              {(provided) => (
                <div
                  className="kanbanBoard"
                  style={
                    isDarkMode
                      ? { boxShadow: `0px 0px 5px ${status?.color}` }
                      : {}
                  }
                  {...provided?.droppableProps}
                  ref={provided?.innerRef}
                >
                  <div
                    className="kanbanBoardColor"
                    style={{ background: status?.color }}
                  ></div>
                  <div className="namesContent">
                    <span>{`${status?.name} (${
                      inters?.[status?.name]?.length || 0
                    })`}</span>
                  </div>
                  <div className="displayInterviews">
                    {inters?.[status?.name]?.map((intervista, i) => {
                      return (
                        <Draggable
                          draggableId={`draggable-${index}-${i}`}
                          index={i}
                          key={i}
                        >
                          {(provided) => {
                            return (
                              <ApplicantCard
                                view={"kanban"}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                cardRef={provided.innerRef}
                                key={intervista?.applicantId}
                                onClick={() => {
                                  setKandidatiSelektuar(intervista);
                                  setCreateUserPage(true);
                                }}
                                {...{ intervista }}
                              />
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          );
        })}
      </div>
      {isVisible && (
        <KanbanConfirmModal
          handleCancel={handleCancel}
          handleOk={handleOk}
          open={isVisible}
          questions={fieldOptions}
        />
      )}
    </DragDropContext>
  );
});

export default Kanban;
