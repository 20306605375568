import { message } from "antd";
import ReactGA from "react-ga4";

export const eventTracker = (category, action, label) => {
  if (!category || !action) {
    message.error("Category and action are required for tracking events");
    return;
  }
  ReactGA.event({ category, action, label });
};
