import React, { useEffect, useState } from "react";
import { IconI } from "../../../../assets/icons";
import { Form, Input, Row, Col, DatePicker } from "antd";
import { useSelector } from "react-redux";

const { TextArea } = Input;

function DetajetKompaniaCard() {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <div className="comp-info-card-container">
      <div className="input">
        <h3>
          <span className="star-color">* </span>NUIS:
        </h3>
        <Form.Item
          name="nusi"
          rules={[
            {
              required: true,
              message: "Shkruaj NUIS !",
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="Shkruaj numrin unik i identifikimit të subjektit"
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input">
        <h3>
          <span className="star-color">* </span>Shteti:
        </h3>
        <Form.Item
          name="shteti"
          rules={[
            {
              required: true,
              message: "Shkruaj shtetin !",
            },
          ]}
        >
          <Input autoComplete="off" placeholder="Shkruaj shtetin" />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input">
        <h3>
          <span className="star-color">* </span>Emri:
        </h3>
        <Form.Item
          name="emriSubjektit"
          rules={[
            {
              required: true,
              message: "Shkruaj emrin !",
            },
          ]}
        >
          <Input autoComplete="off" placeholder="Shkruaj emrin" />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input">
        <h3>
          <span className="star-color">* </span>Qyteti:
        </h3>
        <Form.Item
          name="qyteti"
          rules={[
            {
              required: true,
              message: "Shkruaj qytetin !",
            },
          ]}
        >
          <Input autoComplete="off" placeholder="Shkruaj qytetin" />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input">
        <h3 className="data-label">
          <span className="star-color">* </span>Data e regjistrimit:
        </h3>
        <Form.Item
          name="dataRegjistrimit"
          rules={[
            {
              required: true,
              message: "Selekto nje opsion !",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            popupClassName={isDarkMode ? "darkDropdown" : ""}
            placeholder="Data e regjistrimit"
            className="responsive-date-picker"
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input">
        <h3>
          <span className="star-color">* </span>Kodi postar:
        </h3>
        <Form.Item
          name="kodiPostar"
          rules={[
            {
              required: true,
              message: "Shkruaj kodin postar!",
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="Shkruaj kodin postar"
            type="number"
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input">
        <h3>
          <span className="star-color">* </span>Email i kompanisë:
        </h3>
        <Form.Item
          name="subjektEmail"
          rules={[
            {
              message: "Shkruaj emailin e kompanisë!",
            },
          ]}
        >
          <Input placeholder="Emaili kompanisë" />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="text-area">
        <h3>
          <span className="star-color">* </span>Adresa:
        </h3>
        <Form.Item
          name="adresa"
          rules={[
            {
              required: true,
              message: "Ju lutem Shkruaj Adresën !",
            },
          ]}
        >
          <TextArea placeholder="Shkruaj adresen " rows={3} />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
    </div>
  );
}

export default DetajetKompaniaCard;
