import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, Card, Tooltip } from "antd";
import "./paga_fitimet_setigns.scss";
import { IconKerko } from "../../../../assets/icons";
import { FitimetEditCard, KrijoNjeTeReModal, FitimetViewCard } from "./index";
import { API } from "aws-amplify";
import { FitimetContext } from "./FitimetContext";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import DragContainer from "../../../dashboard/AdminDashboard/Draggable/DragContainer";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { LogsIcon } from "../../../Documentation/View/assets";
import PagatLogs from "../PagatLogs/PagatLogs";
import { useSelector } from "react-redux";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

/**
 * * @EneaXharau - Websocket connection
 */
// const socketHandler = new WebSocket(getSocketUrl("/settings/wages/profit/sockets"));

function PagatFitimetSetigns() {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [showViewEdit, setShowViewEditCard] = useState(false);
  const [toBeEdited, setToBeEdited] = useState(null);
  const [searchTerm, setSearchTerm] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);

  //open the edit card view
  const handleShowEditView = (id) => {
    setShowViewEditCard(true);
    setToBeEdited(id);
  };

  // close the edit card view
  const handleHideEditView = (id) => {
    setShowViewEditCard(false);
    if (id === toBeEdited) {
      setToBeEdited(null);
    }
  };

  // getting earnings data form api
  const getEarnings = async () => {
    await API.get("earnings", "/earnings").then((r) => {
      setTableData(r);
    });
  };

  const openModal = () => {
    setVisible(true);
    setToggle(false);
  };

  // get the api data when the page is renderd
  useEffect(() => {
    getEarnings();
  }, []);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "profit_added_received":
  // 				{
  // 					getEarnings();
  // 				}
  // 				break;
  // 			case "profit_removed_received":
  // 				{
  // 					getEarnings();
  // 				}
  // 				break;
  // 			case "profit_updated_received":
  // 				{
  // 					getEarnings();
  // 				}
  // 				break;
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  // search card function
  const onSearchCard = (e) => {
    let substring = e.target.value;
    let filteredData = tableData?.filter(
      (item) => item.earningName.toLowerCase().includes(substring.toLowerCase())
      // item.earningName.toLowerCase().includes(substring.toLowerCase())
    );
    setSearchTerm(filteredData);
    substring !== "" ? setFilterActive(true) : setFilterActive(false);
  };

  return (
    <LoadableComp loading={!!tableData ? false : true}>
      <FitimetContext.Provider
        value={{ tableData, setTableData, setToBeEdited }}
      >
        <div className={`pagat-fitimet-setings ${isDarkMode ? "dark" : ""}`}>
          <div className="pagat-fitimet-header">
            <div className="pagat-fitimet-search">
              <div className="search-icon">
                <IconKerko width={15} height={15} />
              </div>{" "}
              <Input
                placeholder="Kërko një rekord"
                onChange={onSearchCard}
                style={{ width: 212 }}
              />
            </div>
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => openModal()}
            >
              Krijo një të re
            </MondayButton>
            <div className="header-icons">
              <Tooltip title={"Shfaq historikun"}>
                <LogsIcon fill="black" onClick={() => setLogsVisible(true)} />
              </Tooltip>
            </div>

            <div className="toogleDiv">
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => setToggle(!toggle)}
              >
                Drag & Drop
              </MondayButton>
            </div>
            {visible ? (
              <KrijoNjeTeReModal
                open={visible}
                setOpen={setVisible}
                // socketHandler={socketHandler}
                socketHandler={() => {}}
              />
            ) : null}
          </div>
          {tableData?.length > 0 ? (
            filterActive === false ? (
              toggle === true ? (
                <DragContainer
                  floatingHeader={true}
                  view={"settingsFitimet"}
                  dynamicMap={tableData}
                />
              ) : (
                <Row className="row-search">
                  {tableData?.map((Data) => (
                    <Col key={Data.earningId}>
                      <div className="card-fitimet" key={Data.earningId}>
                        <Card
                          key={Data.earningId}
                          className={isDarkMode ? "dark" : ""}
                          height="100%"
                          title={Data.earningName}
                          bordered={false}
                        >
                          {toBeEdited === Data?.earningId ? (
                            <FitimetEditCard
                              id={Data.earningId}
                              Data={Data}
                              handleHideEditView={handleHideEditView}
                              socketHandler={() => {}}
                              // socketHandler={socketHandler}
                            />
                          ) : (
                            <FitimetViewCard
                              id={Data.earningId}
                              handleShowEditView={handleShowEditView}
                              Data={Data}
                            />
                          )}
                        </Card>
                      </div>
                    </Col>
                  ))}
                </Row>
              )
            ) : (
              <Row className="row-search">
                {searchTerm.map((Data) => (
                  <Col
                    className="search-col"
                    style={{
                      width: "20%",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    key={Data.earningId}
                  >
                    <div className="card-fitimet" key={Data.earningId}>
                      <Card
                        key={Data.earningId}
                        height="100%"
                        title={Data.earningName}
                        bordered={false}
                      >
                        {toBeEdited === Data?.earningId ? (
                          <FitimetEditCard
                            id={Data.earningId}
                            Data={Data}
                            handleHideEditView={handleHideEditView}
                          />
                        ) : (
                          <FitimetViewCard
                            id={Data.earningId}
                            handleShowEditView={handleShowEditView}
                            Data={Data}
                          />
                        )}
                      </Card>
                    </div>
                  </Col>
                ))}
              </Row>
            )
          ) : (
            <div className="ska-fitime-data">
              Nuk ka asnjë rekord për fitimet e pagave
            </div>
          )}
        </div>
        {logsVisible && (
          <PagatLogs
            isVisible={logsVisible}
            setIsVisible={setLogsVisible}
            data={tableData}
            logsName={"Fitimet"}
          />
        )}
      </FitimetContext.Provider>
    </LoadableComp>
  );
}
export default PagatFitimetSetigns;
