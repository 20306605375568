import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import {
	getIndEmplWagesPieChart,
	getWagesPieChart,
} from "../../../../Pagat/wages/utils/calculations";
import "./EmployeeWageCard.scss";
import { Alert, DatePicker, Space } from "antd";
import { ClearIcon, IconCalendar, NextIcon } from "../../../../dashboard/assets";
const { RangePicker } = DatePicker;

function EmployeeWageCard({ employee }) {
	const { departments } = useSelector((state) => state.departments);
	const { clockings } = useSelector((state) => state.clockings);
	const { employees } = useSelector((state) => state.employeesList);
	const [wageDataSet, setWageDataSet] = useState([]);
	const { wages } = useSelector((state) => state.wages);
	const { employeeRequests } = useSelector((state) => state.employeeRequests);
	const { prepayments } = useSelector((state) => state.prepayments);
	const { programFields } = useSelector((state) => state.programFields);

	useEffect(() => {
		if (
			!!employeeRequests &&
			!!programFields &&
			!!employees &&
			!!clockings &&
			!!wages &&
			!!prepayments
		) {
			setWageDataSet(
				getIndEmplWagesPieChart(
					employee?.employeeId,
					wages,
					clockings,
					employeeRequests,
					employees,
					prepayments,
					programFields
				)
			);
		}
	}, [employees, clockings, programFields, wages, employeeRequests, prepayments]);

	return (
    <div className="employee_wage_card">
      {" "}
      {/* <DatePicker
				onChange={handleChange}
				format={"MMMM YYYY"}
				placeholder="Selekto Muajin" 
				picker="month"
				clearIcon={<ClearIcon />}
				suffixIcon={<IconCalendar />}
			/> */}
      {wageDataSet.length > 0 && wageDataSet[1][1] !== 0 ? (
        <>
          <br />
          <br />
          <Chart
            chartType="PieChart"
            width="100%"
            height="100%"
            data={wageDataSet}
            options={{ chartArea: { width: 350, height: 300 }, is3D: true }}
            // options={{ legend: "none" }}
          />
        </>
      ) : (
        <>
          {Array.from(Array(5)).map((_) => (
            <br />
          ))}
          <Alert
            type="info"
            showIcon
            message="Nuk ka të dhëna page për punonjësin!"
          />
        </>
      )}
    </div>
  );
}

export default EmployeeWageCard;
