import React, { useState, useEffect } from "react";
import SpinningRacket from "../SpinningRacket/SpinningRacket";
import { Modal } from "antd";
import "./SuccessComponent.scss";
import MondayButton from "../MondayButton/MondayButton";
import { useSelector } from "react-redux";

function SuccessComponent({ open, onOk, setOpen, text }) {
  const { isDarkMode } = useSelector(state => state.usersList);

  const onClick = () => {
    setOpen(false);
    if (!!onOk && typeof onOk === "function") {
      onOk();
    }
  };

  return (
    <div>
      <Modal
        className={`success-modal ${isDarkMode ? "darkHeader" : ""}`}
        maskClosable={true}
        closable={false}
        centered={true}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        destroyOnClose={true}
      >
        <SpinningRacket />
        <div className="success-modal-text">
          <span>{text}</span>
        </div>
        <MondayButton className="mondayButtonGreen" onClick={onClick}>
          Ne rregull
        </MondayButton>
      </Modal>
    </div>
  );
}
export default SuccessComponent;
