import { useMemo, useState } from "react";
import FormCard from "../../../Documentation/FormCard/FormCard";
import { Select, message } from "antd";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { formatCategoryName } from "../../View/utils/formatCategoryName";
import { NewIcon } from "../../assets";
import { useProgramFields } from "../../hooks/useProgramFields";
import { useDocumentationContext } from "../../context";
import { createCategory } from "../../utils/dispatchFunctions";
import "./NewCategoryPanel.scss";
import { useSelector } from "react-redux";

const PREVIEW_TITLE = "Emri do të shfaqet këtu...";
const PANEL_TITLE = "Këtu mund të krijoni një kategori.";

const NewCategoryPanel = () => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  const { categories = [], curryDispatch } = useDocumentationContext();

  const programFields = useProgramFields();
  const documentationMapping = programFields?.["Documentation Mapping"];

  const [categoryTitle, setCategoryTitle] = useState("");
  const [previewCardTitle, setPreviewCardTitle] = useState(PREVIEW_TITLE);
  const [saving, setSaving] = useState(false);

  const availableCategories = useMemo(() => {
    return Object.keys(documentationMapping || {})
      .filter(
        (name) =>
          !categories.map(({ categoryName }) => categoryName).includes(name)
      )
      .map((name) => ({
        label: formatCategoryName(name),
        value: formatCategoryName(name),
      }));
  }, [documentationMapping, categories]);

  const onNameSelect = (value = "") => {
    setPreviewCardTitle(value || PREVIEW_TITLE);
    setCategoryTitle(value);
  };

  //* creates categories
  const onCreate = async () => {
    if (!!categoryTitle) {
      const formattedCategoryTitle = formatCategoryName(categoryTitle);
      if (
        !!categories
          ?.map(({ categoryName = "" }) => categoryName.toLowerCase())
          ?.includes(formattedCategoryTitle.toLowerCase())
      ) {
        message.warning("Ekziston një kategori ekzistuese me këtë emër!");
      } else {
        setSaving(true);
        await curryDispatch(createCategory(formattedCategoryTitle));
        setSaving(false);
        setCategoryTitle("");
        setPreviewCardTitle(PREVIEW_TITLE);
      }
    }
  };

  return (
    <div className={`docConfigNewCategoryPanel ${isDarkMode ? "dark" : ""}`}>
      <div className="panelTitle">{PANEL_TITLE}</div>
      <div className="cardsContainer">
        <FormCard
          className="newCategoryCard"
          childrenClassName="fieldsContainer"
        >
          <Select
            {...{
              placeholder: "Emërtojeni këtu...",
              onSelect: onNameSelect,
              onPressEnter: onCreate,
              options: availableCategories,
              popupClassName: isDarkMode ? "darkDropdown" : "",
            }}
            {...(!!categoryTitle ? { value: categoryTitle } : {})}
          />
          <MondayButton
            className="createButton"
            disabled={saving}
            onClick={onCreate}
            Icon={<NewIcon />}
          >
            Krijo
          </MondayButton>
        </FormCard>
      </div>
    </div>
  );
};

export default NewCategoryPanel;
