import defaultDayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjsBusinessDays from "dayjs-business-days";
import weekOfYear from "dayjs/plugin/weekOfYear";
import "dayjs/locale/sq";

defaultDayjs.locale("sq");
defaultDayjs.extend(utc);
defaultDayjs.extend(timezone);
defaultDayjs.extend(customParseFormat);
defaultDayjs.extend(duration);
defaultDayjs.extend(relativeTime);
defaultDayjs.extend(isSameOrBefore);
defaultDayjs.extend(isBetween);
defaultDayjs.extend(isSameOrAfter);
defaultDayjs.extend(weekday);
defaultDayjs.extend(weekOfYear);
defaultDayjs.extend(localizedFormat);
defaultDayjs.extend(dayjsBusinessDays);

const guessedTimeZone = defaultDayjs.tz.guess();

function getLastSunday(year, month) {
  let lastDay = new Date(year, month + 1, 0);
  let dayOfWeek = lastDay.getDay();
  let lastSunday = new Date(lastDay);
  lastSunday.setDate(lastDay.getDate() - dayOfWeek);

  return lastSunday;
}

const getOffsetTz = (...args) => {
  const date = defaultDayjs(...args);

  const marchIterator = getLastSunday(date.year(), 2);
  const novemberIterator = getLastSunday(date.year(), 10);

  const dateMonth = date.month();
  const marchMonth = new Date(marchIterator).getMonth();
  const novemberMonth = new Date(novemberIterator).getMonth();

  const dateDate = date.date();
  const marchDate = new Date(marchIterator).getDate();
  const novemberDate = new Date(novemberIterator).getDate();

  let isEdt = false;
  if (dateMonth > marchMonth && dateMonth < novemberMonth) {
    isEdt = true;
  } else if (dateMonth === marchMonth) {
    if (dateDate > marchDate) {
      isEdt = true;
    }
  } else if (dateMonth === novemberMonth) {
    if (dateDate < novemberDate) {
      isEdt = true;
    }
  }

  return isEdt ? "Etc/GMT-2" : "Etc/GMT-1";
};

// defaultDayjs.tz.setDefault("America/New_York");
if (!guessedTimeZone.startsWith("Europe/Tirane")) {
  defaultDayjs.tz.setDefault("Europe/Tirane");
}

const dayjsTZ = (...args) => {
  return defaultDayjs(...args).tz(getOffsetTz(...args));
  return defaultDayjs(...args).tz();
};

const timezonedUnix = (value) => {
  return defaultDayjs.unix(value).tz(getOffsetTz(value));
  return defaultDayjs.unix(value).tz();
};

dayjsTZ.unix = timezonedUnix;

export default dayjsTZ;
