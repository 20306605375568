import colorShade from "../../../../utils/colorShade";
import dayjsTZ from "../../../../utils/dayjs";
import getAgThemePreference from "../../../../utils/getAgThemePreference";

const { mode, accentColor } = getAgThemePreference();

export const colDefs = ({ navigate }) => [
  {
    headerName: "Punonjësi",
    field: "employeeFullName",
    filter: "agTextColumnFilter",
    flex: 2,
    mobile: true,
    isCollapseTitle: true,
    cellClickKey: "employeeId",
    isExported: true,
    cellRenderer: ({ data }) => (
      <div
        className="navigate-row"
        onClick={() =>
          navigate(`/konfigurimet/perdoruesit/${data?.identityId}`)
        }
        style={
          mode.includes("dark")
            ? { color: "#FFF" }
            : { color: colorShade(accentColor, 0.25) }
        }
      >
        {data?.employeeFullName}
      </div>
    ),
  },
  {
    headerName: "Gjinia",
    field: "gender",
    filter: "agTextColumnFilter",
    flex: 2,
    mobile: true,
    isExported: true,
  },
  {
    headerName: "Email",
    field: "email",
    filter: "agTextColumnFilter",
    flex: 2,
    mobile: true,
    isExported: true,
  },
  {
    headerName: "Lloji i userit",
    field: "userRole",
    filter: "agTextColumnFilter",
    mobile: true,
    isExported: true,
    flex: 2,
  },
  {
    headerName: "Departamenti",
    field: "department",
    filter: "agTextColumnFilter",
    mobile: true,
    isExported: true,
    flex: 3,
    onCellClick: () => navigate(`/departamentet`),
  },
  {
    headerName: "Pozicioni",
    field: "departmentRole",
    filter: "agTextColumnFilter",
    flex: 2,
    mobile: true,
    isExported: true,
    onCellClick: () => navigate(`/departamentet`),
  },
  {
    headerName: "Nr.Tel",
    field: "phone_number",
    filter: "agTextColumnFilter",
    flex: 2,
    mobile: true,
    isExported: true,
  },
  {
    headerName: "Krijuar në",
    field: "createdAt",
    filter: "agTextColumnFilter",
    flex: 2,
    mobile: true,
    isExported: true,
    format: "DD/MM/YYYY",
    cellRenderer: ({ data }) => {
      try {
        return dayjsTZ(data?.createdAt).format("DD/MM/YYYY");
      } catch (err) {
        console.error("createdAt--err");
      }
    },
  },
];
