import { Col, Popconfirm, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { GoBack, IconDil, LogoFlex } from "../../../assets/icons";
import DynamicHeaderTitle from "../DynamicHeaderTitle";
import ThemeToggle from "./ThemeToggle";
import { useSelector } from "react-redux";

function KonfigurimetHeader({ handleLogOut }) {
  const { isDarkMode } = useSelector((state) => state.usersList);

  return (
    <Row
      className="header-row-konfigurimet"
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: 30,
        paddingRight: 5,
        background: isDarkMode ? "#121212" : "#1d3445",
      }}
    >
      <Col
        span={3}
        style={{ justifyContent: "flex-start", height: "100%" }}
      ></Col>
      <Col
        className="konfig-header"
        span={16}
        style={{
          justifyContent: "center",
          display: "flex",
          height: "100%",
        }}
      >
        <DynamicHeaderTitle />
      </Col>
      <Col
        span={5}
        style={{
          justifyContent: "flex-end",
          display: "flex",
          height: "100%",
        }}
      >
        <div className="icons-div">
          <div className="icons-kryefaqja">
            <Link to="/kryefaqja">
              <GoBack />
            </Link>
          </div>
          <div className="icons-kryefaqja">
            <ThemeToggle fill="#232323" />
          </div>
          <div className="icons-kryefaqja">
            <Popconfirm
              placement="left"
              title={"A jeni të sigurt që dëshironi të dilni nga programi?"}
              onConfirm={handleLogOut}
              okText="Po"
              cancelText="Jo"
              classNames={{
                root: `popup-logout-modal globalPopover ${
                  isDarkMode ? "dark" : ""
                }`,
              }}
              icon={<LogoFlex width={45} height={45} />}
            >
              <div className="icons-kryefaqja">
                <IconDil />
              </div>
            </Popconfirm>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default KonfigurimetHeader;
