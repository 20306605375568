import { Card, Checkbox, Col } from "antd";
import React from "react";

function CardComonentFitimet({ data }) {
  return (
    <Col key={data?.data?.earningId}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        key={data?.data?.earningId}
        // title={data?.earningName}
        bordered={false}
      >
        <div className="Fitimet-view-card">
          <div>
            <h3 style={{ width: "100%" }}>Lloji i fitimit:</h3>
            <div className="krijo-data">{data?.data?.earningType}</div>
          </div>

          <div>
            <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
            <div className="krijo-data">{data?.data?.earningFormula}</div>
          </div>
          <div>
            <h3>Raporti:</h3>
            <div className="krijo-data">{data?.data?.earningRate}</div>
          </div>
          <div>
            <h3>Rregulli:</h3>
            <div className="krijo-data">{data?.data?.rateFactor}</div>
          </div>
          <div>
            {/* <h3>Vlera:</h3>
                <div className="krijo-data">{data.earningAmount}</div> */}
          </div>

          <Checkbox
            defaultChecked={data?.data?.excludeFromTotalHours}
            className="festat-zyrtare-checkbox"
            style={{ width: "100%" }}
          >
            <h3 style={{ width: 222 }}>
              Përjashtoje në shfaqjen e orëve totale
            </h3>
          </Checkbox>
        </div>
      </div>
    </Col>
  );
}

export default CardComonentFitimet;
