import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import dayjsTZ from "../../../../../utils/dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { InfoBluecIcon } from "../../../../../assets/icons";
import { useAuth } from "../../../../../authentication/authHelpers";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";
import { notificationToAllAdminAndSecifikUser } from "../../../../../utils/sendNotification";
import { getAllUsers } from "../../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
import DetailsOvertimeModal from "../../../UserDashboard/OvertimeShift/DetailsOvertimeModal";
import "./WorkShiftAdminCard.scss";

const WorkShiftAdminCard = () => {
  const { users, isDarkMode } = useSelector((state) => state.usersList);
  
  const [workshift, setWorkShift] = useState([]);
  const [specificData, setSpecificData] = useState([]);
  const [open, setOpen] = useState(false);
  const { employee } = useAuth();
  const headerData = [
    "Emri i punonjësit",
    "Data e krijimit",
    "Kërkesë për",
    "Statusi",
    "Ora e fillimit",
    "Ora e mbarimit",
    "Lloji i kërkesës",
  ];

  const getColor = {
    Aprovuar: "#1da193",
    Refuzuar: "#ea3943",
    "Në pritje": "#FCC94A",
  };

  const onApprove = async (status) => {
    let editStatus = {
      requestStatus: status,
    };
    message.loading({ content: "Duke u ndryshuar...", duration: 1.5 });
    await API.put(
      "employeeOvertime",
      `/employeeOvertime/${specificData?.overtimeId}`,
      {
        body: editStatus,
      }
    )
      .then(() => {
        setWorkShift((r) => {
          return { ...r, editStatus };
        });
        console.log("workshift", workshift);
        message.success("Statusi u ndryshua me sukses!");
        setOpen(false);
        if (!!users && users.allUsers.Items.length > 0) {
          notificationToAllAdminAndSecifikUser(
            {
              notificationTitle: `${
                status === "Refuzuar" ? "Refuzim" : "Aprovim"
              } leje.`,
              createdAt: dayjsTZ().format("DD/MM/YYYY"),
              notificationPriority: false,
              notificationBody: `${employee?.employeeFirstName} ${
                employee?.employeeLastName
              } ${
                status === "Refuzuar" ? "refuzoi" : "aprovoi"
              } lejen e orëve shtesë për ${specificData?.given_name} ${
                specificData?.family_name
              } në datën ${dayjsTZ(specificData?.overtimeDay).format(
                "DD/MM/YYYY"
              )}.`,
              isAnnouncement: false,
              notificationUrl: "/kryefaqja",
              notificationCategory: "Critical",
              notificationFirstName: employee?.employeeFirstName,
              notificationLastName: employee?.employeeLastName,
            },
            users?.allUsers?.Items?.filter(
              (el) =>
                el?.userSub === specificData?.userSub ||
                el?.userRole === "Admin" ||
                el?.userRole === "Super Admin"
            ),
            users?.allUsers?.Items?.find(
              (el) => el?.userSub === specificData?.userSub
            )
          );
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Ndodhi nje gabim");
      });
  };

  const getEmployeeOvertime = async () => {
    await API.get("employeeOvertime", "/employeeOvertime").then((e) =>
      setWorkShift(e)
    );
  };

  useEffect(() => {
    getEmployeeOvertime();
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <div className={`workShiftAdminCardWrapper ${isDarkMode ? "dark" : ""}`}>
      {workshift?.records?.length > 0 ? (
        isDesktopOrLaptop ? (
          <>
            <div className="headerDataWorkShift">
              {headerData?.map((el) => {
                return (
                  <div key={el} className="header-shift-item">
                    {el}
                  </div>
                );
              })}
            </div>
            <div className="overtimeWorkshift">
              {workshift?.records?.map((el, i) => {
                return (
                  <div key={el?.overtimeId} className="shift-overtime">
                    <div
                      style={{ width: 145 }}
                    >{`${el?.given_name} ${el?.family_name}`}</div>
                    <div style={{ width: 115 }}>
                      {dayjsTZ(el?.createdAt).format("DD/MM/YYYY")}
                    </div>
                    <div style={{ width: 85 }}>
                      {dayjsTZ(el?.overtimeDay).format("DD/MM/YYYY")}
                    </div>
                    <div
                      onClick={() => {
                        return setOpen(true), setSpecificData(el);
                      }}
                      style={{
                        width: 80,
                        height: "80%",
                        cursor: "pointer",
                        backgroundColor: getColor[el?.requestStatus],
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        borderRadius: 5,
                      }}
                    >
                      {el?.requestStatus}
                    </div>
                    <div style={{ width: 135, paddingLeft: 30 }}>
                      {el?.requestPeriod[0]}
                    </div>
                    <div style={{ width: 115 }}>{el?.requestPeriod[1]}</div>
                    <div>{el?.requestType}</div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <MobileHtmlTabel
            header={headerData}
            tableData={workshift?.records?.map?.(
              ({
                given_name,
                family_name,
                createdAt,
                overtimeDay,
                requestStatus,
                requestPeriod,
                requestType,
              }) => ({
                Emri: `${given_name} ${family_name}`,
                krijuar: dayjsTZ(createdAt).format("DD/MM/YYYY"),
                kerkesa: dayjsTZ(overtimeDay).format("DD/MM/YYYY"),
                requestStatus,
                oraFillimit: requestPeriod[0],
                oraMbarimit: requestPeriod[1],
                requestType,
              })
            )}
            isLink={false}
            isHeader={false}
          />
        )
      ) : (
        <div className="admin-noOvertime">
          <InfoBluecIcon
            style={{
              width: 52,
              height: 52,
            }}
          />
          <div className="admin-noOvertimeText">
            Nuk ka kërkesë për orë shtesë.
          </div>
          <div className="admin-noOvertimeText-text">
            Përshëndetje <strong>{employee?.employeeFirstName}</strong>,
            aktualisht asnjë punonjës nuk ka derguar kërkesë për orë shtesë.
          </div>
        </div>
      )}
      <DetailsOvertimeModal {...{ open, setOpen, specificData, onApprove }} />
    </div>
  );
};

export default WorkShiftAdminCard;
