import { useSelector } from "react-redux";
import "./ViewLogoHeader.scss";

const ViewLogoHeader = ({ icon, title, subtitle, className = "" }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);
  return (
    <div
      className={`docConfigLogoHeader ${
        isDarkMode ? "dark" : ""
      } ${className}`.trim()}
    >
      <img src={icon} className="logo" alt="" />
      <div className="labelContainer">
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default ViewLogoHeader;
