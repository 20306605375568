import { xorWith, isEqual, isEmpty } from "lodash";

const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const getObjectChanges = (originalObject = {}, updatedObject = {}) =>
  Object.keys(updatedObject).reduce(
    (acc, currKey) =>
      !!Object.keys(originalObject).includes(currKey) &&
      (Array.isArray(originalObject[currKey])
        ? isArrayEqual(originalObject[currKey], updatedObject[currKey])
        : isEqual(originalObject[currKey], updatedObject[currKey]))
        ? acc
        : { ...acc, [currKey]: updatedObject[currKey] },
    {}
  );
