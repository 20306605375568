import { useEffect, useMemo, useState } from "react";
import AddLeaveSendEmail from "../AddLeaveSendEmail/AddLeaveSendEmail";
import "./RequestModal.scss";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import { QuestionsMark } from "../../../../../Intervistat/Kandidatet/ShtoKandidat/Image";
import { ModalXIcon, RequestChair } from "../../../../../../assets/icons";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";
import dayjsTZ from "../../../../../../utils/dayjs";
import { RichTextEditor } from "../../../../../commonComponents";
import { getObjectChanges, htmlParser } from "../../../../../../utils";
import { API } from "aws-amplify";
import { useAuth } from "../../../../../../authentication/authHelpers";
import { notificationUserToAdmin } from "../../../../../../utils/sendNotification";
import { useDispatch } from "react-redux";
import { employeeRequests as employeeRequestsReducer } from "../../../../../../store/actions";
import { excludeWeekends } from "../LeaveRequests";
import { v4 as uuidv4 } from "uuid";

const { Text } = Typography;
const { Option } = Select;

const REQUEST_TYPES_FIELD_ID = "e480aa5f-a671-4781-a8bb-89876006cc90";

const RequestModal = ({ open, setOpen, request, setRequest }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useAuth();

  const { users, isDarkMode } = useSelector((state) => state.usersList);
  const { clockings } = useSelector((state) => state.clockings);
  const { programFields } = useSelector((state) => state.programFields);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailObject, setEmailObject] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({});
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [spin, setSpin] = useState(false);

  //region ON CANCEL
  const handleCancel = (skipValidation) => {
    const formData = form.getFieldsValue();
    const changes = getObjectChanges(initialFormValues, formData);

    if (!!skipValidation || !Object.keys(changes || {}).length) {
      form.resetFields();
      setRequest({});
      setOpen(false);
    } else {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Text>
            Ju po anuloni{" "}
            {!!Object.keys(request || {}).length ? "ndryshimin" : "krijimin"} e
            një kërkese për leje
            <Space className="space-gap">
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anuloje"</span>
            </Space>
          </Text>
        ),
        okText: "Po, anuloje",
        cancelText: "Jo, mos e anulo",
        width: "660px",
        className: `cancelModal ${isDarkMode ? "dark" : ""}`,
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#ea3943",
            borderColor: "#ea3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          setRequest({});
          setOpen(false);
          form.resetFields();
        },
        onCancel() {},
      });
    }
  };

  const getKeylogs = (originalObj, currentObj) => {
    if (!Object.keys(originalObj || {}).length) return [];
    const changes = getObjectChanges(originalObj, currentObj);

    let constants = {
      activity: "Ndryshuar",
      author: user?.given_name + " " + user?.family_name,
      userSub: user?.userSub,
      changeDate: dayjsTZ().valueOf(),
    };

    const keylogs = [];
    const albKeys = {
      requestType: "LLoji i lejes",
      requestDescription: "Përshkrimi",
      requestPeriod: "Periudha e lejes",
      given_name: "Emri i punonjësit",
      family_name: "Mbiemri i punonjësit",
    };
    const getPeriodLog = (val) =>
      dayjsTZ(val[0]).format("DD/MM/YYYY") +
      " - " +
      dayjsTZ(val[val.length - 1]).format("DD/MM/YYYY");

    Object.entries(changes || {}).forEach(([key, val]) => {
      if (!!albKeys[key]) {
        let log = { ...constants, field: albKeys[key], id: uuidv4() };

        if (key === "requestPeriod") {
          log["oldValue"] = getPeriodLog(originalObj["requestPeriod"]);
          log["newValue"] = getPeriodLog(val);
        } else {
          log["oldValue"] = originalObj[key];
          log["newValue"] = val;
        }
        keylogs.push(log);
      }
    });

    return keylogs;
  };

  //region ON SAVE
  const handleSave = () => {
    form
      .validateFields()
      .then(async (res) => {
        const { startDate, endDate, requestType, requestDescription } =
          res || {};

        let timePeriod = enumerateDaysBetweenDates(startDate, endDate);
        let allFilteredRequests = filteredRequests(employeeRequests);

        if (!!Object.keys(request || {}).length) {
          allFilteredRequests = allFilteredRequests.filter(
            (item) => item.requestId !== request?.requestId
          );
        }
        //checkTimePeriods nje vektor me vlera true ose false, ne varesi nqs
        //periudha kohore e marre nga db ka dite te perbashketa me intervalin e kerkeses se re
        const checkTimePeriods = (allFilteredRequests || []).map((fr) => {
          return checkIfUserHasRequested(fr.requestPeriod, timePeriod);
        });

        const { userSub, family_name, given_name, employeeId } =
          selectedEmployee;

        let toSend = {
          userSub,
          family_name,
          given_name,
          requestType,
          requestPeriod: timePeriod,
          requestDescription,
          creatorIsAdmin: true,
          employeeId,
        };
        toSend["requestLogs"] = [
          ...(request?.requestLogs || []),
          ...getKeylogs(request, { ...toSend }),
        ];

        const idx = activeEmployees.findIndex((el) => el?.userSub === userSub);
        //Nese vektori checkTimePeriods permban nje vlere true
        //dmth qe per kete punonjes, ne njeren nga lejet e tij te meparshme ka dite qe i perkasin
        //intervalit te ri, qe ai kerkon te marre leje
        if (!checkTimePeriods.includes(true)) {
          setSpin(true);

          const notifBody = (action) => ({
            notificationTitle: "Kërkesë për leje",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationPriority: false,
            notificationCategory: "Critical",
            notificationBody: `${user?.given_name}  ${
              user?.family_name
            } ${action} një kërkesë për leje nga data ${dayjsTZ(
              startDate
            ).format("DD/MM/YYYY")} deri në datën ${dayjsTZ(endDate).format(
              "DD/MM/YYYY"
            )} për punonjësin ${given_name} ${family_name}`,
            isAnnouncement: false,
            notificationUrl: "/kryefaqja",
            notificationFirstName: user?.given_name,
            notificationLastName: user?.family_name,
          });
          if (
            !Object.keys(request || {}).length &&
            !!activeEmployees[idx] &&
            (clockings || []).some(
              (el) =>
                el.employeeId === activeEmployees[idx]?.employeeId &&
                (timePeriod || []).some(
                  (d) =>
                    dayjsTZ(d).format("DD/MM/YYYY") ===
                    dayjsTZ(el.clockInDate).format("DD/MM/YYYY")
                )
            )
          ) {
            message.warning({
              key: "requestModal",
              content:
                "Ju nuk mund të shtoni një kërkesë në të njëjtën periudhë me clockIn!",
            });
          } else {
            const call = !!Object.keys(request || {}).length
              ? { api: "put", notif: "ndryshoi", message: "ndryshua" }
              : { api: "post", notif: "bëri", message: "krijua" };

            await API[call.api](
              "employeeRequests",
              `/employeeRequests${
                !!request?.requestId ? `/${request?.requestId}` : ""
              }`,
              {
                body: { ...toSend },
              }
            ).then((res) => {
              message.success({
                key: "requestModal",
                content: `Kërkesa u ${call.message} me sukses!`,
              });
              handleCancel(true);
              updateReduxRequests(res, request?.requestId);

              if (!!(users?.allUsers?.Items || []).length) {
                notificationUserToAdmin(
                  { ...notifBody(call.notif) },
                  users?.allUsers?.Items
                );
                setEmailObject({
                  startDate: startDate,
                  endDate: endDate,
                  user: toSend,
                });
                setOpenEmailModal(true);
              }
            });
          }
        } else {
          message.warning({
            key: "requestModal",
            content:
              "Ju nuk mund të shtoni një kërkesë në të njëjtën periudhë kohore me kërkesat e mëparshme!",
          });
        }
      })
      .catch(() => {
        message.error({
          key: "requestModal",
          content: "Ju lutem plotësoni të gjitha fushat e detyrueshme",
        });
      })
      .finally(() => setSpin(false));
  };

  //region >updateReduxRequests()
  const updateReduxRequests = (obj = {}, id) => {
    let newRequests = [...(employeeRequests || [])];

    if (!!id) {
      const idx = newRequests.findIndex((el) => el?.requestId === id);
      if (idx > -1) {
        newRequests[idx] = {
          ...newRequests[idx],
          ...obj,
        };
      }
    } else {
      newRequests.push(obj);
    }
    dispatch(employeeRequestsReducer(newRequests));
  };

  //region employeesOptions
  const employeesOptions = useMemo(() => {
    if (!Object.keys(request || {}).length) {
      if (!activeEmployees) return [];

      const options = [];
      activeEmployees.forEach((el, idx) => {
        const {
          employeeFirstName: given_name,
          employeeLastName: family_name,
          userSub,
          employeeId,
        } = el || {};
        const value = given_name + " " + family_name;

        options.push(
          <Option
            key={idx}
            {...{ given_name, family_name, userSub, employeeId, value }}
          >
            {value}
          </Option>
        );
      });

      return options;
    }
    return [];
  }, [activeEmployees, request]);

  //region requestOptions
  const requestOptions = useMemo(() => {
    if (!programFields) return [];

    const idx = programFields.findIndex(
      (el) => el?.fieldId === REQUEST_TYPES_FIELD_ID
    );
    if (idx === -1) return [];

    const options = [];
    (programFields[idx].fieldOptions || []).forEach(({ statusTitle }, i) => {
      options.push(
        <Option key={i} value={statusTitle}>
          {statusTitle}
        </Option>
      );
    });

    return options;
  }, [programFields]);

  useEffect(() => {
    if (!!Object.keys(request || {}).length) {
      const {
        requestDescription,
        given_name,
        family_name,
        requestPeriod,
        requestType,
        userSub,
        employeeId,
      } = request;
      const fieldsValue = {
        requestType,
        requestDescription,
        startDate: dayjsTZ(requestPeriod?.[0]),
        employeeName: {
          userSub,
          employeeId,
          given_name,
          family_name,
          value: given_name + " " + family_name,
        },
        endDate: dayjsTZ(requestPeriod[requestPeriod?.length - 1]),
      };
      setSelectedDates({
        startDate: fieldsValue.startDate,
        endDate: fieldsValue.endDate,
      });
      setSelectedEmployee(fieldsValue.employeeName);
      setInitialFormValues(fieldsValue);
      form.setFieldsValue(fieldsValue);
    }
  }, [request]);

  const rules = [
    { required: true, message: "Kjo fushë nuk mund të jetë bosh!" },
  ];

  //region RETURN
  return (
    <>
      <Modal
        className={`requestModal ${
          isDarkMode ? "dark darkHeader" : "lightHeader"
        }`}
        title={`${
          !!Object.keys(request || {}).length
            ? "Ndrysho kërkesën"
            : "Krijo një kërkesë"
        } për leje`}
        open={open}
        centered
        width={800}
        onCancel={handleCancel}
        onClose={handleCancel}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton className="mondayButtonRed" onClick={handleCancel}>
            {Object.keys(request || {}).length ? "Anullo Ndryshimet" : "Anullo"}
          </MondayButton>,
          <MondayButton
            className="mondayButtonGreen"
            spin={spin}
            onClick={handleSave}
          >
            {Object.keys(request || {}).length
              ? "Ndrysho kërkesën"
              : "Krijo kërkesën"}
          </MondayButton>,
        ]}
      >
        <div className="requestModalContent">
          <Form form={form}>
            <Form.Item
              label="Emri i punonjësit"
              name="employeeName"
              rules={rules}
            >
              <Select
                disabled={!!Object.keys(request || {}).length}
                showSearch
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                className="select-details"
                placeholder="Selekto emrin e punonjësit"
                onChange={(_, options) => setSelectedEmployee(options)}
              >
                {employeesOptions}
              </Select>
            </Form.Item>
            <Form.Item label="Lloji i lejes" name="requestType" rules={rules}>
              <Select
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                className="select-details"
                placeholder="Selekto llojin e lejes"
              >
                {requestOptions}
              </Select>
            </Form.Item>
            <Form.Item label="Data e fillimit" name="startDate" rules={rules}>
              <DatePicker
                format={"DD/MM/YYYY"}
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                disabled={
                  dayjsTZ().startOf("day").valueOf() >
                  request?.requestPeriod?.[0]
                }
                placeholder="Selekto daten e fillimit"
                className="my-picker"
                disabledDate={(current) => {
                  return dayjsTZ().add(-1, "days") >= current;
                }}
                onChange={(val) => {
                  setSelectedDates((prev) => ({
                    ...(prev || {}),
                    startDate: val,
                  }));
                  form.setFieldValue("endDate", null);
                }}
              />
            </Form.Item>
            <Form.Item label="Data e mbarimit" name="endDate" rules={rules}>
              <DatePicker
                format={"DD/MM/YYYY"}
                placeholder="Selekto datën e mbarimit"
                popupClassName={isDarkMode ? "darkDropdown" : ""}
                className="my-picker"
                onChange={(val) =>
                  setSelectedDates((prev) => ({
                    ...(prev || {}),
                    endDate: val,
                  }))
                }
                disabledDate={(current) => {
                  return dayjsTZ(selectedDates?.startDate) > current;
                }}
              />
            </Form.Item>
            {!!selectedDates?.startDate && !!selectedDates?.endDate ? (
              <Form.Item label="Ditë pa fundjavë">
                <Input
                  disabled
                  value={
                    !!selectedDates?.startDate && !!selectedDates?.endDate
                      ? `${excludeWeekends(selectedDates)} ditë`
                      : ""
                  }
                />
              </Form.Item>
            ) : null}
            <Form.Item
              label="Përshkrimi"
              name="requestDescription"
              rules={rules}
            >
              <RichTextEditor className="request-text" />
            </Form.Item>
          </Form>
          <RequestChair className="chair-icon" width={400} />
        </div>
      </Modal>
      {openEmailModal ? (
        <AddLeaveSendEmail
          open={openEmailModal}
          setOpen={setOpenEmailModal}
          emailObject={emailObject}
          setEmailObject={setEmailObject}
        />
      ) : null}
    </>
  );
};

export default RequestModal;

//region >enumerateDaysBetweenDates()
var enumerateDaysBetweenDates = function (startDate, endDate) {
  var dates = [];
  let currDate = startDate.startOf("day");
  let lastDate = endDate.startOf("day");

  while (currDate.diff(lastDate, "days", true) <= 0) {
    dates.push(currDate.clone().valueOf());
    currDate = currDate.add(1, "day");
  }
  return dates;
};
//region >filteredRequests()
const filteredRequests = (allRequestsData, userSub) => {
  let filteredReq = allRequestsData?.filter(
    (req) => req?.requestPeriod?.slice(-1)[0] >= dayjsTZ().valueOf()
  );
  return filteredReq?.filter((rec) => rec?.userSub === userSub);
};
//region >checkIfUserHasRequested()
const checkIfUserHasRequested = (existingPeriod, newPeriod) => {
  const contains = existingPeriod.some((element) => {
    return newPeriod.includes(element);
  });
  return contains;
};
