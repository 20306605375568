import CardComponent from "../../../../Konfigurimet/KompaniaSettings/KompaniaPozicionet/CardComponent";
import { iconPickerDep } from "../../../../Konfigurimet/KompaniaSettings/KompaniaPozicionet/iconPickerDep";
import { iconPicker } from "../../AdminCards/ClockInStats/iconPicker";
import TotalEmployeeNumber from "../../TotalEmployeeNumber";
import ComponentCard from "../ComponentCard";

const defaultData = {
  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false,
};
export const saved = [
  {
    i: 0,
    data: {
      name: "TotalEmployee",
      emri: "Punonjësit total",
      src: ComponentCard,
      content: TotalEmployeeNumber,
      parent: true,
      // data: cardData[0],
      hidden: false,
    },
    ...defaultData,
  },
];

const proccessMap = (dynamicMap, fill) => {
  let a = 0;
  let b = 0;
  console.log(fill)

  return dynamicMap.map((el, index) => {
    if (a === 4) {
      a = 0;
      b++;
    }
    const result = {
      i: `${index}`,
      ...defaultData,
      data: {
        name: el.departmentName,
        emri: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            {iconPicker(el.departmentName, fill)} &nbsp; {el.departmentName}
          </div>
        ),
        src: CardComponent,
        parent: false,
        data: el,
        hidden: false,
      },
      x: a,
      y: b,
    };

    a++;

    return result;
  });
};

export const settingsPozicionet = (dynamicMap, _, fill) => {
  let processedMap = proccessMap(dynamicMap, fill);
  const ls = JSON.parse(localStorage.getItem("draggableSetingsPozicionet"));

  if (ls !== null) {
    let toReturn = [];
    let toLocalStorage = [];

    ls.forEach((el) => {
      let idx = processedMap.findIndex((l, x) => l.i === el.i);

      if (idx !== -1) {
        let item = {
          ...processedMap[idx],
          data: { ...processedMap[idx].data },
          x: ls[idx].x,
          y: ls[idx].y,
          h: ls[idx].h,
          w: ls[idx].w,
          minW: ls[idx].minW,
          minH: ls[idx].minH,
        };

        toReturn.push({ ...item });
        toLocalStorage.push({
          ...item,
          data: { ...(item?.data || {}), data: {} },
        });
      }
    });

    const updateLs = ls.some((el) => !!Object.keys(el?.data?.data));
    if (!!updateLs) {
      console.log("updated: draggableSetingsPozicionet");

      localStorage.setItem(
        "draggableSetingsPozicionet",
        JSON.stringify(toLocalStorage)
      );
    }

    return toReturn;
  } else if (ls === null) {
    let toLocalStorage = [];
    (processedMap || []).forEach((el) => {
      const item = { ...el, data: { ...(el?.data || {}), data: {} } };
      toLocalStorage.push(item);
    });

    console.log("updated: draggableSetingsPozicionet");
    localStorage.setItem(
      "draggableSetingsPozicionet",
      JSON.stringify(toLocalStorage)
    );
    return processedMap;
  }
};

const swap = (theArray, indexA, indexB) => {
  var temp = theArray[indexA];
  theArray[indexA] = theArray[indexB];
  theArray[indexB] = temp;
};
