import React from "react";
import { Modal } from "antd";
import "./statusModal.scss";
import { useSelector } from "react-redux";

const StatusModal = ({ isModalVisible, setIsModalVisible, dataLista }) => {
  const { isDarkMode } = useSelector((state) => state.usersList);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        className={`request-status-modal ${
          isDarkMode ? "dark darkHeader" : "lightHeader"
        }`}
        title="Statuset e kërkesave"
        open={isModalVisible}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[null]}
      >
        {dataLista?.statusName?.map((data) => {
          return (
            <div
              className="modal-status-text"
              style={{ background: data.color }}
            >
              {data.name}
            </div>
          );
        })}
      </Modal>
    </div>
  );
};

export default StatusModal;
