import React, { useState, useEffect } from "react";
import { Row, Col, message, Popover, Alert } from "antd";
import { FlexLogo, IconPerdoruesi, IconEmail } from "../../assets/icons";
import "./auth-scss/forgotPassword.scss";
import { Form, Input, Button } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { emailRegex, passwordRegex } from "../dashboard/utils/regex";
import { EyeFilled, InfoCircleOutlined, LockFilled } from "@ant-design/icons";
import swal from "sweetalert";
import { useAuth } from "../../authentication/authHelpers";
import MondayButton from "../commonComponents/MondayButton/MondayButton";

function ResetPassword() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [spin, setSpin] = useState(false);

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);
    setSpin(true);
    let identityId = auth?.userAccess[0]?.identityId;
    let forceReset = auth?.userAccess[0]?.forceReset;

    if (!!identityId && forceReset === true) {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            values?.oldPassword,
            values?.confirmPassword
          );
        })
        .then(async () => {
          await API.put("users", `/users/${identityId}`, {
            body: { forceReset: false },
          }).then(() => {
            message.success("Fjalëkalimi u ndryshua me sukses!");
            navigate("/kryefaqja");
          });
        })
        .catch((err) => {
          message.error("Ndodhi një gabim në ndryshimin e fjalëkalimit!");
          console.log(err);
        })
        .finally(() => setSpin(false));
    }

    // await Auth.forgotPassword(values.username).then(
    //   (result) => {
    //     message.success("Kodi i sigurisë është dërguar në emailin tuaj. ");
    //     //   console.log("result ", result);
    //     setSentCode(true);
    //   },
    //   (err) => {
    //     console.log("error sending code", err);
    //     message.error("Nodhi një gabim!");
    //   }
    // );
    // Auth.changePassword();
  };

  useEffect(() => {
    let resetIndic = auth?.userAccess[0]?.forceReset;
    if (resetIndic !== true) {
      navigate("/kryefaqja");
    }
  }, [auth]);

  return (
    <div className="forgot-password">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Alert
          message="Fjalëkalimi juaj duhet të ketë të paktën 8 karaktere, një shkronjë të vogël, një shkronjë të madhe, dhe një karakter të veçantë."
          type="warning"
          banner
          style={{ margin: "0 30px" }}
        />
        <br />
        <Form.Item
          name="oldPassword"
          rules={[
            {
              required: true,
              pattern: new RegExp(passwordRegex),
              message:
                "Ju lutem vendosni një fjalëkalim që zbaton rregullat e sigurisë.",
            },
          ]}
        >
          <Input.Password
            placeholder="Fjalëkalimi i vjetër"
            prefix={<LockFilled style={{ height: 25 }} />}
            autoComplete="new-password"
            iconRender={(visible) => (visible ? <EyeFilled /> : <EyeFilled />)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              pattern: new RegExp(passwordRegex),
              message:
                "Ju lutem vendosni një fjalëkalim që zbaton rregullat e sigurisë.",
            },
          ]}
        >
          <Input.Password
            placeholder="Fjalëkalimi i ri"
            prefix={<LockFilled style={{ height: 25 }} />}
            autoComplete="new-password"
            iconRender={(visible) => (visible ? <EyeFilled /> : <EyeFilled />)}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          // hasFeedback
          rules={[
            {
              required: true,
              message: "Ju lutem, konfirmoni fjalëkalimin e ri!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("Fjalekalimet nuk bashkangjiten!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Konfirmo Fjalëkalimin i ri"
            prefix={<LockFilled style={{ height: 25 }} />}
            autoComplete="new-password"
            iconRender={(visible) => (visible ? <EyeFilled /> : <EyeFilled />)}
          />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Link to="/login">
              <MondayButton className="mondayButtonRed">
                Kthehu pas
              </MondayButton>
            </Link>
            <MondayButton
              type="primary"
              htmlType="submit"
              className={"mondayButtonGreen"}
              spin={spin}
            >
              Ndrysho
            </MondayButton>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ResetPassword;
